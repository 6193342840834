import { request } from '../../../shared/api'

import { formatCustomReportsAddEditBody } from './lib'

import type { AxiosError, AxiosResponse } from 'axios'
import type {
  CustomReportsAddEditBody,
  CustomReportsGroupsResponse, CustomReportsManageGroupsBody,
  CustomReportsResponse,
} from './types'

// Reports
export const getReports = (query: URLSearchParams): Promise<CustomReportsResponse | AxiosError> =>
  request.get('/custom-reports/reports/get', { params: query })

export const addReport = (body: CustomReportsAddEditBody): Promise<AxiosResponse | AxiosError> =>
  request.post('/custom-reports/reports/add', { ...formatCustomReportsAddEditBody(body) })

export const updateReport = (body: CustomReportsAddEditBody): Promise<AxiosResponse | AxiosError> =>
  request.post('/custom-reports/reports/update', { ...formatCustomReportsAddEditBody(body) })

export const deleteReport = (query: URLSearchParams): Promise<AxiosResponse | AxiosError> =>
  request.delete('/custom-reports/reports/delete', { params: query })

export const getPayloadFromLink = (url: string): Promise<AxiosResponse | AxiosError> =>
  request.get('/custom-reports/reports/get-payload-from-link', { params: { url } })

// Groups
export const getGroups = (): Promise<CustomReportsGroupsResponse | AxiosError> =>
  request.get('/custom-reports/groups/get', {})

export const addGroup = (data: CustomReportsManageGroupsBody): Promise<AxiosResponse | AxiosError> =>
  request.post('/custom-reports/groups/add', { ...data })

export const updateGroup = (data: CustomReportsManageGroupsBody): Promise<AxiosResponse | AxiosError> =>
  request.post('/custom-reports/groups/update', { ...data })

export const deleteGroup = (query: URLSearchParams): Promise<AxiosResponse | AxiosError> =>
  request.delete('/custom-reports/groups/delete', { params: query })
