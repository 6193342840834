import { request } from '../../../shared/api'

import type { SettingsMembersListData, SettingsMembersListResponse } from './types'
import type { AxiosError, AxiosResponse } from 'axios'

export const getMembersList = (): Promise<SettingsMembersListResponse | AxiosError> =>
  request.get('/users/request-users')

export const deleteMember = (email: SettingsMembersListData['email']): Promise<AxiosResponse | AxiosError> =>
  request.delete('/users/delete-user', { data: { email } })

export const sendInvitation = (email: SettingsMembersListData['email']): Promise<AxiosResponse | AxiosError> =>
  request.post('/users/add-to-team', { email })

export const resendInvitation = (email: SettingsMembersListData['email']): Promise<AxiosResponse | AxiosError> =>
  request.post('/users/resend-verification', { email })
