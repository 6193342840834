import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { AnnualProgressData, CountriesData, GroupProgressData, NetworksData, WeekProgressData } from './types'
import {
  getAnnualProgressList,
  getCountriesDataList,
  getGroupsProgressList,
  getNetworksList,
  getWeekProgressList,
} from './api'

export type IDashboardStoreProps = {
  weekProgress: WeekProgressData[] | null,
  weekIsLoading: boolean,
  weekHasError: string | null,
  getWeekProgress: () => Promise<void>,

  groupProgress: GroupProgressData | null,
  groupIsLoading: boolean,
  groupHasError: string | null,
  getGroupsProgress: (search: URLSearchParams) => Promise<void>,

  annualProgress: AnnualProgressData[] | null,
  annualIsLoading: boolean,
  annualHasError: string | null,
  getAnnualProgress: () => Promise<void>,

  networks: NetworksData[] | null,
  networksIsLoading: boolean,
  networksHasError: string | null,
  getNetworksList: () => Promise<void>,

  countries: CountriesData[] | null,
  countriesIsLoading: boolean,
  countriesHasError: string | null,
  getCountriesList: () => Promise<void>,
}

export const useDashboardStore = create<IDashboardStoreProps>((set, get) => ({
  weekProgress: null,
  weekIsLoading: false,
  weekHasError: null,
  getWeekProgress: async () => {
    try {
      set({ weekProgress: null })
      set({ weekIsLoading: true })
      set({ weekHasError: null })

      const response = await getWeekProgressList()
      if (!axios.isAxiosError(response)) {
        const data = response.data
        set({ weekProgress: data })
      }
    } catch (error) {
      let errorText = t('dashboard:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ weekHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ weekIsLoading: false })
    }
  },

  groupProgress: null,
  groupIsLoading: false,
  groupHasError: null,
  getGroupsProgress: async (search: URLSearchParams) => {
    try {
      set({ groupProgress: null })
      set({ groupIsLoading: true })
      set({ groupHasError: null })

      const response = await getGroupsProgressList(search)
      if (!axios.isAxiosError(response)) {
        const data = response.data

        set({ groupProgress: data })
      }
    } catch (error) {
      let errorText = t('dashboard:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ groupHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ groupIsLoading: false })
    }
  },

  annualProgress: null,
  annualIsLoading: false,
  annualHasError: null,
  getAnnualProgress: async () => {
    try {
      set({ annualProgress: null })
      set({ annualIsLoading: true })
      set({ annualHasError: null })

      const response = await getAnnualProgressList()
      if (!axios.isAxiosError(response)) {
        set({ annualProgress: response.data })
      }
    } catch (error) {
      let errorText = t('dashboard:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ annualHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ annualIsLoading: false })
    }
  },

  networks: null,
  networksIsLoading: false,
  networksHasError: null,
  getNetworksList: async () => {
    try {
      set({ networks: null })
      set({ networksIsLoading: true })
      set({ networksHasError: null })

      const response = await getNetworksList()
      if (!axios.isAxiosError(response)) {
        set({ networks: response.data })
      }
    } catch (error) {
      let errorText = t('dashboard:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ networksHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message

        throw errorText
      }
    } finally {
      set({ networksIsLoading: false })
    }

  },

  countries: null,
  countriesIsLoading: false,
  countriesHasError: null,
  getCountriesList: async () => {
    try {
      set({ countries: null })
      set({ countriesIsLoading: true })
      set({ countriesHasError: null })

      const response = await getCountriesDataList()
      if (!axios.isAxiosError(response)) {
        set({ countries: response.data })
      }
    } catch (error) {
      let errorText = t('dashboard:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ countriesHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message

        throw errorText
      }
    } finally {
      set({ countriesIsLoading: false })
    }

  },
}))