import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import { Button } from '../../../shared/ui/button'

export const ResetAllFilters = () => {
  const [ disabled, setDisabled ] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const locationHasFilters = location?.search.length > 0
  const locationPath = location?.pathname

  const handleOnClick = () => {
    navigate(locationPath)
  }

  // Update disabled if filters count changes
  useEffect(() => {
    setDisabled(!locationHasFilters)
  }, [locationHasFilters])

  return (
    <>
      <Button
        disabled={ disabled }
        onClick={ handleOnClick }
        size={ 'small' }
        variant={ 'contained' }
        color={'warning'}
        type={ 'reset' }
      >
        { t('domains-watcher:Reset filters') }
      </Button>
    </>
  )
}