export const getFormattedFullDate = (stamp: string) => {
  const s = new Date(stamp)
  let date = s.getUTCDate(),
    month = s.getUTCMonth() + 1,
    year = s.getUTCFullYear()

  const newDate = (date < 10) ? `0${date}` : date
  const newMonth = (month < 10) ? `0${month}` : month

  return`${newDate}-${newMonth}-${year}`
}