import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Alert, Box, Button, CircularProgress, Link, TextField } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as Yup from 'yup'
import { t } from 'i18next'

import { recoverPasswordRequest } from '../../../entities/user'

import type { IRecoverForm } from './types'

export const initialValues: IRecoverForm = {
  email: '',
  submit: null,
}

export function RecoverForm() {
  const yupValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth:The email provided should be a valid email address'))
      .max(255)
      .required(t('auth:The email field is required')),
  })

  const formikOnSubmitAction = async (values: IRecoverForm, {
    setErrors,
    setStatus,
    setSubmitting,
  }: FormikHelpers<IRecoverForm>) => {
    try {
      await recoverPasswordRequest(values.email)

      setStatus({ success: true })
      setSubmitting(false)
    } catch (error: any) {
      setStatus({ success: false })
      setErrors({ submit: error.response.data.message ?? error.message })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupValidationSchema}
      onSubmit={formikOnSubmitAction}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.submit && touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            label={t('auth:Email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />

          <Box
            alignItems="center"
            display="flex"
            justifyContent="left"
            style={{
              marginBottom: '1rem',
            }}
          >
            <Link
              component={RouterLink}
              to="/signin"
              display="flex"
              alignItems="center">
              <ArrowBackIcon/>
              <b>{t('auth:Back to Sign in page')}</b>
            </Link>
          </Box>

          {
            touched.submit && !status?.success && Object.keys(errors).length > 0
              && (
                <Alert severity="error">
                  { errors.email && touched.email && <div>
                    { t(`auth:${errors.email}`) } </div>
                  }
                  { errors.submit && touched.submit && <div
                    style={{
                      display: 'flex', flexDirection: 'column', gap: '1rem',
                    }}>
                    <span>{t(`auth:${errors.submit}`)}</span>
                  </div>
                  }
                </Alert>
              )
          }

          {
            touched.submit && status?.success
              && (
                <Alert severity="success">
                  { touched.submit && <div
                    style={{
                      display: 'flex', flexDirection: 'column', gap: '1rem',
                    }}>
                    <span>{t('auth:Recovery link was sent to your email {{email}}', { email: values.email })}</span>
                  </div>
                  }
                </Alert>
              )
          }

          {
            !status?.success && (
              <Button
                sx={{
                  mt: 3,
                }}
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null}
                disabled={isSubmitting}
                type="submit"
                fullWidth
                size="large"
                variant="contained"
              >
                {t('auth:Submit')}
              </Button>
            )
          }
        </form>
      )}
    </Formik>
  )
}
