import type { CustomReportsGroups } from '../../../../entities/custom-reports/catalog'

export const handleAttributeChange = async (
  id: CustomReportsGroups['id'],
  key: keyof CustomReportsGroups,
  value: CustomReportsGroups['name'] | CustomReportsGroups['disabled'],
  changedGroups: CustomReportsGroups[] | null,
) => {
  return changedGroups?.map(group => {
    if (group.id === id) {
      return { ...group, [key]: value }
    }
    return group
  })
}
