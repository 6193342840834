import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { TitleBlock, BlockContent } from '../../../../shared/ui/style'
import { AddDictionaryButton } from '../../../../features/domains-generator/settings/add-edit-dictionary'

import { DictionariesListBlock } from './dictionaries-list-block'

export function DictionariesBlock() {

  return (
    <SDictsWrapper>
      <TitleBlock>
        { t('subdomains-generator:Vocabulary settings') }
      </TitleBlock>
      <BlockContent>
        <AddDictionaryButton/>
        <DictionariesListBlock/>
      </BlockContent>
    </SDictsWrapper>
  )
}

export const SDictsWrapper = styled.div`
  width: 40%;
  min-width: 300px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 #D7DCE2;
  border-radius: 5px;
`
