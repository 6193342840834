import React, { FC, useState } from 'react'
import { t } from 'i18next'

import { Tooltip } from '../../../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../../../shared/ui/table-button-action'
import { MapiButtonProps } from '../types'

import { AddEditFbMarketingModal } from './add-edit-fb-marketing-modal'

export const EditFBMarketingButton: FC<MapiButtonProps> = ({ antyData }: MapiButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  const initialValues = {
    antyId: antyData.id,
    newValue: antyData.mapiValue,
    oldValue: antyData.mapiValue,
    submit: null,
  }

  return (
    <>
      <AddEditFbMarketingModal
        onClose={() => setModalVisible(false)}
        initialValues={initialValues}
        isOpen={modalVisible}
      />

      <Tooltip title={t('kacc:Edit')}>
        <TableButtonAction
          target={'edit'}
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
