import { Icon } from '../../icon'

export function KeitaroAddedCheckbox() {
  return (
    <Icon>
      <svg
        width="15" height="15"
        viewBox="0 0 15 15" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="15" height="15"
          rx="7.5" fill="#04924A"/>
        <path
          d="M9.915 12L6.184 7.866V12H5.001V2.939H6.184V7.138L9.928
        2.939H11.423L7.315 7.476L11.462 12H9.915Z" fill="white"/>
      </svg>

    </Icon>
  )
}
