import styled from 'styled-components'

import { AddReportButton } from '../../../../features/custom-reports/catalog/add-edit-report'
import { NameFilter } from '../../../../features/custom-reports/catalog/filter-name'
import { GroupsFilter } from '../../../../features/custom-reports/catalog/filter-groups'
import { DisabledFilter } from '../../../../features/custom-reports/catalog/filter-disabled'
import { ResetAllFilters } from '../../../../features/custom-reports/catalog/reset-all-filters'
import { ManageGroupsButton } from '../../../../features/custom-reports/catalog/manage-groups'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <AddReportButton/>
        <ManageGroupsButton/>
        <NameFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <DisabledFilter/>
        <GroupsFilter/>
        <ResetAllFilters/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`

const SFiltersGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 15px;
`
