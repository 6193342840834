import styled from 'styled-components'

import { SyncDataButton } from '../../../../features/kacc/catalog/sync-data-button'
import { ResetAllFilters } from '../../../../features/kacc/catalog/filters-reset'
import { ManageSettingsButton } from '../../../../features/kacc/catalog/manage-settings'
import { CampaignGroupsFilter } from '../../../../features/kacc/catalog/filter-campaign-groups'
import { CampaignOfferGroupsFilter } from '../../../../features/kacc/catalog/filter-offer-groups'
import { AffiliateNetworksFilter } from '../../../../features/kacc/catalog/filter-affiliate-networks'
import { IntervalFilter } from '../../../../features/kacc/catalog/filter-interval'
import { ManageIntervalsButton } from '../../../../features/kacc/catalog/manage-intervals'
import { ExcludeZeroFilter } from '../../../../features/kacc/catalog/filter-exclude-zero'
import { OnlyZeroFilter } from '../../../../features/kacc/catalog/filter-only-zero'

export function FiltersBlock() {
  return (
    <>
      {/*Filters top*/}
      <SManage>
        <SGroup>
          <SyncDataButton/>
          <ManageIntervalsButton/>
        </SGroup>
        <SGroup>
          <ResetAllFilters/>
          <ManageSettingsButton/>
        </SGroup>
      </SManage>

      {/*Filters bottom*/}
      <SFilters>
        <OnlyZeroFilter/>
        <ExcludeZeroFilter/>
        <AffiliateNetworksFilter/>
        <CampaignGroupsFilter/>
        <CampaignOfferGroupsFilter/>
        <IntervalFilter/>
      </SFilters>
    </>
  )
}

const SManage = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
`

const SFilters = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
`

const SGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 15px;
`
