/* eslint-disable max-len */
import './ui.css'

export function ErrorSpaceManIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <defs>
        <clipPath id="clip-path">
          <path
            className="cls-1"
            d="M555.73,633.88s69.93.45,89.58,5.47,71.66,122.73,93.18,134S778.29,765.9,802,752s45.44-30.72,46.12-37.74-9.39-35.07-18.1-40.73-34-6.06-47.49,2.58c-19.82-54-48-132.75-73.84-143.59s-104.15-16-104.15-16Z"/>
        </clipPath>
      </defs>
      <g className="cls-2">
        <g id="Layer_3" data-name="Layer 3">
          <path
            className="cls-3"
            d="M289.08,381.9s-34,13.45-39,46.12,72.26,128.37,72.26,128.37L560.6,428,485.27,295.8Z"/>
          <path
            className="cls-1"
            d="M341.76,412.78S316.69,438.52,309,467s-8.68,79.34-7.4,98.39C339,600,486.83,571,486.83,571L629.29,403.42S605.14,357.57,592.88,345s-52-28.2-97.85-9.75C468.12,373.44,341.76,412.78,341.76,412.78Z"/>
          <path
            className="cls-4"
            d="M330.57,426.09s55.31,17.75,117.57-6.3S521.68,328,521.68,328L495,335.26,341.76,412.78Z"/>
          <path
            className="cls-1"
            d="M555.73,633.88s69.93.45,89.58,5.47,71.66,122.73,93.18,134S778.29,765.9,802,752s45.44-30.72,46.12-37.74-9.39-35.07-18.1-40.73-34-6.06-47.49,2.58c-19.82-54-48-132.75-73.84-143.59s-104.15-16-104.15-16Z"/>
          <g className="cls-5">
            <path
              className="cls-6"
              d="M629.29,582.34s56.94,6.23,72.67,27.42,46.9,150.85,81.79,153.92c.56,42-71.7,57.17-71.7,57.17L538.84,628.39Z"/>
          </g>
          <path
            className="cls-1"
            d="M367.85,623.26s43,90.19,82,114.79S559.51,760.66,579,776.77s15.37,93.5,34.85,114,46.89,33.06,72.51,30.75,46.12-14.6,46.38-26.13-.77-21.53-12.81-27.68-29-11.27-29.73-18.44-14.35-116.33-20-146-49.53-70.94-106.08-91.73c-53.55-44.84-135.8-25.63-135.8-25.63Z"/>
          <path
            className="cls-4"
            d="M380.36,647.39s49.35,2.52,93.67-16.95c-8.47-45.1-34.6-58.42-34.6-58.42l-71.58,51.24Z"/>
          <path
            className="cls-1"
            d="M448.65,176.4C410,155.71,329,148.73,279,210.74S253.92,337.06,274.93,367c22,31.42,97.62,79.44,178.33,34.34S542.43,226.62,448.65,176.4Z"/>
          <path
            className="cls-7"
            d="M442,214.32c34.33,8.2,56.63,56.37,45.1,102.49s-51.25,77.13-95.83,77.38S302.36,364,302.6,307.08C302.84,252.38,367.45,196.52,442,214.32Z"/>
          <path
            className="cls-4"
            d="M343.09,574.58c44.73-20.76,11.27-68.16,55.86-78.94C457.74,504,474,501,474,501S361.53,599.78,343.09,574.58Z"/>
          <polygon
            className="cls-8"
            points="380.36 447.8 454.8 639.35 560.11 636.84 656.45 575.86 685.15 488.74 615.46 279.66 581.63 377.54 494 447.23 380.36 447.8"/>
          <path
            className="cls-1"
            d="M343.09,574.58c24.68-2,37.25-18.07,44.73-25.2,3.81-3.64,3.05-6.83,1.13-17.33s4.74-21.27,13.84-25.88c5,13.45,11.14,16,15.88,15.37s9.61-28.95,25.11-28.7,27.93,20.76,30,31-7.94,39.45-20.5,59.95S423,629.67,383.11,637.61s-62.57-12.43-75.64-38.18c-4.41-8.69-6-20.7-5.86-34.08C301.61,565.35,310.54,577.14,343.09,574.58Z"/>
          <path
            className="cls-1"
            d="M639.46,351.67s4.75,15.45,1.55,21.47-7.56,11-10.76,18.71,1.54,36.77,15.63,41.76,25.75-8.07,29.47-15.63,1.66-27.41-3.85-41.25S655.63,350.59,639.46,351.67Z"/>
          <path
            className="cls-9"
            d="M655.66,159.71l3.16,6.4a2.65,2.65,0,0,0,2,1.46l7.07,1a2.67,2.67,0,0,1,1.48,4.56l-5.11,5a2.66,2.66,0,0,0-.77,2.36l1.2,7a2.67,2.67,0,0,1-3.87,2.82l-6.33-3.32a2.65,2.65,0,0,0-2.48,0l-6.33,3.32a2.67,2.67,0,0,1-3.88-2.82l1.21-7a2.66,2.66,0,0,0-.77-2.36l-5.11-5a2.67,2.67,0,0,1,1.48-4.56l7.07-1a2.65,2.65,0,0,0,2-1.46l3.16-6.4A2.68,2.68,0,0,1,655.66,159.71Z"/>
          <path
            className="cls-9"
            d="M317.34,837l2.65,5.37a2.2,2.2,0,0,0,1.68,1.22l5.93.86a2.24,2.24,0,0,1,1.24,3.82l-4.29,4.18a2.22,2.22,0,0,0-.64,2l1,5.9a2.25,2.25,0,0,1-3.25,2.37l-5.3-2.79a2.25,2.25,0,0,0-2.08,0L309,862.7a2.24,2.24,0,0,1-3.25-2.37l1-5.9a2.26,2.26,0,0,0-.65-2l-4.28-4.18a2.24,2.24,0,0,1,1.24-3.82l5.92-.86a2.23,2.23,0,0,0,1.69-1.22l2.65-5.37A2.24,2.24,0,0,1,317.34,837Z"/>
          <ellipse
            className="cls-10" cx="408.56"
            cy="256.06" rx="45.63"
            ry="16.14"
            transform="translate(-45.54 93.94) rotate(-12.43)"/>
          <path
            className="cls-4"
            d="M468.08,552.56s-28.31-25.3-33.44-25.3-18.79,20-15.71,25.3,18.9,22.66,36.27,28C460.83,569.82,468.08,552.56,468.08,552.56Z"/>
          <polygon className="cls-11" points="494 447.23 560.11 636.84 656.45 575.86 581.63 377.54 494 447.23"/>
          <path
            className="cls-4"
            d="M675.87,739.49a123,123,0,0,1-33,19.57c-11.21,4.32-15,13.16-11.61,29.38s5.29,41.51,12.29,48.17,31.08,3.12,43.56-12.36C683.2,793.75,675.87,739.49,675.87,739.49Z"/>
          <path
            className="cls-4"
            d="M741.12,574.12s-14,6.1-12.67,15.83,15.63,56.76,21.27,61,22.33-3.28,22.33-3.28C754.91,599.11,741.12,574.12,741.12,574.12Z"/>
          <path
            className="cls-12"
            d="M150.32,686.66c-11.13-23.08,25.14-47.78,56-46s43.83,30.27,54.76,40.9c22.83,22.18,22.11,43.88,6.3,53.21S165.05,717.19,150.32,686.66Z"/>
          <path
            className="cls-12"
            d="M772.88,329.11c-9-7.88.26-44.92,21.52-53.59s47.66.81,53.3,15.67S791.33,345.25,772.88,329.11Z"/>
          <path
            className="cls-12"
            d="M877.06,348.93c-4.61,6.57-7.68,25.28,2.05,28.61s17.09-15.92,13.67-25.94S880.14,344.54,877.06,348.93Z"/>
          <path
            className="cls-13"
            d="M884.06,197.1c-7.26,3.42-19.23,18.12-12.43,25.85s22.73-5.33,24.74-15.72S888.91,194.82,884.06,197.1Z"/>
          <path
            className="cls-13"
            d="M201.38,783.77c-6.28-5-24.75-9.25-28.69.26s14.82,18,25,15.26S205.56,787.11,201.38,783.77Z"/>
          <path
            className="cls-14"
            d="M255.61,675.23c-11.17,2.1-9.37,12.09-5.79,21.14S266,708,274.89,699.73C269.12,686,255.61,675.23,255.61,675.23Z"/>
          <path
            className="cls-14"
            d="M191.4,660.16c-8,.89-14.52,12.47-8.88,21.86s16.39,16.23,22.54,13.67,7.18-16.4,2.74-25.28S199.09,659.3,191.4,660.16Z"/>
          <path
            className="cls-14"
            d="M822.74,283.5c-4.39,1.4-8.84,6.15-8.45,11.28s4.87,9.61,9.73,9,9-8.46,8-14.1S826.09,282.44,822.74,283.5Z"/>
          <path
            className="cls-14"
            d="M776.25,293.5s6,6,5,12.81-11.34,6.18-11.34,6.18S771.45,300.88,776.25,293.5Z"/>
        </g>
      </g>
    </svg>
  )
}
