import styled from 'styled-components'

export const PageRows = styled.div`
  padding: 24px;
`

export const ContentRowWrapper = styled.div`
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 16px;
  color: #275572;
`

export const BlockContent = styled.div`
  padding: 16px;
`

export const TitleBlock =styled.div`
  font-size: 14px;
  padding: 16px;
  font-weight: 500;
  letter-spacing: 0.38px;
  text-transform: uppercase;
  color: #407698;
  line-height: 17px;
  border-bottom: 1px solid #B7C6D3;
`
