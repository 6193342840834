import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { useWatchCatCatalogStore } from '../../../entities/watch-cat/catalog'
import { FiltersBlock, PaginationBlock, TableBlock } from '../../../widgets/watch-cat/catalog'
import { MainLayout } from '../../../widgets/layouts'

export function WatchCatCatalog() {
  const [search] = useSearchParams()
  const { getCatalog } = useWatchCatCatalogStore()
  const { t } = useTranslation('watch-cat')

  const debouncedResults = useMemo(() => {
    return debounce(() => getCatalog(search), 300)
  }, [getCatalog, search])

  useEffect(() => {
    debouncedResults()

    return () => {
      debouncedResults.clear()
    }
  }, [debouncedResults])

  return (
    <MainLayout header={t('Watch cat catalog')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <FiltersBlock/>
            <TableBlock/>
            <PaginationBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
