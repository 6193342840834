import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import { Avatar } from '@mui/material'
import { useSnackbar } from 'notistack'

import { Tooltip } from '../../../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../../../shared/ui/table-button-action'
import { AlertMessage } from '../../../../../shared/ui/alert-message'
import { UTableAlertMessage } from '../../../../../shared/ui/table'
import {
  useManageMembersStore,
  SettingsMembersListData,
  resendInvitation,
} from '../../../../../entities/settings/members'
import { useUserStore } from '../../../../../entities/user'
import { DeleteUserButton } from '../../../../../features/user/settings/delete-user'

export const MembersList = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { session } = useUserStore()
  const { members, loading, error, getMembersList } = useManageMembersStore()
  const hasData = !loading && !error && members && members?.length > 0

  const handleOnClickResend = async (email: SettingsMembersListData['email']) => {
    try {
      await resendInvitation(email)

      enqueueSnackbar(
        t('settings:{{email}} was re-invited successfully!', { email: email }), { variant: 'success' },
      )

      getMembersList()
    } catch (error: any) {
      enqueueSnackbar(
        `${t('settings:{{email}} was not re-invited!', { email: email })} ${
          error?.response?.data?.message
            ? t(`settings:${error.response.data.message}`)
            : t('settings:Unknown error')
        }`,
        { variant: 'error' },
      )
    }
  }

  return (
    <SMembersWrapper>
      <SListBlock>
        <STitleBlock>{ t('settings:Members') }</STitleBlock>
        { hasData && members.filter(user => user?.email !== session?.email)
          .map((member, index) => (
            <SListItem key={ index + 1 }>
              <SMemberWrap>
                <Avatar sx={ { width: 32, height: 32 } }>
                  <SAvatar>{ member?.email.slice(0, 2) }</SAvatar>
                </Avatar>
                <SMemberInfo>
                  <SMemberEmail>{member.email}</SMemberEmail>
                </SMemberInfo>
              </SMemberWrap>
              <SMemberActions>
                <SInviteStatusWrap>
                  { !member.isVerified && <SInviteStatus>{t('settings:Invited')}</SInviteStatus>}
                  { member.isVerified && <SInviteStatusActive>{t('settings:Activated')}</SInviteStatusActive>}
                </SInviteStatusWrap>
                { session?.role === 'admin' && <DeleteUserButton email={member.email}/> }
                <Tooltip title={ t('settings:Resend invitation') }>
                  <TableButtonAction
                    target={ 'resend' }
                    disabled={member.isVerified}
                    onClick={() => handleOnClickResend(member.email)}
                  />
                </Tooltip>
              </SMemberActions>
            </SListItem>
          ))}
        {
          !error
          && !loading
          && members?.length === 0 && (
            <UTableAlertMessage>
              <AlertMessage severity="warning">
                {t('settings:Oops! The table is empty - no data!')}
              </AlertMessage>
            </UTableAlertMessage>
          )
        }
        {!hasData && error && (
          <UTableAlertMessage>
            <AlertMessage severity="error">
              {t(`settings:${error}`)}
            </AlertMessage>
          </UTableAlertMessage>
        )}
        {
          !hasData
            && loading && (
            <UTableAlertMessage>
              <AlertMessage severity="info">
                {t('settings:Just a minute! Table is loading/updating!')}
              </AlertMessage>
            </UTableAlertMessage>
          )
        }
      </SListBlock>
    </SMembersWrapper>
  )
}

const STitleBlock = styled.div`
  padding-bottom: 13px;
  border-bottom: 1px solid #E9EBEC;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #275572;
`

const SMembersWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  border: 1px solid #e9ebec;
  border-radius: 4px;
  padding: 13px 16px;
  max-height: 470px;
  min-height: 500px;
  overflow: hidden auto;
  box-shadow: 0 1px 2px 0 #aab7c1;
`

const SListBlock = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const SListItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 13px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #E9EBEC;

  &:hover {
    background-color: #ECF6FB;
    border-radius: 4px;
    z-index: 1;
  }
`

const SMemberWrap = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  color: #275572;
`

const SMemberInfo = styled.div`
  letter-spacing: 0.01em;
`

const SMemberEmail = styled.div`
  font-weight: 300;
  font-size: 13px;
`

const SMemberActions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const SAvatar = styled.div`
  color: #2D5E7D;
  text-transform: uppercase;
  font-weight: 400;
`

const SInviteStatusWrap = styled.div`
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.01em;
  text-align: center;
`

const SInviteStatus = styled.div`
  color: #78909c;
  background: #e8eef3;
  padding: 2px 7px;
`

const SInviteStatusActive = styled.div`
  color: #109B87;
  background: #CBFFE4;
  padding: 2px 7px;
`
