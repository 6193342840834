import React from 'react'
import styled from 'styled-components'

import { SettingsSidebar } from './settings/sidebar'

export type MainLayoutProps = {
  children?: React.ReactNode;
};

export function UserSettingsLayout({ children }: MainLayoutProps) {
  return (
    <UserSettings>
      <SettingsSidebar/>
      { children }
    </UserSettings>
  )
}

const UserSettings = styled.div`
  display: flex;
  gap: 15px;
  padding: 24px;
  min-height: 450px;
`
