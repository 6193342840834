import axios from 'axios'
import { create } from 'zustand'

import { ICampaignsHistoryObject } from './types'
import { getCampaignsHistoryData } from './api'

export type KaccCampaignsHistoryStoreProps = {
  historyData: ICampaignsHistoryObject[] | null,
  getHistoryData: () => Promise<void>,
  loading: boolean,
  error: string | null,
}

export const useKaccCampaignsHistoryStore = create<KaccCampaignsHistoryStoreProps>((set, get) => ({
  historyData: null,
  loading: false,
  error: null,

  getHistoryData: async () => {
    try {
      set({ historyData: null })
      set({ loading: true })

      const response = await getCampaignsHistoryData()
      if (!axios.isAxiosError(response))
        set({ historyData: response.data })
    } catch (error) {
      let errorText = 'Unknown error'

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },

}))