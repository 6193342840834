import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { KaccCatalogLimitEnum, useKaccCatalogStore } from '../../../../entities/kacc/catalog'

export function PaginationBlock() {
  const { catalogCount } = useKaccCatalogStore()

  return (
    <SPaginationBlock>
      <Pagination
        count={catalogCount ?? 0} limits={
          Object.values(KaccCatalogLimitEnum).map(s => Number(s))
        }/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
  display: flex;
  gap: 20px;
`
