import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useFilter } from '../../../../shared/lib'
import { useKaccCatalogStore } from '../../../../entities/kacc/catalog'
import { KaccCatalogFilters } from '../../../../entities/kacc/catalog/types'

export const CampaignOfferGroupsFilterParam = 'offer_group_id'

export const CampaignOfferGroupsFilter = () => {
  const { filterActive } = useFilter(CampaignOfferGroupsFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const { catalogFilters } = useKaccCatalogStore()
  const offerGroups = catalogFilters?.offer_groups
  const initialOptions = filterActive
        && offerGroups?.filter(
          (item) => item && filterActive
            .split(',').includes(String(item.id)))

  /**
   * Handle onChange action
   */
  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: KaccCatalogFilters['offer_groups'],
  ) => {
    const values = value.map((v) => v?.id).join()

    if (values.length > 0) search.set(CampaignOfferGroupsFilterParam, values as string)
    if (values.length === 0 && filterActive) search.delete(CampaignOfferGroupsFilterParam)
    setSearch(search, { replace: true })
  }

  // Check query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && initialOptions && !(initialOptions.length > 0)
      )) {
        search.delete(CampaignOfferGroupsFilterParam)
        setSearch(search, { replace: true })
      }
    }

  }, [offerGroups, initialOptions, filterActive])

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      multiple
      limitTags={-1}
      popupIcon={<KeyboardArrowDownIcon/>}
      onChange={handleOnChange}
      options={offerGroups ?? []}
      openOnFocus={true}
      noOptionsText={t('custom-reports:No options')}
      value={initialOptions ? initialOptions : []}
      defaultValue={initialOptions ? initialOptions : []}
      getOptionLabel={(option) => `${option?.label} (${option?.count})`}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        (option && <Box
          component="li"
          value={option?.id}
          style={{ color: option?.count === 0 ? '#b2b7c1' : '' }}
          {...props}
        >
          {option?.label} <span style={{ color: '#b2b7c1', marginLeft: '5px' }}>({option?.count})</span>
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('kacc:Offer groups')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        maxWidth: 300,
      }}
    />
  )
}
