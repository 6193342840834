import React from 'react'
import styled from 'styled-components'

import { WorldMapWithMarkers } from './world-map-with-markers'

export function CountriesChart() {
  return (
    <SMainContainer>
      <SMapContainer>
        <WorldMapWithMarkers/>
      </SMapContainer>
    </SMainContainer>
  )
}

const SMainContainer = styled.div`
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 365px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
`

const SMapContainer = styled.div`
  width: 100%;
`

