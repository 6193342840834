import React from 'react'
import styled from 'styled-components'

import { useSettingsDomainsStore } from '../../../../entities/domains-generator/settings'
import { EditDictionaryButton } from '../../../../features/domains-generator/settings/add-edit-dictionary'
import { DeleteDictionaryButton } from '../../../../features/domains-generator/settings/delete-dictionary'

import type { Dictionary } from '../../../../entities/domains-generator/settings'

export function DictionariesListBlock() {
  const {
    dictionariesData,
    getDictionariesData,
    deleteDictionary,
  } = useSettingsDomainsStore()

  return (
    <SDictionaryWrapper>
      <SListBlock>
        {Array.isArray(dictionariesData) &&
          dictionariesData?.map((dict: Dictionary, index: number) => (
            <SListItem key={index}>
              <SItemContent>
                <SItemText>
                  {dict?.name}
                </SItemText>

                <SButtonsWrap>
                  <EditDictionaryButton
                    initialValues={{
                      id: dict.id,
                      name: dict.name,
                      dict: dict.dict,
                    }}/>
                  <DeleteDictionaryButton
                    dict={dict}
                    getDictionariesData={getDictionariesData}
                    deleteDictionary={deleteDictionary}
                  />
                </SButtonsWrap>
              </SItemContent>
            </SListItem>
          ))
        }
      </SListBlock>
    </SDictionaryWrapper>
  )
}

const SDictionaryWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #B7C6D3;
  border-radius: 4px;
  padding: 16px 1px;
  max-height: 100%;
  min-height: 500px;
  overflow: hidden auto;
`

const SListBlock = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
const SListItem = styled.li`
  padding: 6px 10px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: normal;
  cursor: default;

  &:hover {
    background-color: #ECF6FB;
    border-radius: 4px;
    z-index: 1;
  }
`

const SItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SItemText = styled.div`
`

const SButtonsWrap = styled.div`
  display: flex;
  gap: 10px;
`
