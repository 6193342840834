import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @keyframes rotate {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  /* Common styles for all browsers */
  body {
    scrollbar-width: thin;
    scrollbar-color: #B7C6D3 transparent;
  }

  /* Styles for WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #B7C6D3;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
`
