import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { MainLayout } from '../../../widgets/layouts'
import { TableBlock } from '../../../widgets/kacc/campaign-history'
import { useKaccCampaignsHistoryStore } from '../../../entities/kacc/campaign-history/model'

export function CampaignChangeHistory() {
  const { getHistoryData } = useKaccCampaignsHistoryStore()
  const { t } = useTranslation('kacc')

  useEffect(() => {
    getHistoryData()
  }, [getHistoryData])

  return (
    <MainLayout header={t('Campaign change history')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <TableBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}