import React from 'react'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'
import { useSearchParams } from 'react-router-dom'

import { SyncButton } from '../../../../shared/ui/sync-button'
import { useKaccCatalogStore } from '../../../../entities/kacc/catalog'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { getPrettyDate } from '../../../../shared/lib'

export const SyncDataButton = () => {
  const { getForceSync, getCatalog, forceSyncLoading, catalogInterval } = useKaccCatalogStore()
  const [search] = useSearchParams()

  const syncHandler = async () => {
    try {
      const params = new URLSearchParams()
      catalogInterval && params.append('interval_id', String(catalogInterval.id))

      await getForceSync(params)
      await getCatalog(search)

      enqueueSnackbar(
        t('kacc:Synchronization was success!'), { variant: 'success' },
      )
    } catch (error: any) {
      enqueueSnackbar(`${t(String(error?.response?.data?.message ?? error))}`, { variant: 'error' })
    }
  }

  return (
    <Tooltip title={t('kacc:Note! Data is updated automatically every 15 minutes')}>
      <SyncButton
        onClick={syncHandler}
        updating={forceSyncLoading}
        disabled={forceSyncLoading}>
        <div>
          <div>{t('kacc:Update now')}</div>
          { catalogInterval &&
              (
                <div style={{ fontSize: '10px' }}>
                  ({catalogInterval && getPrettyDate(catalogInterval?.updated_at, true)})
                </div>
              )
          }
        </div>
      </SyncButton>
    </Tooltip>
  )
}
