import * as React from 'react'
import styled, { css } from 'styled-components'
import { t } from 'i18next'

export type ButtonIconProps = {
  status: string;
}

export const TableIntegrationStatus = ({ status }: ButtonIconProps) => {

  return (
    <SStatus status={status}>
      {
        (status === 'success' || status === 'integrated' || status === 'active') && (t('kacc:Integrated'))
      }
      {
        status === 'error' && (t('kacc:Error'))
      }
      {
        (status === 'notIntegrated' || status === 'notExist') && (t('kacc:Not Integrated'))
      }
      {
        status === 'disabled' && (t('kacc:Inactive'))
      }
    </SStatus>

  )
}

const SStatus = styled.div<{ status: string}>`
  display: table;
  margin-bottom: 5px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 7px;
  width: auto;

  ${({ status }) => {
    switch (status) {
    case 'success':
    case 'active':
    case 'integrated':
      return css`
          background: #DCF6E9;
          color: #10AF6E;
        `
    case 'error':
    case 'notIntegrated':
    case 'notExist':
    case 'disabled':
      return css`
          background: #FFE4E4;
          color: #D32B30;
        `
    }
  }
};
`
