import React from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import type { OptionsGroupProps } from './types'

export const OptionsGroup = ({ value, options, error, handleOnChange }: OptionsGroupProps) => {

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      popupIcon={ <KeyboardArrowDownIcon/> }
      defaultValue={ value ?? undefined }
      value={ value ?? null }
      noOptionsText={t('subdomains-generator:No options')}
      onChange={ (_e, value) => handleOnChange(value) }
      options={ options ?? [] }
      openOnFocus={ true }
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id }
      renderOption={ (props, option) => (
        (option && <Box
          component="li"
          value={ option?.id }
          {...props}
        >
          { option?.name }
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('subdomains-generator:Select group') }
          inputProps={{
            ...params.inputProps,
          }}
          error={ error }
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        width: 300,
      }}
    />
  )
}
