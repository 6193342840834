import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { ViewNoteModalProps } from '../types'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

export const ViewNoteModal = ({ onClose, initialValue, isOpen }: ViewNoteModalProps) => {

  return (
    <ModalOverlayMui isOpen={isOpen}>
      <SModalHeader>
        <SModalHeaderText>
          {t('kacc:View Anty note')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <SModalForm
          noValidate
          // onSubmit={ handleSubmit }
        >
          <SModalContent>
            <SNoteInfoBlock>
              <div dangerouslySetInnerHTML={{ __html: initialValue.notes }}/>
            </SNoteInfoBlock>

            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginTop: 'auto',
                gap: '15px',
              }}
            >
              <SModalCancel
                variant="outlined"
                onClick={onClose}
                size={'medium'}
                type={'button'}
                style={{ marginLeft: 'auto' }}
                disableRipple
              >
                {t('kacc:Close')}
              </SModalCancel>
            </div>
          </SModalContent>
        </SModalForm>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`

const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`

const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  width: 100%;
`
const SNoteInfoBlock = styled.div`
  height: 450px;
  overflow: hidden scroll;
  word-break: break-word;
  border: 1px solid #B7C6D3;
  padding: 10px;
  margin-bottom: 40px;
`
const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #F35B5B;
    color: #F35B5B;

    &:hover {
      border-color: #F35B5B;
      background-color: #FFE2E2;
    }
  }
`
