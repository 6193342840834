import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)`
  border-bottom: 1px dotted #B7C6D3;
`

export const TableRow = styled(UTableRow)`
  height: max-content;
  max-width: 400px;
  width: 100%;
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
  align-items: flex-start;
  padding: 10px;

  &:nth-child(1) {
    min-width: 250px;
    flex-grow: 1;
  }

  &:nth-child(2) {
    min-width: 50px;
    flex-grow: 0;
  }

  &:nth-child(3) {
    min-width: 60px;
    flex-grow: 0;
  }

  &:nth-child(4) {
    min-width: 40px;
    flex-grow: 0;
  }
`

export const TableValue = styled(UTableValue)`
  align-items: center;
  display: flex;
  color: #275572;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1px;
  word-wrap: break-word;

  & input {
    margin: 0 10px 2px 0;
  }

  & svg {
    color: #3FBDF6;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 0;
    margin-right: 10px;
  }

  &.error {
    color: #ff0000;
  }
`

export const TableAlertMessage = styled(UTableAlertMessage)`
  width: 100%;
`

export const TableColumnWrapper = styled.div`
  display: flex;
`

export const TableColumn = styled.div`
  border-right: 1px dotted #B7C6D3;
  flex-grow: 1; 
  height: fit-content;
  width: 100%;
  
  &:nth-child(1) {
    &:is(:last-child) {
      border-right-width: 0;
      
      & ${TableColumnWrapper} {
        border-right-width: 0;
        justify-content: left;
      }
      
      & ${TableRow} {
        max-width: inherit;
      }
    }
  }
  
  &:nth-child(2):not(:last-child) {
    & ${TableColumnWrapper} {
      justify-content: center;
    }
  }
  
  &:last-child:not(:first-child) {
    border-right-width: 0;
    
    & ${TableColumnWrapper} {
      border-right-width: 0;
      justify-content: right;
    }
  }
`

export const TableBody = styled(UTableBody)`
  display: flex;
  justify-content: stretch;
  max-height: 300px;
`
