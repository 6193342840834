import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import styled from 'styled-components'

import { useAppStore } from '../../entities/app'
import { UserSettings } from '../../features/topbar/user-settings'
import { TopBarLanguageSwitcher } from '../../features/topbar/language-switcher'

export function Topbar() {
  const { sidebarOpen, setSidebarOpen } = useAppStore()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static" sx={(theme) => ({
          backgroundColor: theme.header.background,
          height: theme.header.height,
        })}
        elevation={0}>
        <Toolbar sx={{ height: '100%' }}>
          <SMenuIcon
            onClick={() => {
              setSidebarOpen(!sidebarOpen)
            }}/>
          <Box sx={{ flexGrow: 1 }}/>
          {/*
          <Search/>
          <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
            <QuickAdd/>
            <Reminder/>
            <Events/>
            <Notifications/>
          </Box>
          */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <TopBarLanguageSwitcher/>
          </Box>
          <UserSettings/>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

const SMenuIcon = styled(MenuIcon)`
  margin: 0 24px;
  cursor: pointer;

  &.MuiSvgIcon-root {
    font-size: 35px;
  }
`
