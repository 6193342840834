import React from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import styled from 'styled-components'

import { Button } from '../button'

import type { SyncButtonType } from './types'

export const SyncButton = (props: SyncButtonType) => {
  const {
    onClick,
    updating,
    disabled,
    variant,
    size,
    startIcon,
    type,
    children,
  } = props

  return <SButton
    onClick={onClick}
    $updating={updating}
    variant={variant ?? 'contained'}
    size={size ?? 'small'}
    disabled={disabled ?? false}
    type={type ?? 'button'}
    startIcon={startIcon ?? <CachedIcon/>}
  >
    {children}
  </SButton>
}

const SButton = styled(Button)<{ $updating?: boolean }>`
  &.MuiButtonBase-root {
    background: #CBFFE4;
    box-shadow: 0 1px 1px 0 #aab7c1;
    color: #109B87;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    padding: 12px 16px;

    &:hover {
      background: #B4EED1;
    }
  }

  & .MuiSvgIcon-root {
    animation: ${({ $updating }) => $updating
    ? 'rotate 1s linear infinite'
    : 'none'};
    height: 16px;
    width: 16px;
  }
`
