import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getReport } from './api'

import type { CustomReportInfoData, CustomReportInfoReport, CustomReportInfoRow } from './types'

export type CustomReportInfoType = {
    catalog: CustomReportInfoRow[] | null,
    catalogCount: CustomReportInfoData['total'] | null,
    catalogIsLoading: boolean,
    catalogHasError: string | boolean,
    getCatalog: (id: number, search: URLSearchParams) => Promise<void>,

    report: CustomReportInfoReport | null,
}

export const useCustomReportInfoStore = create<CustomReportInfoType>((set, get) => ({
  catalog: null,
  catalogCount: null,
  catalogIsLoading: false,
  catalogHasError: false,
  getCatalog: async (id: number, search: URLSearchParams) => {
    try {
      set({ catalog: null })
      set({ report: null })
      set({ catalogIsLoading: true })
      set({ catalogHasError: false })

      const response = await getReport(id, search)

      if (!axios.isAxiosError(response)) {
        set({ catalog: response.data?.rows })
        set({ catalogCount: response.data?.total })
        set({ report: response.data?.report })
      }
    } catch (error) {
      let errorText = t('custom-reports:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ catalogHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ catalogIsLoading: false })
    }
  },

  report: null,
}))
