import { request } from '../../shared/api'

import type { AuthRecoverUser, AuthRegisterUser, AuthResponse } from './types'
import type { AxiosError, AxiosResponse } from 'axios'

export const loginAction = (values: object): Promise<AuthResponse | AxiosError> =>
  request.post('/auth/signin', values)

export const recoverPasswordRequest = (email: AuthRecoverUser['email']): Promise<AxiosResponse | AxiosError> =>
  request.post('/auth/change-password', { email })

export const recoverTokenVerify = (token: AuthRecoverUser['token']): Promise<AxiosResponse | AxiosError> =>
  request.post('/auth/verify-token-change-password', { token })

export const recoverPasswordConfirm = (body: Partial<AuthRecoverUser>): Promise<AxiosResponse | AxiosError> =>
  request.post('/auth/restore-password', { ...body })

export const registerTokenVerify = (token: AuthRegisterUser['token']): Promise<AxiosResponse | AxiosError> =>
  request.post('/auth/verify-registration', { token })

export const registerUserConfirm = (body: Partial<AuthRegisterUser>): Promise<AxiosResponse | AxiosError> =>
  request.post('/auth/add-passwords', { ...body })
