import React from 'react'
import { useTranslation } from 'react-i18next'

import { SButtonChart, SInfoBlock, STitleChart } from '../../../pages/dashboard/style'

import { WeekProgressChart } from './week-progress'

export function WeekProgressBlock(){
  const { t } = useTranslation('dashboard')

  return(
    <>
      <SInfoBlock>
        <STitleChart>{t('Week progress')}</STitleChart>
        <SButtonChart>{t('View Details')}</SButtonChart>
      </SInfoBlock>
      <WeekProgressChart/>
    </>
  )
}