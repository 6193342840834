import React, { useRef } from 'react'
import { t } from 'i18next'

import { useDimensions } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { catalogKeysConfig, useCustomReportInfoStore } from '../../../../entities/custom-reports/info'
import { getReportInfoKeys } from '../lib'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderLabel,
  TableRow, TableValue,
  TableWrapper,
} from './table'

export function TableBlock() {
  const { catalog, catalogHasError, catalogIsLoading } = useCustomReportInfoStore()

  // data
  const hasData = (
    !catalogIsLoading && !catalogHasError && catalog && catalog?.length > 0
  )
  const catalogKeys = hasData ? getReportInfoKeys(catalog) : null

  // table sizing
  const tableRef = useRef(null)
  const { offsetTop } = useDimensions(tableRef)
  const maxHeight = `calc(100vh - ${offsetTop + 95}px)`

  return (
    <Table>
      {hasData && catalogKeys && (
        <TableWrapper>
          <TableHeader>
            <TableRow>
              {catalogKeys?.map((keyName, keyIndex) => (
                <TableCell
                  key={keyIndex} style={{
                    minWidth: catalogKeysConfig[keyName]?.toString() || '150px',
                    maxWidth: catalogKeysConfig[keyName]?.toString() || '150px',
                  }}>
                  <Tooltip title={ t(`custom-reports:${keyName}`) }>
                    <TableHeaderLabel className={'disabled'}>
                      {t(`custom-reports:${keyName}`)}
                    </TableHeaderLabel>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody ref={tableRef} style={{ maxHeight: maxHeight }}>
            {catalog?.map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                {catalogKeys?.map((keyName, keyIndex) => (
                  <TableCell
                    key={keyIndex} style={{
                      minWidth: catalogKeysConfig[keyName]?.toString() || '150px',
                      maxWidth: catalogKeysConfig[keyName]?.toString() || '150px',
                    }}>
                    <TableValue className={'disabled'}>
                      {String(rowData[keyName])}
                    </TableValue>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      )}

      {!hasData && !catalogIsLoading && !catalogHasError
          && (
            <TableAlertMessage>
              <AlertMessage severity="warning">
                {t('custom-reports:Oops! The table is empty - no data!')}
              </AlertMessage>
            </TableAlertMessage>
          )}
      {!hasData && catalogHasError
          && (
            <TableAlertMessage>
              <AlertMessage severity="error">
                {t(`custom-reports:${catalogHasError}`)}
              </AlertMessage>
            </TableAlertMessage>
          )}
      {!hasData && catalogIsLoading
          && (
            <TableAlertMessage>
              <AlertMessage severity="info">
                {t('custom-reports:Just a minute! Table is loading/updating!')}
              </AlertMessage>
            </TableAlertMessage>
          )}
    </Table>
  )
}
