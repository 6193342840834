import styled from 'styled-components'

import { CheckButton } from '../../../../features/kacc/integrations/sync-button'
import { FilterKeitaroStatus } from '../../../../features/kacc/integrations/filter-keitaro-status'
import { ResetAllFilters } from '../../../../features/kacc/integrations/reset-all-filters'
import { AntyNameSearchFilter } from '../../../../features/kacc/integrations/search-antyname/ui'
import { WatchHistoryButton } from '../../../../features/kacc/integrations/integrations-history'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <CheckButton/>
        <AntyNameSearchFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <FilterKeitaroStatus/>
        <ResetAllFilters/>
        <WatchHistoryButton/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`

const SFiltersGroup = styled.div`
  display: flex;
  gap: 15px;
`
