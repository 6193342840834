import styled from 'styled-components'

import { MembersForm } from './members-form'
import { MembersList } from './members-list'

export const MembersBlock = () => {
  return (
    <SMembersBlock>
      <MembersForm/>
      <MembersList/>
    </SMembersBlock>
  )
}

const SMembersBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`