import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

import { Tooltip } from '../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../shared/ui/table-button-action'
import { disableDomain, useDomainsWatcherStore } from '../../../entities/domains-watcher'

import type { DisableDomainButtonProps } from './types'

export const DisableDomainButton: FC<DisableDomainButtonProps> = (props) => {
  const [search] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const { getDomains } = useDomainsWatcherStore()

  const handleClick = async () => {
    try {
      await disableDomain(props.initialValues.id)

      if (props.disabled) {
        enqueueSnackbar(
          t('domains-watcher:{{name}} was enabled successfully!', { name: props.initialValues.domain }),
          { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('domains-watcher:{{name}} was disabled successfully!', { name: props.initialValues.domain }),
          { variant: 'success' },
        )
      }

      await getDomains(search)
    } catch (error) {
      if (props.disabled) {
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} can not be enabled!', { name: props.initialValues.domain })}  
          ${t(`domains-watcher:${error}`)}`,
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} can not be disabled!', { name: props.initialValues.domain })} 
          ${t(`domains-watcher:${error}`)}`,
          { variant: 'error' },
        )
      }
    }
  }

  return (
    <Tooltip
      title={ props.disabled ? t('domains-watcher:Enable') : t('domains-watcher:Disable') }
    >
      <TableButtonAction
        target={ 'disable' }
        onClick={ handleClick }
      />
    </Tooltip>
  )
}
