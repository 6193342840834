import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { t } from 'i18next'

import { ModalConfirmation } from '../../../../shared/ui/modal-confirmation'
import { TableButtonAction } from '../../../../shared/ui/table-button-action'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { deleteMember, useManageMembersStore } from '../../../../entities/settings/members'
import { useUserStore } from '../../../../entities/user'

import type { DeleteUserButtonProps } from './types'

export const DeleteUserButton = ({ email }: DeleteUserButtonProps) => {
  const { getMembersList } = useManageMembersStore()
  const { session } = useUserStore()
  const [modalVisible, setModalVisible] = useState(false)

  const handleClick = async () => {
    deleteMember(email).then(async () => {
      enqueueSnackbar(
        t('settings:{{email}} was deleted successfully!', { email: email }), { variant: 'success' },
      )
      setModalVisible(false)
      getMembersList()
    }).catch((error) => {
      enqueueSnackbar(
        `${t('settings:{{email}} was not deleted!', { email: email })} ${
          error?.response?.data?.message
            ? t(`settings:${error.response.data.message}`)
            : t('settings:Unknown error')
        }`,
        { variant: 'error' },
      )
    })
  }

  return (
    <>
      {modalVisible && (
        <ModalConfirmation
          isOpen
          onClose={() => setModalVisible(false)}
          buttonTitle={t('settings:Delete user')}
          title={t('settings:Delete user?')}
          description={t('settings:You can’t recover deleted user')}
          handleClick={handleClick}
          cancelText={t('settings:Cancel')}
        />
      )}

      <Tooltip title={ t('settings:Delete') }>
        <TableButtonAction
          target={ 'delete' }
          disabled={session?.role !== 'admin'}
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
