import styled from 'styled-components'
import { t } from 'i18next'
import React from 'react'

import { TableRowStatus } from '../../../../shared/ui/table-row-status'
import { getPrettyDate } from '../../../../shared/lib'
import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'

export function TitleBlock() {
  const { report, catalogHasError, catalogIsLoading } = useCustomReportInfoStore()

  // data
  const hasData = (
    !catalogIsLoading && !catalogHasError && report
  )

  return (
    <>
      {
        hasData && (
          <STitleBlock>
            <STitleInfo>
              <SInfoWrapper>
                <SInfoLabel>
                  {t('custom-reports:Name')}:
                </SInfoLabel>
                <SInfoContent className="name">
                  { report?.name }
                </SInfoContent>

                <SStatus>
                  <TableRowStatus
                    active={ !report?.disabled }
                    activeText={t('custom-reports:Active')}
                    inactiveText={t('custom-reports:Inactive')}
                  />
                </SStatus>

                { report?.groups && report.groups?.length > 0 &&
                    (
                      <>
                        <SInfoContent className="groups">
                          {report?.groups?.filter(group => !group.disabled)
                            ?.map((group, groupIndex) => (
                              <SGroupName key={groupIndex}>
                                {group?.name}
                              </SGroupName>
                            ))}
                        </SInfoContent>
                      </>
                    )
                }
              </SInfoWrapper>

              { report?.comment && report.comment?.length > 0 &&
                  (
                    <SInfoWrapper>
                      <SInfoLabel>
                        {t('custom-reports:Comment')}:
                      </SInfoLabel>
                      <SInfoContent className="comment">
                        { report?.comment }
                      </SInfoContent>
                    </SInfoWrapper>
                  )
              }

              <SInfoWrapper>
                <SInfoLabel>
                  {t('custom-reports:Date created')}:
                </SInfoLabel>
                <SInfoContent className="date">
                  { getPrettyDate(report?.created_at) }
                </SInfoContent>
                <SInfoDelimiter/>

                <SInfoLabel>
                  {t('custom-reports:Date updated')}:
                </SInfoLabel>
                <SInfoContent className="date">
                  { getPrettyDate(report?.updated_at) }
                </SInfoContent>
              </SInfoWrapper>
            </STitleInfo>
          </STitleBlock>
        )
      }
    </>
  )
}

const STitleBlock = styled.div`
  display: flex;
  padding: 16px;
`
const STitleInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const SInfoLabel = styled.div`
  color: #455A64;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
const SInfoContent = styled.div`
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  gap: unset;

  &.name {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  &.comment {
    color: #000;
    letter-spacing: -0.2px;
  }

  &.date {
    color: #455A64;
    font-size: 13px;
    letter-spacing: -0.2px;
  }
`

const SStatus = styled.div`
  margin: 0 0 0 5px;
`

const SInfoDelimiter = styled.div`
  border-right: 1px solid rgba(33, 37, 41, 0.25);
  height: 100%;
`
const SInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`

export const SGroupName = styled.div`
  color: #64B0E0;
  background: #cbf1f6;
  border-radius: 4px;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  margin-left: 4px;
  padding: 2px 7px;
  width: auto;
`
