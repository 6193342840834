import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { MainLayout } from '../../widgets/layouts'
import { useDashboardStore } from '../../entities/dashboard'
import {
  AnnualProgressChart,
  GroupsCampaignChart,
  WeekProgressBlock,
  CountriesChart,
  NetworksChart,
  WeekProgressTestChart,
  AnnualProgressTestChart,
  AnnualProgressRadar,
  GroupsCampaignFilters,
} from '../../widgets/dashboard'

import {
  SPage,
  SPageBlock,
  SPageContainer,
  SInfoBlock,
  STitleChart,
  SButtonChart,
  SPageWithMoreBlocks,
  SChartBlockTwo,
  SChartBlockThree,
  SIconButtonChart,
} from './style'

export function Dashboard() {
  const { t } = useTranslation('dashboard')
  const {
    getWeekProgress,
    getGroupsProgress,
    getAnnualProgress,
    getNetworksList,
    getCountriesList,
  } = useDashboardStore()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    getWeekProgress()
  }, [])

  useEffect(() => {
    getGroupsProgress(searchParams)
  }, [searchParams])

  useEffect(() => {
    getAnnualProgress()
  }, [])

  useEffect(() => {
    getNetworksList()
  }, [])

  useEffect(() => {
    getCountriesList()
  }, [])

  return (
    <MainLayout header={t('Dashboard')}>
      <SPage>
        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <WeekProgressBlock/>
          </SPageContainer>
        </SPageBlock>

        {/*two charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockTwo>
              <WeekProgressBlock/>
            </SChartBlockTwo>
            <SChartBlockTwo>
              <GroupsCampaignFilters/>
              <GroupsCampaignChart/>
            </SChartBlockTwo>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <GroupsCampaignFilters/>
            <GroupsCampaignChart/>
          </SPageContainer>
        </SPageBlock>

        {/*three charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockThree>
              <WeekProgressBlock/>
            </SChartBlockThree>
            <SChartBlockThree>
              <GroupsCampaignFilters/>
              <GroupsCampaignChart/>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Networks. Top of the week.')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <NetworksChart/>
            </SChartBlockThree>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <SInfoBlock>
              <STitleChart>{t('Networks. Top of the week.')}</STitleChart>
              <SButtonChart>{t('View Details')}</SButtonChart>
            </SInfoBlock>
            <NetworksChart/>
          </SPageContainer>
        </SPageBlock>

        {/*two charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Networks. Top of the week.')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <NetworksChart/>
            </SChartBlockTwo>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Annual progress')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <AnnualProgressChart/>
            </SChartBlockTwo>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <SInfoBlock>
              <STitleChart>{t('Annual progress')}</STitleChart>
              <SButtonChart>{t('View Details')}</SButtonChart>
            </SInfoBlock>
            <AnnualProgressChart/>
          </SPageContainer>
        </SPageBlock>

        {/*three charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Annual progress')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <AnnualProgressChart/>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Countries. Top of the week.')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <CountriesChart/>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Networks. Top of the week.')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <NetworksChart/>
            </SChartBlockThree>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*two charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Countries. Top of the week.')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <CountriesChart/>
            </SChartBlockTwo>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Week progress')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <WeekProgressTestChart/>
            </SChartBlockTwo>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <SInfoBlock>
              <STitleChart>{t('Week progress')}</STitleChart>
              <SButtonChart>{t('View Details')}</SButtonChart>
            </SInfoBlock>
            <WeekProgressTestChart/>
          </SPageContainer>
        </SPageBlock>

        {/*three charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Week progress')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <WeekProgressTestChart/>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Annual progress.')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <AnnualProgressTestChart/>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Annual progress')}</STitleChart>
                <SIconButtonChart>
                  <MoreHorizIcon/>
                </SIconButtonChart>
              </SInfoBlock>
              <AnnualProgressRadar/>
            </SChartBlockThree>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        {/*<SPageBlock>*/}
        {/*  <SPageContainer>*/}
        {/*    <SInfoBlock>*/}
        {/*      <STitleChart>{t('Annual progress.')}</STitleChart>*/}
        {/*      <SButtonChart>{t('View Details')}</SButtonChart>*/}
        {/*    </SInfoBlock>*/}
        {/*    <LineChart/>*/}
        {/*  </SPageContainer>*/}
        {/*</SPageBlock>*/}

        {/*two charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Annual progress')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <AnnualProgressTestChart/>
            </SChartBlockTwo>
            <SChartBlockTwo>
              <SInfoBlock>
                <STitleChart>{t('Week progress')}</STitleChart>
                <SButtonChart>{t('View Details')}</SButtonChart>
              </SInfoBlock>
              <WeekProgressTestChart/>
            </SChartBlockTwo>
          </SPageWithMoreBlocks>
        </SPageBlock>

        {/*only one chart*/}
        <SPageBlock>
          <SPageContainer>
            <SInfoBlock>
              <STitleChart>{t('Week progress')}</STitleChart>
              <SButtonChart>{t('View Details')}</SButtonChart>
            </SInfoBlock>
            <WeekProgressTestChart/>
          </SPageContainer>
        </SPageBlock>
      </SPage>
    </MainLayout>
  )
}
