import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
import styled from 'styled-components'
import { t } from 'i18next'

import { useUserStore } from '../../../entities/user'

import { SwitchSessionsModal } from './manage-sessions/switch-sessions-modal'

export function UserSettings() {
  const navigate = useNavigate()
  // const { translationCurrent, setTranslationCurrent } = useTranslationStore()
  // const { themeCurrent, setThemeCurrent } = useAppStore()
  const { session, sessions, logout } = useUserStore()
  const [modalVisible, setModalVisible] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const openSettingsPage = () => {
    navigate('/settings')
  }

  // const switchLanguage = () => {
  //   translationCurrent === 'ru'
  //     ? setTranslationCurrent('en')
  //     : setTranslationCurrent('ru')
  // }
  //
  // const switchTheme = () => {
  //   themeCurrent === 'DarkTheme'
  //     ? setThemeCurrent('LightTheme')
  //     : setThemeCurrent('DarkTheme')
  // }
  //
  // const translationCurrentName = translationCurrent === 'ru'
  //   ? 'Russian (set English)'
  //   : 'English (set Russian)'
  //
  // const themeCurrentName = themeCurrent === 'DarkTheme'
  //   ? 'Theme dark (set light)'
  //   : 'Theme light (set dark)'

  const handleClose = () => {
    if (anchorEl) {
      setAnchorEl(null)
    }
    setOpen(false)
  }

  useEffect(() => {
    setOpen(Boolean(anchorEl as HTMLElement))
  }, [anchorEl])

  return (
    <SSettings>

      <SwitchSessionsModal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
      />

      <SButtonWrapper>
        <Tooltip arrow title={t('global:Account')}>
          <UserBlock onClick={handleClick}>
            <Avatar sx={{ width: 32, height: 32, backgroundColor: '#B7C6D3' }}>
              <AvatarText>
                {session?.email.slice(0, 2)}
              </AvatarText>
            </Avatar>
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <UserBoxText>
                <UserBoxLabel noWrap variant="body1">
                  {session?.email}
                </UserBoxLabel>
              </UserBoxText>
              <ExpandMoreIcon/>
            </Box>
          </UserBlock>
        </Tooltip>
      </SButtonWrapper>
      <SMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#407698',
            color: '#adb5bd',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <DropdownMenuItem onClick={() => openSettingsPage()}>
          <ListItemIcon>
            <Settings sx={{ color: '#adb5bd' }} fontSize="small"/>
          </ListItemIcon>
          {t('global:Settings')}
        </DropdownMenuItem>

        {sessions && (
          <DropdownMenuItem onClick={() => setModalVisible(true)}>
            <ListItemIcon>
              <AssignmentIndIcon sx={{ color: '#adb5bd' }} fontSize="small"/>
            </ListItemIcon>
            {t('global:Manage sessions')}
          </DropdownMenuItem>
        )}

        {/*<DropdownMenuItem onClick={ () => switchTheme() }>*/}
        {/*  <ListItemIcon>*/}
        {/*    {*/}
        {/*      themeCurrent === 'DarkTheme'*/}
        {/*        ? <DarkModeIcon sx={{ color: '#adb5bd' }} fontSize="small"/>*/}
        {/*        : <LightModeIcon sx={{ color: '#adb5bd' }} fontSize="small"/>*/}
        {/*    }*/}
        {/*  </ListItemIcon>*/}
        {/*  { t(themeCurrentName) }*/}
        {/*</DropdownMenuItem>*/}
        {/*<DropdownMenuItem onClick={ () => switchLanguage() }>*/}
        {/*  <ListItemIcon>*/}
        {/*    <PublicIcon sx={{ color: '#adb5bd' }} fontSize="small"/>*/}
        {/*  </ListItemIcon>*/}
        {/*  { t(translationCurrentName) }*/}
        {/*</DropdownMenuItem>*/}

        <DropdownMenuItemDivider/>

        <DropdownMenuItem onClick={() => session && logout(session)}>
          <ListItemIcon>
            <Logout sx={{ color: '#adb5bd' }} fontSize="small"/>
          </ListItemIcon>
          {t('global:Logout')}
        </DropdownMenuItem>
      </SMenu>
    </SSettings>
  )
}

const AvatarText = styled.span`
  color: #2D5E7D;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
`

export const SSettings = styled.div`
  margin: 0 24px 0 0;
`

export const SButtonWrapper = styled.div`
  padding: 8px 12px;
  background-color: #2D5E7D;
  border-radius: 15px;
  min-width: 200px;
`

export const SMenu = styled(Menu)({
  '& .MuiPaper-root': {
    padding: '0',
  },
})

const UserBlock = styled.div(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',

  '& .MuiSvgIcon-root': {
    color: '#B7C6D3',
    fontSize: '16px',
    marginLeft: 'auto',
  },
}))

const UserBoxText = styled(Box)({
  marginLeft: '7px',
  textAlign: 'left',

  '& .MuiPaper-root': {
    padding: '0',
  },
})

const UserBoxLabel = styled(Typography)(({ theme }) => ({
  color: theme.colors.alpha.white[85],
  display: 'block',
  fontWeight: '400',

  '&.MuiTypography-body1': {
    paddingRight: '8px',
    maxWidth: '200px',
    color: '#B7C6D3',
  },
}))

const DropdownMenuItem = styled(MenuItem)({
  '&.MuiMenuItem-root.MuiButtonBase-root.MuiMenuItem-gutters': {
    paddingLeft: '10px',
    paddingRight: '10px',
    height: 'fit-content',
  },

  '&.MuiMenuItem-root.MuiButtonBase-root .MuiListItemIcon-root': {
    minWidth: '32px',
  },

  '&.MuiMenuItem-root.MuiButtonBase-root.MuiMenuItem-gutters,& svg': {
    backgroundColor: 'transparent',
    color: '#e2e7ea',
  },

  '&.MuiMenuItem-root.MuiButtonBase-root.MuiMenuItem-gutters:hover': {
    backgroundColor: '#8CADC1',
    color: '#fff',
    borderRadius: '4px',
  },

  '&.MuiMenuItem-root.MuiButtonBase-root.MuiMenuItem-gutters:hover svg': {
    color: '#fff',
  },
})

const DropdownMenuItemDivider = styled.div`
  border-bottom: 1px solid #A1B7BF;
  margin: 0.5rem 0;
`
