import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'
import { ReactCountryFlag } from '../../../shared/ui/react-country-flag'
import { useWatchCatCatalogStore } from '../../../entities/watch-cat/catalog'

export const GeoFilterParam = 'geo'

export function GeoFilter() {
  const { geo } = useWatchCatCatalogStore()
  const { filterActive } = useFilter(GeoFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const { offsetActive } = usePagination()

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(GeoFilterParam, value as string)
    if (!value && filterActive) search.delete(GeoFilterParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check geo query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && geo && (
          !(geo.some(e => e === filterActive))
        )
      )) {
        search.delete(GeoFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [geo, filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ?? null }
      defaultValue={ filterActive ?? null }
      onChange={ handleOnChange }
      options={ geo ?? [] }
      openOnFocus={ true }
      noOptionsText={ t('watch-cat:No options') }
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => option === value }
      renderOption={ (props, option) => (
        (option !== '' && <Box
          component="li"
          sx={ {
            '& > img': { mr: 1, flexShrink: 0 },
            textTransform: 'uppercase',
          } }
          value={ option }
          { ...props }
          key={ Math.random() }
        >
          <ReactCountryFlag
            countryCode={ option }
            svg
            style={ {
              height: '12px',
              width: 'auto',
            } }
          />
          { t(`watch-cat:${ option }`) }
        </Box>)
      ) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ t('watch-cat:Geo') }
          inputProps={ {
            ...params.inputProps,
          } }
        />
      ) }
      slotProps={ {
        clearIndicator: {
          tabIndex: 0,
        },
      } }
    />
  )
}
