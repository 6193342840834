import * as React from 'react'
import styled, { css } from 'styled-components'

import type { TableRowStatusProps } from './types'

export function TableRowStatus({ active, activeText, inactiveText }: TableRowStatusProps) {

  return (
    <SStatus $active={active}>
      { active ? activeText : inactiveText}
    </SStatus>
  )
}


const SStatus = styled.div<{ $active: boolean }>`
  display: inline-flex;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 7px;
  width: auto;

  ${ ({ $active }) => $active
    ? css`
      background: #DCF6E9;
      color: #10AF6E;
    `
    : css`
      background: #FFE4E4;
      color: #D32B30;
    `
}
`
