import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'
import { useWatchCatCatalogStore } from '../../../entities/watch-cat/catalog'

export const PartnerFilterParam = 'network'

export function PartnerFilter() {
  const { networks } = useWatchCatCatalogStore()
  const { filterActive } = useFilter(PartnerFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const { offsetActive } = usePagination()

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(PartnerFilterParam, value as string)
    if (!value && filterActive) search.delete(PartnerFilterParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check geo query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && networks && (
          !(networks.some(e => e === filterActive))
        )
      )) {
        search.delete(PartnerFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [networks, filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ?? null }
      defaultValue={ filterActive ?? null }
      onChange={ handleOnChange }
      options={ networks ?? [] }
      openOnFocus={ true }
      noOptionsText={ t('watch-cat:No options') }
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => option === value }
      renderOption={ (props, option) => (
        (option !== '' && <Box
          component="li"
          value={ option }
          { ...props }
          key={ Math.random() }
        >
          { t(`watch-cat:${ option }`) }
        </Box>)
      ) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ t('watch-cat:Networks') }
          inputProps={ {
            ...params.inputProps,
          } }
        />
      ) }
      slotProps={ {
        clearIndicator: {
          tabIndex: 0,
        },
      } }
    />
  )
}
