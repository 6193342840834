import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import { useFilter } from '../../../shared/lib'
import { PaginationOffsetParam, usePagination } from '../../../shared/lib'
import { Switcher } from '../../../shared/ui/switcher'
import { DisabledFilterType } from '../../domains-generator/settings/filter-disabled/types'

// Disabled query name
export const DisabledParam = 'disabled'

/**
 * DisabledFilter feature
 */
export function DisabledFilter() {
  const [search, setSearch] = useSearchParams()
  const { filterActive } = useFilter(DisabledParam, DisabledFilterType.false)
  const { offsetActive } = usePagination()

  const getCurrentFilterBoolean = filterActive === DisabledFilterType.true

  /**
   * Handle ItemClickHandler action
   */
  const handleItemClickHandler = (): void => {
    search.set(DisabledParam, String(!getCurrentFilterBoolean))
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check disabled query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive
          || (
            !(filterActive in DisabledFilterType)
              || filterActive === DisabledFilterType.false
          )
      ) {
        search.delete(DisabledParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <SSwitcherWrapper>
      <Switcher
        label={ t('domains-watcher:Disabled') }
        itemClickHandler={ handleItemClickHandler }
        itemActive={ getCurrentFilterBoolean }
        defaultValue={ false }
      />
    </SSwitcherWrapper>
  )
}

const SSwitcherWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
