import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getDomains, syncKeitaro } from './api'

import type { DomainsListData } from './types'

export interface IDomainsWatcherStore {
  count: number | null,
  limits: number[],
  list: DomainsListData[] | null,
  loading: boolean,
  error: string | null,
  getDomains: (search: URLSearchParams) => void,

  syncing: boolean,
  syncError: string | null,
  syncKeitaro: (search: URLSearchParams) => void,
}

export const useDomainsWatcherStore = create<IDomainsWatcherStore>((set, get) => ({
  count: null,
  list: null,
  limits: [10, 25, 50, 100, 250],
  loading: false,
  syncing: false,
  error: null,
  getDomains: async (search) => {
    try {
      set({ count: null })
      set({ error: null })
      set({ list: null })
      set({ loading: true })
      set({ syncing: false })

      const response = await getDomains(search)
      if (!axios.isAxiosError(response)) {
        set({ count: response.data.count })
        set({ list: response.data.domains })
      }
    } catch (error) {
      let errorText = t('domains-watcher:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  syncError: null,
  syncKeitaro: async (search) => {
    try {
      set({ syncing: true })
      set({ syncError: null })

      const response = await syncKeitaro()
      if (!axios.isAxiosError(response)) {
        get().getDomains(search)
      }
    } catch (error) {
      let errorText = t('domains-watcher:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ syncError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
