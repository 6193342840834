import React, { useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button } from '../../../../../shared/ui/button'

import { ManageSettingsModal } from './manage-settings-modal'

export const ManageSettingsButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <ManageSettingsModal
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <Button
        onClick={() => setModalVisible(true)}
        sx={(theme) => theme.customButtons.iconButton}
        type={'button'}
      >
        <SettingsIcon/>
      </Button>
    </>
  )
}