import styled from 'styled-components'
import { t } from 'i18next'

export function SimilarBlock() {

  return (
    <SOfferSimilarBlock>
      {t('watch-cat:Similar')}
    </SOfferSimilarBlock>
  )
}

const SOfferSimilarBlock = styled.div`
  display: flex;
  padding: 32px 16px;
`
