import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import debounce from 'debounce'
import { t } from 'i18next'

import {
  getFormattedFullTime,
  getFormattedRelativeDate,
  SortColumnParam,
  SortType,
  SortTypeParam,
  useDimensions,
  useSort,
} from '../../../shared/lib'
import { AlertMessage } from '../../../shared/ui/alert-message'
import { TableMenu } from '../../../shared/ui/table-menu'
import {
  DomainsListDataFbStatus,
  DomainsListDataSource,
  DomainsListSortKeys,
  useDomainsWatcherStore,
} from '../../../entities/domains-watcher'
import { EditDomainButton } from '../../../features/domains-watcher/add-edit-domain'
import { DeleteDomainButton } from '../../../features/domains-watcher/delete-domain'
import { DisableDomainButton } from '../../../features/domains-watcher/disable-domain'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
} from './table'

export function TableBlock(){
  const [search, setSearch] = useSearchParams()
  const {
    list,
    loading,
    error,
    syncError,
  } = useDomainsWatcherStore()

  const { setAsc, setDesc, sortActive, sortColumn, toggleSort } = useSort()

  // data
  const dataDomains = list || null
  const dataDomainsError = error ?? null
  const hasData = (
    !loading
    && !dataDomainsError
    && !syncError
    && dataDomains
    && dataDomains?.length > 0
  )

  // table dimensions
  const domainsHeaderRef = useRef(null)
  const domainsHeader = useDimensions(domainsHeaderRef)
  const minWidth = domainsHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      domainsHeader.offsetHeight + domainsHeader.offsetTop + 95
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      domainsHeader.offsetTop + 95
    }px)`
    : ''

  // check sort
  useEffect(() => {
    if (sortColumn && !(sortColumn in DomainsListSortKeys)) {
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }
  }, [sortColumn])

  const handleSortChange = (
    column: string,
    initialSort: SortType = SortType.asc,
    initialColumn: DomainsListSortKeys = DomainsListSortKeys.expiredDate,
  ) => {
    if (!sortActive && !sortColumn) {
      column === initialColumn && initialSort !== SortType.asc
        ? setAsc(column)
        : setDesc(column)
    } else if (sortColumn && sortColumn !== column) {
      initialSort === SortType.asc
        ? setAsc(column)
        : setDesc(column)
    } else {
      toggleSort(column)
    }
  }

  const getSortDirectionClassName = (
    column: string,
    initialSort: SortType = SortType.asc,
    initialColumn: DomainsListSortKeys = DomainsListSortKeys.expiredDate,
  ) => {
    let direction = ''

    if (column === sortColumn) {
      if (sortActive === initialSort)
        direction = initialSort
      else
        direction = initialSort !== SortType.asc
          ? SortType.asc
          : SortType.desc
    }

    if (!sortActive || !sortColumn)
      direction = column === initialColumn
        ? initialSort
        : direction

    return direction
  }

  const getFormattedDate = (stamp: string) => {
    return `${getFormattedRelativeDate(stamp)}, ${getFormattedFullTime(stamp)}`
  }

  const debouncedSortChange = debounce(handleSortChange, 150)

  return (
    <Table>
      <TableWrapper
        style={{ height: maxHeight }}
        className="overflow-auto"
      >
        { hasData &&
            (<TableHeader>
              <TableRow ref={ domainsHeaderRef }>
                <TableCell>
                  <TableHeaderLabel
                    className={ getSortDirectionClassName(DomainsListSortKeys.domain) }
                    onClick={() => debouncedSortChange(DomainsListSortKeys.domain)}
                  >
                    { t('domains-watcher:Domain') }
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel
                    className={getSortDirectionClassName(DomainsListSortKeys.source)}
                    onClick={() => debouncedSortChange(DomainsListSortKeys.source)}
                  >
                    { t('domains-watcher:Source') }
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel
                    className={getSortDirectionClassName(DomainsListSortKeys.expiredDate)}
                    onClick={() => debouncedSortChange(DomainsListSortKeys.expiredDate)}
                  >
                    { t('domains-watcher:Expiration date') }
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel
                    className={getSortDirectionClassName(DomainsListSortKeys.statusFb)}
                    onClick={() => debouncedSortChange(DomainsListSortKeys.statusFb)}
                  >
                    { t('domains-watcher:FB Status') }
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    { t('domains-watcher:Comment') }
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    { t('domains-watcher:Menu') }
                  </TableHeaderLabel>
                </TableCell>
              </TableRow>
            </TableHeader>)
        }
        <TableBody style={{ height: minHeight }}>
          { hasData && dataDomains
          && dataDomains.map((row, rowIndex) => (
            <TableRow
              key={ rowIndex }
              style={{ minWidth: minWidth }}
              className={ row.disabled ? 'disabled' : '' }
            >
              <TableCell>
                <TableValue>
                  { t(String(row.domain)) }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  { t(`domains-watcher:${row.source}`) }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  { (row.expired_date !== null)
                    ? getFormattedDate(row.expired_date)
                    :<div className="is-empty">
                      { t('domains-watcher:No info') }
                    </div>
                  }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  { (row.fb_status === DomainsListDataFbStatus.active)
                            && <div className="is-active">
                              { t(`domains-watcher:${row.fb_status}`) }
                            </div>
                  }
                  { (row.fb_status === DomainsListDataFbStatus.banned)
                            && <div className="is-banned">
                              { t(`domains-watcher:${row.fb_status}`) }
                            </div>
                  }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  { row.comment }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  { row.source === DomainsListDataSource.manual
                            && (
                              <TableMenu>
                                <EditDomainButton
                                  initialValues={{
                                    id: row.id,
                                    domain: row.domain,
                                    comment: row.comment,
                                  }}
                                />
                                <DisableDomainButton
                                  initialValues={{
                                    id: row.id,
                                    domain: row.domain,
                                  }}
                                  disabled={ row.disabled }
                                />
                                <DeleteDomainButton
                                  initialValues={{
                                    id: row.id,
                                    domain: row.domain,
                                  }}
                                />
                              </TableMenu>
                            )
                  }
                </TableValue>
              </TableCell>
            </TableRow>
          ))
          }
          {
            !hasData
              && !syncError
              && dataDomains && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  {t('domains-watcher:Oops! The table is empty - no data!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
              && syncError && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  {t(`domains-watcher:${syncError}`)}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
              && loading && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  {t('domains-watcher:Just a minute! Table is loading/updating!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
