import React, { FC, useEffect, useMemo, useState } from 'react'
import PanToolIcon from '@mui/icons-material/PanTool'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { t } from 'i18next'

import { getArraySplitToNChunks } from '../../../../../shared/lib'
import {
  changeElementPosition,
  toggleGroupChecked,
  toggleGroupValueChecked,
  toggleValueChecked,
  DRAG_DEFAULT,
} from '../lib'
import { SETTINGS_DEFAULT_GROUPS } from '../../../../../entities/kacc/catalog'

import {
  Table,
  TableBody,
  TableHeader,
  TableWrapper,
  TableCell,
  TableColumn,
  TableRow,
  TableValue,
  TableGroup,
  TableBodyWrapper,
} from './table'

import type { DragProps, SettingsTableProps } from '../types'
import type { KaccCatalogSettingsFilter, KaccCatalogSettingsGroup } from '../../../../../entities/kacc/catalog'

export const TableSettingsData: FC<SettingsTableProps> = (props) => {
  const [selected, setSelected] = useState<KaccCatalogSettingsFilter>(props.settings)
  const [groups, setGroups] = useState<KaccCatalogSettingsGroup[]>(SETTINGS_DEFAULT_GROUPS)
  const [dragInfo, setDragInfo] = useState<DragProps>(DRAG_DEFAULT)

  // split array to chunks
  const offersNChunksColumns = 2
  const offersNChunks: KaccCatalogSettingsFilter['offers'][] = useMemo(() => {
    return selected?.offers && getArraySplitToNChunks(selected.offers, offersNChunksColumns)
  }, [selected])

  const handleOnDrop = () => {
    if (dragInfo.start.key && dragInfo.end.key && dragInfo.start.group === dragInfo.end.group) {
      let { anty, facebook, offers, campaigns } = selected

      if (dragInfo.start.group === 'anty') {
        const startIndex = anty.findIndex(value => value.key === dragInfo.start.key)
        const endIndex = anty.findIndex(value => value.key === dragInfo.end.key)
        anty = changeElementPosition(anty, startIndex, endIndex)
      }
      if (dragInfo.start.group === 'facebook') {
        const startIndex = facebook.findIndex(value => value.key === dragInfo.start.key)
        const endIndex = facebook.findIndex(value => value.key === dragInfo.end.key)
        facebook = changeElementPosition(facebook, startIndex, endIndex)
      }
      if (dragInfo.start.group === 'campaigns') {
        const startIndex = campaigns.findIndex(value => value.key === dragInfo.start.key)
        const endIndex = campaigns.findIndex(value => value.key === dragInfo.end.key)
        campaigns = changeElementPosition(campaigns, startIndex, endIndex)
      }
      if (dragInfo.start.group === 'offers') {
        const startIndex = offers.findIndex(value => value.key === dragInfo.start.key)
        const endIndex = offers.findIndex(value => value.key === dragInfo.end.key)
        offers = changeElementPosition(offers, startIndex, endIndex)
      }

      setSelected({ anty: anty, facebook: facebook, campaigns: campaigns, offers: offers })
    }

    // set drag info default (null)
    setDragInfo(DRAG_DEFAULT)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (dragInfo.start.key && dragInfo.end.key && dragInfo.start.group === dragInfo.end.group)
      e.preventDefault()
  }

  const handleOnChange = (
    e: React.SyntheticEvent<HTMLInputElement>,
    group: keyof KaccCatalogSettingsFilter,
  ) => {
    let { anty, facebook, offers, campaigns } = selected

    if (group === 'anty') anty = toggleValueChecked(e, anty)
    if (group === 'facebook') facebook = toggleValueChecked(e, facebook)
    if (group === 'campaigns') campaigns = toggleValueChecked(e, campaigns)
    if (group === 'offers') offers = toggleValueChecked(e, offers)

    setSelected({ anty: anty, facebook: facebook, campaigns: campaigns, offers: offers })
  }

  const handleGroupOnChange = (
    e: React.SyntheticEvent<HTMLInputElement>,
    group: keyof KaccCatalogSettingsFilter,
  ) => {
    let { anty, facebook, offers, campaigns } = selected

    if (group === 'anty') anty = toggleGroupValueChecked(e, anty)
    if (group === 'facebook') facebook = toggleGroupValueChecked(e, facebook)
    if (group === 'campaigns') campaigns = toggleGroupValueChecked(e, campaigns)
    if (group === 'offers') offers = toggleGroupValueChecked(e, offers)

    setGroups(toggleGroupChecked(e, groups, group))
    setSelected({ anty: anty, facebook: facebook, campaigns: campaigns, offers: offers })
  }

  // Update formik settings value
  useEffect(() => {
    props.formik.setFieldValue('settings', selected)
  }, [selected])

  return (
    <Table>
      <TableWrapper className={'overflow-auto'}>
        <TableHeader>
          <TableGroup>
            <TableColumn>
              <TableRow>
                <TableCell>
                  <TableValue>
                    <PanToolIcon/>
                    <input
                      id={'anty_group'}
                      onChange={(e) => handleGroupOnChange(e, 'anty')}
                      type="checkbox"
                      checked={groups.filter(values => values.key === 'anty')[0].checked}
                    />
                    <label htmlFor={'anty_group'}>
                      {t('kacc:anty_group')}
                    </label>
                  </TableValue>
                </TableCell>
              </TableRow>
            </TableColumn>
          </TableGroup>

          <TableGroup>
            <TableColumn>
              <TableRow>
                <TableCell>
                  <TableValue>
                    <PanToolIcon/>
                    <input
                      id={'facebook_group'}
                      onChange={(e) => handleGroupOnChange(e, 'facebook')}
                      type="checkbox"
                      checked={groups.filter(values => values.key === 'facebook')[0].checked}
                    />
                    <label htmlFor={'facebook_group'}>
                      {t('kacc:facebook_group')}
                    </label>
                  </TableValue>
                </TableCell>
              </TableRow>
            </TableColumn>
          </TableGroup>

          <TableGroup>
            <TableColumn>
              <TableRow>
                <TableCell>
                  <TableValue>
                    <PanToolIcon/>
                    <input
                      id={'campaigns_group'}
                      onChange={(e) => handleGroupOnChange(e, 'campaigns')}
                      type="checkbox"
                      checked={groups.filter(values => values.key === 'campaigns')[0].checked}
                    />
                    <label htmlFor={'campaigns_group'}>
                      {t('kacc:campaigns_group')}
                    </label>
                  </TableValue>
                </TableCell>
              </TableRow>
            </TableColumn>
          </TableGroup>

          <TableGroup>
            <TableColumn>
              <TableRow>
                <TableCell>
                  <TableValue>
                    <PanToolIcon/>
                    <input
                      id={'offers_group'}
                      onChange={(e) => handleGroupOnChange(e, 'offers')}
                      type="checkbox"
                      checked={groups.filter(values => values.key === 'offers')[0].checked}
                    />
                    <label htmlFor={'offers_group'}>
                      {t('kacc:offers_group')}
                    </label>
                  </TableValue>
                </TableCell>
              </TableRow>
            </TableColumn>
          </TableGroup>
        </TableHeader>

        <TableBody>
          <TableBodyWrapper>
            <TableGroup>
              <TableColumn>
                {selected && selected.anty?.map((value, index) =>
                  <TableRow
                    key={index}
                    draggable={value.checked}
                    onDragStart={(_e) => {
                      dragInfo.start.key = value.key
                      dragInfo.start.group = 'anty'
                    }}
                    onDragEnter={(_e) => {
                      dragInfo.end.key = value.key
                      dragInfo.end.group = 'anty'
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                  >
                    <TableCell>
                      <TableValue>
                        <DragHandleIcon className={!value.checked ? 'unchecked' : ''}/>
                        <input
                          type="checkbox"
                          onChange={(e) => handleOnChange(e, 'anty')}
                          id={'anty_' + value.key}
                          name={'configs'}
                          value={value.key}
                          checked={Boolean(value.checked)}
                          disabled={Boolean(value.uncheckable)}
                        />
                        <label htmlFor={'anty_' + value.key}>
                          {t(`kacc:${value.key}`)}
                        </label>
                      </TableValue>
                    </TableCell>
                  </TableRow>,
                )}
              </TableColumn>
            </TableGroup>

            <TableGroup>
              <TableColumn>
                {selected && selected.facebook?.map((value, index) =>
                  <TableRow
                    key={index}
                    draggable={value.checked}
                    onDragStart={(_e) => {
                      dragInfo.start.key = value.key
                      dragInfo.start.group = 'facebook'
                    }}
                    onDragEnter={(_e) => {
                      dragInfo.end.key = value.key
                      dragInfo.end.group = 'facebook'
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                  >
                    <TableCell>
                      <TableValue>
                        <DragHandleIcon className={!value.checked ? 'unchecked' : ''}/>
                        <input
                          type="checkbox"
                          onChange={(e) => handleOnChange(e, 'facebook')}
                          id={'accounts_' + value.key}
                          name={'configs'}
                          value={value.key}
                          checked={Boolean(value.checked)}
                          disabled={Boolean(value.uncheckable)}
                        />
                        <label htmlFor={'accounts_' + value.key}>
                          {t(`kacc:${value.key}`)}
                        </label>
                      </TableValue>
                    </TableCell>
                  </TableRow>,
                )}
              </TableColumn>
            </TableGroup>

            <TableGroup>
              <TableColumn>
                {selected && selected.campaigns?.map((value, index) =>
                  <TableRow
                    key={index}
                    draggable={value.checked}
                    onDragStart={(_e) => {
                      dragInfo.start.key = value.key
                      dragInfo.start.group = 'campaigns'
                    }}
                    onDragEnter={(_e) => {
                      dragInfo.end.key = value.key
                      dragInfo.end.group = 'campaigns'
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                  >
                    <TableCell>
                      <TableValue>
                        <DragHandleIcon className={!value.checked ? 'unchecked' : ''}/>
                        <input
                          type="checkbox"
                          onChange={(e) => handleOnChange(e, 'campaigns')}
                          id={'campaigns_' + value.key}
                          name={'configs'}
                          value={value.key}
                          checked={value.checked}
                          disabled={Boolean(value.uncheckable)}
                        />
                        <label htmlFor={'campaigns_' + value.key}>
                          {t(`kacc:${value.key}`)}
                        </label>
                      </TableValue>
                    </TableCell>
                  </TableRow>,
                )}
              </TableColumn>
            </TableGroup>

            <TableGroup>
              {[...Array(offersNChunksColumns)]
                ?.map((_, index) =>
                  <TableColumn key={index}>
                    {offersNChunks && offersNChunks[index]
                      ?.map((value, index) =>
                        <TableRow
                          key={index}
                          draggable={value.checked}
                          onDragStart={(_e) => {
                            dragInfo.start.key = value.key
                            dragInfo.start.group = 'offers'
                          }}
                          onDragEnter={(_e) => {
                            dragInfo.end.key = value.key
                            dragInfo.end.group = 'offers'
                          }}
                          onDragOver={handleDragOver}
                          onDrop={handleOnDrop}
                        >
                          <TableCell>
                            <TableValue>
                              <DragHandleIcon
                                className={!value.checked ? 'unchecked' : ''}/>
                              <input
                                type="checkbox"
                                onChange={(e) => handleOnChange(e, 'offers')}
                                id={'offers_' + value.key}
                                name={'configs'}
                                value={value.key}
                                checked={value.checked}
                                disabled={Boolean(value.uncheckable)}
                              />
                              <label htmlFor={'offers_' + value.key}>
                                {t(`kacc:${value.key}`)}
                              </label>
                            </TableValue>
                          </TableCell>
                        </TableRow>,
                      )}
                  </TableColumn>,
                )}
            </TableGroup>
          </TableBodyWrapper>
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
