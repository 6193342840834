import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Sidebar as SidebarPro, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter'
import RemoveIcon from '@mui/icons-material/Remove'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import styled from 'styled-components'
import { t } from 'i18next'
import { Theme } from '@mui/material'

import { useDimensionsWindow } from '../../shared/lib'
import { GuestLogoIcon } from '../../shared/ui/icon-assets/guest-logo'
import { useAppStore } from '../../entities/app'

/*
  https://github.com/azouaoui-med/react-pro-sidebar
  https://azouaoui-med.github.io/react-pro-sidebar/?path=/docs/submenu--root-styles
*/
export function Sidebar() {
  const { sidebarOpen, setSidebarOpen } = useAppStore()
  const { screenSize } = useDimensionsWindow()
  const [collapsedNeeded, setCollapsedNeeded] = useState(screenSize.width > 768)

  const theme:Theme = useTheme()

  const isRouteActive = (route: string) => window.location.pathname === route

  const isRouteWithChildrenActive = (route: string) => {
    const currentPath = window.location.pathname
    return currentPath === route || currentPath.startsWith(`${route}/`)
  }

  useEffect(() => {
    setCollapsedNeeded(screenSize.width > 768)
  }, [screenSize])

  return (
    <StyledSidebar>
      <SidebarPro
        onBackdropClick={() => setSidebarOpen(false)}
        toggled={sidebarOpen}
        customBreakPoint={`${theme.breakpoints.values.sm}px`}
        collapsed={collapsedNeeded && !sidebarOpen}
        collapsedWidth="0px"
        transitionDuration={0}
        backgroundColor={theme.sidebar.background}
        rootStyles={{ borderRight: `1px solid ${theme.colors.border.primary}` }}
        width={'300px'}
      >
        <StyledLogo>
          <a href="/">
            <GuestLogoIcon design="sidebar"/>
          </a>
        </StyledLogo>

        <Menu>
          <MenuItem
            active={isRouteActive('/')}
            component={<Link to="/"/>}
            icon={<SpaceDashboardOutlinedIcon/>}
          >
            {t('global:Dashboard')}
          </MenuItem>

          <MenuItem
            active={isRouteActive('/domains-watcher')}
            component={<Link to="/domains-watcher"/>}
            icon={<PublishedWithChangesIcon/>}
          >
            {t('global:Domains watcher')}
          </MenuItem>

          <MenuItem
            active={isRouteWithChildrenActive('/custom-reports')}
            component={<Link to="/custom-reports"/>}
            icon={<AddRoadIcon/>}
          >
            {t('global:Custom reports')}
          </MenuItem>

          <SubMenu
            defaultOpen={isRouteActive('/kacc')
              || isRouteActive('/kacc/integrations')
              || isRouteActive('/kacc/campaign-list')
              || isRouteActive('/kacc/campaign-changes')}
            component={<Link to="/kacc"/>}
            icon={<AnalyticsOutlinedIcon/>}
            label={t('global:KACC')}>

            <MenuItem
              active={isRouteActive('/kacc')}
              component={<Link to="/kacc"/>}
              icon={<RemoveIcon/>}>
              {t('global:КАСС catalog')}
            </MenuItem>
            <MenuItem
              active={isRouteActive('/kacc/integrations')}
              component={<Link to="/kacc/integrations"/>}
              icon={<RemoveIcon/>}>
              {t('global:Integrations')}
            </MenuItem>
            <MenuItem
              active={isRouteActive('/kacc/campaign-list')}
              component={<Link to="/kacc/campaign-list"/>}
              icon={<RemoveIcon/>}>
              {t('global:Campaign list')}
            </MenuItem>
            <MenuItem
              active={isRouteActive('/kacc/campaign-changes')}
              component={<Link to="/kacc/campaign-changes"/>}
              icon={<RemoveIcon/>}>
              {t('global:Campaign changes history')}
            </MenuItem>
          </SubMenu>

          <SubMenu
            defaultOpen={isRouteActive('/subdomains-generator')
              || isRouteActive('/subdomains-generator/settings')}
            component={<Link to="/subdomains-generator"/>}
            icon={<PhotoFilterIcon/>}
            label={t('global:Subdomains generator')}>

            <MenuItem
              active={isRouteActive('/subdomains-generator')}
              component={<Link to="/subdomains-generator"/>}
              icon={<RemoveIcon/>}>
              {t('global:Generator')}
            </MenuItem>
            <MenuItem
              active={isRouteActive('/subdomains-generator/settings')}
              component={<Link to="/subdomains-generator/settings"/>}
              icon={<RemoveIcon/>}>
              {t('global:Settings')}
            </MenuItem>
          </SubMenu>

          <SubMenu
            defaultOpen={isRouteActive('/white-generator')}
            component={<Link to="/white-generator"/>}
            icon={<AutoFixHighIcon/>}
            label={t('global:White page generator')}>

            <MenuItem
              active={isRouteActive('/white-generator')}
              component={<Link to="/white-generator"/>}
              icon={<RemoveIcon/>}>
              {t('global:Generator')}
            </MenuItem>
          </SubMenu>

          <MenuItem
            active={isRouteWithChildrenActive('/watch-cat')}
            component={<Link to="/watch-cat"/>}
            icon={<TravelExploreIcon/>}>
            {t('global:Watch Cat')}
          </MenuItem>
        </Menu>

      </SidebarPro>
    </StyledSidebar>
  )
}

const StyledSidebar = styled.div`
  height: 100%;

  aside {
    height: 100%;
  }

  .ps-menu-root {
    & ul {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    & .ps-menuitem-root {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    & .ps-menu-icon {
      margin-right: 5px;
      height: 18px;
      min-width: 18px;
      width: 18px;

      & .MuiSvgIcon-root {
        font-size: 18px;
      }
    }

    & .ps-menu-button {
      background-color: inherit;
      border-radius: 4px;
      color: #A1B7BF !important;
      flex-shrink: 0;
      display: flex;
      gap: 5px;
      margin-right: 16px;
      margin-left: 16px;
      padding: 13px 5px 13px 10px;
      height: 44px !important;
      width: auto;

      &.ps-active,
      &:hover {
        color: #FFFFFF !important;
        background-color: #407698 !important;
      }

      &.ps-open {
        color: #FFFFFF !important;
      }

      & .ps-menu-label {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
      }
    }
  }

  & .ps-submenu-content {
    background-color: inherit;

    & .MuiSvgIcon-root {
      font-size: 18px;
    }
  }

  & .ps-submenu-expand-icon {
    margin-top: -3px;
    margin-right: 5px;

    & > span {
      height: 6px;
      width: 6px;
    }
  }
`

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
`
