import React from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { IntegrateFormValues } from '../types'
import { useKaccAntyListStore } from '../../../../entities/kacc/integration'

import { IntegrationsTable } from './integrations-table'

export const IntegrationForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { integrate, integrateLoading, getList } = useKaccAntyListStore()
  const [search] = useSearchParams()

  const validationSchema = Yup.object().shape({
    integrates: Yup.array()
      .of(
        Yup.object().shape({
          integrate: Yup.number(),
        }),
      )
      .nullable(),
  })

  const formik = useFormik<IntegrateFormValues>({
    initialValues: {
      integrates: null,
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { integrates } = values
      const sendValues = integrates?.map(item => item.id) ?? null

      try {
        const messages = await integrate(sendValues)

        messages.forEach((item) => {
          enqueueSnackbar(item.message, { variant: item.variant })
        })

        await getList(search)

      } catch (errors) {
        enqueueSnackbar(`${t('kacc:Something went wrong!')} ${errors}`, { variant: 'error' })
      } finally {
        formikHelpers.setSubmitting(false)
        formikHelpers.resetForm()
        if (sendValues && sendValues.length > 0) {
          formik.setFieldValue('integrates', [])
        }
      }
    },
  })

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <IntegrationsTable formik={formik}/>

      <SubmitButton
        startIcon={integrateLoading
          ? <CircularProgress size="1rem"/>
          : null}
        disabled={integrateLoading
          || formik.values.integrates === null}
        type={'submit'}
        size={'small'}
        variant={'contained'}
      >
        {integrateLoading
          ? t('kacc:Integrating')
          : t('kacc:Integrate')}
      </SubmitButton>
    </form>
  )
}

const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    background: #179EAE;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    line-height: 16px;
    margin-top: 16px;
    padding: 12px 80px;
    height: 40px;
  }

  & .MuiButton-startIcon {
    margin: 0;
  }
`
