import styled from 'styled-components'

import { AddDomainButton } from '../../../features/domains-watcher/add-edit-domain'
import { DisabledFilter } from '../../../features/domains-watcher/filter-disabled'
import { ExpiredFilter } from '../../../features/domains-watcher/filter-expired'
import { FBStatusFilter } from '../../../features/domains-watcher/filter-fb'
import { SourceFilter } from '../../../features/domains-watcher/filter-source'
import { ResetAllFilters } from '../../../features/domains-watcher/reset-all-filters'
import { DomainSearchFilter } from '../../../features/domains-watcher/search-domains'
import { UpdateButton } from '../../../features/domains-watcher/update-table'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <UpdateButton/>
        <AddDomainButton/>
        <DomainSearchFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <DisabledFilter/>
        <FBStatusFilter/>
        <SourceFilter/>
        <ExpiredFilter/>
        <ResetAllFilters/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 16px;
`

const SFiltersGroup = styled.div`
  display: flex;
  gap: 15px;
`
