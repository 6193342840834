export const getFormattedFullTime = (stamp: string) => {
  const s = new Date(Date.parse(stamp))
  let hours = s.getUTCHours(),
    minutes = s.getUTCMinutes(),
    seconds = s.getUTCSeconds()

  const newHours = (hours < 10) ? `0${hours}` : hours
  const newMinutes = (minutes < 10) ? `0${minutes}` : minutes
  const newSeconds = (seconds < 10) ? `0${seconds}` : seconds

  return`${newHours}:${newMinutes}:${newSeconds}`
}