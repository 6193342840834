import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'
import { PeriodFilterType } from '../../../../entities/domains-generator/generator'

export const PeriodFilterParam = 'period'

/**
 * Table Options - Period select feature
 * @constructor {JSX.Element}
 */
export const TableOptionsPeriod = () => {
  const { filterActive } = useFilter(PeriodFilterParam, '')
  const [ search, setSearch] = useSearchParams()
  const filters = Object.keys(PeriodFilterType)

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(PeriodFilterParam, value as string)
    if (!value && filterActive) search.delete(PeriodFilterParam)
    setSearch(search, { replace: true })
  }

  // Check period query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && !(filterActive in PeriodFilterType)
      )) {
        search.delete(PeriodFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ? t(`subdomains-generator:${filterActive}`) : null }
      defaultValue={ filterActive ? t(`subdomains-generator:${filterActive}`) : null }
      onChange={ handleOnChange }
      options={ filters ?? [] }
      openOnFocus={ true }
      noOptionsText={t('subdomains-generator:No options')}
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => t(`subdomains-generator:${option}`) === value }
      renderOption={ (props, option) => (
        (option !== '' && <Box
          component="li"
          value={ option }
          {...props}
        >
          { t(`subdomains-generator:${option}`) }
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('subdomains-generator:Period') }
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
