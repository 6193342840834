import { create } from 'zustand'
import axios from 'axios'
import { t } from 'i18next'

import {
  getWhiteGeneratorData,
  getLastGeneration,
  uploadTemplateRequest,
  generateTemplates,
  uploadGenerations,
} from './api'
import { TemplatesData, TemplatesGenerationsData } from './types'

export interface IWhiteGeneratorStore {
  templates: TemplatesData | null,
  error: string | null,
  loading: boolean,
  getGeneratorData: () => Promise<void>

  generations: TemplatesGenerationsData | null
  generateError: string | null,
  generateLoading: boolean,
  generateMain: (values: object) => Promise<void>,

  lastGeneratedError: string | null,
  lastGeneratedLoading: boolean,
  getGeneratedData: () => Promise<void>,

  uploadTemplate: (title: string | null, file: File | null) => Promise<void>,
  uploadTemplateError: string | null,

  sendGeneratedLoading: boolean,
  sendGeneratedError: string | null,
  sendToKeitaro: (
      pack: string | null,
      name: string | null,
      title: string | null,
      comment: string | null,
      generations: TemplatesGenerationsData | null
  ) => Promise<void>,

  comment: string,
  setComment: (comment: string) => void,

  title: string,
  setTitle: (title: string) => void,
}

export const useWhiteGeneratorStore = create<IWhiteGeneratorStore>((set, get) => ({
  templates: null,
  error: null,
  loading: false,

  getGeneratorData: async () => {
    try {
      set({ templates: null })
      set({ error: null })
      set({ loading: true })

      const response = await getWhiteGeneratorData()

      if (!axios.isAxiosError(response)) {
        set({ templates: response.data.generator })
      }
    } catch (error) {
      let errorText = t('white-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },

  generations: null,
  generateError: null,
  generateLoading: false,
  generateMain: async (values: object) => {
    try {
      set({ generations: null })
      set({ generateError: null })
      set({ generateLoading: true })

      const response = await generateTemplates(values)

      if (!axios.isAxiosError(response)) {
        set({ generations: response.data.generations })
      }
    } catch (error) {
      let errorText = t('white-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ generateError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ generateLoading: false })
    }
  },

  lastGeneratedError: null,
  lastGeneratedLoading: false,
  getGeneratedData: async () => {
    try {
      set({ generations: null })
      set({ lastGeneratedError: null })
      set({ lastGeneratedLoading: true })

      const response = await getLastGeneration()

      if (!axios.isAxiosError(response)) {
        set({ generations: response.data.generations })
      }
    } catch (error) {
      let errorText = t('white-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ lastGeneratedError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ lastGeneratedLoading: false })
    }
  },

  uploadTemplateError: null,
  uploadTemplate: async (title: string | null, file: File | null) => {
    try {
      set({ loading: true })
      set({ uploadTemplateError: null })

      if (title !== null && file !== null) {
        await uploadTemplateRequest(title, file)
      }
    } catch (error) {
      let errorText = t('white-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ uploadTemplateError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },

  sendGeneratedError: null,
  sendGeneratedLoading: false,
  sendToKeitaro: async (
    pack: string | null,
    name: string | null,
    title: string | null,
    comment: string | null,
    generations: TemplatesGenerationsData | null,
  ) => {
    let titleGeneration = ''

    try {
      set({ sendGeneratedError: null })
      set({ sendGeneratedLoading: true })

      if (title === '' && pack !== null) {
        const foundItem = generations?.pack.find((item) => item.pack === pack)

        if (foundItem) {
          titleGeneration = foundItem.title || ''
        }
      }
      if (pack !== null && name !== null && title !== null && comment !== null) {
        const response = await uploadGenerations(pack, name, title ? title : titleGeneration, comment)

        if (!axios.isAxiosError(response)) {
          set({ generations: response.data.generations })
        }
      }
    } catch (error) {
      let errorText = t('white-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ sendGeneratedError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ sendGeneratedLoading: false })
    }
  },

  comment: '',
  setComment: (comment: string) => set({ comment }),

  title: '',
  setTitle: (title: string) => set({ title }),

}))
