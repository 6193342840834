import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'


export const ResetAllFilters = () => {
  const [ disabled, setDisabled ] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const locationHasFilters = location?.search.length > 0
  const locationPath = location?.pathname

  const handleOnClick = () => {
    navigate(locationPath)
  }

  // Update disabled if filters count changes
  useEffect(() => {
    setDisabled(!locationHasFilters)
  }, [locationHasFilters])

  return (
    <>
      <SButton
        disabled={ disabled }
        onClick={ handleOnClick }
        size={ 'small' }
        variant={ 'outlined' }
        type={ 'reset' }
      >
        { t('watch-cat:Reset filters') }
      </SButton>
    </>
  )
}

const SButton = styled(Button)`
  &.MuiButtonBase-root {
    box-shadow: 0 1px 1px 0 #aab7c1;
    padding-left: 16px;
    padding-right: 16px;
    min-width: 120px;
    white-space: nowrap;

    &:not([disabled]) {
      background: #FFEF9D;
      border-width: 0;
      color: #BD9406;
    }

    &[disabled] {
      background: #e8eef3;
      color: #78909c;
    }

    &:hover {
      background: #EFDE87;
    }
  }
`
