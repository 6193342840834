import { AxiosError, AxiosResponse } from 'axios'

import { request } from '../../../shared/api'

import type { DictionariesDataResponse, DomainsDataResponse, GroupsDataResponse } from './types'

export const createDictionaryRequest = (name: string, dict: string): Promise<AxiosResponse | AxiosError> =>
  request.post('/domains-generator/dict', { name, dict })

export const editDictionaryRequest = (id: number, name: string, dict: string): Promise<AxiosResponse | AxiosError> =>
  request.put(`/domains-generator/dict/${ id }`, { name, dict })

export const deleteDictionaryRequest = (id: number): Promise<AxiosResponse | AxiosError> =>
  request.delete(`/domains-generator/dict/${ id }`, {})

export const getDomainsDataRequest = (params: URLSearchParams): Promise<DomainsDataResponse | AxiosError> =>
  request.get('/domains-generator/domains/settings-list', { params })

export const disableDomainRequest = async (id: number): Promise<AxiosResponse | AxiosError> =>
  request.patch(`/domains-generator/domain-toggle/${ id }`, {})

export const getDictionariesDataRequest = async (): Promise<DictionariesDataResponse | AxiosError> =>
  request.get('/domains-generator/dict/list', {})

export const getGroupsDataRequest = async (): Promise<GroupsDataResponse | AxiosError> =>
  request.get('/domains-generator/groups', {})

export const syncKeitaro = (): Promise<AxiosResponse | AxiosError> =>
  request.get('/domains-generator/keitaro-sync', {})
