import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { Alert, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'
import { OptionsCount } from '../../../../features/domains-generator/generator/set-count'
import { OptionsVocabulary } from '../../../../features/domains-generator/generator/select-vocabulary'

import { TableDomains } from './table-domains'
import { TableOptions } from './table-options'

import type { DomainsGeneratorDictionaries } from '../../../../entities/domains-generator/generator'
import type { GeneratorFormValues } from '../types'

export const GeneratorForm = () => {
  const [loading, setLoading] = useState(false)
  const {
    generateMain,
    getLastGeneratedMain,
    data,
  } = useDomainsGeneratorStore()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object().shape({
    count: Yup.number()
      .transform((value) => {
        parseInt(value)
        return value === 0 ? null : value
      })
      .required(t('subdomains-generator:The count field is required')),
    domains: Yup.string()
      .nullable(),
    vocabulary: Yup.object().shape({
      id: Yup.number(),
    }).required(t('subdomains-generator:The vocabulary field is required')),
  })

  const formik = useFormik<GeneratorFormValues>({
    initialValues: {
      count: null,
      vocabulary: null,
      domains: null,
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { count, vocabulary, domains } = values
      const sendValues = {
        ...(count && { count: count }),
        ...(vocabulary && { dictionary: vocabulary.id }),
        ...(domains && { ids: domains }),
      }

      try {
        setLoading(true)
        await generateMain(sendValues)

        enqueueSnackbar(t('subdomains-generator:Subdomains were generated successfully!'), { variant: 'success' })

        await getLastGeneratedMain()
      } catch (error) {
        enqueueSnackbar(`${
          t('subdomains-generator:Something went wrong!')
        } ${
          t(`subdomains-generator:${error}`)
        }`, { variant: 'error' })
      } finally {
        setLoading(false)
        formikHelpers.setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('vocabulary', data?.dicts?.filter(item => item?.selected === true)[0] ?? null)
  }, [data?.dicts])

  useEffect(() => {
    formik.setFieldValue('count', data?.count ? String(data?.count) : null)
  }, [data?.count])

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <TableOptions/>
      <TableDomains formik={ formik }/>

      <Options>
        <OptionsVocabulary
          value={formik.values.vocabulary}
          options={data?.dicts}
          handleOnChange={ (value: DomainsGeneratorDictionaries | null) => {
            formik.setFieldValue('vocabulary', value)
          } }
          error={ Boolean(formik.touched.vocabulary && formik.errors.vocabulary) }
        />
        <OptionsCount
          value={formik.values.count}
          handleOnChange={ (value: string | null ) => formik.setFieldValue('count', value) }
          error={ Boolean(formik.touched.count && formik.errors.count) }
        />
      </Options>

      { Boolean(
        formik.touched.submit
          && Object.keys(formik.errors).length > 0,
      ) && (
        <div
          style={{
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          <Alert
            severity="error"
            style={{
              width: 'fit-content',
            }}
          >
            { formik.errors.count && <div>
              { t(`subdomains-generator:${formik.errors.count}`) } </div>
            }
            { formik.errors.vocabulary && <div>
              { t(`subdomains-generator:${formik.errors.vocabulary}`) } </div>
            }
          </Alert>
        </div>
      )}

      <Button
        startIcon={ loading
          ? <CircularProgress size="1rem"/>
          : null }
        disabled={ loading
            || formik.values.domains === null }
        type={ 'submit' }
        sx={(theme) => theme.customButtons.textSizeExtraLargeButton}
        variant={ 'contained' }
      >
        { loading
          ? t('subdomains-generator:Generating')
          : t('subdomains-generator:Generate') }
      </Button>
    </form>
  )
}

const Options = styled.div`
  border-bottom: 1px dotted #B7C6D3;
  display: flex;
  gap: 16px;
  padding: 16px 0;
`