import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { t } from 'i18next'

import { useKaccAntyListStore } from '../../../../../../entities/kacc/integration'
import { HistoryStatuses } from '../../../../../../entities/kacc/integration/types'
import { getPrettyDate, useDimensions } from '../../../../../../shared/lib'

import {
  Table,
  TableBody,
  TableModalCell,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableValue,
  TableWrapper,
} from './table'

export function HistoryTable() {
  const { integrationsHistory, getIntegrationsHistory } = useKaccAntyListStore()

  // table sizing
  const tableRef = useRef(null)
  const { offsetTop } = useDimensions(tableRef)
  const maxHeight = `calc(100vh - ${offsetTop + 140}px)`

  useEffect(() => {
    getIntegrationsHistory()
  }, [])

  return (
    <Table>
      <TableWrapper
        className="overflow-auto"
        // style={ { height: maxHeight } }
      >
        {/*{ hasData &&(*/}
        <TableHeader>
          <TableRow>
            <TableModalCell>
              <TableHeaderLabel className={'disabled'}>
                {t('kacc:Anty id')}
              </TableHeaderLabel>
            </TableModalCell>
            <TableModalCell>
              <TableHeaderLabel className={'disabled'}>
                {t('kacc:Account id')}
              </TableHeaderLabel>
            </TableModalCell>
            <TableModalCell>
              <TableHeaderLabel className={'disabled'}>
                {t('kacc:Pixel Id')}
              </TableHeaderLabel>
            </TableModalCell>
            <TableModalCell>
              <TableHeaderLabel className={'disabled'}>
                {t('kacc:Date updated')}
              </TableHeaderLabel>
            </TableModalCell>
            <TableModalCell>
              <TableHeaderLabel className={'disabled'}>
                {t('kacc:Status')}
              </TableHeaderLabel>
            </TableModalCell>
          </TableRow>
        </TableHeader>

        <TableBody ref={tableRef} style={{ maxHeight: maxHeight }}>
          {
            integrationsHistory?.map((historyData, index) => (
              <TableRow key={index + 1}>
                <TableModalCell>
                  <TableValue>
                    {historyData.antyId}
                  </TableValue>
                </TableModalCell>
                <TableModalCell>
                  <TableValue>
                    {historyData.accountId}
                  </TableValue>
                </TableModalCell>
                <TableModalCell>
                  <TableValue>
                    {historyData.pixelId || ' - '}
                  </TableValue>
                </TableModalCell>
                <TableModalCell>
                  <TableValue>
                    {getPrettyDate(historyData.created_at, true)}
                  </TableValue>
                </TableModalCell>
                <TableModalCell>
                  <TableValue>
                    <SStatus status={historyData.status}>
                      {t(`kacc:${historyData.status}`)}
                    </SStatus>
                  </TableValue>
                </TableModalCell>
              </TableRow>
            ))
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}

const SStatus = styled.div<{ status: HistoryStatuses }>`
  display: inline-flex;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 7px;
  width: auto;

  ${({ status }) => {
    switch (status) {
    case HistoryStatuses.created:
    case HistoryStatuses.updated:
      return css`
          background: #DCF6E9;
          color: #10AF6E;`
    }
  }
}
`
