import React from 'react'
import { Alert } from '@mui/material'

import type { AlertMessageProps } from './types'

export const AlertMessage: React.FC<AlertMessageProps> = (props) => {
  return (
    <Alert severity={props.severity || 'success'} {...props}>
      {props.children}
    </Alert>
  )
}