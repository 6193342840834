import { create } from 'zustand'

import { LOCALSTORAGE_TRANSLATION_KEY } from '../../shared/configs/constants'

export interface ITranslationStore {
    translationCurrent: string | null,

    setTranslationCurrent: (translation: string) => void,
}

export const useTranslationStore = create<ITranslationStore>((set, get) => ({
  translationCurrent: localStorage.getItem(LOCALSTORAGE_TRANSLATION_KEY) || null,

  setTranslationCurrent: (translation) => {
    localStorage.setItem(LOCALSTORAGE_TRANSLATION_KEY, String(translation))
    set({ translationCurrent: String(translation) })
  },
}))
