import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'

import { StatusFilterType } from './types'

export const StatusFilterParam = 'active'

/**
 * StatusFilter feature
 */
export function StatusFilter() {
  const { filterActive } = useFilter(StatusFilterParam, '')
  const { offsetActive } = usePagination()
  const [ search, setSearch] = useSearchParams()
  const filters = Object.values(StatusFilterType)

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(StatusFilterParam, value as string)
    if (!value && filterActive) search.delete(StatusFilterParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check source query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && !(filters.some(value => value === filterActive))
      )){
        search.delete(StatusFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ? t(`watch-cat:Status_${filterActive}`) : null }
      defaultValue={ filterActive ? t(`watch-cat:Status_${filterActive}`) : null }
      onChange={ handleOnChange }
      options={ filters ?? [] }
      openOnFocus={ true }
      noOptionsText={t('watch-cat:No options')}
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => t(`watch-cat:Status_${option}`) === value }
      renderOption={ (props, option) => (
        (option !== '' && <Box
          component="li"
          value={ option }
          {...props}
          key={ Math.random() }
        >
          { t(`watch-cat:Status_${option}`) }
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('watch-cat:Select status') }
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
