import React from 'react'
import { HelmetProvider } from 'react-helmet-async'

HelmetProvider.canUseDOM = true

const withHelmet = (component: () => React.ReactNode) => () => (
  <HelmetProvider>
    {component()}
  </HelmetProvider>
)

export default withHelmet