import styled from 'styled-components'

import { Button } from '../../../../shared/ui/button'
import {
  UTable,
  UTableHeader,
  UTableHeaderLabel,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)`
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
`

export const TableWrapper = styled(UTableWrapper)`
  border: 1px solid #efefef;
  border-radius: 4px;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    height: 59px;
  }
`

export const TableHeader = styled(UTableHeader)`
  background: #E3F2F6;
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
  color: #32383E;
  position: sticky;
  top: 0;
  z-index: 99;
`

export const TableHeaderLabel = styled(UTableHeaderLabel)`
  gap: 0.5rem;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.2px;

  &:after, &:before {
    content: "";
    border-color: #d9d7d7;
    border-style: solid;
    border-radius: 1px;
    border-width: 4px;
    position: absolute;
    right: -1rem;
  }

  &:after {
    border-bottom-width: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 5px;
    margin-bottom: -6px;
  }

  &:before {
    border-bottom-width: 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    margin-top: -6px;
  }

  &.asc:after, &.desc:before {
    border-color: #a9b5b9;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &:not(.disabled):hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;

    &:after, &:before {
      display: none;
    }
  }
`

export const TableRow = styled(UTableRow)`
  min-height: 47px;
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
`

export const CampaignRow = styled(UTableRow)`
  min-height: 47px;
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
`

export const TableCell = styled(UTableCell)`
  padding: 12px 0 12px 16px;

  &:nth-child(1) {
    min-width: 200px;
    flex-grow: 1;
  }

  &:nth-child(2) {
    min-width: 200px;
    flex-grow: 2;
  }

  &:nth-child(3) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(4) {
    min-width: 200px;
    flex-grow: 1;
  }

  &:nth-child(5) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(6) {
    min-width: 200px;
    flex-grow: 1;
  }

  &:nth-child(7) {
    min-width: 220px;
    flex-grow: 1;
  }

  &:nth-child(8) {
    min-width: 150px;
    text-align: center;
    flex-grow: 1;
  }

  &:nth-child(9) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(10) {
    min-width: 200px;
    flex-grow: 1;
  }

  &:nth-child(11) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(12) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(13) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(14) {
    min-width: 150px;
    flex-grow: 1;
  }

  &:nth-child(15) {
    min-width: 180px;
    flex-grow: 1;
  }
`

export const TableValue = styled(UTableValue)`
  word-wrap: break-word;

  & label {
    margin-left: 5px;
    font-size: 14px;
  }

  & span {
    &.white-space {
      white-space: nowrap;
    }
  }
`

export const STagsValue = styled(TableValue)`
  display: flex;
  flex-wrap: wrap;
`

export const SStatusValue = styled(TableValue)``

export const TableAlertMessage = styled(UTableAlertMessage)`
  width: 100%;
`

export const SBlockWithCollapse = styled.div`
  max-height: 100%;
`

export const TableBody = styled(UTableBody)`
  border-top: 1px solid rgba(39, 85, 114, 0.10);

  & ${TableRow} {
    &:not(.anty) {
      background-color: white;
    }

    &.anty {
      background-color: #F6FBFC;

      &.centered {
        align-items: center;
        justify-content: center;
      }
    }

    &.aligh-baseline {
      align-items: baseline;
    }
  }

  & ${CampaignRow} {
    //box-shadow: 0px -1px 0px 0px #C2DDED inset;
    background-color: #F6FBFC;

    & ${TableCell} {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background-color: white;
        min-height: 47px;
        box-shadow: 0px -1px 0px 0px #C2DDED inset;
      }
    }
  }

  & ${TableCell} {
    &:nth-child(1) ${TableValue} {
      display: inline-flex;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 500;
      width: inherit;
      align-items: center;

      &:has(.is-true) {
        background: #DCF6E9;
        color: #10AF6E;
      }

      &:has(.is-false) {
        background: #FFE4E4;
        color: #D32B30;
      }
    }
  }
`

export const UTableList = styled.div``

export const UTableListItem = styled.div``

export const SAntyMore = styled.div`
  display: inline-block;
`

export const SAntyMoreButton = styled.a`
  border-radius: 4px;
  border: 1px solid #3FBDF6;
  box-shadow: 0 2px 4px 0 rgba(39, 85, 114, 0.15);
  color: #3FBDF6;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding: 5px 20px;
  transition: all 0.3s ease-in-out;
  text-decoration: unset;

  &:hover {
    border: 1px solid #28abe3;
    color: #28abe3;
  }
`

export const SAlignCell = styled.div`
  display: flex;
  align-items: center;
`

export const SCellTitle = styled.div`
  white-space: nowrap;
  margin-right: 16px;
  flex-grow: 1;
  text-align: left;
`

export const SFlexGrowContent = styled.div`
  white-space: nowrap;
  flex-grow: 1;
`

export const SAntyStatus = styled.div`
  text-transform: uppercase;
`

export const SButtonLinkWrap = styled.div`

`
export const SButtonLink = styled(Button)`
  &.MuiButtonBase-root {
    background: #CBFFE4;
    box-shadow: 0 1px 1px 0 rgba(39, 85, 114, 0.23);
    color: #109B87;
    padding: 4px 5px;
    line-height: 16px;
    font-size: 12px;
    height: 24px;

    &:hover {
      background: #B4EED1;
    }
  }
`

