import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { darken } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { useUserStore } from '../../../../entities/user'
import { ModalOverlayMui } from '../../../../shared/ui/modal-overlay-mui'

import { Table, TableBody, TableCell, TableRow, TableValue, TableWrapper } from './table'
import { SwitchSessionsModalType } from './types'

export const SwitchSessionsModal = ({ onClose, isOpen }: SwitchSessionsModalType) => {
  const { session, sessions, setSession, logout } = useUserStore()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  return (
    <ModalOverlayMui
      isOpen={isOpen}
      handleClose={onClose}
    >
      <SModalHeader>
        <SModalHeaderText>
          {t('global:Manage sessions')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <SModalForm>
          <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px' }}>
            <Button
              size={'medium'}
              variant="contained"
              color="success"
              onClick={() => navigate('/signin')}
            >
              {t('global:Add new session (Sign in)')}
            </Button>
          </div>
          <Table>
            <TableWrapper className={'overflow-auto'}>
              <TableBody>
                {sessions && sessions
                  ?.sort((a, b) => a?.email.localeCompare(b?.email))
                  ?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TableValue>
                          {item?.email}
                        </TableValue>
                      </TableCell>

                      <TableCell>
                        <Button
                          size={'small'}
                          variant="contained"
                          color="primary"
                          disabled={item?.id === session?.id}
                          onClick={() => {
                            setSession(item)
                            enqueueSnackbar(
                              t('global:{{name}} is now activated', { name: item.email }),
                              { variant: 'success' },
                            )
                            setTimeout(() => {
                              window.location.reload()
                            }, 3000)
                          }}
                        >{ item?.id === session?.id ? t('global:Active'): t('global:Activate')}
                        </Button>
                      </TableCell>

                      <TableCell>
                        <Tooltip
                          title={
                            item?.id !== session?.id
                              ? t('global:Delete session')
                              : t('global:Cant delete active session, use logout instead')}>
                          <SDeleteButton
                            size={'medium'}
                            variant="contained"
                            color="primary"
                            disabled={item?.id === session?.id}
                            onClick={() => {
                              logout(item)
                              enqueueSnackbar(
                                t('global:{{name}} was deleted', { name: item.email }),
                                { variant: 'success' },
                              )
                            }}
                          ><DeleteForeverIcon/>
                          </SDeleteButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </TableWrapper>
          </Table>

          <div
            style={
              {
                display: 'flex',
                marginLeft: 'auto',
                marginTop: 'auto',
                gap: '15px',
              }
            }
          >
            <SModalCancel
              variant={'outlined'}
              onClick={onClose}
              size={'medium'}
              type={'button'}
              style={{ marginLeft: 'auto' }}
            >
              {t('global:Close')}
            </SModalCancel>
          </div>
        </SModalForm>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalBody = styled.div`
    display: inline-flex;
    padding: 16px 24px;
    height: 100%;
    width: 500px;
`
const SModalHeader = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.primary.main};
    display: inline-flex;
    padding: 16px 24px;
    flex-shrink: 0;
    justify-content: space-between;
`
const SModalHeaderText = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha.white[100]};

    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
`
const SModalForm = styled.form`
    display: flex;
    flex-direction: column;
    min-height: 280px;
    height: 100%;
    width: 100%;
`
// .attrs({ as: 'a' })
const SModalCancel = styled(Button)`
    &.MuiButtonBase-root {
        border-color: #FF6961;
        color: #FF6961;

        &:hover {
            border-color: ${darken('#FF6961', 0.05)};
            color: ${darken('#FF6961', 0.05)};
        }
    }
`

const SDeleteButton = styled(Button)`
    &.MuiButtonBase-root {
        background-color: #FF6961;
        color: #FFF;

        &:hover {
            background-color: ${darken('#FF6961', 0.1)};
        }
    }
`
