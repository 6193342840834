import axios, { AxiosError } from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getMembersList } from './api'

import type { SettingsMembersListData } from './types'

export interface IManageMembersStore {
    loading: boolean,
    error: AxiosError | null,
    members: SettingsMembersListData[] | null,
    getMembersList: () => void,
}

export const useManageMembersStore = create<IManageMembersStore>((set, get) => ({
  loading: false,
  error: null,
  members: null,
  getMembersList: async () => {
    try {
      set({ members: null })
      set({ error: null })
      set({ loading: true })

      const response = await getMembersList()
      if (!axios.isAxiosError(response)) {
        set({ members: response.data.response })
      }
    } catch (error) {
      let errorText = t('settings:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
