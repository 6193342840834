import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export type OfferListDataGeo = {
  [key: string]: any
}

export enum OfferListSortKeys {
  createdAt = 'date_created'
}

export type OfferListDataGoal = {
  id: number,
  active: boolean,
  name: string,
  type: string,
  code: string,
  geo: string,
  price: number,
  cash: number,
  up: number,
  cross: number,
  percent: number,
  currency: boolean | string
}

export type OfferListData = {
  id: number,
  id_generated: number,
  name: string,
  active: boolean,
  idAffiliateNetwork: number,
  network: string,
  url: string,
  date_created: string,
  date_updated: string,
  geo: OfferListDataGeo[],
  goal: OfferListDataGoal[]
}

export type OffersData = {
  count: number | null,
  offers: OfferListData[],
  networks: string[],
  geo: string[]
  goalsTypes: string[]
}

export type DataResponse = {
  data: OffersData
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}
