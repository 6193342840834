import { t } from 'i18next'
import { Box, Button, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React from 'react'

export function RegisterTokenInvalid() {
  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          mt: 10,
        }}>
        <Typography
          variant="h1"
          sx={(theme) => theme.customTypographyStyles.NotFound404}
        >
          {t('auth:Error')}
        </Typography>
        <Typography
          variant="h5"
          sx={(theme) => theme.customTypographyStyles.NotFoundHeader}
        >
          {t('auth:Sorry, page was not found!')} 😭
        </Typography>
        <Typography
          variant="subtitle1"
          sx={(theme) => theme.customTypographyStyles.NotFoundSubHeader}
        >
          {t('auth:Register link is invalid or has expired!')}
        </Typography>
        <Button
          sx={{
            mt: 3,
            mb: 10,
          }}
          color="primary"
          size="large"
          variant="contained"
          href="/"
        >
          <ArrowBackIcon/>
          {t('auth:Back to Sign in page')}
        </Button>
      </Box>
    </>
  )
}