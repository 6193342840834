import { useEffect, useRef } from 'react'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'

import { AlertMessage } from '../../../../shared/ui/alert-message'
import { SortColumnParam, SortTypeParam, useDimensions, useSort } from '../../../../shared/lib'
import { useKaccCatalogStore, KaccCatalogSortEnum } from '../../../../entities/kacc/catalog'

import {
  FacebookRow,
  CampaignRow,
  OfferRow,
  Table,
  TableAlertMessage,
  TableBody,
  TableTotals,
  TableHeader,
  AntyRow,
  TableWrapper,
  TableGroup,
  TableData,
} from './table'
import { TableBlockHeaderGroups } from './table-block-header-groups'
import { TableBlockTotalsGroups } from './table-block-totals-groups'
import { TableBlockBodyGroups } from './table-block-body-groups'

export function TableBlock() {
  const [search, setSearch] = useSearchParams()
  const { sortActive, sortColumn } = useSort()
  const {
    catalog,
    catalogTotals,
    catalogError,
    catalogLoading,
    settings,
    forceSyncLoading,
    forceSyncError,
  } = useKaccCatalogStore()

  // Check if catalog has data and settings and not loading and not error
  const hasData = catalog && catalog?.length > 0 && !catalogError && !catalogLoading && settings

  // Get and set table height
  const tableRef = useRef(null)
  const tableRefSize = useDimensions(tableRef)
  const maxHeight = `calc(100vh - ${tableRefSize.offsetTop + 95}px)`

  // Remove sort if not in KaccCatalogSortEnum
  useEffect(() => {
    if (sortColumn && !(sortColumn in KaccCatalogSortEnum)) {
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }
  }, [sortColumn])

  return (
    <Table>
      <TableWrapper>

        { /*{ Header }*/}
        <TableHeader>
          {
            hasData &&
            <AntyRow>
              { /*{ Anty }*/}
              <TableBlockHeaderGroups
                settings={settings} settingsKey={'anty'}
                sortActive={sortActive} sortColumn={sortColumn}/>

              <TableGroup>
                { /*{ Facebook }*/}
                <FacebookRow>
                  <TableBlockHeaderGroups
                    settings={settings} settingsKey={'facebook'}
                    sortActive={sortActive} sortColumn={sortColumn}/>

                  <TableGroup>
                    { /*{ Campaigns }*/}
                    <CampaignRow>
                      <TableBlockHeaderGroups
                        settings={settings} settingsKey={'campaigns'}
                        sortActive={sortActive} sortColumn={sortColumn}/>

                      <TableGroup>
                        { /*{ Offers }*/}
                        <OfferRow>
                          <TableBlockHeaderGroups
                            settings={settings} settingsKey={'offers'}
                            sortActive={sortActive} sortColumn={sortColumn}/>

                        </OfferRow>
                      </TableGroup>
                    </CampaignRow>
                  </TableGroup>
                </FacebookRow>
              </TableGroup>
            </AntyRow>
          }
        </TableHeader>

        { /*{ Body }*/}
        <TableBody ref={tableRef} style={{ minHeight: maxHeight, maxHeight: maxHeight }}>

          <TableData>
            { /*{ Anty }*/}
            {
              hasData && catalog?.map((antyData, antyIndex) =>
                (
                  <AntyRow key={antyIndex + 1}>
                    <TableBlockBodyGroups
                      settings={settings} settingsKey={'anty'}
                      dataItem={antyData}/>

                    { /*{ Facebook }*/}
                    <TableGroup>
                      {
                        antyData?.facebook
                          ?.map((facebookData, facebookIndex) =>
                            (<FacebookRow key={facebookIndex + 1}>
                              <TableBlockBodyGroups
                                settings={settings} settingsKey={'facebook'}
                                dataItem={facebookData}/>

                              { /*{ Campaigns }*/}
                              <TableGroup>
                                {
                                  facebookData?.campaigns
                                    ?.map((campaignsData, campaignsIndex) =>
                                      (<CampaignRow key={campaignsIndex + 1}>
                                        <TableBlockBodyGroups
                                          settings={settings} settingsKey={'campaigns'}
                                          dataItem={campaignsData}/>

                                        { /*{ Offers }*/}
                                        <TableGroup>
                                          {
                                            campaignsData?.offers
                                              ?.map((offersData, offersIndex) =>
                                                (<OfferRow
                                                  key={offersIndex + 1} style={{
                                                    height: campaignsData?.offers?.length === 1 ? '100%' : '',
                                                  }}>
                                                  <TableBlockBodyGroups
                                                    settings={settings}
                                                    settingsKey={'offers'}
                                                    dataItem={offersData}
                                                    offerIdTooltip={
                                                      <div style={{ textAlign: 'left' }}>
                                                        {
                                                          antyData?.anty && (
                                                            <div>
                                                              <b>{`${t('kacc:anty')}: `}</b>
                                                              {`${antyData?.anty}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          antyData?.anty_id && (
                                                            <div>
                                                              <b>{`${t('kacc:anty_id')}: `}</b>
                                                              {`${antyData?.anty_id}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          facebookData?.facebook_id && (
                                                            <div>
                                                              <b>{`${t('kacc:facebook_id')}: `}</b>
                                                              {`${facebookData?.facebook_id}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          campaignsData?.campaign && (
                                                            <div>
                                                              <b>{`${t('kacc:campaign')}: `}</b>
                                                              {`${campaignsData?.campaign}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          campaignsData?.campaign_id && (
                                                            <div>
                                                              <b>{`${t('kacc:campaign_id')}: `}</b>
                                                              {`${campaignsData?.campaign_id}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          offersData?.offer && (
                                                            <div>
                                                              <b>{`${t('kacc:offer')}: `}</b>
                                                              {`${offersData?.offer}`}
                                                            </div>
                                                          )
                                                        }
                                                        {
                                                          offersData?.offer_id && (
                                                            <div>
                                                              <b>{`${t('kacc:offer_id')}: `}</b>
                                                              {`${offersData?.offer_id}`}
                                                            </div>
                                                          )
                                                        }
                                                      </div>
                                                    }/>
                                                </OfferRow>),
                                              )
                                          }
                                        </TableGroup>
                                      </CampaignRow>),
                                    )

                                }
                              </TableGroup>
                            </FacebookRow>),
                          )
                      }
                    </TableGroup>
                  </AntyRow>
                ),
              )
            }
          </TableData>

          { /*{ Footer }*/}
          {
            catalogTotals && hasData &&
                        (
                          <TableTotals>

                            { /*{ Anty }*/}
                            <AntyRow>
                              <TableBlockTotalsGroups
                                settings={settings} settingsKey={'anty'}
                                catalogTotals={catalogTotals}/>

                              <TableGroup>
                                { /*{ Facebook }*/}
                                <FacebookRow>
                                  <TableBlockTotalsGroups
                                    settings={settings} settingsKey={'facebook'}
                                    catalogTotals={catalogTotals}/>

                                  <TableGroup>
                                    { /*{ Campaigns }*/}
                                    <CampaignRow>
                                      <TableBlockTotalsGroups
                                        settings={settings} settingsKey={'campaigns'}
                                        catalogTotals={catalogTotals}/>

                                      <TableGroup>
                                        { /*{ Offers }*/}
                                        <OfferRow>
                                          <TableBlockTotalsGroups
                                            settings={settings} settingsKey={'offers'}
                                            catalogTotals={catalogTotals}/>

                                        </OfferRow>
                                      </TableGroup>
                                    </CampaignRow>
                                  </TableGroup>
                                </FacebookRow>
                              </TableGroup>
                            </AntyRow>
                          </TableTotals>
                        )}

          { /*{ Alert messages }*/}
          {
            !hasData && !catalogError && !forceSyncError && !catalogLoading &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="warning">
                              {t('kacc:Oops! The table is - no data!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }

          {
            (forceSyncError || catalogError) && !catalogLoading &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="error">
                              {t(`kacc:${catalogError}`)}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }

          {
            forceSyncLoading && !forceSyncError && !catalogError &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="warning">
                              {t('kacc:Wait a minute! Synchronization is already in progress!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }

          {
            catalogLoading && !catalogError && !forceSyncLoading &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="info">
                              {t('kacc:Just a minute! Table is loading/updating!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
