import { useTranslation } from 'react-i18next'

import { ErrorLayout } from '../../widgets/layouts'
import { UnderConstructionContent } from '../../widgets/errors/under-construction'

export function UnderConstruction() {
  const { t } = useTranslation('under-construction')

  return (
    <ErrorLayout header={t('Under construction')}>
      <UnderConstructionContent/>
    </ErrorLayout>
  )
}
