import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { SyncButton } from '../../../../shared/ui/sync-button'
import { Tooltip } from '../../../../shared/ui/tooltip'

import type { DGUpdateButtonProps } from './types'

export function DGUpdateButton({ updating, updateSettings }: DGUpdateButtonProps) {
  const [search] = useSearchParams()

  const updateHandler = () => updateSettings(search)

  return (
    <Tooltip title={t('subdomains-generator:Sync Keitaro')}>
      <SyncButton
        onClick={updateHandler}
        updating={updating}
      >
        {t('subdomains-generator:Sync')}
      </SyncButton>
    </Tooltip>
  )
}
