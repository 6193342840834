import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { t } from 'i18next'

import { ModalConfirmation } from '../../../../shared/ui/modal-confirmation'
import { TableButtonAction } from '../../../../shared/ui/table-button-action'
import { Tooltip } from '../../../../shared/ui/tooltip'

import type { DeleteDictionaryButtonProps } from './types'

export const DeleteDictionaryButton = ({
  dict,
  getDictionariesData,
  deleteDictionary,
}: DeleteDictionaryButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  const handleClick = async () => {
    deleteDictionary(dict?.id).then(() => {
      enqueueSnackbar(
        t('subdomains-generator:{{name}} was deleted successfully!', { name: dict?.name }),
        { variant: 'success' },
      )
      getDictionariesData()
    }).catch((error) => {
      enqueueSnackbar(
        t('subdomains-generator:{{name}} can not be deleted! An error occurred!', { name: dict?.name }) + t(error),
        { variant: 'error' },
      )
    })
  }

  return (
    <>
      <ModalConfirmation
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        buttonTitle={t('subdomains-generator:Delete vocabulary')}
        title={t('subdomains-generator:Delete vocabulary?')}
        description={t('subdomains-generator:You can’t recover deleted vocabulary')}
        handleClick={handleClick}
        cancelText={t('subdomains-generator:Cancel')}
      />

      <Tooltip title={t('subdomains-generator:Delete')}>
        <TableButtonAction
          target={ 'delete' }
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
