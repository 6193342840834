import { alpha, createTheme } from '@mui/material/styles'

const themeColors = {
  primary: '#0094A6',
  secondary: '#2D5E7D',
  success: '#2BB148',
  warning: '#F2D32D',
  error: '#F6453B',
  info: '#51ADE7',
  black: '#000000',
  white: '#ffffff',
  font: {
    primary: '#1C2529',
    secondary: '#275572',
    textBody: '#455A64',
    textGrey: '#7996A9',
    textLight: '#B7C6D3',
  },
  light: {
    primary: '#32C5C9',
    secondary: '#407698',
    success: '#49E17D',
    warning: '#FEE15D',
    error: '#FF6961',
    info: '#6ED7F9',
  },
  soft: {
    primary: '#D2FAFF',
    secondary: '#ABC4D4',
    success: '#CBFFE4',
    warning: '#FFEF9D',
    error: '#FFE2E2',
    info: '#DDF4FF',
  },
  softHover: {
    primary: '#B6E2E8',
    secondary: '#407698',
    success: '#B4EED1',
    warning: '#EFDE87',
    error: '#F4D3D3',
    info: '#C6E6F5',
  },
  dark: {
    primary: '#00738E',
    secondary: '#275572',
    success: '#0D8A6C',
    warning: '#C89D08',
    error: '#CD3D40',
    info: '#2E95D3',
  },
  border: {
    disable: 'transparent',
    primary: '#212529',
  },
  backgroundColor: {
    layout: '#F2F5F6',
    paper: '#ffffff',
    content: '#109B69',
    white: '#ffffff',
  },
  shadowsColor: {
    primary: '#AAB7C1',
    secondary: '#d7dce2',
    third: '#d7dce2',
  },
  accent: {
    primary: '#AF52DE',
    primarySoft: '#EFDCF8',
    warning: '#FF9500',
    active: '#3FBDF6',
  },
}

const text = {
  fontFamily: {
    Rubik: 'Rubik, sans-serif',
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.25rem', // 20px
    xl: '1.375rem', // 22px
    xxl: '1.5rem', // 24px
    xxxl: '2.125rem',// 34px
  },
  lineHeights: {
    default: 1.5,
    tight: 1.2,
    normal: 1.4,
    relaxed: 1.6,
  },
  textTransforms: {
    none: 'none' as const,
    capitalize: 'capitalize' as const,
    uppercase: 'uppercase' as const,
    lowercase: 'lowercase' as const,
    initial: 'initial' as const,
    inherit: 'inherit' as const,
  },
}

let colors: any = {
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      85: alpha(themeColors.white, 0.85),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      85: alpha(themeColors.white, 0.85),
      100: themeColors.white,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      85: alpha(themeColors.black, 0.85),
      100: themeColors.black,
    },
  },
}

colors = {
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      85: alpha(themeColors.white, 0.85),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      85: alpha(themeColors.white, 0.85),
      100: themeColors.white,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      85: alpha(themeColors.black, 0.85),
      100: themeColors.black,
    },
  },
  layout: {
    general: {
      bodyBg: themeColors.backgroundColor.layout,
    },
    sidebar: {
      background: themeColors.secondary,
      textColor: themeColors.font.textLight,
      hoverTextColor: themeColors.white,
      activeTextColor: themeColors.white,
      hoverBg: themeColors.softHover.secondary,
      iconTextColor: themeColors.font.textLight,
      iconTextColorActive: themeColors.white,
    },
  },
  gradients: {},
  shadows: {
    default: 'none',
    elements: `0 1px 1px 0 ${themeColors.shadowsColor.primary}`,
    card: `0 1px 1px 0 ${themeColors.shadowsColor.third}`,
    body: `0 4px 10px 0 ${themeColors.shadowsColor.secondary}`,
    modal: `0 5px 10px 0 ${themeColors.shadowsColor.secondary}`,
    dropdown: `0 5px 10px 0 ${themeColors.shadowsColor.primary}`,
  },
  primary: {
    main: themeColors.primary,
    light: themeColors.light.primary,
    dark: themeColors.dark.primary,
    contrastText: colors.alpha.white[100],
  },
  secondary: {
    main: themeColors.secondary,
    light: themeColors.light.secondary,
    dark: themeColors.dark.secondary,
    contrastText: colors ? colors.alpha.white[100] : '#ffffff',
  },
  success: {
    main: themeColors.success,
    light: themeColors.light.success,
    dark: themeColors.dark.success,
    contrastText: colors ? colors.alpha.white[100] : '#ffffff',
  },
  warning: {
    main: themeColors.warning,
    light: themeColors.light.warning,
    dark: themeColors.dark.warning,
    contrastText: colors ? colors.alpha.white[100] : '#ffffff',
  },
  error: {
    main: themeColors.error,
    light: themeColors.light.error,
    dark: themeColors.dark.error,
    contrastText: colors ? colors.alpha.white[100] : '#ffffff',
  },
  soft: {
    primary: themeColors.soft.primary,
    secondary: themeColors.soft.secondary,
    success: themeColors.soft.success,
    warning: themeColors.soft.warning,
    error: themeColors.soft.error,
    info: themeColors.soft.info,
  },
  softHover: {
    primary: themeColors.softHover.primary,
    secondary: themeColors.softHover.secondary,
    success: themeColors.softHover.success,
    warning: themeColors.softHover.warning,
    error: themeColors.softHover.error,
    info: themeColors.softHover.info,
  },
  light: {
    primary: themeColors.light.primary,
    secondary: themeColors.light.secondary,
    success: themeColors.light.success,
    warning: themeColors.light.warning,
    error: themeColors.light.error,
    info: themeColors.light.info,
  },
  dark: {
    primary: themeColors.dark.primary,
    secondary: themeColors.dark.secondary,
    success: themeColors.dark.success,
    warning: themeColors.dark.warning,
    error: themeColors.dark.error,
    info: themeColors.dark.info,
  },
  info: {
    main: themeColors.info,
    light: themeColors.light.info,
    dark: themeColors.dark.info,
  },
  accent: {
    primary: themeColors.accent.primary,
    primarySoft: themeColors.accent.primarySoft,
    warning: themeColors.accent.warning,
  },
  white: themeColors.white,
  black: themeColors.black,
  text: {
    primary: themeColors.font.primary,
    secondary: themeColors.font.secondary,
    body: themeColors.font.textBody,
    grey: themeColors.font.textGrey,
    light: themeColors.font.textLight,
  },
  border: {
    primary: themeColors.border.primary,
    disable: themeColors.border.disable,
  },
  background: {
    default: themeColors.backgroundColor.layout,
    paper: themeColors.backgroundColor.paper,
    white: themeColors.backgroundColor.white,
  },
  action: {
    disabled: '#78909c',
    disabledBackground: '#e8eef3',
    hover: themeColors.soft.info,
    active: themeColors.black,
    hoverOpacity: 0.1,
    selectedOpacity: 0.1,
    focusOpacity: 0.05,
    activatedOpacity: 0.12,
    disabledOpacity: 0.38,
  },
}

const shape = {
  borderRadius: 6,
  radius: {
    default: 6,
    sm: 4,
    lg: 10,
    xl: 18,
    xxl: 50,
  },
  borderWidth: {
    thin: 1,
    medium: 2,
    thick: 3,
  },
  spacing: {
    none: 0,
    small: 8,
    medium: 16,
    large: 24,
    xlarge: 32,
  },
  padding: {
    small: 4,
    medium: 8,
    large: 12,
  },
  margin: {
    none: 0,
    small: 4,
    medium: 8,
    large: 12,
    negativeSmall: -4,
    negativeMedium: -8,
    negativeLarge: -12,
  },
}

const widths = {
  sidebar: '290px',
}

const boxStyles = {
  auth: {
    content: {
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    mainContent: {
      alignItems: 'center',
      display: 'flex',
      padding: 0,
      width: '100%',
    },
    authContent: {
      marginBottom: '2rem',
      textAlign: 'center' as const,
    },
  },
  SvgContent: {
    maxWidth: '350px',
    width: '100%',
  },
}

export const LightTheme = createTheme({
  colors: {
    gradients: colors.gradients,
    shadows: colors.shadows,
    primary: colors.primary,
    secondary: colors.secondary,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    white: colors.white,
    black: colors.black,
    info: colors.info,
    light: colors.light,
    dark: colors.dark,
    soft: colors.soft,
    softHover: colors.softHover,
    alpha: colors.alpha,
    action: colors.action,
    background: colors.background,
    border: colors.border,
  },
  general: {
    borderRadius: shape.radius.default,
    borderRadiusSm: shape.radius.sm,
    borderRadiusLg: shape.radius.lg,
    borderRadiusXl: shape.radius.xl,
    // reactFrameworkColor: '#00D8FF'
    boxStyles: boxStyles,
  },
  typography: {
    fontFamily: text.fontFamily.Rubik,
    h1: {
      fontWeight: text.fontWeights.medium,
      fontSize: text.fontSizes.xl,
    },
    h2: {
      fontWeight: text.fontWeights.bold,
      fontSize: text.fontSizes.xl,
    },
    h3: {
      fontWeight: text.fontWeights.bold,
      fontSize: text.fontSizes.lg,
      lineHeight: text.lineHeights.normal,
      color: colors.text.body,
    },
    h4: {
      fontWeight: text.fontWeights.bold,
      fontSize: text.fontSizes.md,
    },
    h5: {
      fontWeight: text.fontWeights.bold,
      fontSize: text.fontSizes.sm,
    },
    h6: {
      fontSize: text.fontSizes.md,
    },
    body1: {
      fontSize: text.fontSizes.sm,
    },
    body2: {
      fontSize: text.fontSizes.sm,
    },
    button: {
      fontWeight: text.fontWeights.medium,
    },
    caption: {
      fontSize: text.fontSizes.xs,
      textTransform: text.textTransforms.uppercase,
      color: colors.text.body,
    },
    subtitle1: {
      fontSize: text.fontSizes.sm,
      color: colors.text.body,
    },
    subtitle2: {
      fontWeight: text.fontWeights.regular,
      fontSize: text.fontSizes.sm,
      color: colors.text.body,
    },
    overline: {
      fontSize: text.fontSizes.xs,
      fontWeight: text.fontWeights.bold,
      textTransform: text.textTransforms.uppercase,
    },
  },
  customTypographyStyles: {
    NotFound404: {
      //color: theme.pages.NotFoundPage.title.color,
      marginBottom: '0.5rem',
    },
    NotFoundHeader: {
      //color: theme.pages.NotFoundPage.title.color,
      marginBottom: '0.5rem',
    },
    NotFoundSubHeader: {
      //color: theme.pages.NotFoundPage.title.color,
      marginBottom: '0.5rem',
    },
    AuthHeader: {
      marginBottom: '0.5rem',
      marginTop: '1rem',
    },
    AuthSubHeader: {
      marginBottom: '0.5rem',
    },
  },
  customButtons: {
    iconButton: {
      width: '40px',
      backgroundColor: colors.primary.main,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.primary.dark,
      },
    },
    noShadowButton: {
      boxShadow: 'none',
      color: colors.primary.main,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: colors.soft.info,
      },
    },
    textSizeExtraLargeButton: {
      padding: '12px 80px',
      fontWeight: 500,
      marginTop: '16px',
      fontSize: '14px',
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
    uploadImageButton: {
      height: '80px',
      padding: '30px',
      backgroundColor: 'transparent',
      marginTop: '10px',
      display: 'flex',
      verticalAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  },
  palette: {
    mode: 'light',
    primary: colors.primary,
    secondary: colors.secondary,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    info: colors.info,
    text: colors.text,
    background: colors.background,
    action: colors.action,
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    hoverTextColor: colors.layout.sidebar.hoverTextColor,
    activeTextColor: colors.layout.sidebar.activeTextColor,
    hoverBg: colors.layout.sidebar.hoverBg,
    iconTextColor: colors.layout.sidebar.iconTextColor,
    iconTextColorActive: colors.layout.sidebar.iconTextColorActive,
    width: widths.sidebar,
  },
  header: {
    height: '70px',
    background: themeColors.light.secondary,
    textColor: themeColors.font.primary,
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1840,
    },
  },
  shadows: [
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
  ],
  shape: {
    borderRadius: shape.borderRadius,
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.alpha.white[10],
          backdropFilter: 'blur(15px)',
          timeout: 500,

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: shape.margin.medium,
          marginRight: shape.margin.medium,
          fontWeight: 'bold',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@keyframes mover': {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-16px)' },
        },
        'html, body': {
          fontFamily: 'Rubik, sans-serif',
          height: '100%',
          width: '100%',
        },
        body: {
          display: 'grid',
          minHeight: '100%',
          width: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          background: colors.primary,
        },
        '#nprogress .spinner-icon': {
          borderTopColor: colors.primary,
          borderLeftColor: colors.soft.primary,
        },
        '#nprogress .peg': {
          boxShadow: `0 0 15px ${colors.soft.primary}, 0 0 8px${colors.primary.light}`,
        },
        'img, svg': {
          verticalAlign: 'middle',
        },
        ':root': {
          '--swiper-theme-color': colors.primary.main,
        },
        code: {
          background: colors.soft.info,
          color: colors.info.dark,
          borderRadius: shape.radius.sm,
          padding: 4,
        },
        '@keyframes pulse': {
          '0%': {
            transform: 'scale(.75)',
          },
          '20%': {
            transform: 'scale(1.1)',
          },
          '40%': {
            transform: 'scale(.75)',
          },
          '60%': {
            transform: 'scale(1.05)',
          },
          '80%': {
            transform: 'scale(.75)',
          },
          '100%': {
            transform: 'scale(.75)',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0,
          },
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)',
          },
          '100%': {
            transform: 'translate(3%, 3%)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50],
        },
        icon: {
          top: 'calc(50% - 14px)',
        },
        select: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.alpha.black[50],
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          lineHeight: '40px',
          fontSize: 13,
          background: colors.alpha.black[5],
          color: colors.alpha.black[70],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
        title: {
          fontSize: 15,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: shape.radius.xxl,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          background: colors.alpha.black[5],
          color: colors.alpha.black[100],

          '&:hover': {
            background: colors.alpha.black[10],
          },
        },
        deleteIcon: {
          color: colors.error.light,

          '&:hover': {
            color: colors.error.main,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: shape.margin.none,
          },
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold',
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: 13,
          color: colors.alpha.black[70],
          fontWeight: 'bold',

          '&:first-of-type': {
            border: 0,
            background: 'transparent',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: shape.margin.none,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: 'bold',
          transition: 'all .2s',
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.elements,
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5],
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '0.1px',
          minWidth: 'inherit',
          gap: '5px',
          padding: '10px 16px',
          lineHeight: '16px',
          height: '40px',
          textTransform: text.textTransforms.none,
          boxShadow: colors.shadows.elements,
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },
          '&.Mui-disabled': {
            color: colors.action.disabled,
            backgroundColor: colors.action.disabledBackground,
          },
          '&.customUploadButton': {
            height: '80px',
            padding: '30px',
            border: '1px dashed #B7C6D3',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            boxShadow: 'none',
            color: '#455A64',
            '&:hover': {
              boxShadow: 'none',
              // backgroundColor: '#F2F5F6',
              border: '1px dashed #B7C6D3',
            },
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: colors.primary.main,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.primary.dark,
          },
        },
        containedSecondary: {
          backgroundColor: '#9c27b0',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#7b1fa2',
          },
        },
        containedSuccess: {
          backgroundColor: '#4caf50',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#388e3c',
          },
        },
        containedWarning: {
          backgroundColor: colors.soft.warning,
          color: colors.dark.warning,
          '&:hover': {
            backgroundColor: colors.softHover.warning,
          },
        },
        containedError: {
          backgroundColor: '#f44336',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#d32f2f',
          },
        },
        containedInfo: {
          backgroundColor: '#2196f3',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1976d2',
          },
        },
        outlined: {
          borderWidth: '1px',
          padding: '9px 15px',
          boxShadow: 'none',
          '&:hover': {
            borderWidth: '1px',
          },
        },
        outlinedError: {
          borderColor: '#f44336',
          color: '#f44336',
          '&:hover': {
            backgroundColor: '#ffebee',
          },
        },
        outlinedInfo: {
          borderColor: '#2196f3',
          color: '#2196f3',
          '&:hover': {
            backgroundColor: '#e3f2fd',
          },
        },
        text: {
          padding: '10px 16px',
        },
        textPrimary: {
          color: '#1976d2',
          '&:hover': {
            backgroundColor: '#e3f2fd',
          },
        },
        textSecondary: {
          color: '#9c27b0',
          '&:hover': {
            backgroundColor: '#f3e5f5',
          },
        },
        textSuccess: {
          color: '#4caf50',
          '&:hover': {
            backgroundColor: '#e8f5e9',
          },
        },
        textWarning: {
          color: '#ff9800',
          '&:hover': {
            backgroundColor: '#fff3e0',
          },
        },
        textError: {
          color: '#f44336',
          '&:hover': {
            backgroundColor: '#ffebee',
          },
        },
        textInfo: {
          color: '#2196f3',
          '&:hover': {
            backgroundColor: '#e3f2fd',
          },
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '9px 16px',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
        sizeSmall: {
          padding: '6px 16px',
          lineHeight: 1.5,
        },
        sizeMedium: {
          padding: '8px 20px',
        },
        sizeLarge: {
          padding: '11px 24px',
          lineHeight: 1.75,
          height: 48,
        },
        startIcon: {},
        endIcon: {
          marginRight: -8,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          borderRadius: shape.radius.default,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          color: colors.primary.main,
          background: colors.alpha.white[100],
          transition: 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            background: colors.primary.main,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: 6,

          '& .MuiTouchRipple-root': {
            borderRadius: 6,
          },
        },
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: shape.margin.none,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[10],
          border: 0,
          height: 1,
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto',
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%',
          },
        },
        withChildren: {
          '&:before, &:after': {
            border: 0,
          },
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: shape.margin.negativeLarge,
          color: 'inherit',
          textTransform: 'uppercase',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        elevation0: {
          boxShadow: 'none',
        },
        elevation: {
          boxShadow: colors.shadows.elements,
        },
        elevation2: {
          boxShadow: colors.shadows.card,
        },
        elevation24: {
          boxShadow: colors.shadows.card,
        },
        outlined: {
          boxShadow: colors.shadows.card,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: shape.radius.default,
            background: colors.alpha.black[100],
            color: colors.alpha.white[100],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34,
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },
          },
          // '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
          //   backgroundColor: alpha(colors.primary.lighter, 0.4),
          // },
          // '& .MuiMenuItem-root.MuiButtonBase-root:active': {
          //   backgroundColor: alpha(colors.primary.lighter, 0.4),
          // },
          '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
            opacity: 0.2,
          },
        },
        padding: {
          padding: '12px',

          '& .MuiListItem-button': {
            borderRadius: 6,
            margin: '1px 0',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 38,
          minHeight: 38,
          overflow: 'visible',
        },
        indicator: {
          height: 38,
          minHeight: 38,
          borderRadius: 6,
          border: `1px solid ${colors.primary.dark}`,
          boxShadow: `0px 2px 10px ${colors.primary.light}`,
        },
        scrollableX: {
          overflow: 'visible !important',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 38,
          minHeight: 38,
          borderRadius: 6,
          transition: 'color .2s',
          textTransform: 'capitalize',

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
            marginRight: 4,
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            zIndex: 5,
          },
          '&:hover': {
            color: colors.alpha.black[100],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '.MuiTextField-root.MuiInputBase-root-MuiOutlinedInput-root': {
            height: 40,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          //padding: 12,
        },
        list: {
          padding: 12,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: colors.alpha.black[70],

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },

            // '&:hover, &:active, &.active, &.Mui-selected': {
            //   color: colors.alpha.black[100],
            //   background: alpha(colors.primary.lighter, 0.4),
            // },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          transition: 'all .2s',

          // '&:hover, &:active, &.active, &.Mui-selected': {
          //   color: colors.alpha.black[100],
          //   background: alpha(colors.primary.lighter, 0.4),
          // },
          // '&.Mui-selected:hover': {
          //   background: alpha(colors.primary.lighter, 0.4),
          // },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.secondary.main,

            // '&:hover, &:active, &.active, &.Mui-selected': {
            //   color: colors.alpha.black[100],
            //   background: lighten(colors.primary.lighter, 0.5),
            // },
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          minWidth: 160,

          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: 14,
          },
          '& .MuiAutocomplete-clearIndicator.MuiIconButton-root': {
            background: 'rgb(255,242,242)',
            visibility: 'visible',
          },

          '& .MuiAutocomplete-endAdornment': {
            //top: 'calc(50% - 12px)',
          },
          '& .MuiOutlinedInput-root': {
            height: '40px',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1rem',
          },
        },
        clearIndicator: {
          background: colors.light.error,
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: colors.light.error,
            color: colors.error.dark,
          },
        },
        popupIndicator: {
          color: colors.alpha.black[50],

          '&:hover': {
            background: colors.soft.info,
            color: colors.primary.main,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.alpha.black[5],
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: colors.alpha.black[5],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[10],
          fontSize: 14,
        },
        head: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 'bold',
          color: colors.alpha.black[70],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          borderRadius: '3px',
          borderStyle: 'solid',
          borderWidth: '1px',
          display: 'flex',
          fontSize: '14px',
          lineHeight: '17px',
          margin: '30px 0',
          // padding: '12px 24px',
          justifyContent: 'center',
          gap: '10px',
          minWidth: '400px',
          width: 'fit-content',
          height: '40px',
        },
        standardError: {
          backgroundColor: '#FFE4E4',
          borderColor: '#FFD3D3',
          color: '#D32B30',
        },
        standardSuccess: {
          backgroundColor: '#DCF6E9',
          borderColor: '#B6E7CE',
          color: '#13AD97',
        },
        standardInfo: {
          backgroundColor: '#E2F6FF',
          borderColor: '#BFEBFF',
          color: '#51ADE7',
        },
        standardWarning: {
          backgroundColor: '#FFF4C7',
          borderColor: '#F5E295',
          color: '#BD9406',
        },
        message: {
          lineHeight: 1.5,
          fontSize: 14,
        },
        action: {
          color: colors.alpha.black[70],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.primary.main,
          fontSize: 12,
          letterSpacing: '0.2px',
          padding: '10px 16px',
        },
        arrow: {
          color: colors.primary.main,
        },
        popper: {
          '& .MuiTooltip-tooltip': {
            textAlign: 'center',
          },
          '&.edit': {
            '& .MuiTooltip-tooltip': {
              backgroundColor: alpha('#189EAE', 0.9),

              '& .MuiTooltip-arrow': {
                color: alpha('#189EAE', 0.9),
              },
            },
          },
          '&.disable': {
            '& .MuiTooltip-tooltip': {
              backgroundColor: alpha('#BD9406', 0.9),

              '& .MuiTooltip-arrow': {
                color: alpha('#BD9406', 0.9),
              },
            },
          },
          '&.delete': {
            '& .MuiTooltip-tooltip': {
              backgroundColor: alpha('#D32B30', 0.9),

              '& .MuiTooltip-arrow': {
                color: alpha('#D32B30', 0.9),
              },
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            minWidth: '90px',
          },

          '& .MuiButtonBase-root': {
            backgroundColor: colors.background.white,
            zIndex: '1',

            '&.clearButton': {
              background: 'rgb(255,242,242)',
              color: '#FF5757',
              opacity: '1',
              padding: '4px',
              transition: 'all 0.3s ease-in-out',

              '&:hover': {
                background: 'rgb(253,231,231)',
              },

              '& .MuiSvgIcon-root': {
                color: '#FF5757',
              },
            },

            '&.MuiAutocomplete-clearIndicator': {
              '& .MuiSvgIcon-root': {
                color: '#FF5757',
              },
            },

            '& .MuiSvgIcon-root': {
              color: 'rgba(18, 42, 81, 0.60)',
              fontSize: '1rem',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 33,
          overflow: 'visible',

          '& .MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition:
              'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            transform: 'translateX(5px)',
          },
          '& .MuiIconButton-root': {
            borderRadius: 100,
          },
          '& .MuiButtonBase-root.Mui-checked:not(.MuiSwitch-colorError)': {
            color: '#02B8CE',
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#9FE4EC',
            opacity: 0.3,
          },
          '& .Mui-focusVisible': {
            backgroundColor: alpha('#000', 0.1),
          },
          '& .MuiFormControlLabel-root': {
            color: '#607D8B',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '15px',
            letterSpacing: '-0.10000000149011612px',
            textAlign: 'left',
          },
        },
        thumb: {
          boxShadow: '0px 1px 3px rgba(58, 53, 65, 0.12),' +
            '0px 1px 1px rgba(58, 53, 65, 0.14),' +
            '0px 2px 1px rgba(58, 53, 65, 0.20)}',
          height: '20px',
          width: '20px',
        },
        track: {
          backgroundColor: '#919191',
          marginTop: '-2px',
          opacity: 1,
          height: '14px',
          width: '34px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: colors.alpha.black[5],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& > .MuiPaper-root': {
            boxShadow: '0px 4px 18px 3px rgba(46, 50, 52, 0.08), 0px 1px 6px 0px rgba(11, 60, 93, 0.12)',
          },

          '& .MuiDateCalendar-root': {
            background: '#407698',

            '& > .MuiPickersCalendarHeader-root': {
              '& .MuiPickersCalendarHeader-label': {
                color: '#ffffff',
                fontSize: '16px',
                fontWeight: '700',
              },

              '& button': {
                color: '#ffffff',
              },
            },

            '& > .MuiPickersFadeTransitionGroup-root': {
              background: '#ffffff',

              '& .Mui-selected': {
                background: '#64B0E0',
              },
            },
          },
        },
      },
    },
  },
})