import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { t } from 'i18next'

import {
  getArraySplitToNChunks,
  getFormattedFullDate,
  useDimensions,
  useDimensionsWindow,
} from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'
import { Tooltip } from '../../../../shared/ui/tooltip'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnWrapper,
  TableHeader,
  TableValue,
  TableRow,
  TableWrapper,
} from './table'

import type { DomainsGeneratorDomains } from '../../../../entities/domains-generator/generator'
import type { TableDomainsProps } from '../types'

export const DomainsSelectedParam = 'domains'

export const TableDomains: FC<TableDomainsProps> = (props) => {
  const [ columnsCount, setColumnsCount ] = useState(1)
  const [ selected, setSelected ] = useState<string[] | null>(null)
  const { screenSize } = useDimensionsWindow()
  const { data, error, loading } = useDomainsGeneratorStore()

  // table dimensions
  const domainsHeaderRef = useRef(null)
  const { offsetWidth } = useDimensions(domainsHeaderRef)

  const hasError = Boolean(error) ?? null
  const hasData = (
    !loading
    && !hasError
    && data?.domains
    && data.domains?.length > 0
  )

  const domainsNChunks = useMemo(() => {
    return data?.domains && getArraySplitToNChunks(data.domains, columnsCount)
  }, [data?.domains, columnsCount])

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    let reSelected = selected
    const value = String(e.currentTarget.value)

    if (!e.currentTarget.checked)
      reSelected = reSelected?.filter(e => e !== value) ?? null

    if (e.currentTarget.checked)
      reSelected = reSelected?.concat([value]) ?? null

    setSelected(reSelected)
  }

  useEffect(() => {
    if (screenSize.width < 1100)
      setColumnsCount(1)

    if (screenSize.width >= 1100 && screenSize.width < 1600)
      setColumnsCount(2)

    if (screenSize.width >= 1600)
      setColumnsCount(3)
  }, [screenSize])

  // Set initial selected ids
  useEffect(() => {
    const initialSelected = data?.domains?.filter((v) =>
      v?.selected === true).map((v) => String(v?.id)) ?? null

    initialSelected && setSelected(initialSelected)

  }, [data?.domains])

  // Set formik input value
  useEffect(() => {
    props.formik.setFieldValue(
      DomainsSelectedParam,
      selected && selected?.length > 0
        ? selected?.join()
        : null,
    )
  }, [selected])

  return <>
    <Table>
      <TableWrapper className={ 'overflow-auto' }>
        { hasData &&
            (
              <TableHeader>
                {[...Array(columnsCount)]
                  ?.map((_, index) =>
                    <TableColumn key={ index }>
                      <TableColumnWrapper ref={ domainsHeaderRef }>
                        <TableRow>
                          <TableCell>
                            <TableValue>
                              { t('subdomains-generator:2nd level domains') }
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              { t('subdomains-generator:Total') }
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              { t('subdomains-generator:Period') }
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              { t('subdomains-generator:Last') }
                            </TableValue>
                          </TableCell>
                        </TableRow>
                      </TableColumnWrapper>
                    </TableColumn>,
                  )}
              </TableHeader>
            )
        }
        <TableBody>
          { hasData
            && [...Array(columnsCount)]
              ?.map((_, index) =>
                <TableColumn key={ index }>
                  { domainsNChunks && domainsNChunks[index]
                    ?.map((row: DomainsGeneratorDomains, rowIndex: number) =>
                      <TableColumnWrapper
                        key={ rowIndex }
                        style={ { minWidth: offsetWidth } }
                      >
                        <TableRow>
                          <TableCell>
                            <TableValue>
                              <input
                                type="checkbox"
                                onChange={ handleOnChange }
                                id={row?.name}
                                name={ 'domains' }
                                value={ row?.id }
                                checked={ Boolean(selected?.includes(String(row?.id))) }
                              />
                              { row?.expired_date
                                ? (
                                  <Tooltip
                                    title={`${t('subdomains-generator:Expire date')}: ${
                                      getFormattedFullDate(row?.expired_date)
                                    }`}>
                                    <label
                                      htmlFor={row?.name}
                                      style={ row?.expired_date && new Date() > new Date(row?.expired_date)
                                        ? { color: 'red' }
                                        : {}
                                      }>
                                      { row?.name }
                                    </label>
                                  </Tooltip>
                                )
                                : (
                                  <label
                                    htmlFor={row?.name}>
                                    {row?.name}
                                  </label>
                                )}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {row?.total}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {row?.period }
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              { row?.last_use }
                            </TableValue>
                          </TableCell>
                        </TableRow>
                      </TableColumnWrapper>,
                    )
                  }
                </TableColumn>,
              )
          }
          {
            !hasError
              && !loading
              && data?.domains?.length === 0 && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  { t('subdomains-generator:Oops! The table is empty - no data!') }
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            hasError
              && !loading && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  { t(`subdomains-generator:${error}`) }
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
              && loading && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  { t('subdomains-generator:Just a minute! Table is loading/updating!') }
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  </>
}
