import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { PageBlock, PageContainer } from '../../../../shared/ui/page'
import { useSettingsIntegrationsStore } from '../../../../entities/settings/integrations'
import { IntegrationsBlock } from '../../../../widgets/user/settings/intergations'
import { MainLayout } from '../../../../widgets/layouts'
import { UserSettingsLayout } from '../../../../widgets/layouts/settings'

export function UserSettingsIntegrations() {
  const { getIntegrations } = useSettingsIntegrationsStore()
  const { t } = useTranslation('settings')

  const debouncedIntegrations = useMemo(() => {
    return debounce(() => getIntegrations(), 300)
  }, [getIntegrations])

  useEffect(() => {
    debouncedIntegrations()

    return () => {
      debouncedIntegrations.clear()
    }
  }, [debouncedIntegrations])

  return (
    <MainLayout header={t('User settings | Integration')}>
      <UserSettingsLayout>
        <PageBlock style={{ width: '100%', height: 'auto' }}>
          <PageContainer style={{ width: '100%', height: 'fit-content' }}>
            <IntegrationsBlock/>
          </PageContainer>
        </PageBlock>
      </UserSettingsLayout>
    </MainLayout>
  )
}
