import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useSearch } from '../../../shared/lib'

// DomainSearchParam query name
export const DomainSearchParam = 'domain'

/**
 * DomainSearchFilter feature
 * @constructor {JSX.Element}
 */
export function DomainSearchFilter() {
  const [search, setSearch] = useSearchParams()
  const [focus, setFocus] = useState(false)
  const { searchActive } = useSearch(DomainSearchParam)
  const { offsetActive } = usePagination()

  /**
   * Handle InputChange action

   */
  const handleOnInputChange = (event: React.SyntheticEvent<Element, Event>, value: any): void => {
    value = value.replace(/\s/g, '')

    if (value) search.set(DomainSearchParam, value)
    if (!value && searchActive) search.delete(DomainSearchParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check domain query values
  useEffect(() => {
    if (searchActive !== null || searchActive === '') {
      if (!searchActive || (
        searchActive && !(searchActive.length > 0)
      )) {
        search.delete(DomainSearchParam)
        setSearch(search, { replace: true })
      }
    }
  }, [searchActive])

  return (
    <Autocomplete
      freeSolo
      defaultValue={searchActive ? searchActive : null}
      value={searchActive ? searchActive : null}
      clearOnBlur={true}
      open={false}
      options={[]}
      onInputChange={handleOnInputChange}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      noOptionsText={t('domains-watcher:No options')}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('domains-watcher:Search by domain')}
          autoFocus
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchOutlinedIcon/>
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={
            !searchActive && !focus
              ? {
                shrink: false,
                style: { paddingLeft: '1.5rem' },
              }
              : {}
          }
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{
        minWidth: '250px',
        height: '40px',
        '& .MuiInputAdornment-positionStart': {
          color: 'rgba(18, 42, 81, 0.60)',
          paddingLeft: '8px',
        },
        '& .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputAdornedStart': {
          paddingLeft: '0',
        },
      }}
    />
  )
}
