import React from 'react'
import styled from 'styled-components'

import { Resizable } from '../resizable'

import type { ResizableProps } from '../resizable'

export const TableResizable: React.FC<ResizableProps> = (props) => {
  return (
    <SResizable
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        right: 'resizable-right',
      }}
      handleStyles={{
        right: {
          right: -10,
          width: 20,
        },
      }}
      minWidth={50}
      maxWidth={600}

      {...props}
    >
      { props.children }
    </SResizable>
  )
}

export const SResizable = styled(Resizable)`
    & .resizable-right {
        display: none;
    }

    &:hover {
        & .resizable-right {
            display: block;
            z-index: 100;
        }
    }
`
