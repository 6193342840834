import styled from 'styled-components'

import { IntegrationSearchFilter } from '../../../../features/kacc/campaigns-list/filter-search'
import { IntegrationStateFilter } from '../../../../features/kacc/campaigns-list/filter-integrate-state'
import { ResetAllFilters } from '../../../../features/kacc/campaigns-list/reset-all-filters'
import { IntegrationIdSearchFilter } from '../../../../features/kacc/campaigns-list/filter-search-by-id'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <IntegrationSearchFilter/>
        <IntegrationIdSearchFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <IntegrationStateFilter/>
        <ResetAllFilters/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
`

const SFiltersGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 15px;
`
