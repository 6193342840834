import { t } from 'i18next'

export const getFormattedRelativeDate = (stamp: string) => {
  const s = new Date(stamp)
  const c = new Date()
  let date = s.getUTCDate(),
    month = s.getUTCMonth() + 1,
    year = s.getUTCFullYear(),
    relative = null

  if (s.getUTCFullYear() === c.getUTCFullYear()) {
    if (s.getUTCMonth() === c.getUTCMonth()) {
      if (s.getUTCDate() === c.getUTCDate()) {
        relative = t('global:Today')
      } else if (s.getUTCDate() === c.getUTCDate() - 1) {
        relative = t('global:Yesterday')
      } else if (s.getUTCDate() === c.getUTCDate() + 1) {
        relative = t('global:Tomorrow')
      }
    }
  }

  const newDate = (date < 10) ? `0${date}` : date
  const newMonth = (month < 10) ? `0${month}` : month

  return relative
    ? `${relative}`
    : `${newDate}-${newMonth}-${year}`
}
