import React, { useState } from 'react'
import { t } from 'i18next'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { darken } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { enqueueSnackbar } from 'notistack'
import CachedIcon from '@mui/icons-material/Cached'
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers'

import { Button } from '../../../../../shared/ui/button'
import { Tooltip } from '../../../../../shared/ui/tooltip'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'
import {
  addCatalogInterval,
  useKaccCatalogStore,
  KaccCatalogIntervalsEnum,
  deleteCatalogInterval,
} from '../../../../../entities/kacc/catalog'
import { checkDateIsValid } from '../lib'

import { Table, TableBody, TableCell, TableRow, TableWrapper } from './table'

import type { KaccCatalogInterval } from '../../../../../entities/kacc/catalog'
import type { KaccCatalogManageIntervalsButtonProps } from '../types'

export const ManageIntervalsModal = ({ onClose, isOpen }: KaccCatalogManageIntervalsButtonProps) => {
  const { intervals, getIntervals } = useKaccCatalogStore()
  const [ interval, setInterval ] = useState<KaccCatalogIntervalsEnum | null>(null)
  const [ intervalFrom, setIntervalFrom ] = useState<KaccCatalogInterval['interval_from'] | null>(null)
  const [ intervalTo, setIntervalTo ] = useState<KaccCatalogInterval['interval_to'] | null>(null)
  const [ intervalAddLoading, setIntervalAddLoading ] = useState<boolean>(false)
  const [ intervalDeleteLoading, setIntervalDeleteLoading ] = useState<KaccCatalogInterval['id'] | null>(null)
  const [ intervalsIsError, setIntervalsIsError ] = useState<boolean>(false)

  // initialize isSameOrBefore plugin
  dayjs.extend(isSameOrBefore)

  // Set initial intervals
  const filteredIntervals = Object.values(KaccCatalogIntervalsEnum)
    .filter((interval) => {
      return !intervals?.some((value) =>
        value.interval === interval &&
            // Ignore CUSTOM_DATE_RANGE & CUSTOM_TIME_RANGE
            value.interval !== KaccCatalogIntervalsEnum.CUSTOM_DATE_RANGE &&
            value.interval !== KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE)
    })

  // Add formats
  const dateFormat = 'YYYY-MM-DD'
  const dateTimeFormat = 'YYYY-MM-DD hh:mm'

  // Handle interval change
  const handleIntervalChange = (event: SelectChangeEvent) => {
    setInterval(event.target.value as KaccCatalogIntervalsEnum)
  }

  // Handle interval add
  const handleAddSubmit = async () => {
    try {
      setIntervalAddLoading(true)
      setIntervalsIsError(false)

      if (intervalFrom && intervalTo && dayjs(intervalTo).isSameOrBefore(dayjs(intervalFrom))) {
        setIntervalsIsError(true)
        throw Error( t('kacc:Ending data time should be always grater then starting'))
      }

      await addCatalogInterval({
        ...(interval && { interval: interval }),
        ...(intervalFrom && { interval_from: intervalFrom }),
        ...(intervalTo && { interval_to: intervalTo }),
      })

      await useKaccCatalogStore.getState().getIntervals()

      enqueueSnackbar(
        t('kacc:{{name}} was added successfully!', { name: interval }),
        { variant: 'success' },
      )

      setInterval(null)
      setIntervalFrom(null)
      setIntervalTo(null)
    } catch (error: any) {
      enqueueSnackbar(
        t(`kacc:${error?.response?.data?.message ?? error?.message}`),
        { variant: 'error' },
      )
    } finally {
      setIntervalAddLoading(false)
    }
  }

  // Handle interval delete
  const handleDeleteSubmit = async (interval: KaccCatalogInterval) => {
    const params = new URLSearchParams()
    params.append('id', String(interval.id))

    try {
      setIntervalDeleteLoading(interval.id)

      await deleteCatalogInterval(params)
      await getIntervals()

      enqueueSnackbar(
        t('kacc:{{name}} was deleted successfully!', { name: interval.id }),
        { variant: 'success' },
      )
    } catch (error: any) {
      enqueueSnackbar(
        t(`kacc:${error?.response?.data?.message ?? error?.message}`),
        { variant: 'error' },
      )
    } finally {
      setIntervalDeleteLoading(null)
    }
  }

  // Handle interval date and time change
  const handleIntervalDateTimeChange = (value: string | null, type: 'from' | 'to'): void => {
    setIntervalsIsError(false)

    const date = interval === KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE
      ? String(dayjs(value).format(dateTimeFormat))
      : String(dayjs(value).format(dateFormat))

    if ((intervalFrom && type === 'to' && dayjs(date).isSameOrBefore(dayjs(intervalFrom)))
        || (intervalTo && type === 'from' && dayjs(intervalTo).isSameOrBefore(dayjs(date)))) {
      setIntervalsIsError(true)
      enqueueSnackbar(
        t('kacc:Ending data time should be always grater then starting'),
        { variant: 'error' },
      )
    }

    if (checkDateIsValid(value)) {
      if (interval === KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE) {
        if (type === 'from') setIntervalFrom(date)
        if (type === 'to') setIntervalTo(date)
      }

      if (interval === KaccCatalogIntervalsEnum.CUSTOM_DATE_RANGE) {
        if (type === 'from') setIntervalFrom(date + ' 00:00')
        if (type === 'to') setIntervalTo(date + ' 23:59')
      }
    }
  }

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {t('kacc:Manage intervals')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <SModalForm>

            <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px' }}>
              <FormControl fullWidth>
                <InputLabel id="manage-intervals-label">{ t('kacc:Select interval') }</InputLabel>
                <Select
                  labelId="manage-intervals-label"
                  id="interval"
                  value={interval ?? '' }
                  size={'medium'}
                  label={ t('kacc:Interval') }
                  onChange={handleIntervalChange}
                >
                  { filteredIntervals?.map((value) =>
                    (
                      <MenuItem key={value} value={value}>
                        { t(`kacc:${value}`) }
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>

              <SButton
                size={'medium'}
                style={{ height: 'inherit' }}
                variant="contained"
                color="primary"
                onClick={handleAddSubmit}
                disabled={intervalAddLoading || intervalsIsError}
                $updating={intervalAddLoading ?? false}
                startIcon={intervalAddLoading && <CachedIcon/>}
              >
                {t('kacc:Add')}
              </SButton>
            </div>

            {(interval === KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE) &&
                (
                  <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px', marginTop: '12px' }}>
                    <SDateTimePicker
                      ampm={false}
                      disableFuture
                      label={t('kacc:From')}
                      format={dateTimeFormat}
                      value={intervalFrom ? dayjs(intervalFrom) : null}
                      onChange={(newValue: any) => handleIntervalDateTimeChange(newValue, 'from')}
                      views={['day', 'hours']}
                      viewRenderers={{ hours: renderDigitalClockTimeView }}
                      sx={{}}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: intervalsIsError,
                        },
                        field: { clearable: true, onClear: () => handleIntervalDateTimeChange(null, 'from') },
                      }}
                    />

                    <SDateTimePicker
                      ampm={false}
                      disableFuture
                      label={t('kacc:To')}
                      format={dateTimeFormat}
                      value={intervalTo ? dayjs(intervalTo) : null}
                      onChange={(newValue: any) => handleIntervalDateTimeChange(newValue, 'to')}
                      views={['day', 'hours']}
                      viewRenderers={{ hours: renderDigitalClockTimeView }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: intervalsIsError,
                        },
                        field: { clearable: true, onClear: () => handleIntervalDateTimeChange(null, 'to') },
                      }}
                    />
                  </div>
                )
            }

            {(interval === KaccCatalogIntervalsEnum.CUSTOM_DATE_RANGE) &&
                (
                  <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px', marginTop: '12px' }}>
                    <SDatePicker
                      disableFuture
                      label={t('kacc:From')}
                      format={dateFormat}
                      value={intervalFrom ? dayjs(intervalFrom) : null}
                      onChange={(newValue: any) => handleIntervalDateTimeChange(newValue, 'from')}
                      slotProps={{
                        textField: { size: 'small' },
                        field: { clearable: true, onClear: () => handleIntervalDateTimeChange(null, 'from') },
                      }}
                    />

                    <SDatePicker
                      disableFuture
                      label={t('kacc:To')}
                      format={dateFormat}
                      value={intervalTo ? dayjs(intervalTo) : null}
                      onChange={(newValue: any) => handleIntervalDateTimeChange(newValue, 'to')}
                      slotProps={{
                        textField: { size: 'small' },
                        field: { clearable: true, onClear: () => handleIntervalDateTimeChange(null, 'to') },
                      }}
                    />
                  </div>
                )
            }

            <Table>
              <TableWrapper className={'overflow-auto'}>
                <TableBody>
                  {intervals && intervals?.map((interval, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Tooltip title={t('kacc:Interval ID')}>
                          <>{interval?.id}</>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        { t(`kacc:${interval?.interval}`) }

                        {(interval?.interval === KaccCatalogIntervalsEnum.CUSTOM_DATE_RANGE ||
                                interval?.interval === KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE) &&
                            (
                              <div className={'date-time'}>
                                ({interval?.interval_from} – {interval?.interval_to})
                              </div>
                            )
                        }
                      </TableCell>

                      <TableCell>
                        <Tooltip title={t('kacc:Delete')}>
                          <SDeleteButton
                            size={'small'}
                            variant="contained"
                            color="primary"
                            onClick={() => handleDeleteSubmit(interval)}
                            disabled={
                              intervalDeleteLoading === interval?.id
                                || interval?.interval === KaccCatalogIntervalsEnum.SEVEN_DAYS_AGO
                            }
                          >
                            <DeleteOutlinedIcon/>
                          </SDeleteButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableWrapper>
            </Table>

            <div
              style={
                {
                  display: 'flex',
                  marginLeft: 'auto',
                  marginTop: 'auto',
                  gap: '15px',
                }
              }
            >
              <SModalCancel
                variant={'outlined'}
                onClick={onClose}
                size={'medium'}
                type={'button'}
                style={{ marginLeft: 'auto' }}
              >
                {t('kacc:Close')}
              </SModalCancel>
            </div>
          </SModalForm>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`
const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`
const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #FF6961;
    color: #FF6961;

    &:hover {
      border-color: ${darken('#FF6961', 0.05)};
      color: ${darken('#FF6961', 0.05)};
    }
  }
`
const SDeleteButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #FFE2E2;
    color: #F6453B;
    padding: 6px;
    width: 40px;

    &:hover {
      background-color: #F6453B;
      color: #FFFFFF;
    }

    & svg {
      font-size: 18px;
    }
  }
`

const SDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-input {
    max-width: 150px;
  }

  & .MuiOutlinedInput-root {
    height: 40px;
  }
`

const SDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    max-width: 150px;
  }

  & .MuiOutlinedInput-root {
    height: 40px;
  }
`

const SButton = styled(Button)<{ $updating?: boolean }>`
  &.MuiButtonBase-root {
    background: #CBFFE4;
    box-shadow: 0 1px 1px 0 #aab7c1;
    color: #109B87;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    padding: 12px 16px;

    &:hover {
      background: #B4EED1;
    }
  }

  & .MuiSvgIcon-root {
    animation: ${({ $updating }) => $updating
    ? 'rotate 1s linear infinite'
    : 'none'};
    height: 16px;
    width: 16px;
  }
`
