import React from 'react'
import { Routes, Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import { UnderConstruction } from '../../pages/under-construction'

export function UnderConstructionRoutes(): React.ReactNode {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*" element={
            <UnderConstruction/>
          }
        />
      </Routes>/
    </BrowserRouter>
  )
}
