import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useSearchParams } from 'react-router-dom'
import debounce from 'debounce'
import styled from 'styled-components'
import { MenuItem, Select } from '@mui/material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { t } from 'i18next'

import { PaginationLimitParam, PaginationOffsetParam, usePagination } from '../../lib'

import {
  getOffsetPositiveOrZero,
  getCurrentOffsetLimit,
  getCurrentPagePosition,
  getNewPagePosition,
  getTotalPages,
} from './lib'

import type { PaginationProps } from './types'

export function Pagination({ count, limits }: PaginationProps) {
  const [search, setSearch] = useSearchParams()
  const { limitActive, setLimit, offsetActive, setOffset } = usePagination()
  const totalPages = getTotalPages(Number(count), Number(limitActive))

  // Check limit and offset query values
  useEffect(() => {
    // if search has PaginationLimitParam
    if (search.get(PaginationLimitParam)) {

      // if a limit query is not in limit object
      if (!Object.values(limits).includes(Number(limitActive))) {
        // do remove limit query
        search.delete(PaginationLimitParam)
        setSearch(search, { replace: true })
      }
    }

    // if search has PaginationOffsetParam
    if (search.get(PaginationOffsetParam)) {

      // if offset is not an integer
      if (!Number.isInteger(Number(offsetActive))
        // or offset is an integer
        || (Number.isInteger(Number(offsetActive)) && (
          // and offset less than zero
          Number(offsetActive) < 0
          // or offset divided to limit is not integer
          || !Number.isInteger(Number(offsetActive) / Number(limitActive))
          // or count more than zero and offset more than count
          || (count > 0 && (Number(offsetActive) + 1) > count)
        ))) {
        // do remove an offset
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }, [count, limitActive, offsetActive])

  /**
   * Set OffsetFrom using offsetActive
   * @return {number}
   */
  const setOffsetFrom = (): number => {
    return getOffsetPositiveOrZero(Number(offsetActive))
  }

  /**
   * Set OffsetTo using offsetActive|limit|count
   * @return {number}
   */
  const setOffsetTo = (): number => {
    return getCurrentOffsetLimit(
      Number(offsetActive),
      Number(limitActive),
      count,
    )
  }

  /**
   * Set CurrentPagePosition
   * @return {number}
   */
  const setCurrentPagePosition = (): number => {
    const currentOffset = getOffsetPositiveOrZero(Number(offsetActive))
    const currentPagePosition = getCurrentPagePosition(currentOffset, Number(limitActive))

    // if offset is more than count return 0
    // else return currentPagePosition
    return currentOffset > count ? 0 : currentPagePosition
  }

  /**
   * Handle LimitChange action
   * @param {number} limit
   * @return {void}
   */
  const handleLimitChange = (limit: number): void => {
    setLimit(String(limit))
    setOffset('0')

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  /**
   * Handle OnClick action
   * @return {void}
   * @param {any} selected
   */
  const handleOnClick = (selected?: number): void => {
    selected = selected || 0
    const newPagePosition = getNewPagePosition(
      selected,
      Number(limitActive),
    )
    setLimit(limitActive)
    setOffset(String(newPagePosition))

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const debouncedLimitChange = debounce(handleLimitChange, 150)
  const debouncedOnClick = debounce(handleOnClick, 150)

  return (
    <>
      {
        count > 0 ?
          (
            <SPagination>
              {totalPages > 0 ?
                (
                  <ReactPaginate
                    breakLabel="..."
                    className="react-paginate"
                    forcePage={setCurrentPagePosition()}
                    nextLabel={<ChevronRightOutlinedIcon/>}
                    onClick={(e) => debouncedOnClick(e.nextSelectedPage)}
                    pageRangeDisplayed={3}
                    pageCount={totalPages}
                    previousLabel={<ChevronLeftOutlinedIcon/>}
                    renderOnZeroPageCount={null}
                  />
                ) : null
              }

              <SPaginationLimit>
                <SPaginationLimitSelect
                  name={'LimitSelect'}
                  value={limitActive}
                  onChange={
                    (e) => debouncedLimitChange(e.target.value as number)
                  }
                  IconComponent={ExpandMoreOutlinedIcon}>
                  {
                    Object.values(limits)
                      .map((limit, index) => (
                        <SPaginationLimitOption
                          value={String(limit)} key={index}>
                          {t(`global:${limit}`)}
                        </SPaginationLimitOption>
                      ))
                  }
                </SPaginationLimitSelect>
              </SPaginationLimit>
              <SPaginationCount>
                <SPaginationCountFrom>
                  {setOffsetFrom() === 0 ? 1 : setOffsetFrom()}
                </SPaginationCountFrom>
                -
                <SPaginationCountTo>
                  {setOffsetTo()}
                </SPaginationCountTo>
                <SPaginationCountOf>{t('global:Of')}</SPaginationCountOf>
                <SPaginationCountTotal>{count}</SPaginationCountTotal>
              </SPaginationCount>
            </SPagination>
          ) : null
      }
    </>
  )
}

const SPagination = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 1rem;
  color: #7996A9;
  margin-top: 20px;

  & .react-paginate {
    align-items: center;
    border: 1px solid #B7C6D3;
    border-radius: 0.25rem;
    display: inline-flex;
    justify-content: center;
    list-style-type: none;
    overflow: hidden;
    height: 33px;
    margin: 0;
    padding: 0;

    svg {
      fill: #33A9B8;
      height: 1rem;
      width: 1rem;
    }

    & > li {
      border-left: 1px solid #B7C6D3;
      color: #33A9B8;
      display: flex;
      transition: all 0.8s;
      box-sizing: border-box;
      height: 33px;

      &:first-child {
        border-color: transparent;
      }

      & > a {
        cursor: pointer;
        padding: 0.33rem 0.75rem;
      }

      &.selected > a {
        background-color: #33A9B8;
        color: #ffffff;
        cursor: pointer;

        &:hover {
          background-color: #2c929f;
        }
      }

      &.disable,
      &.disabled {
        background-color: inherit;
        color: #889daa;

        & > a {
          pointer-events: none;

          &:hover {
            cursor: not-allowed;
          }

          & > svg {
            fill: #beccd6;
          }
        }

        &:hover {
          background-color: inherit;
          color: inherit;
          cursor: not-allowed;
        }
      }
    }
  }
`
const SPaginationLimit = styled.div``
const SPaginationLimitSelect = styled(Select)`
  .MuiOutlinedInput-notchedOutline {
    border-color: #B7C6D3;
  }

  &.MuiInputBase-root {
    border-radius: 0.25rem;
    padding: 0.42rem 0.75rem;
    height: 33px;
    color: #7996A9;
  }

  & .MuiSelect-select {
    padding: 0;

    &.MuiSelect-outlined.MuiInputBase-input {
      padding-left: 12px;
      padding-right: 25px;
    }
  }

  & .MuiSvgIcon-root {
    top: calc(50% - 0.5rem);
    height: 1rem;
    width: 1rem;
  }
`
const SPaginationLimitOption = styled(MenuItem)``

const SPaginationCount = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
`
const SPaginationCountFrom = styled.div``
const SPaginationCountTo = styled.div``
const SPaginationCountOf = styled.div`
  padding: 0 0.5rem;
`
const SPaginationCountTotal = styled.div``
