import { handleValueEmptyData, setPriceColor } from '../lib'
import { SETTINGS_FORMAT_KEYS, SETTINGS_PRICE_COLOR_KEYS } from '../../../../entities/kacc/catalog'
import { Tooltip } from '../../../../shared/ui/tooltip'

import { TableCell, TableValue } from './table'

import type {
  KaccCatalogAnty,
  KaccCatalogCampaigns,
  KaccCatalogFacebook,
  KaccCatalogOffer,
  KaccCatalogSettingsFilter,
} from '../../../../entities/kacc/catalog'


export type TableBlockBodyGroupsProps = {
    settings: KaccCatalogSettingsFilter,
    settingsKey: keyof KaccCatalogSettingsFilter,
    dataItem: KaccCatalogAnty | KaccCatalogFacebook | KaccCatalogCampaigns | KaccCatalogOffer
    offerIdTooltip?: React.ReactNode,
}
export function TableBlockBodyGroups({ settings, settingsKey, dataItem, offerIdTooltip }: TableBlockBodyGroupsProps) {

  return (
    <>
      {settings?.[settingsKey]?.filter((f) => f.checked)
        ?.map((setting, index) => {
          const key = setting.key as keyof TableBlockBodyGroupsProps['dataItem']
          const color = SETTINGS_PRICE_COLOR_KEYS
            .some(value => value === key)
          const format = SETTINGS_FORMAT_KEYS
            .filter(value => value.key === key)
            .map(value => value.format)[0]
                ?? 'string'

          return key in dataItem
            && (
              <TableCell
                key={setting.key}
                className={setting.key}
                style={{
                  minWidth: setting.width,
                  maxWidth: setting.width,
                }}
              >
                { offerIdTooltip && key === 'offer_id'
                  ? (
                    <Tooltip
                      title={offerIdTooltip} componentsProps={{
                        tooltip: {
                          sx: {
                            color: '#32383E',
                            bgcolor: '#edf4f6',
                            border: '1px solid rgba(38, 86, 115, 0.1)',
                            '& .MuiTooltip-arrow': {
                              color: '#edf4f6',
                            },
                          },
                        },
                      }}>
                      <TableValue
                        style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                        {handleValueEmptyData(dataItem[key], format)}
                      </TableValue>
                    </Tooltip>
                  )
                  : (
                    <TableValue
                      style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                      {handleValueEmptyData(dataItem[key], format)}
                    </TableValue>
                  )
                }
              </TableCell>
            )
        })
      }
    </>
  )
}
