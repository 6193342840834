import * as React from 'react'
import { useEffect, useState } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import styled from 'styled-components'

import type { SwitcherProps } from './types'

export function Switcher({
  label,
  itemClickHandler,
  itemActive,
  defaultValue,
}: SwitcherProps) {
  const [toggle, setToggle] = useState(defaultValue)

  const handleSwitcherChange = () => {
    setToggle(itemActive)
    itemClickHandler && itemClickHandler()
  }

  useEffect(() => {
    setToggle(itemActive)
  }, [itemActive])

  return (
    <>
      <SFormControlLabel
        control={
          <Switch
            checked={toggle}
            onChange={handleSwitcherChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleSwitcherChange()
              }
            }}
          />
        }
        label={label}
        labelPlacement="start"
      />
    </>
  )
}

const SFormControlLabel = styled(FormControlLabel)`
  height: 40px;
`
