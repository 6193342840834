import React from 'react'
import { t } from 'i18next'

import { SortType, useSort } from '../../../../shared/lib'
import { TableRowStatus } from '../../../../shared/ui/table-row-status'
import { OfferDetailsSortKeys, useOfferDetailsStore } from '../../../../entities/watch-cat/offer-details'

import {
  Table,
  TableBody,
  TableCell,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
  SGeo,
  SCountryFlag,
} from './table'

export function GoalsTable() {
  const { sortActive, sortColumn } = useSort()
  const { offerDetails } = useOfferDetailsStore()

  const getSortDirectionClassName = (
    column: string,
    initialSort: SortType = SortType.asc,
    initialColumn: OfferDetailsSortKeys = OfferDetailsSortKeys.name,
  ) => {
    let direct = ''

    if (column === sortColumn) {
      if (sortActive === initialSort)
        direct = initialSort
      else
        direct = initialSort !== SortType.asc
          ? SortType.asc
          : SortType.desc
    }

    if (!sortActive || !sortColumn)
      direct = column === initialColumn
        ? initialSort
        : direct

    return direct
  }

  return (
    <Table>
      <TableWrapper>
        <TableHeader>
          <TableRow>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Status') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Geo') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel
                className={ getSortDirectionClassName(OfferDetailsSortKeys.name) }
              >
                { t('watch-cat:Name') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Price') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Payment') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Currency') }
              </TableHeaderLabel>
            </TableCell>
            <TableCell>
              <TableHeaderLabel className={ 'disabled' }>
                { t('watch-cat:Type') }
              </TableHeaderLabel>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody style={ { maxHeight: '350px', height: '100%' } }>
          { offerDetails !== null && (
            Object.values(offerDetails.goal)
              ?.map((goal, index) => (
                <TableRow key={ index }>
                  <TableCell>
                    <TableValue>
                      <TableRowStatus
                        active={ goal.active }
                        activeText={ t('watch-cat:Active') as string }
                        inactiveText={ t('watch-cat:Inactive') as string }
                      />
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      <SGeo>
                        { goal.geo !== '' &&
                        <SCountryFlag
                          countryCode={ String(goal.geo) }
                          svg
                          style={ {
                            height: '12px',
                            width: 'auto',
                          } }
                        />
                        }
                        { String(goal.geo) }
                      </SGeo>
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      { goal.name }
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      { goal.price }
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      { goal.cash } {/*PPL*/ }
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      { goal.currency }
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      { goal.type }
                    </TableValue>
                  </TableCell>
                </TableRow>
              ),
              )) }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
