import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { t } from 'i18next'

import { useSearch } from '../../../../shared/lib'

export const AntyNameSearchParam = 'name'

export function AntyNameSearchFilter() {
  const [ search, setSearch ] = useSearchParams()
  const [ focus, setFocus ] = useState(false)
  const { searchActive } = useSearch(AntyNameSearchParam)

  const handleOnInputChange = (event: React.SyntheticEvent<Element, Event>, value: any): void => {
    if (value) search.set(AntyNameSearchParam, value)
    if (!value && searchActive) search.delete(AntyNameSearchParam)
    setSearch(search, { replace: true })
  }

  // Check offer query values
  useEffect(() => {
    if (searchActive !== null || searchActive === '') {
      if (!searchActive || (
        searchActive && !(searchActive.length > 0)
      )) {
        search.delete(AntyNameSearchParam)
        setSearch(search, { replace: true })
      }
    }
  }, [searchActive])

  return (
    <Autocomplete
      freeSolo
      defaultValue={ searchActive ?? null }
      value={ searchActive ?? null }
      clearOnBlur={ true }
      open={ false }
      options={ [] }
      noOptionsText={t('kacc:No options')}
      onInputChange={ handleOnInputChange }
      onFocus={ () => setFocus(true) }
      onBlur={ () => setFocus(false) }
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('kacc:Search by name') }
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={
            !searchActive && !focus
              ? {
                shrink: false,
                style: { paddingLeft: '1.5rem' },
              }
              : {}
          }
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{
        minWidth: '250px',

        '& .MuiInputAdornment-positionStart': {
          color: 'rgba(18, 42, 81, 0.60)',
          paddingLeft: '8px',
        },
        '& .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputAdornedStart': {
          paddingLeft: '0',
        },
      }}
    />
  )
}
