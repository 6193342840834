import React, { useRef } from 'react'
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import styled from 'styled-components'

import { useDashboardStore } from '../../../entities/dashboard'

export const LineChart = () => {
  const networkChartRef = useRef<ReactApexChart>(null)
  const networkContainerRef = useRef<HTMLDivElement>(null)

  const { annualProgress } = useDashboardStore()

  if (!annualProgress) {
    return <div>Loading...</div>
  }

  // Сортируем данные по месяцу
  const sortedAnnualProgress = annualProgress.sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())

  const saleRevenueData = sortedAnnualProgress.map(item => item.saleRevenue)
  const costData = sortedAnnualProgress.map(item => item.cost)
  const profitConfirmedData = sortedAnnualProgress.map(item => item.profitConfirmed)

  // Преобразуем формат YYYY/MM в название месяца
  const labelData = sortedAnnualProgress.map(item => {
    const date = new Date(`${item.month}/01`)
    return date.toLocaleString('default', { month: 'long' })
  })

  const series = [
    {
      name: 'Revenue',
      data: saleRevenueData,
    },
    {
      name: 'Cost',
      data: costData,
    },
    {
      name: 'Profit',
      data: profitConfirmedData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
    },
    colors: ['#77B6EA', '#545454', '#FF5733'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'stepline',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 5,
    },
    xaxis: {
      categories: labelData,
    },
  }


  return (
    <ChartContainer ref={networkContainerRef}>
      <ReactApexChart
        ref={networkChartRef}
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`