import compose from 'compose-function'

import withHelmet from './with-helmet'
import withTheme from './with-theme'
import withTranslation from './with-translation'
import withXDatePicker from './with-x-date-picker'
import withSnackbar from './with-snackbar'

export const withProviders = compose(
  withHelmet,
  withSnackbar,
  withTheme,
  withTranslation,
  withXDatePicker,
)
