import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../../shared/ui/table'

export const Table = styled(UTable)`
  border: 1px solid #C2DDED;
  border-radius: 4px;
  margin: 24px 0;
`

export const TableRow = styled(UTableRow)`
  min-height: 47px;
  
  &:not(:last-child) {
    box-shadow: #C2DDED 0px -1px 0px 0px inset;
  }
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
  margin: 12px 16px 12px 16px;
  
  & .date-time {
    color: #b2b7c1;
  }

  &:nth-child(1) {
    color: #0294a7;
    border-radius: 4px;
    box-shadow: #C2DDED 0px 0px 0px 1px inset;
    flex-grow: 0;
    min-width: 40px;
    justify-content: center;
    margin-right: 0;
  }

  &:nth-child(2) {
    flex-grow: 1;
  }

  &:nth-child(3) {
    flex-grow: 0;
  }

  &:last-child {
    margin-right: 16px;
  }
`

export const TableValue = styled(UTableValue)`
  word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)``

export const TableBody = styled(UTableBody)`
  height: 400px;
`
