import React from 'react'
import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'
import styled, { createGlobalStyle } from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { CountriesData } from './countriesData'
import { ChartAlertMessage } from './alerts'

export function WorldMapWithMarkers() {
  const { countries, countriesIsLoading, countriesHasError } = useDashboardStore()

  //избегаем лишний перерасчет маркеров перед каждым рендерингом
  const markers = React.useMemo(() =>
    countries?.map(country => ({
      latLng: country.coords,
      name: country.country,
    })),
  [countries],
  )

  if (!markers || markers.length === 0 || countriesIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('kacc:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (countriesHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(countriesHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!countries) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('kacc:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }
  return (
    <>
      <SWrap>
        <TooltipStyle/>
        <SMapContainer>
          <VectorMap
            map={worldMill}
            markers={markers}
            markerStyle={{
              initial: {
                fill: '#0094A6',
                stroke: '#00738E',
                strokeWidth: 2.5,
              },
              hover: {
                fill: '#00738E',
                stroke: '#00738E',
              },
              selected: {
                fill: '#28D17C',
                stroke: '#109B69',
                hover: {
                  fill: '#28D17C',
                  stroke: '#28D17C',
                },
              },
            }}
            selectedMarkers={[0]}
            containerStyle={{
              width: '100%',
              height: '100%',
            }}
            markerLabelStyle={{
              initial: {
                fontSize: 13,
                fontWeight: 500,
                fill: '#35373e',
              },
              selected: {
                fill: '#109B69',
              },
            }}
            containerClassName="map-container"
            backgroundColor="#ffffff"
            regionStyle={{
              initial: {
                fill: '#D3D3D3',
              },
            }}
            zoomOnScroll={false}
            panOnDrag={false}
            zoomButtons={false}
            labels={{
              markers: {
                render: (index: number) => {
                  const marker = markers[index]
                  return marker ? marker.name : ''
                },
              },
            }}
            regionLabelStyle={{
              initial: {
                fill: '#35373e',
                fontWeight: 500,
                fontSize: '14px',
              },
              hover: {
                fill: '#00738E',
              },
            }}
          />
        </SMapContainer>
      </SWrap>
      <SListContainer>
        <CountriesData/>
      </SListContainer>
    </>

  )
}

const SWrap = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 230px;
  padding: 0;
`

const SMapContainer = styled.div`
  width: 90%;
  height: 100%;
`

const TooltipStyle = createGlobalStyle`
  .jvectormap-container {
    svg {
      vertical-align: top !important;
    }

  }

  .jvectormap-tip {
    background-color: #0094A6 !important;
    color: #FFFFFF !important;
  }
`

const SListContainer = styled.div`
  padding: 0 20px 0;
`