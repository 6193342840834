import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

/*
 *  STORE
 */

export type KaccCatalogStoreProps = {
  catalog: KaccCatalogData['catalog']
  catalogCount: KaccCatalogData['count'],
  catalogQuery: KaccCatalogData['query'],
  catalogTotals: KaccCatalogData['totals'],
  catalogFilters: KaccCatalogData['filters'],
  catalogInterval: KaccCatalogData['interval'],
  catalogLoading: boolean,
  catalogError: string | null,
  getCatalog: (search: URLSearchParams) => Promise<void>,

  forceSyncLoading: boolean,
  forceSyncError: string | null,
  getForceSync: (search: URLSearchParams) => Promise<void>,

  settings: KaccCatalogSettingsFilter | null,
  getSettings: () => Promise<void>,
  updateSettings: (config: KaccCatalogSettingsFilter) => Promise<void>,
  saveSettings: (config: KaccCatalogSettingsFilter | null) => Promise<void>,

  intervals: KaccCatalogInterval[] | null,
  getIntervals: () => Promise<void>,
}


/*
 *  CATALOG
 */

export enum KaccCatalogLimitEnum {
  TwentyFive = '25',
  Fifty = '50',
  OneHundred = '100',
  TwoHundredFifty = '250',
  FiveHundreds = '500',
  Thousand = '1000',
}

export enum KaccCatalogDirectionEnum {
  ascendant = 'asc',
  descendant = 'desc',
}

export enum KaccCatalogSortEnum {
  anty = 'anty',
  anty_id = 'anty_id',
  facebook_id = 'facebook_id',
  remaining_amount = 'remaining_amount',
  campaign = 'campaign',
  campaign_id = 'campaign_id',
  campaign_group = 'campaign_group',
  campaign_group_id = 'campaign_group_id',
  affiliate_network = 'affiliate_network',
  affiliate_network_id = 'affiliate_network_id',
  offer = 'offer',
  offer_id = 'offer_id',
  offer_group = 'offer_group',
  offer_group_id = 'offer_group_id',
  stream_id = 'stream_id',
  clicks = 'clicks',
  campaign_unique_clicks = 'campaign_unique_clicks',
  conversions = 'conversions',
  roi_confirmed = 'roi_confirmed',
  roi = 'roi',
  sale_revenue = 'sale_revenue',
  revenue = 'revenue',
  cost = 'cost',
  profitability = 'profitability',
  profit_confirmed = 'profit_confirmed',
  profit = 'profit',
  leads = 'leads',
  sales = 'sales',
  rejected = 'rejected',
  approve = 'approve',
  cr = 'cr',
  crs = 'crs',
  epc_confirmed = 'epc_confirmed',
  uepc_confirmed = 'uepc_confirmed',
  cps = 'cps',
  cpa = 'cpa',
  cpl = 'cpl',
  cpc = 'cpc',
  ucpc = 'ucpc',
  ecpc = 'ecpc',
  ecpm = 'ecpm',
  ecpm_confirmed = 'ecpm_confirmed',
  ec = 'ec',
  ec_confirmed = 'ec_confirmed',
  lead_revenue = 'lead_revenue',
  rejected_revenue = 'rejected_revenue',
  rebills = 'rebills',
  stream_unique_clicks = 'stream_unique_clicks',
  global_unique_clicks = 'global_unique_clicks',
  uc_campaign_rate = 'uc_campaign_rate',
  uc_stream_rate = 'uc_stream_rate',
  uc_global_rate = 'uc_global_rate',
  bots = 'bots',
  bot_share = 'bot_share',
  proxies = 'proxies',
  empty_referrers = 'empty_referrers',
  crl = 'crl',
  epc = 'epc',
  uepc = 'uepc',
  epc_hold = 'epc_hold',
  uepc_hold = 'uepc_hold',
  landing_clicked_period = 'landing_clicked_period',
  lp_views = 'lp_views',
  lp_clicks = 'lp_clicks',
  lp_ctr = 'lp_ctr',
  deposits = 'deposits',
  deposit_revenue = 'deposit_revenue',
  reg_revenue = 'reg_revenue',
  regs = 'regs',
  ucr_regs = 'ucr_regs',
  cr_regs_to_deps = 'cr_regs_to_deps',
}

export type KaccCatalogOffer = {
  offer: string;
  offer_id: number;
  offer_group: string;
  offer_group_id: number;
  stream_id: number;
  clicks: number;
  campaign_unique_clicks: number;
  conversions: number;
  roi_confirmed: number;
  roi: number;
  sale_revenue: number;
  revenue: number;
  cost: number;
  profitability: number;
  profit_confirmed: number;
  profit: number;
  leads: number;
  sales: number;
  rejected: number;
  approve: number;
  cr: number;
  crs: number;
  epc_confirmed: number;
  uepc_confirmed: number;
  cps: number;
  cpa: number;
  cpl: number;
  cpc: number;
  ucpc: number;
  ecpc: number;
  ecpm: number;
  ecpm_confirmed: number;
  ec: number;
  ec_confirmed: number;
  lead_revenue: number;
  rejected_revenue: number;
  rebills: number;
  stream_unique_clicks: number;
  global_unique_clicks: number;
  uc_campaign_rate: number;
  uc_stream_rate: number;
  uc_global_rate: number;
  bots: number;
  bot_share: number;
  proxies: number;
  empty_referrers: number;
  crl: number;
  epc: number;
  uepc: number;
  epc_hold: number;
  uepc_hold: number;
  landing_clicked_period: number;
  lp_views: number;
  lp_clicks: number;
  lp_ctr: number;
  deposits: number;
  deposit_revenue: number;
  reg_revenue: number;
  regs: number;
  ucr_regs: number;
  cr_regs_to_deps: number;
}

export enum KaccCatalogFilterColumnEnum {
  remaining_amount = 'remaining_amount',
  clicks = 'clicks',
  campaign_unique_clicks = 'campaign_unique_clicks',
  conversions = 'conversions',
  roi_confirmed = 'roi_confirmed',
  roi = 'roi',
  sale_revenue = 'sale_revenue',
  revenue = 'revenue',
  cost = 'cost',
  profitability = 'profitability',
  profit_confirmed = 'profit_confirmed',
  profit = 'profit',
  leads = 'leads',
  sales = 'sales',
  rejected = 'rejected',
  approve = 'approve',
  cr = 'cr',
  crs = 'crs',
  epc_confirmed = 'epc_confirmed',
  uepc_confirmed = 'uepc_confirmed',
  cps = 'cps',
  cpa = 'cpa',
  cpl = 'cpl',
  cpc = 'cpc',
  ucpc = 'ucpc',
  ecpc = 'ecpc',
  ecpm = 'ecpm',
  ecpm_confirmed = 'ecpm_confirmed',
  ec = 'ec',
  ec_confirmed = 'ec_confirmed',
  lead_revenue = 'lead_revenue',
  rejected_revenue = 'rejected_revenue',
  rebills = 'rebills',
  stream_unique_clicks = 'stream_unique_clicks',
  global_unique_clicks = 'global_unique_clicks',
  uc_campaign_rate = 'uc_campaign_rate',
  uc_stream_rate = 'uc_stream_rate',
  uc_global_rate = 'uc_global_rate',
  bots = 'bots',
  bot_share = 'bot_share',
  proxies = 'proxies',
  empty_referrers = 'empty_referrers',
  crl = 'crl',
  epc = 'epc',
  uepc = 'uepc',
  epc_hold = 'epc_hold',
  uepc_hold = 'uepc_hold',
  landing_clicked_period = 'landing_clicked_period',
  lp_views = 'lp_views',
  lp_clicks = 'lp_clicks',
  lp_ctr = 'lp_ctr',
  deposits = 'deposits',
  deposit_revenue = 'deposit_revenue',
  reg_revenue = 'reg_revenue',
  regs = 'regs',
  ucr_regs = 'ucr_regs',
  cr_regs_to_deps = 'cr_regs_to_deps',
}

export type KaccCatalogCampaigns = {
  campaign: string;
  campaign_id: number;
  campaign_group: string;
  campaign_group_id: number;
  affiliate_network: string;
  affiliate_network_id: number;
  offers: KaccCatalogOffer[] | null
}

export type KaccCatalogFacebook = {
  facebook_id: number;
  remaining_amount: number;
  campaigns: KaccCatalogCampaigns[] | null
}

export type KaccCatalogAnty = {
  anty_id: number;
  anty: string;
  facebook: KaccCatalogFacebook[];
}

export type KaccCatalogFilter = {
  id: number;
  label: string;
  count: number;
};

export type KaccCatalogFilters = {
  affiliate_networks: KaccCatalogFilter[];
  campaign_groups: KaccCatalogFilter[];
  offer_groups: KaccCatalogFilter[];
  intervals: KaccCatalogInterval[];
};

export type KaccCatalogTotals = Partial<Record<keyof Partial<KaccCatalogFacebook>, string | number>> &
    Partial<Record<keyof Partial<KaccCatalogOffer>, string | number>>;

export type KaccCatalogQuery = {
  interval_id: number;
  affiliate_network_id: string;
  campaign_group_id: string;
  offer_group_id: string;
  exclude_zero: string;
  offset: number;
  limit: KaccCatalogLimitEnum;
  column: KaccCatalogSortEnum;
  sort: KaccCatalogDirectionEnum;
};

export type KaccCatalogData = {
  count: number | null;
  query: KaccCatalogQuery | null;
  interval: KaccCatalogInterval | null;
  totals: KaccCatalogTotals | null;
  filters: KaccCatalogFilters | null;
  catalog: KaccCatalogAnty[] | null;
}

export type KaccCatalogResponse = {
  data: KaccCatalogData
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}


/*
 *  INTERVALS
 */

export enum KaccCatalogIntervalsEnum {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_MONDAY = 'last_monday',
  SEVEN_DAYS_AGO = '7_days_ago',
  FIRST_DAY_OF_THIS_MONTH = 'first_day_of_this_month',
  PREVIOUS_MONTH = 'previous_month',
  ONE_MONTH_AGO = '1_month_ago',
  FIRST_DAY_OF_THIS_YEAR = 'first_day_of_this_year',
  ALL_TIME = 'all_time',
  CUSTOM_DATE_RANGE = 'custom_date_range',
  CUSTOM_TIME_RANGE = 'custom_time_range',
}

export type KaccCatalogIntervalDefault = KaccCatalogIntervalsEnum.SEVEN_DAYS_AGO;

export type KaccCatalogInterval = {
  id: number;
  interval: KaccCatalogIntervalsEnum;
  interval_from: string;
  interval_to: string;
  created_at: string;
  updated_at: string;
}

export type KaccCatalogIntervalsResponse = {
  data: KaccCatalogInterval[]
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}

/*
 *  SETTINGS
 */

export type KaccCatalogSettings = {
  checked: boolean;
  uncheckable: boolean;
  width: number
}

export type KaccCatalogSettingsFormat = {
  key: keyof KaccCatalogAnty | keyof KaccCatalogFacebook | keyof KaccCatalogCampaigns | keyof KaccCatalogOffer;
  format: 'currency' | 'number' | 'percent' | 'string' | 'time';
}

export type KaccCatalogSettingsDefault<K> = {
  key: K;
  width: KaccCatalogSettings['width']
}

export type KaccCatalogSettingsAnty = {
  key: keyof KaccCatalogAnty;
} & KaccCatalogSettings

export type KaccCatalogSettingsFacebook = {
  key: keyof KaccCatalogFacebook;
} & KaccCatalogSettings

export type KaccCatalogSettingsCampaigns = {
  key: keyof KaccCatalogCampaigns;
} & KaccCatalogSettings

export type KaccCatalogSettingsOffers = {
  key: keyof KaccCatalogOffer;
} & KaccCatalogSettings

export type KaccCatalogSettingsFilter = {
  anty: KaccCatalogSettingsAnty[];
  facebook: KaccCatalogSettingsFacebook[];
  campaigns: KaccCatalogSettingsCampaigns[];
  offers: KaccCatalogSettingsOffers[]
}

export type KaccCatalogSettingsGroup = {
  key: keyof KaccCatalogSettingsFilter;
  checked: boolean
}

export type DataSettings = {
  settings: KaccCatalogSettingsFilter
}

export type KaccCatalogSettingsResponse = {
  data: DataSettings
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}
