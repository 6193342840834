import { AxiosError, AxiosResponse } from 'axios'

import { request } from '../../shared/api'

import type { DataResponse } from './types'

export const getDomains = (params: URLSearchParams): Promise<DataResponse | AxiosError> =>
  request.get('/domain-watcher/filter', { params })

export const addDomain = (domain: string, comment?: string): Promise<AxiosResponse | AxiosError> =>
  request.post('/domain-watcher', { domain, comment })

export const editDomainComment = (id: string, comment?: string): Promise<AxiosResponse | AxiosError> =>
  request.put(`/domain-watcher/comment/${id}`,
    { comment })

export const disableDomain = (id: string): Promise<AxiosResponse | AxiosError> =>
  request.put(`/domain-watcher/toggle/${id}`, {})

export const deleteDomain = (id: string): Promise<AxiosResponse | AxiosError> =>
  request.delete(`/domain-watcher/domain/${id}`, {})

export const syncKeitaro = async (): Promise<AxiosResponse | AxiosError> =>
  request.get('/domain-watcher/keitaro-sync')
