import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import debounce from 'debounce'
import styled from 'styled-components'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'

import type { DomainsGeneratorGroups } from '../../../../entities/domains-generator/generator'
import type { GroupsFilterProps } from './types'

export const GroupsFilterParam = 'groups'

export function GroupsFilter({ getGroupsData, groupsData }: GroupsFilterProps) {
  const { filterActive } = useFilter(GroupsFilterParam, '')
  const [ search, setSearch] = useSearchParams()
  const initialOptions = filterActive
      && groupsData?.filter(
        (item) => item
              && filterActive
                .split(',')
                .includes(String(item.id)),
      )

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: DomainsGeneratorGroups[]) => {
    const values = value.map((v) => v?.id).join()

    if (values.length > 0) search.set(GroupsFilterParam, values as string)
    if (values.length === 0 && filterActive) search.delete(GroupsFilterParam)
    setSearch(search, { replace: true })
  }

  const debouncedGroups = useMemo(() => {
    return debounce(() => getGroupsData(), 300)
  }, [getGroupsData])

  // Check groups query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && initialOptions && !(initialOptions.length > 0)
      )) {
        search.delete(GroupsFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [groupsData, filterActive])

  useEffect(() => {
    debouncedGroups()

    return () => {
      debouncedGroups.clear()
    }
  }, [debouncedGroups])

  return (
    <SGroupsFilter>
      <Autocomplete
        autoHighlight
        disablePortal
        multiple
        limitTags={1}
        popupIcon={ <KeyboardArrowDownIcon/> }
        onChange={ handleOnChange }
        options={ groupsData ?? [] }
        openOnFocus={ true }
        noOptionsText={t('subdomains-generator:No options')}
        value={ initialOptions ? initialOptions : [] }
        defaultValue={ initialOptions ? initialOptions : [] }
        getOptionLabel={option => option?.name ?? ''}
        isOptionEqualToValue={(option, value) => option?.id === value?.id }
        renderOption={ (props, option) => (
          (option && <Box
            component="li"
            value={ option?.id }
            {...props}
          >
            { option?.name }
          </Box>)
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={ t('subdomains-generator:Groups') }
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
        slotProps={{
          clearIndicator: {
            tabIndex: 0,
          },
        }}
        style={{
          width: 200,
        }}
      />
    </SGroupsFilter>
  )
}

export const SGroupsFilter = styled.div`
  margin-left: 16px;
`
