import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { Alert, CircularProgress, TextField } from '@mui/material'
import * as Yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'

import { Button } from '../../../../../shared/ui/button'
import { sendInvitation, useManageMembersStore } from '../../../../../entities/settings/members'
import { capitalizeFirstLetter } from '../../../../../shared/lib'

import type { InviteMemberFormProps } from '../types'

export const initialValues: InviteMemberFormProps = {
  email: '',
  submit: null,
}

export const MembersForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { getMembersList } = useManageMembersStore()
  const yupValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('settings:The email provided should be a valid email address'))
      .required(t('settings:The email field is required')),
  })

  const formikOnSubmitAction = async (values: InviteMemberFormProps, {
    setErrors,
    setStatus,
    setSubmitting,
  }: FormikHelpers<InviteMemberFormProps>) => {
    try {
      await sendInvitation(values.email)

      enqueueSnackbar(
        t('settings:{{email}} was invited successfully!', { email: values.email }), { variant: 'success' },
      )

      getMembersList()

      setStatus({ success: true })
    } catch (error: any) {
      enqueueSnackbar(
        `${t('settings:{{email}} was not invited!', { email: values.email })} ${
          error?.response?.data?.message
            ? t(`settings:${error.response.data.message}`)
            : t('settings:Unknown error')
        }`,
        { variant: 'error' },
      )
      setStatus({ success: false })
    } finally {
      setSubmitting(false)
    }
  }


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupValidationSchema}
      onSubmit={formikOnSubmitAction}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Title>
            { t('settings:Manage members') }
          </Title>

          <TextField
            error={Boolean(touched.submit && touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            label={t('settings:Type to add an email')}
            name="email"
            placeholder={ t('settings:Email') }
            onBlur={handleBlur}
            onChange={handleChange}
            size={ 'small' }
            type="email"
            value={values.email}
            variant="outlined"
          />

          { touched.submit && Object.keys(errors).length > 0 && (
            <Alert severity="error" style={{ maxWidth: '500px', marginBottom: '1rem' }}>
              { errors.email && touched.email && <div>
                { t(`settings:${errors.email}`) } </div>
              }
              { errors.submit && touched.submit && <div
                style={{
                  display: 'flex', flexDirection: 'column', gap: '1rem',
                }}>
                { errors.submit?.split('|')?.map((item: string) => (
                  <span key={item}>{t(`settings:${capitalizeFirstLetter(item)}`)}</span>
                ))}
              </div>
              }
            </Alert>
          )}

          <ButtonsBlock>
            <SDescription>
              { t('settings:You can invite only one member at a time') }
            </SDescription>
            <SubmitButton
              startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null}
              disabled={isSubmitting}
              type={ 'submit' }
              size={ 'small' }
              variant={ 'contained' }
            >
              <ShareOutlinedIcon/>
              { t('settings:Invite member') }
            </SubmitButton>
          </ButtonsBlock>
        </Form>
      )}
    </Formik>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .MuiFormControl-root {
    max-width: 500px;

    &:last-child {
      margin-bottom: 10px;
    }
  }
`
const Title = styled.div`
  color: #407698;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
`

const ButtonsBlock = styled.div`
  display: flex;
  gap: 16px;
  margin-top: auto;
  justify-content: space-between;
  max-width: 500px;
`

const SDescription = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 117%;
  letter-spacing: -0.01em;
  color: #3fbdf6;

`

const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 13px;
  }
`
