import styled from 'styled-components'

import { Pagination } from '../../../shared/ui/pagination'
import { useDomainsWatcherStore } from '../../../entities/domains-watcher'

export function PaginationBlock() {
  const { count, limits } = useDomainsWatcherStore()

  return (
    <SPaginationBlock>
      <Pagination count={count ?? 0} limits={limits}/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
  display: flex;
  gap: 20px;
`
