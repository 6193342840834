import { Suspense, useEffect, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'

import { FallbackLoader } from '../shared/ui/fallback-loader'
import { useAppStore } from '../entities/app'

import { PagesWithRoutes, UnderConstructionRoutes } from './routes'
import { withProviders } from './providers'

import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'

const App = () => {
  const { getAppVersion } = useAppStore()
  const [version, setVersion] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const fetchedVersion = await getAppVersion()
        if (fetchedVersion) {
          setVersion(fetchedVersion)
          console.log('Backend version:', fetchedVersion)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchVersion()
  }, [getAppVersion])

  return (
    <Suspense fallback={<FallbackLoader />}>
      <CssBaseline />
      {loading ? <FallbackLoader /> : version ? <PagesWithRoutes /> : <UnderConstructionRoutes />}
    </Suspense>
  )
}

export default withProviders(App)
