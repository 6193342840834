import styled from 'styled-components'

export const TableMenu = styled.div`
  align-items: flex-start;
  display: inline-flex;
  gap: 7px;
  justify-content: center;
`

export const TableMenuItem = styled.div`
  align-items: center;
  display: flex;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(39, 85, 114, 0.23);
  padding: 5px;
  cursor: pointer;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  & .MuiSvgIcon-root {
    display: block;
    font-size: 1rem;
  }
`
