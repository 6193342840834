import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

import { TranslationLanguage } from './types'

export const fallbackLng = TranslationLanguage.en

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      // TODO: Add loadPath for `backend` namespace from backend api point
      // https://stackoverflow.com/questions/58539069/load-translations-from-multiples-paths-i18n-in-nodejs
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'global',
    fallbackLng: fallbackLng,
    //debug: true,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: '|$!', // Set key separator
    lng: TranslationLanguage.en, // Set the default language
    ns: ['global'], // Initial namespace to load
    nsSeparator: ':', // Set namespace separator
    react: {
      useSuspense: true, // Set to true to enable Suspense for loading translations
    },
    supportedLngs: [TranslationLanguage.en, TranslationLanguage.ru],
  })

export default i18n
