import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const PaginationLimitParam = 'limit'
export const PaginationOffsetParam = 'offset'

export enum PaginationDefaults {
  limit = '25',
  offset = '0',
}

export type PaginationProps = {
  setLimit: (limit: string) => void
  setOffset: (offset: string) => void
  limitActive: string
  offsetActive: string
}

export function usePagination(): PaginationProps {
  const [search, setSearch] = useSearchParams()
  const [limitActive, setLimitActive] = useState<PaginationProps['limitActive']>(PaginationDefaults.limit)
  const [offsetActive, setOffsetActive] = useState<PaginationProps['offsetActive']>(PaginationDefaults.offset)

  const setLimit: PaginationProps['setLimit'] = (limit) => {
    search.set(PaginationLimitParam, limit)
    setSearch(search, { replace: true })
  }

  const setOffset: PaginationProps['setOffset'] = (offset) => {
    search.set(PaginationOffsetParam, offset)
    setSearch(search, { replace: true })
  }

  useEffect(() => {
    const limitActive = search.get(PaginationLimitParam) || PaginationDefaults.limit
    const offsetActive = search.get(PaginationOffsetParam) || PaginationDefaults.offset

    setLimitActive(limitActive as string)
    setOffsetActive(offsetActive as string)
  }, [search])

  return {
    setLimit,
    setOffset,
    limitActive,
    offsetActive,
  }
}
