import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Card, Container, styled, Typography } from '@mui/material'

import { GuestLogoIcon } from '../../shared/ui/icon-assets/guest-logo'
import { AuthLanguageSwitcher } from '../../features/auth/language-switcher'


const MainContainer = styled(Container)(() => ({
  '&': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 0,
    maxWidth: '450px',
    zIndex: 2,
  },
}),
)

const AuthContainer = styled(Card)(() => ({
  '&': {
    padding: '2rem',
    width: '100%',
  },
}),
)

export type AuthLayoutProps = {
    header: string,
    title?: string,
    subtitle?: string | React.ReactNode,
    hasLogo?: boolean,
    children?: React.ReactNode;
};

export function AuthLayout({ header, title, subtitle, children, hasLogo = true }: AuthLayoutProps) {

  return (
    <>
      <Helmet>
        <title>{header}</title>
      </Helmet>
      <Box sx={(theme) => theme.general.boxStyles.auth.content}>
        <Box sx={(theme) => theme.general.boxStyles.auth.mainContent}>
          <MainContainer
            maxWidth="sm">
            <AuthContainer>
              <Box sx={(theme) => theme.general.boxStyles.auth.authContent}>
                { hasLogo && <GuestLogoIcon design="login"/> }
                <Typography
                  variant="h1"
                  sx={(theme) => theme.customTypographyStyles.AuthHeader}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={(theme) => theme.customTypographyStyles.AuthSubHeader}
                >
                  {subtitle}
                </Typography>
              </Box>
              {children}
            </AuthContainer>
            <AuthLanguageSwitcher/>
          </MainContainer>
        </Box>
      </Box>
    </>
  )
}
