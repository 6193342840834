import { useCallback, useEffect, useState } from 'react'

const getWindowDimensions = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const useDimensionsWindow = () => {
  const [ screenSize, setScreenSize ] = useState(getWindowDimensions)

  const updateDimensions = useCallback(() => {
    setScreenSize(getWindowDimensions())
  }, [])

  useEffect(() => {
    window.addEventListener('load', updateDimensions)
    window.addEventListener('resize', updateDimensions)

    return(() => {
      window.addEventListener('load', updateDimensions)
      window.removeEventListener('resize', updateDimensions)
    })
  }, [screenSize, updateDimensions])

  return { screenSize }
}
