import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { IntegrationForm } from '../../../widgets/kacc/integrations/ui/integration-form'
import { FiltersBlock } from '../../../widgets/kacc/integrations'
import { useKaccAntyListStore } from '../../../entities/kacc/integration'
import { MainLayout } from '../../../widgets/layouts'

export function IntegrationList() {
  const { getList } = useKaccAntyListStore()
  const [search] = useSearchParams()
  const { t } = useTranslation('kacc')

  useEffect(() => {
    getList(search)
  }, [getList, search])

  return (
    <MainLayout header={t('Anty account integrations')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <FiltersBlock/>
            <IntegrationForm/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
