import React, { useState } from 'react'
import styled from 'styled-components'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { t } from 'i18next'

import { Tooltip } from '../../../../../shared/ui/tooltip'
import { ViewNoteButtonProps } from '../types'

import { ViewNoteModal } from './view-note-modal'

export const ViewNoteButton = ({ antyData }:ViewNoteButtonProps) => {
  const [modalvisible, setModalVisible] = useState(false)

  const initialValue = {
    id: antyData.id,
    notes: antyData.notes,
    submit: null,
  }

  return (
    <>
      <ViewNoteModal
        onClose={() => setModalVisible(false)}
        initialValue={ initialValue }
        isOpen={modalvisible}
      />

      <SViewNoteWrap>
        <Tooltip title={t('kacc:View Anty note')}>
          <SViewNoteButton
            onClick={() => setModalVisible(true)}>
            <VisibilityIcon/>
            {t('kacc:View')}
          </SViewNoteButton>
        </Tooltip>
      </SViewNoteWrap>
    </>
  )
}

export const SViewNoteWrap = styled.div`
  display: inline-block;
`

export const SViewNoteButton = styled.a`
  border-radius: 4px;
  border: 1px solid #0094A6;
  box-shadow: 0 2px 4px 0 rgba(39, 85, 114, 0.15);
  color: #0094A6;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  gap: 5px;
  line-height: 17px;
  padding: 5px 15px;
  transition: all 0.3s ease-in-out;
  text-decoration: unset;

  &:hover {
    border: 1px solid #28abe3;
    color: #28abe3;
  }
  
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`
