import React, { useRef } from 'react'
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { ChartAlertMessage } from './alerts'

export const NetworksChart = () => {
  const { networks, networksIsLoading, networksHasError } = useDashboardStore()
  const networkChartRef = useRef<ReactApexChart>(null)
  const networkContainerRef = useRef<HTMLDivElement>(null)

  if (networksIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('kacc:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (networksHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(networksHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!networks) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('kacc:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }
  const networkLabel = networks.map(item => item.network)
  const saleRevenueData = networks.map(item => item.saleRevenue)
  const profitData = networks.map(item => item.profitConfirmed || 0)
  const costData = networks.map(item => item.cost || 0)

  const series = [
    {
      name: 'Revenue',
      data: saleRevenueData,
    },
    {
      name: 'Cost',
      data: costData,
    },
    {
      name: 'Profit',
      data: profitData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    dataLabels: {
      style: {
        fontSize: '10px',
      },
      formatter: function (value: number) {
        return `${value.toFixed(2)} $`
      },
    },
    plotOptions: {
      bar: {
        horizontal: true ,
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: false,
        },
        barHeight: '100%',
      },
    },
    xaxis: {
      categories: networkLabel,
      labels: {
        formatter: (val: any) => {
          return val / 1000 + 'K'
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return `${value.toFixed(2)} $`
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#337AFF', '#33FF57', '#FF5733'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
  }

  return (
    <ChartContainer ref={networkContainerRef}>
      <ReactApexChart
        ref={networkChartRef}
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`