import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { useCustomReportsCatalogStore } from '../../../entities/custom-reports/catalog'
import { FiltersBlock, PaginationBlock, TableBlock } from '../../../widgets/custom-reports/catalog'
import { MainLayout } from '../../../widgets/layouts'

export function CustomReportsCatalog() {
  const [search] = useSearchParams()
  const { getCatalog, getGroups } = useCustomReportsCatalogStore()
  const { t } = useTranslation('custom-reports')

  const debouncedCatalogResults = useMemo(() => {
    return debounce(() => getCatalog(search), 300)
  }, [getCatalog, search])

  const debouncedGroupsResults = useMemo(() => {
    return debounce(() => {
      getGroups()
    }, 300)
  }, [getGroups])

  useEffect(() => {
    debouncedCatalogResults()

    return () => {
      debouncedCatalogResults.clear()
    }
  }, [debouncedCatalogResults])

  useEffect(() => {
    debouncedGroupsResults()

    return () => {
      debouncedGroupsResults.clear()
    }
  }, [debouncedGroupsResults])

  return (
    <MainLayout header={t('Custom reports catalog')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <FiltersBlock/>
            <TableBlock/>
            <PaginationBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
