import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { t } from 'i18next'

import { OptionsCountProps } from './types'

export const OptionsCount = ({ value, handleOnChange, error, inputWidth }: OptionsCountProps) => {

  return (
    <Autocomplete
      freeSolo
      autoFocus={false}
      defaultValue={value}
      value={value}
      inputValue={value ?? ''}
      clearOnBlur={true}
      open={false}
      options={[]}
      noOptionsText={t('white-generator:No options')}
      onInputChange={(_e, value) => {
        const numberValue = value.replace(/\D/g, '')
        handleOnChange(numberValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={t('white-generator:Set number of generates')}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{ width: inputWidth ? inputWidth : '200px' }}
    />
  )
}
