import React from 'react'
import styled, { css } from 'styled-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import ReplayIcon from '@mui/icons-material/Replay'

import type { ButtonIconProps } from './types'

export const TableButtonAction = ({ target, disabled, onClick }: ButtonIconProps) => {

  return (
    <STableButtonAction
      target={target}
      type={'button'}
      onClick={onClick}
      className={disabled ? 'disabled' : ''}
    >
      {
        target === 'edit' && (<ModeEditIcon/>)
      }
      {
        target === 'delete' && (<DeleteOutlinedIcon/>)
      }
      {
        target === 'disable' && (<ErrorOutlineOutlinedIcon/>)
      }
      {
        target === 'watch' && (<VisibilityOutlinedIcon/>)
      }
      {
        target === 'star' && (<StarOutlinedIcon/>)
      }
      {
        target === 'link' && (<InsertLinkOutlinedIcon/>)
      }
      {
        target === 'resend' && (<ReplayIcon/>)
      }
    </STableButtonAction>
  )
}

const STableButtonAction = styled.button<ButtonIconProps & { theme: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 26px;
  width: 26px;
  z-index: 99;
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.colors.shadows.elements};
  transition: 0.3s;
  border-color: transparent;

  ${'svg'} {
    font-size: 16px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    ${({ target, theme }) => {
    switch (target) {
    case 'edit':
      return css`
            background: ${theme.colors.primary.main};
          `
    case 'delete':
      return css`
            background: ${theme.colors.error.main};
          `
    case 'disable':
      return css`
            background: ${theme.colors.warning.dark};
          `
    case 'watch':
      return css`
            background: ${theme.colors.info.main};
          `
    case 'star':
      return css`
            background: ${theme.colors.accent.primary};
          `
    case 'link':
      return css`
            background: ${theme.colors.success.main};
          `

    case 'resend':
      return css`
            background: ${theme.colors.success.main};
          `
    }
  }
};
  }

  ${({ target, theme }) => {
    switch (target) {
    case 'edit':
      return css`
          background: ${theme.colors.soft.primary};
          color: ${theme.colors.primary.main};
        `

    case 'delete':
      return css`
          background: ${theme.colors.soft.error};
          color: ${theme.colors.error.main};

          ${'svg'} {
            font-size: 18px;
          }
        `

    case 'disable':
      return css`
          background: ${theme.colors.soft.warning};
          color: ${theme.colors.warning.dark};
        `

    case 'watch':
      return css`
          background: ${theme.colors.soft.info};
          color: ${theme.colors.info.main};
        `

    case 'star':
      return css`
          background: ${theme.colors.soft.warning};
          color: ${theme.colors.accent.primary};
        `

    case 'link':
      return css`
          background: ${theme.colors.soft.success};
          color: ${theme.colors.success.dark};
        `

    case 'resend':
      return css`
          background: ${theme.colors.soft.success};
          color: ${theme.colors.success.dark};
        `
    }
  }
}
  &.disabled {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.action.disabledBackground};
  }
`
