import * as React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { t } from 'i18next'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

type InputFileUploadProps = {
  value: string
  onChange: (file: File | null) => void
  error: boolean
}
export const InputFileUpload = ({ value, onChange }: InputFileUploadProps) => {

  return (
    <Button
      component="label" variant="outlined"
      className="customUploadButton"
      startIcon={!value ? <CloudDownloadOutlinedIcon style={{ fontSize: '40px' }}/> : t('white-generator:You uploaded')}
    >
      {
        !value ? t('white-generator:Click and upload archive') : value
      }
      <VisuallyHiddenInput
        id="fileInput"
        name="file"
        type="file"
        accept=".zip"
        onChange={(e) => onChange(e.currentTarget.files?.[0] || null)}
      />
    </Button>
  )
}
