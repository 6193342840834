import React from 'react'
import { Modal, Backdrop } from '@mui/material'
import styled, { keyframes } from 'styled-components'

import { ModalOverlayMuiProps } from './types'

export const ModalOverlayMui = ({ isOpen, handleClose, children }: ModalOverlayMuiProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
    >
      <SContentWrapper>
        {children}
      </SContentWrapper>
    </Modal>
  )
}

const backdropAppearAnimation = keyframes`
  from {
    opacity: 0;
    top: 0;
  }

  to {
    opacity: 1;
  }
`

export const SContentWrapper = styled.div`
  animation: ${backdropAppearAnimation} 0.3s ease-in-out;
  background: ${({ theme }) => theme.colors.alpha.white[100]};
  border: ${({ theme }) => theme.colors.alpha.black[100]};
  box-shadow: 0 5px 15px ${({ theme }) => theme.colors.alpha.black[10]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: auto;
  position: fixed;
  right: 0;
  left: 0;
  width: fit-content;
  height: auto;
  box-sizing: border-box;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1000;
`