import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useFilter } from '../../../../shared/lib'
import { useCustomReportsCatalogStore } from '../../../../entities/custom-reports/catalog'

import type { CustomReportsGroups } from '../../../../entities/custom-reports/catalog'

export const GroupsFilterParam = 'groups'

export const GroupsFilter = () => {
  const { filterActive } = useFilter(GroupsFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const { groups } = useCustomReportsCatalogStore()
  const initialOptions = filterActive
        && groups?.filter(
          (item) => item
                && filterActive
                  .split(',')
                  .includes(String(item.id)),
        )

  /**
   * Handle onChange action
   */
  const handleOnChange = (_event: React.SyntheticEvent<Element, Event>, value: CustomReportsGroups[]) => {
    const values = value.map((v) => v?.id).join()

    if (values.length > 0) search.set(GroupsFilterParam, values as string)
    if (values.length === 0 && filterActive) search.delete(GroupsFilterParam)
    setSearch(search, { replace: true })
  }

  // Check groups query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && initialOptions && !(initialOptions.length > 0)
      )) {
        search.delete(GroupsFilterParam)
        setSearch(search, { replace: true })
      }
    }

  }, [groups, filterActive])

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      multiple
      limitTags={1}
      popupIcon={<KeyboardArrowDownIcon/>}
      onChange={handleOnChange}
      options={groups?.filter(group => group?.disabled === false) ?? []}
      openOnFocus={true}
      noOptionsText={t('custom-reports:No options')}
      value={initialOptions ? initialOptions : []}
      defaultValue={initialOptions ? initialOptions : []}
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        (option && <Box
          component="li"
          value={option?.id}
          {...props}
        >
          {option?.name}
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('custom-reports:Groups')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        width: 300,
      }}
    />
  )
}
