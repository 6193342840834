import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Alert, CircularProgress, TextField } from '@mui/material'
import { darken } from '@mui/material/styles'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { changePassword } from '../api'

import type { PasswordChangeFormValues } from '../types'

export const PasswordChangeForm = () => {
  const [ changed, setChanged ] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object()
    .shape({
      old_password: Yup.string()
        .required(t('settings:Current password is required!')),
      new_password: Yup.string().nullable()
        .required(t('settings:New password is required!'))
        .min(6, t('settings:New password is too short!')),
      new_password_confirm: Yup.string()
        .required(t('settings:Confirm new password is required!'))
        .oneOf([Yup.ref('new_password')],
          t('settings:New password must match with confirm password!'),
        ),
    })

  const formik = useFormik<PasswordChangeFormValues>({
    initialValues: {
      old_password: '',
      new_password: '',
      new_password_confirm: '',
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      const { old_password, new_password, new_password_confirm } = values
      const sendValues = {
        ...(old_password && { password: old_password }),
        ...(new_password && { new_password: new_password }),
        ...(new_password_confirm && { confirm_password: new_password_confirm }),
      }

      changePassword(sendValues)
        .then(() => {
          setChanged(false)
          formikHelpers.setSubmitting(false)
          enqueueSnackbar(t('settings:Changed your password!'), { variant: 'success' })
        })
        .catch(error => {
          formikHelpers.setSubmitting(false)
          enqueueSnackbar(`${
            t('settings:Unable to change your password!')
          } ${
            t(`settings:${error.response?.data?.message ?? error?.message}`)
          }`, { variant: 'error' })
        })
    },
  })

  return (
    <Form
      onChange={ () => setChanged(true) }
      onSubmit={ formik.handleSubmit }
    >
      <Title>
        { t('settings:Change password') }
      </Title>
      <TextField
        error={ Boolean(formik.touched.submit && formik.touched.old_password && formik.errors.old_password) }
        margin="normal"
        label={ t('settings:Current password') }
        name="old_password"
        onBlur={ formik.handleBlur }
        onChange={ formik.handleChange }
        size={ 'small' }
        type="password"
        value={ formik.values.old_password }
        variant="outlined"
      />
      <TextField
        error={ Boolean(formik.touched.submit && formik.touched.new_password && formik.errors.new_password) }
        margin="normal"
        label={ t('settings:New password') }
        name="new_password"
        onBlur={ formik.handleBlur }
        onChange={ formik.handleChange }
        size={ 'small' }
        type="password"
        value={ formik.values.new_password }
        variant="outlined"
      />
      <TextField
        error={
          Boolean(formik.touched.submit &&
              formik.touched.new_password_confirm &&
              formik.errors.new_password_confirm)
        }
        margin="normal"
        label={ t('settings:Confirm new password') }
        name="new_password_confirm"
        onBlur={ formik.handleBlur }
        onChange={ formik.handleChange }
        size={ 'small' }
        type="password"
        value={ formik.values.new_password_confirm }
        variant="outlined"
      />

      { formik.touched.submit && Object.keys(formik.errors).length > 0
          && (
            <div
              style={{
                marginBottom: '1rem',
                marginTop: '0.5rem',
              }}
            >
              <Alert
                severity="error"
                style={{
                  width: 'fit-content',
                }}
              >
                { formik.errors.old_password && formik.touched.old_password && <div>
                  { t(`settings:${formik.errors.old_password}`) } </div>
                }
                { formik.errors.new_password && formik.touched.new_password && <div>
                  { t(`settings:${formik.errors.new_password}`) } </div>
                }
                { formik.errors.new_password_confirm && formik.touched.new_password_confirm && <div>
                  { t(`settings:${formik.errors.new_password_confirm}`) } </div>
                }
              </Alert>
            </div>
          )}

      <ButtonsBlock>
        <SubmitButton
          startIcon={ formik.isSubmitting
            ? <CircularProgress size="1rem"/>
            : null }
          disabled={ formik.isSubmitting }
          type={ 'submit' }
          size={ 'small' }
          variant={ 'contained' }
        >
          { formik.isSubmitting
            ? t('settings:Changing')
            : t('settings:Change password') }
        </SubmitButton>

        <CancelButton
          variant={ 'contained' }
          onClick={ (e) => {
            formik.handleReset(e)
            setChanged(false)
          }}
          type={ 'reset' }
          disabled={ formik.isSubmitting || !changed }
        >
          { t('settings:Reset') }
        </CancelButton>
      </ButtonsBlock>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  & .MuiFormControl-root {
    max-width: 500px;
    
    &:last-child {
      margin-bottom: 16px;
    }
  }
`

const Title = styled.div`
  color: #407698;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
`

const ButtonsBlock = styled.div`
  display: flex;
  gap: 16px;
  margin-top: auto;
`

const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`

const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #F6453B;
    color: #ffffff;

    &:hover {
      background-color: ${ darken('#F6453B', 0.05) };
      color: ${ darken('#ffffff', 0.05) };
    }
  }
`
