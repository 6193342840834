import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { useWhiteGeneratorStore } from '../../../../entities/white-generator/generator'
import { getArraySplitToNChunks, getPrettyDate, useDimensions, useDimensionsWindow } from '../../../../shared/lib'
import { TableAlertMessage } from '../../../domains-generator/generator/ui/table'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { Template } from '../../../../entities/white-generator/generator/types'
import { Tooltip } from '../../../../shared/ui/tooltip'

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnWrapper,
  TableHeader,
  TableRow,
  TableWrapper,
  TableValue,
  SCellTitle,
} from './table'

import type { TableDomainsProps } from '../types'

export const TableTemplates: FC<TableDomainsProps> = (props) => {
  const [columnsCount, setColumnsCount] = useState(1)
  const { templates, loading, error } = useWhiteGeneratorStore()
  const { screenSize } = useDimensionsWindow()
  const [templatesNChunks, setTemplatesNChunks] = useState<any>()
  const [selectedItem, setSelectedItem] = useState<{ title: string, group: string } | null>(null)

  const domainsHeaderRef = useRef(null)
  const { offsetWidth } = useDimensions(domainsHeaderRef)

  const hasData = (
    !loading
    && !error
    && templates?.templates
    && templates.templates?.length > 0
  )

  const handleCheckboxChange = (title: string, group: string) => {
    setSelectedItem({ title, group })
  }

  const truncateString = (str: any, num: number) => {
    return str.length > num ? `${str.slice(0, num)}...` : str
  }

  useEffect(() => {
    if (screenSize.width < 1100)
      setColumnsCount(1)

    if (screenSize.width >= 1100 && screenSize.width < 1600)
      setColumnsCount(2)

    if (screenSize.width >= 1600)
      setColumnsCount(2)
  }, [screenSize])

  useEffect(() => {
    if (templates?.templates) {
      const t = getArraySplitToNChunks(templates.templates, columnsCount)
      setTemplatesNChunks(t)
    }
  }, [templates?.templates, columnsCount])

  useEffect(() => {
    props.formik.setFieldValue('template', selectedItem)
  }, [selectedItem])

  return (
    <Table>
      <TableWrapper className={'overflow-auto'}>
        {hasData &&
          (
            <TableHeader>
              {[...Array(columnsCount)]
                ?.map((_, index) =>
                  <TableColumn key={index}>
                    <TableColumnWrapper ref={domainsHeaderRef}>
                      <TableRow>
                        <TableCell>
                          <TableValue>
                            {t('white-generator:Name')}
                          </TableValue>
                        </TableCell>
                        {/*<TableCell>*/}
                        {/*  <TableValue>*/}
                        {/*    {t('white-generator:Generation results')}*/}
                        {/*  </TableValue>*/}
                        {/*</TableCell>*/}
                        {/*<TableCell>*/}
                        {/*  <TableValue>*/}
                        {/*    {t('white-generator:Generation Date')}*/}
                        {/*  </TableValue>*/}
                        {/*</TableCell>*/}
                        <TableCell>
                          <TableValue>
                            {t('white-generator:Uploaded date')}
                          </TableValue>
                        </TableCell>
                      </TableRow>
                    </TableColumnWrapper>
                  </TableColumn>,
                )}
            </TableHeader>
          )
        }
        <TableBody>
          {hasData
            && [...Array(columnsCount)]
              ?.map((_, index) =>
                <TableColumn key={index}>
                  {templatesNChunks && templatesNChunks[index]
                    ?.map((row: Template, rowIndex: number) =>
                      <TableColumnWrapper
                        key={rowIndex}
                        style={{ minWidth: offsetWidth }}
                      >
                        <TableRow $selectedItem={row?.title === selectedItem?.title}>
                          <TableCell>
                            <TableValue>
                              <SCheckbox
                                type="checkbox"
                                id={Math.random().toString()}
                                name={'templates'}
                                value={row?.title}
                                checked={row?.title === selectedItem?.title}
                                onChange={() => handleCheckboxChange(row?.title, row?.group)}
                              />

                              <label
                                htmlFor={row?.title}>
                                {
                                  row?.title && row?.title?.length > 25
                                    ? <Tooltip arrow title={row?.title}>
                                      <SCellTitle>{truncateString(row?.title, 25)}</SCellTitle>
                                    </Tooltip>
                                    : <SCellTitle>{row?.title}</SCellTitle>
                                }
                              </label>
                            </TableValue>
                          </TableCell>
                          {/*<TableCell>*/}
                          {/*  <TableValue>*/}
                          {/*    {row?.generations}*/}
                          {/*  </TableValue>*/}
                          {/*</TableCell>*/}
                          {/*<TableCell>*/}
                          {/*  <TableValue>*/}
                          {/*    {row?.last_generated}*/}
                          {/*  </TableValue>*/}
                          {/*</TableCell>*/}
                          <TableCell>
                            <TableValue>
                              {getPrettyDate(row?.date_created, true)}
                            </TableValue>
                          </TableCell>
                        </TableRow>
                      </TableColumnWrapper>,
                    )
                  }
                </TableColumn>,
              )
          }
          {
            !error
            && !loading
            && templates?.templates?.length === 0 && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  {t('white-generator:Oops! The table is empty - no data!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            error
            && !loading && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  {t(`white-generator:${error}`)}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
            && loading && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  {t('white-generator:Just a minute! Table is loading/updating!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}

export const SCheckbox = styled.input.attrs({ type: 'checkbox' })`

`
