import React, { useState } from 'react'
import { t } from 'i18next'

import { TableButtonAction } from '../../../../../shared/ui/table-button-action'
import { Tooltip } from '../../../../../shared/ui/tooltip'
import { Dictionary } from '../../../../../entities/domains-generator/settings'

import { AddEditDictionaryModal } from './add-edit-dictionary-modal'

export type EditDictionaryButtonProps = {
  initialValues: Dictionary
}
export const EditDictionaryButton = ({ initialValues }: EditDictionaryButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <AddEditDictionaryModal
        onClose={() => setModalVisible(false)}
        initialValues={initialValues}
        isOpen={modalVisible}
      />

      <Tooltip title={t('subdomains-generator:Edit')}>
        <TableButtonAction
          target={'edit'}
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
