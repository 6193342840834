import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { useCampaignListStore } from '../../../../entities/kacc/campaign-list'

export function PaginationBlock() {
  const { count, limits } = useCampaignListStore()

  return (
    <SPaginationBlock>
      <Pagination count={ count ?? 0 } limits={ limits }/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
  display: flex;
  gap: 20px;
`