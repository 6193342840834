import React from 'react'
import { styled, Box, Typography, Button, keyframes, Theme } from '@mui/material'
import { t } from 'i18next'

import { ErrorSpaceManIcon } from '../../../shared/ui/icon-assets/error-space-man'

const mover = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-16px);
  }
`

const SvgContentBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  ...theme.general.boxStyles.SvgContent,
  animation: `${mover} 1s infinite alternate`,
}))

export function NotFoundContent() {
  return (
    <>
      <SvgContentBox>
        <ErrorSpaceManIcon/>
      </SvgContentBox>
      <Box
        sx={{
          textAlign: 'center',
        }}>
        <Typography
          variant="h1"
          sx={(theme) => theme.customTypographyStyles.NotFound404}
        >
          {t('not-found:404')}
        </Typography>
        <Typography
          variant="h5"
          sx={(theme) => theme.customTypographyStyles.NotFoundHeader}
        >
          {t('not-found:Sorry, page was not found!')} 😭
        </Typography>
        <Typography
          variant="subtitle1"
          sx={(theme) => theme.customTypographyStyles.NotFoundSubHeader}
        >
          {t('not-found:The page you are looking for not available!')}
        </Typography>
        <Button
          sx={{
            mt: 3,
            mb: 10,
          }}
          color="primary"
          size="large"
          variant="contained"
          href="/"
        >
          {t('not-found:Back to home')}
        </Button>
      </Box>
    </>
  )
}
