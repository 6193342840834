import { t } from 'i18next'
import React, { useRef, useState } from 'react'

import { CampaignRow, SAntyMore, SAntyMoreButton, SBlockWithCollapse } from '../../integrations/ui/table'
import { getPrettyDate, useDimensions } from '../../../../shared/lib'
import { useKaccCampaignsHistoryStore } from '../../../../entities/kacc/campaign-history/model'
import { IntegrationCampaignsHistoryActions as HistoryActions } from '../../../../entities/kacc/campaign-history/types'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
  TableValue,
} from './table'

interface CollapsedState {
  [key: string]: boolean
}

export function TableBlock() {
  const [collapsed, setCollapsed] = useState<CollapsedState>({})
  const { historyData } = useKaccCampaignsHistoryStore()
  // data check
  const hasData = historyData && historyData?.length > 0

  // table dimensions
  const offersHeaderRef = useRef(null)
  const offersHeader = useDimensions(offersHeaderRef)
  const minWidth = offersHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      offersHeader.offsetHeight + offersHeader.offsetTop + 150
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      offersHeader.offsetTop + 150
    }px)`
    : ''
  const toggleCollapse = (id: string) => {
    setCollapsed(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const getCellStyle = (status: HistoryActions) => {
    switch (status) {
    case HistoryActions.added:
      return { backgroundColor: '#F6FFFB', boxShadow: '0px -1px 0px 0px #C2DDED inset' }
    case HistoryActions.deleted:
      return { backgroundColor: '#FFF9F9', boxShadow: '0px -1px 0px 0px #C2DDED inset' }
    default:
      return {}
    }
  }

  return (
    <Table>
      <TableWrapper style={{ height: maxHeight }}>
        {hasData && (
          <TableHeader>
            <TableRow>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Created date')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Anty id')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Account id')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Integration name')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Campaign id')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Actions')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('kacc:Source')}
                </TableHeaderLabel>
              </TableCell>
            </TableRow>
          </TableHeader>
        )}
        <TableBody style={{ height: minHeight }}>
          {hasData && historyData?.map((data, index) => (
            <div
              key={index + 1}
              style={{ minWidth: minWidth }}
            >
              <TableRow className="aligh-baseline">
                <TableCell>
                  <TableValue>
                    {getPrettyDate(data.created_at.toLocaleString(), true)}
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue>
                    {data.antyId}
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue>
                    {data.accountId}
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue>
                    {data.integrationName}
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue>
                    <SAntyMore onClick={() => toggleCollapse(data.antyId.toString())}>
                      <SAntyMoreButton>
                        {collapsed[data.antyId]
                          ? t('kacc:View list')
                          : t('kacc:Hide List')
                        }
                      </SAntyMoreButton>
                    </SAntyMore>
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue className={'disabled'}>
                    {/*{t('kacc:Actions')}*/}
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableValue className={'disabled'}>
                    {/*{t('kacc:Source')}*/}
                  </TableValue>
                </TableCell>
              </TableRow>

              {Object.values(data.campaigns).length > 0 && (
                <SBlockWithCollapse style={{ display: collapsed[data.antyId] ? 'none' : 'block' }}>
                  {Object.values(data.campaigns).map((campaign, index) => (
                    <div key={index + 1}>
                      <CampaignRow className={'aligh-baseline'}>
                        <TableCell>
                          <TableValue>
                            {/*{ Created date }*/}
                          </TableValue>
                        </TableCell>
                        <TableCell>
                          <TableValue>
                            {/*{ antyId }*/}
                          </TableValue>
                        </TableCell>
                        <TableCell>
                          <TableValue>
                            {/*{ Account Id }*/}
                          </TableValue>
                        </TableCell>
                        <TableCell>
                          <TableValue>
                            {/*{ Integration name }*/}
                          </TableValue>
                        </TableCell>
                        <TableCell style={getCellStyle(campaign.action)}>
                          <TableValue>
                            {campaign.id}
                          </TableValue>
                        </TableCell>
                        <TableCell style={getCellStyle(campaign.action)}>
                          <TableValue>
                            {campaign.action}
                          </TableValue>
                        </TableCell>
                        <TableCell style={getCellStyle(campaign.action)}>
                          <TableValue>
                            {campaign.source}
                          </TableValue>
                        </TableCell>
                      </CampaignRow>
                    </div>
                  ))}
                </SBlockWithCollapse>
              )}
            </div>
          ))}
        </TableBody>
      </TableWrapper>
    </Table>
  )
}