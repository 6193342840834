import React, { FC } from 'react'
import { Tooltip as MUITooltip, TooltipProps as MUITooltipProps } from '@mui/material'

/**
 * Customized Tooltip MUI component
 */
export const Tooltip: FC<MUITooltipProps> = (props) => {
  const {
    children,
    placement = 'top',
    arrow = true,
    ...otherProps
  } = props

  return (
    <MUITooltip
      arrow = { arrow }
      placement={ placement }
      { ...otherProps }
    >
      <div>
        { children }
      </div>
    </MUITooltip>
  )
}
