import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { t } from 'i18next'

import type { OptionsCountProps } from './types'

export const OptionsCount = ({ value, handleOnChange, error }: OptionsCountProps) => {

  return (
    <Autocomplete
      freeSolo
      autoFocus={ false }
      defaultValue={ value }
      value={ value }
      inputValue={ value ?? '' }
      clearOnBlur={ true }
      open={ false }
      options={ [] }
      noOptionsText={t('subdomains-generator:No options')}
      onInputChange={ (e, value) => {
        const numberValue = value.replace(/[^\d]/g,'')
        handleOnChange(numberValue)
      } }
      renderInput={(params) => (
        <TextField
          {...params}
          error={ error }
          label={ t('subdomains-generator:Enter count') }
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{
        width: '200px',
      }}
    />
  )
}
