import { format } from 'date-fns'

// date format: 2023-08-30 11:06:30
export const getPrettyDate = (date: string | null, withTime?: boolean): string | null => {
  if (!date) {
    return date
  }
  const parsedDate = new Date(date)
  const dateFormat = withTime
    ? 'dd LLL yyyy HH:mm:ss'
    : 'dd LLL yyyy'

  return format(parsedDate, dateFormat)
}