import styled from 'styled-components'

import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'
import { TableOptionsGroups } from '../../../../features/domains-generator/generator/select-groups'
import { TableOptionsPeriod } from '../../../../features/domains-generator/generator/select-period'
import { TableSelectUnselectAll } from '../../../../features/domains-generator/generator/select-unselect-all'
import { TableUpdateButton } from '../../../../features/domains-generator/generator/update-table'

export const TableOptions = () => {
  const { data,
    handleMainData,
    syncKeitaro,
    syncing,
    syncError,
  } = useDomainsGeneratorStore()

  return <STableOptions>
    <TableOptionsGroups
      data={data?.groups}
    />
    <TableOptionsPeriod/>
    <TableSelectUnselectAll
      data={data}
      handleMainData={handleMainData}
    />
    <TableUpdateButton
      updateMain={syncKeitaro}
      updating={syncing}
      updatingError={syncError}
    />
  </STableOptions>
}

const STableOptions = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
`
