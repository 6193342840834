import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)``

export const TableRow = styled(UTableRow)`
  height: max-content;
  max-width: 400px;
  width: 100%;
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
  align-items: flex-start;
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
  padding: 10px;

  &:nth-child(1) {
    min-width: 250px;
    flex-grow: 1;
  }

  &:nth-child(2) {
    min-width: 50px;
    flex-grow: 0;
  }

  &:nth-child(3) {
    min-width: 60px;
    flex-grow: 0;
  }

  &:nth-child(4) {
    min-width: 40px;
    flex-grow: 0;
  }
`

export const TableValue = styled(UTableValue)`
  align-items: center;
  display: flex;
  color: #275572;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: space-between;
  line-height: 14px;
  letter-spacing: 0.1px;
  word-wrap: break-word;
  width: 100%;

  & label {
    margin-left: 5px;
  }

  & svg {
    color: #3FBDF6;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 10px;
  }
`

export const TableAlertMessage = styled(UTableAlertMessage)`
  width: 100%;
`

export const TableColumnWrapper = styled.div`
  display: flex;
`

export const TableColumn = styled.div`
  flex-grow: 1;
  height: fit-content;

  &:nth-child(1) {
    &:is(:last-child) {
      border-right-width: 0;

      & ${TableColumnWrapper} {
        border-right-width: 0;
        justify-content: left;
      }

      & ${TableRow} {
        max-width: inherit;
      }
    }
  }

  &:nth-child(2):not(:last-child) {
    & ${TableColumnWrapper} {
      justify-content: center;
    }
  }

  &:last-child:not(:first-child) {
    border-right-width: 0;

    & ${TableColumnWrapper} {
      border-right-width: 0;
      justify-content: right;
    }
  }
`

export const TableBody = styled(UTableBody)`
  display: flex;
  gap: 20px;
  justify-content: stretch;
  max-height: 450px;
`

export const SCellTitle = styled.div`
  font-size: 14px;
  color: #275572;
  flex-grow: 1;
  min-width: 155px;
  white-space: nowrap;
`
