import { useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export type FilterProps = {
  filterName: string
  defaultFilter: string
  filterActive: string
}

export function useFilter(filterName: string, defaultFilter?: string) {
  const [search, setSearch] = useSearchParams()
  const [filterActive, setFilterActive] = useState<string | null>(null)

  const setFilter = (filter: string) => {
    search.set(filterName, filter)
    setSearch(search, { replace: true })
  }

  useLayoutEffect(() => {
    const filterActive = search.get(filterName)

    if (filterActive !== null) {
      setFilterActive(filterActive as string)
    } else {
      setFilterActive(null)
    }
  }, [search])

  return { setFilter, filterActive }
}
