import { AxiosError, AxiosResponse } from 'axios'

import { request } from '../../../shared/api'

import type { DomainsGeneratedResponse, DomainsGeneratorResponse } from './types'

export const syncKeitaro = (): Promise<AxiosResponse | AxiosError> =>
  request.get('/domains-generator/keitaro-sync', {})

export const getGeneratorMain = (params: URLSearchParams): Promise<DomainsGeneratorResponse | AxiosError> =>
  request.get('/domains-generator/main', { params })

export const generateDomains = (values: object): Promise<DomainsGeneratedResponse | AxiosError> =>
  request.get('/domains-generator/generate-domains',{ params: values })

export const getLastGeneration = (): Promise<DomainsGeneratedResponse | AxiosError> =>
  request.get('/domains-generator/last-generation', {})

export const uploadGenerations = (values: object): Promise<DomainsGeneratedResponse | AxiosError> =>
  request.post('/domains-generator/upload', values)
