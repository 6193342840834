import React, { useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { useCustomReportInfoStore } from '../../../entities/custom-reports/info'
import { FiltersBlock, PaginationBlock, TableBlock, TitleBlock } from '../../../widgets/custom-reports/info'
import { MainLayout } from '../../../widgets/layouts'

export function CustomReportsInfo() {
  const params = useParams()
  const [search] = useSearchParams()
  const { getCatalog } = useCustomReportInfoStore()
  const { t } = useTranslation('custom-reports')

  const debouncedInfoResults = useMemo(() => {
    return debounce(() => getCatalog(Number(params.reportId), search), 300)
  }, [getCatalog, search])

  useEffect(() => {
    debouncedInfoResults()

    return () => {
      debouncedInfoResults.clear()
    }
  }, [debouncedInfoResults])

  return (
    <MainLayout header={t('Custom report info')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <TitleBlock/>
            <FiltersBlock/>
            <TableBlock/>
            <PaginationBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
