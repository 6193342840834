import { Box, FormControl, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import i18n, { t } from 'i18next'

import { fallbackLng } from '../../../shared/configs/translations'
import { useTranslationStore } from '../../../entities/translation'

const LanguageBox = styled(Box)(({ theme }) => ({
  '&': {
    width: '100%',
  },
  '& .MuiSelect-select': {
    paddingLeft: 0,
  },
  '& .MuiSvgIcon-root': {
    color: theme.colors.alpha.black[100],
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
}))

export const AuthLanguageSwitcher = () => {
  const { translationCurrent, setTranslationCurrent } = useTranslationStore()

  const switchLanguage = (event: SelectChangeEvent) => {
    i18n.language !== event.target.value && setTranslationCurrent(event.target.value)
  }

  return (
    <LanguageBox>
      <FormControl>
        <Select
          value={translationCurrent ?? fallbackLng}
          onChange={switchLanguage}
        >
          <MenuItem value="en">{t('global:English')}</MenuItem>
          <MenuItem value="ru">{t('global:Russian')}</MenuItem>
        </Select>
      </FormControl>
    </LanguageBox>
  )
}
