import React, { useLayoutEffect } from 'react'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'

import '../../shared/configs/translations'

import { fallbackLng, TranslationLanguage } from '../../shared/configs/translations'
import { useTranslationStore } from '../../entities/translation'

export type TranslationProviderProps = {
    children?: React.ReactNode;
};

const TranslationProvider = function ({ children }: TranslationProviderProps) {
  const { translationCurrent, setTranslationCurrent } = useTranslationStore()

  useLayoutEffect(() => {
    // if user has no language selected (first visit)
    if (!translationCurrent) {
      // detect language
      let languageMatched = false // Flag to track if any language matched the conditions
      const languageDetector = new LanguageDetector()
      const languageDetected = languageDetector.detect()

      // set detected language
      if (Array.isArray(languageDetected)) {
        // Handle the case where multiple languages are detected
        for (const language of languageDetected) {
          if (language.startsWith('en-')) {
            languageMatched = true
            setTranslationCurrent(TranslationLanguage.en)
            break // Exit the loop once English language is detected
          } else if (language === 'ru') {
            languageMatched = true
            setTranslationCurrent(TranslationLanguage.ru)
            break // Exit the loop once Russian language is detected
          }
        }
      } else
      // Handle the case where only one language is detected
        if (typeof languageDetected === 'string') {
          if (languageDetected.startsWith('en-')) {
            languageMatched = true
            setTranslationCurrent(TranslationLanguage.en)
          } else if (languageDetected === 'ru') {
            languageMatched = true
            setTranslationCurrent(TranslationLanguage.ru)
          }
        }

      // If none of the detected languages matched the conditions, set the translation to fallback language
      if (!languageMatched) {
        setTranslationCurrent(fallbackLng)
      }
    }

    // switch to selected language
    translationCurrent
        && i18n.language !== translationCurrent
        && i18n.changeLanguage(translationCurrent)
  }, [setTranslationCurrent, translationCurrent])

  return (
    <>
      {children}
    </>
  )
}

const withTranslation = (component: () => React.ReactNode) => () => (
  <TranslationProvider>
    {component()}
  </TranslationProvider>
)

export default withTranslation
