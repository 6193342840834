import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { darken } from '@mui/material/styles'
import { t } from 'i18next'

import { getPrettyDate } from '../../../../shared/lib'
import { Button } from '../../../../shared/ui/button'
import { TableRowStatus } from '../../../../shared/ui/table-row-status'
import { useOfferDetailsStore } from '../../../../entities/watch-cat/offer-details'

export function TitleBlock() {
  const { offerDetails } = useOfferDetailsStore()
  const [geoList, setGeoList] = useState<string>()

  useEffect(() => {
    if (offerDetails) {
      const keysArray = Object.keys(offerDetails.geo)
      const keysString = keysArray.join(', ')
      setGeoList(keysString)
    }
  }, [offerDetails])

  return (
    <>
      {
        offerDetails && (
          <STitleBlock>
            <STitleInfo>
              <SInfoWrapper>
                <SInfoLabel>
                  { t('watch-cat:Offer') }:
                </SInfoLabel>
                <SInfoContent className="name">
                  { offerDetails?.name }
                </SInfoContent>

                <SStatus>
                  <TableRowStatus
                    active={ Boolean(offerDetails?.active) }
                    activeText={ t('watch-cat:Active') as string }
                    inactiveText={ t('watch-cat:Inactive') as string }
                  />
                </SStatus>
              </SInfoWrapper>

              <SInfoWrapper>
                <SInfoLabel>
                  { t('watch-cat:Network') }:
                </SInfoLabel>
                <SInfoContent className="network">
                  { offerDetails?.network }
                </SInfoContent>

                <SInfoDelimiter/>

                <SInfoLabel>
                  { t('watch-cat:Published') }:
                </SInfoLabel>
                <SInfoContent className="date">
                  { getPrettyDate(offerDetails.date_created) }
                </SInfoContent>

                {
                  offerDetails.date_updated && (
                    <>
                      <SInfoDelimiter/>

                      <SInfoLabel>
                        { t('watch-cat:Updated') }:
                      </SInfoLabel>
                      <SInfoContent className="date">
                        { getPrettyDate(offerDetails.date_updated) }
                      </SInfoContent>
                    </>
                  )
                }

              </SInfoWrapper>

              <SInfoWrapper>
                <SInfoLabel>
                  { t('watch-cat:Geo') }:
                </SInfoLabel>
                <SInfoContent className="geo">
                  { geoList
                    ? geoList
                    : '-' }
                </SInfoContent>
              </SInfoWrapper>

            </STitleInfo>
            <SActions>
              <a
                href={ offerDetails.url }
                target="_blank"
                rel="noreferrer"
              >
                <SButton
                  variant={ 'contained' }
                  type={ 'button' }
                >
                  { t('watch-cat:Link') }
                </SButton>
              </a>
              {/*
              <SButton
                onClick={ ()=>{} }
                variant={ 'contained' }
                size={ 'small' }
                type={ 'button' }
              >
                <StarIcon/>
              </SButton>
              */ }
            </SActions>
          </STitleBlock>
        )
      }
    </>
  )
}

const STitleBlock = styled.div`
  display: flex;
  padding: 16px;
`
const STitleInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const SInfoLabel = styled.div`
  color: #455A64;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
`
const SInfoContent = styled.div`
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  gap: unset;

  &.name {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  &.network {
    color: #0072E5;
    letter-spacing: -0.2px;
  }

  &.date {
    color: #455A64;
    font-size: 13px;
    letter-spacing: -0.2px;
  }

  &.geo {
    color: #455A64;
    font-size: 13px;
    text-transform: uppercase;
  }
`

const SStatus = styled.div`
  margin: 0 0 0 5px;
`

const SInfoDelimiter = styled.div`
  border-right: 1px solid rgba(33, 37, 41, 0.25);
  height: 100%;
`
const SInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`
const SActions = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  margin-left: auto;
`

const SButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #CBFFE4;
    box-shadow: 0 1px 1px 0 rgba(39, 85, 114, 0.23);
    color: #109B87;
    padding: 11px 20px;
    line-height: 18px;

    &:hover {
      background: ${ darken('#CBFFE4', 0.05) };
    }
  }

  & .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`
