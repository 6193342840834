import styled from 'styled-components'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 24px;
`

export const PageTitle = styled.div`
  color: #495057;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`
export const PageContainer = styled.div`
    padding: 16px;
`

export const PageBlock = styled.div`
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 10px 0 rgba(55, 67, 81, 0.15);
  height: fit-content;
`

export const PageBlockDivider = styled.div`
  box-shadow: 0px -1px 0px 0px #E9EBEC inset;
  display: block;
  height: 1px;
  width: 100%;
`
