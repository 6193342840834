import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { useCustomReportsCatalogStore, CustomReportsLimitEnum } from '../../../../entities/custom-reports/catalog'

export function PaginationBlock() {
  const { catalogCount } = useCustomReportsCatalogStore()

  return (
    <SPaginationBlock>
      <Pagination count={catalogCount ?? 0} limits={Object.values(CustomReportsLimitEnum).map(s => Number(s))}/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
  display: flex;
  gap: 20px;
`
