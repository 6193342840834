import React, { useEffect } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'

import { useFilter } from '../../../shared/lib'

import { OfferDetailsStatusFilterTypes } from './types'

export const OfferDetailsStatusFilterParam = 'active'

export function OfferDetailsStatusFilter() {
  const { filterActive } = useFilter(OfferDetailsStatusFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const filters = Object.values(OfferDetailsStatusFilterTypes)

  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) {
      search.set(OfferDetailsStatusFilterParam, value as string)
    } else {
      search.delete(OfferDetailsStatusFilterParam)
    }

    setSearch(search, { replace: true })
  }

  useEffect(() => {
    if (!filterActive || !filters?.some(value => value === filterActive)) {
      search.delete(OfferDetailsStatusFilterParam)
    } else {
      search.set(OfferDetailsStatusFilterParam, filterActive)
    }
    setSearch(search, { replace: true })
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ? t(`watch-cat:Status_${ filterActive }`) : null }
      defaultValue={ filterActive ? t(`watch-cat:Status_${ filterActive }`) : null }
      onChange={ handleOnChange }
      options={ filters ?? [] }
      openOnFocus
      noOptionsText={ t('watch-cat:No options') }
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => t(`watch-cat:Status_${ option }`) === value }
      renderOption={ (props, option) => (
        <Box
          component="li"
          value={ option }
          { ...props }
          key={ Math.random() }
        >
          { t(`watch-cat:Status_${ option }`) }
        </Box>
      ) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ t('watch-cat:Select status') }
          inputProps={ {
            ...params.inputProps,
          } }
        />
      ) }
      slotProps={ {
        clearIndicator: {
          tabIndex: 0,
        },
      } }
    />
  )
}
