import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import styled from 'styled-components'
import i18n, { t } from 'i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'

import { fallbackLng } from '../../../shared/configs/translations'
import { ReactCountryFlag } from '../../../shared/ui/react-country-flag'
import { useTranslationStore } from '../../../entities/translation'

export const TopBarLanguageSwitcher = () => {
  const { translationCurrent, setTranslationCurrent } = useTranslationStore()

  const switchLanguage = (event: SelectChangeEvent) => {
    i18n.language !== event.target.value && setTranslationCurrent(event.target.value)
  }

  return (
    <SButtonWrapper>
      <LanguageBox>
        <FormControl>
          <Select
            value={translationCurrent ?? fallbackLng}
            onChange={switchLanguage}
            IconComponent={ExpandMoreIcon}
            sx={{
              '& .MuiSelect-select': {
                height: '48px !important',
                padding: '0 24px 0 14px !important',
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="en">
              <ReactCountryFlag
                countryCode={'us'}
                svg
                style={{
                  border: '1px solid #407698',
                  borderRadius: '2px',
                  marginRight: '7px',
                  opacity: '0.9',
                  height: '16px',
                  width: '22px',
                }}
              />
              {t('global:English')}
            </MenuItem>
            <MenuItem value="ru">
              <ReactCountryFlag
                countryCode={'ru'}
                svg
                style={{
                  border: '1px solid #407698',
                  borderRadius: '2px',
                  marginRight: '7px',
                  opacity: '0.9',
                  height: '16px',
                  width: '22px',
                }}
              />
              {t('global:Russian')}
            </MenuItem>
          </Select>
        </FormControl>
      </LanguageBox>
    </SButtonWrapper>
  )
}

const LanguageBox = styled(Box)`
  width: 100%;

  & .MuiSelect-select {
    padding-left: 0;
  }

  & .MuiSvgIcon-root {
    color: #B7C6D3;
    font-size: 16px;
    top: calc(50% - 8px);
    right: 12px;
    padding-right: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiInputBase-input.MuiOutlinedInput-input {
    color: #B7C6D3;
    font-size: 14px;
  }
`

export const SButtonWrapper = styled.div`
  background-color: #2D5E7D;
  border-radius: 15px;
  margin: 0 10px 0 0;
`
