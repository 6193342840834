import { AxiosError } from 'axios'

import { request } from '../../shared/api'

import {
  AnnualProgressResponse,
  CountriesDataResponse,
  GroupProgressResponse,
  NetworksDataResponse,
  WeekProgressResponse,
} from './types'

export const getWeekProgressList = async (): Promise<WeekProgressResponse | AxiosError> =>
  request.get('/dashboard/get-week-progress', {})

export const getGroupsProgressList = async (params: URLSearchParams): Promise<GroupProgressResponse | AxiosError> =>
  request.get('/dashboard/campaign-groups', { params })

export const getAnnualProgressList = async (): Promise<AnnualProgressResponse | AxiosError> =>
  request.get('/dashboard/yearly-progress', {})

export const getNetworksList = async (): Promise<NetworksDataResponse | AxiosError> =>
  request.get('/dashboard/networks-chart', {})

export const getCountriesDataList = async (): Promise<CountriesDataResponse | AxiosError> =>
  request.get('/dashboard/country-chart', {})
