import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { GeneratorBlock } from '../../../widgets/white-generator/generator'
import { useWhiteGeneratorStore } from '../../../entities/white-generator/generator'
import { GenerationsBlock } from '../../../widgets/white-generator/generations'
import { MainLayout } from '../../../widgets/layouts'

export function WhiteGenerator() {
  const { getGeneratorData, getGeneratedData } = useWhiteGeneratorStore()
  const { t } = useTranslation('white-generator')

  useEffect(() => {
    getGeneratorData()
  }, [getGeneratorData])

  useEffect(() => {
    getGeneratedData()
  }, [getGeneratedData])

  return (
    <MainLayout header={t('White page generator')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <GeneratorBlock/>
          </PageContainer>
        </PageBlock>
        <PageBlock>
          <PageContainer>
            <GenerationsBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
