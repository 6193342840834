import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useFilter } from '../../../../shared/lib'
import { KaccCatalogFilterColumnEnum } from '../../../../entities/kacc/catalog'

export const OnlyZeroFilterParam = 'only_zero'

export const OnlyZeroFilter = () => {
  const { filterActive } = useFilter(OnlyZeroFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const filterColumns = Object.values(KaccCatalogFilterColumnEnum)
  const initialOptions = filterActive
        && filterColumns?.filter(
          (item) => item && filterActive
            .split(',').includes(item))

  /**
   * Handle onChange action
   */
  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: KaccCatalogFilterColumnEnum[],
  ) => {
    const values = value.map((value) => value).join()

    if (values.length > 0) search.set(OnlyZeroFilterParam, values as string)
    if (values.length === 0 && filterActive) search.delete(OnlyZeroFilterParam)
    setSearch(search, { replace: true })
  }

  // Check query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && initialOptions && !(
          initialOptions.length > 0
          && filterActive?.split(',')
            ?.every((option) => filterColumns.includes(option as KaccCatalogFilterColumnEnum))
        )
      )) {
        search.delete(OnlyZeroFilterParam)
        setSearch(search, { replace: true })
      }
    }

  }, [initialOptions, filterActive])

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      multiple
      limitTags={-1}
      popupIcon={<KeyboardArrowDownIcon/>}
      onChange={handleOnChange}
      options={filterColumns ?? []}
      openOnFocus={true}
      noOptionsText={t('custom-reports:No options')}
      value={initialOptions ? initialOptions : []}
      defaultValue={initialOptions ? initialOptions : []}
      getOptionLabel={(option) => t(`kacc:${option}`)}
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option) => (
        (option && <Box
          component="li"
          value={option}
          {...props}
        >
          {t(`kacc:${option}`)}
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('kacc:Only zero')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        maxWidth: 300,
      }}
    />
  )
}
