import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)`
  border-top: 1px dotted #B7C6D3;
  border-bottom: 1px dotted #B7C6D3;
  margin-top: 16px;
`

export const TableRow = styled(UTableRow)<{ $selectedItem?: boolean }>`
  height: max-content;
  max-width: 750px;
  width: 100%;
  color: ${({ $selectedItem }) => $selectedItem ? '#275572' : '#7996A9'};
`

export const TableWrapper = styled(UTableWrapper)``

const UTableCell = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`
export const TableCell = styled(UTableCell)`
  align-items: flex-start;
  padding: 10px;
  width: 100%;

  &:nth-child(1) {
    min-width: 250px;
    flex-grow: 1;
  }

  //&:nth-child(2) {
  //  max-width: 90px;
  //  flex-grow: 0;
  //}

  //&:nth-child(3) {
  //  //min-width: 60px;
  //  flex-grow: 0;
  //}

  &:nth-child(2) {
    //min-width: 40px;
    flex-grow: 0;
  }
`

export const TableValue = styled(UTableValue)<{ $selectedItem?: boolean }>`
  align-items: center;
  display: flex;
  color: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1px;
  word-wrap: break-word;
  width: 100%;

  & label {
    margin-left: 5px;
  }

  & svg {
    color: #3FBDF6;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 10px;
  }
`

export const TableAlertMessage = styled(UTableAlertMessage)`
  width: 100%;`

export const TableColumnWrapper = styled.div`
  display: flex;
`

export const TableColumn = styled.div`
  border-right: 1px dotted #B7C6D3;
  flex-grow: 1;
  height: fit-content;
  width: 100%;

  &:nth-child(1) {
    &:is(:last-child) {
      border-right-width: 0;

      & ${TableColumnWrapper} {
        border-right-width: 0;
        justify-content: left;
      }

      & ${TableRow} {
        max-width: inherit;
      }
    }
  }

  &:nth-child(2):not(:last-child) {
    & ${TableColumnWrapper} {
      justify-content: center;
    }
  }

  &:last-child:not(:first-child) {
    border-right-width: 0;

    & ${TableColumnWrapper} {
      border-right-width: 0;
      justify-content: right;
    }
  }
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: stretch;

  & ${TableColumn} {
    border-right-color: transparent;
  }

  & ${TableRow} {
    border-bottom: 1px solid #C2DDED;
  }

  & ${TableValue} {
    color: #7996A9;
    font-size: 12px;
  }
`

export const TableBody = styled(UTableBody)`
  display: flex;
  justify-content: stretch;
  max-height: 300px;
`

export const SCellTitle = styled.div`
  color: inherit;
  flex-grow: 1;
  min-width: 155px;
  white-space: nowrap;
`
