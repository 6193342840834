import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getSettingsIntegrationsMain } from './api'

import type {
  DataError,
  SettingsIntegrationsAntyData,
  SettingsIntegrationsKeitaroData,
  SettingsIntegrationsFlexData,
} from './types'

export interface IDomainsGeneratorStore {
    loading: boolean,
    error: DataError | null,
    anty: SettingsIntegrationsAntyData | null,
    keitaro: SettingsIntegrationsKeitaroData | null,
    flex: SettingsIntegrationsFlexData | null,
    getIntegrations: () => void,
}

export const useSettingsIntegrationsStore = create<IDomainsGeneratorStore>((set, get) => ({
  loading: false,
  error: null,
  anty: null,
  keitaro: null,
  flex: null,
  getIntegrations: async () => {
    try {
      set({ anty: null })
      set({ keitaro: null })
      set({ flex: null })
      set({ error: null })
      set({ loading: true })

      const response = await getSettingsIntegrationsMain()
      if (!axios.isAxiosError(response)) {
        set({ anty: response.data.anty })
        set({ keitaro: response.data.keitaro })
        set({ flex: response.data.flex })
      }
    } catch (error) {
      let errorText = t('settings:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
