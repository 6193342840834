import React from 'react'
import styled from 'styled-components'
import { useSearchParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { t } from 'i18next'

import { SyncButton } from '../../../../shared/ui/sync-button'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { useKaccAntyListStore } from '../../../../entities/kacc/integration'

export const CheckButton = () => {
  const { syncKeitaro, syncing } = useKaccAntyListStore()
  const [search] = useSearchParams()

  const updateHandler = async () => {
    try {
      await syncKeitaro(search)

      enqueueSnackbar(
        t('kacc:Synchronization with the Facebook was success!'), { variant: 'success' },
      )
    } catch (error: any) {
      enqueueSnackbar(`${t(String(error?.response?.data?.message ?? error))}`, { variant: 'error' })
    }
  }

  return <SSyncButtonWrapper>
    <Tooltip
      title={
        t('kacc:Attention! This section is updated once a day, at 12 clock at night! ' +
            'But you can update the section now by clicking on the button!')}>
      <SyncButton
        onClick={updateHandler}
        updating={syncing}
      >
        {t('kacc:Sync')}
      </SyncButton>
    </Tooltip>
  </SSyncButtonWrapper>
}

const SSyncButtonWrapper = styled.div`
    margin-left: auto;
`
