import styled from 'styled-components'
import { Tooltip } from '@mui/material'

import { ReactCountryFlag } from '../../../../shared/ui/react-country-flag'
import {
  UTable,
  UTableHeader,
  UTableHeaderLabel,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)`
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
`

export const TableHeader = styled(UTableHeader)`
  background: #E3F2F6;
  box-shadow: 0px -1px 0px 0px #C2DDED inset;
  color: #32383E;
`

export const TableHeaderLabel = styled(UTableHeaderLabel)`
  gap: 0.5rem;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.2px;

  &:after, &:before {
    content: "";
    border-color: #d9d7d7;
    border-style: solid;
    border-radius: 1px;
    border-width: 4px;
    position: absolute;
    right: -1rem;
  }

  &:after {
    border-bottom-width: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 5px;
    margin-bottom: -6px;
  }

  &:before {
    border-bottom-width: 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    margin-top: -6px;
  }

  &.asc:after, &.desc:before {
    border-color: #a9b5b9;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &:not(.disabled):hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;

    &:after, &:before {
      display: none;
    }
  }
`

export const TableRow = styled(UTableRow)`
  min-height: 47px;
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
  margin: 12px 0 12px 16px;

  &:nth-child(1) {
    min-width: 70px;
    flex-grow: 1;
  }

  &:nth-child(2) {
    min-width: 150px;
    flex-grow: 3;
  }

  &:nth-child(3) {
    min-width: 100px;
    flex-grow: 1;
  }

  &:nth-child(4) {
    min-width: 70px;
    flex-grow: 0;
  }

  &:nth-child(5) {
    min-width: 70px;
    flex-grow: 1;
  }

  &:nth-child(6) {
    min-width: 70px;
    flex-grow: 0;
  }

  &:nth-child(7) {
    min-width: 70px;
    flex-grow: 0;
  }

  &:nth-child(8) {
    min-width: 70px;
    flex-grow: 0;
  }

  &:nth-child(9) {
    min-width: 80px;
    flex-grow: 1;
  }

  &:nth-child(10) {
    min-width: 150px;
    flex-grow: 1.5;
  }

  &:nth-child(11) {
    min-width: 150px;
    flex-grow: 1.5;
  }

  &:nth-child(12) {
    min-width: 100px;
    flex-grow: 0;
  }

  &:last-child {
    margin-right: 16px;
  }
`

export const TableValue = styled(UTableValue)`
  word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)``

export const TableDataMenu = styled.div`
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
`

export const TableBody = styled(UTableBody)`
  border-top: 1px solid rgba(39, 85, 114, 0.10);

  & ${ TableRow } {
    box-shadow: 0px -1px 0px 0px #C2DDED inset;

    &:not(.goal) {
      background-color: #F6FBFC;
    }

    &.goal {
      &.centered {
        align-items: center;
        justify-content: center;
      }
    }
  }

  & ${ TableCell } {
    &:nth-child(1) ${ TableValue } {
      display: inline-flex;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 500;
      padding: 2px 7px;
      width: inherit;

      &:has(.is-true) {
        background: #DCF6E9;
        color: #10AF6E;
      }

      &:has(.is-false) {
        background: #FFE4E4;
        color: #D32B30;
      }
    }

    &:nth-child(6) ${ TableValue },
    &:nth-child(9) ${ TableValue } {
      text-transform: uppercase;
    }
  }
`

export const STooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: alpha(#E1F9FC, 0.95)
  }

  &.watch {
    background: #DDF4FF;
    color: #64B0E0;

    &:hover {
      background: #cbf1f6;
      color: #5b9fcb;
    }

    & .MuiTooltip-popper {
      background-color: alpha(#E1F9FC, 0.95)
    }
  }

  &.favorite {
    background: #FFF6D2;
    color: #FF9500;

    &:hover {
      background: #f8e9ac;
      color: #e58400;
    }
  }

  &.link {
    background: #E3FFF1;
    color: #14B7A0;

    &:hover {
      background: #d1fae5;
      color: #119d89;
    }
  }
`

export const SGoalsMore = styled.div`
  display: inline-block;
`

export const SGoalsMoreButton = styled.a`
  border-radius: 4px;
  border: 1px solid #3FBDF6;
  box-shadow: 0 2px 4px 0 rgba(39, 85, 114, 0.15);
  color: #3FBDF6;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  padding: 5px 20px;
  transition: all 0.3s ease-in-out;
  text-decoration: unset;

  &:hover {
    border: 1px solid #28abe3;
    color: #28abe3;
  }
`

export const SOfferListDataGeo = styled.div`
  align-items: center;
  display: inline-flex;
`

export const SReactCountryFlag = styled(ReactCountryFlag)`
  border: 1px solid #455A64;
  margin-right: 10px;
`
