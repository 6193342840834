import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Box, TextField } from '@mui/material'

import { useFilter } from '../../../shared/lib'
import { useDashboardStore } from '../../../entities/dashboard'

export const CampaignFilterParam = 'campaign_group'

export function DashboardCampaignFilter() {
  const { groupProgress } = useDashboardStore()
  const { filterActive } = useFilter(CampaignFilterParam, '')
  const [search, setSearch] = useSearchParams()

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) search.set(CampaignFilterParam, value)
    if (!value && filterActive) search.delete(CampaignFilterParam)
    setSearch(search, { replace: true })
  }

  // Check query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && groupProgress?.filterList && (
          !(groupProgress.filterList.some(e => e.campaignGroup === filterActive))
        )
      )) {
        search.delete(CampaignFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [groupProgress, filterActive])

  // Get options as strings
  const options = groupProgress?.filterList.map((item) => item.campaignGroup) ?? []

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive ?? null}
      defaultValue={filterActive ?? null}
      onChange={handleOnChange}
      options={options}
      openOnFocus={true}
      noOptionsText={'No options'}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option) => (
        option !== '' && (
          <Box
            component="li" value={option}
            {...props}>
            {option}
          </Box>
        )
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Campaign'}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
