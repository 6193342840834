import React, { ReactElement, useLayoutEffect } from 'react'
import { Navigate } from 'react-router'
import { useLocation } from 'react-router-dom'

import { useUserStore } from '../../../entities/user'

import type { ProtectedRouteType } from './types'

export function ProtectedRoute({
  isAllowed,
  redirectTo = '/',
  children,
}: ProtectedRouteType): ReactElement {
  const { pathname, search, hash } = useLocation()

  // save loginRedirect after login
  useLayoutEffect(() => {
    !isAllowed && useUserStore.setState(() => ({ loginRedirect: `${pathname}${search}${hash}` }))
  }, [isAllowed])

  if (!isAllowed) {
    return <Navigate to={redirectTo} />
  }

  return children as ReactElement
}
