import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)`
    border: 1px dashed #C2DDED;
    border-radius: 4px;
    margin: 24px 0;
`

export const TableRow = styled(UTableRow)`
    min-height: 47px;

    &.disabled {
        background-color: #fdf9f3;
    }
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
    margin: 12px 0 12px 16px;

    &:nth-child(1) {
        min-width: 150px;
        flex-grow: 2;
    }

    &:nth-child(2) {
        flex-grow: 0;
    }

    &:nth-child(3) {
        flex-grow: 0;
    }

    &:last-child {
        margin-right: 16px;
    }
`

export const TableValue = styled(UTableValue)`
    word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)``

export const TableBody = styled(UTableBody)`
    height: 400px;

    & ${TableRow} {
        border-bottom: 1px dashed #C2DDED;
    }
`
