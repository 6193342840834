import styled from 'styled-components'
import { t } from 'i18next'
export function HistoryBlock() {

  return (
    <SOfferHistoryBlock>
      {t('watch-cat:History')}
    </SOfferHistoryBlock>
  )
}

const SOfferHistoryBlock = styled.div`
  display: flex;
  padding: 32px 16px;
`
