import { request } from '../../../shared/api'

import type { AxiosError, AxiosResponse } from 'axios'
import type {
  KaccCatalogInterval,
  KaccCatalogIntervalsResponse,
  KaccCatalogResponse,
  KaccCatalogSettingsFilter,
  KaccCatalogSettingsResponse,
} from './types'

export const getCatalog = async (params: URLSearchParams): Promise<KaccCatalogResponse | AxiosError> =>
  request.get('/kacc/catalog/get', { params })

export const getCatalogForceUpdate = async (params: URLSearchParams): Promise<AxiosResponse | AxiosError> =>
  request.get('/kacc/catalog/force-update', { params })

export const getCatalogIntervals = async (): Promise<KaccCatalogIntervalsResponse | AxiosError> =>
  request.get('/kacc/catalog/intervals/get')

export const addCatalogInterval = async (data: Partial<KaccCatalogInterval>): Promise<AxiosResponse | AxiosError> =>
  request.post('/kacc/catalog/intervals/add', data)

export const deleteCatalogInterval = async (params: URLSearchParams): Promise<AxiosResponse | AxiosError> =>
  request.delete('/kacc/catalog/intervals/delete', { params })

export const getCatalogSettings = async (): Promise<KaccCatalogSettingsResponse | AxiosError> =>
  request.get('/kacc/metrics-settings/get')

export const addCatalogSettings = async (
  data: KaccCatalogSettingsFilter,
): Promise<KaccCatalogSettingsResponse | AxiosError> =>
  request.post('/kacc/metrics-settings/add', data)
