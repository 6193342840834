import React, { useEffect, useRef, useState } from 'react'
import { t } from 'i18next'

import { useDimensions } from '../../../../shared/lib'
import { EditFBMarketingButton } from '../../../../features/kacc/integrations/add-edit-fb-marketing'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { KaccAntyData, useKaccAntyListStore } from '../../../../entities/kacc/integration'
import { ViewNoteButton } from '../../../../features/kacc/integrations/view-note-cell'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { TableIntegrationStatus } from '../../../../features/kacc/integrations/table-integration-status'
import { TableIntegrateProps } from '../types'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  CampaignRow,
  TableWrapper,
  SCellTitle,
  SAlignCell,
  SAntyStatus,
  SStatusValue,
  STagsValue,
  SBlockWithCollapse,
  SFlexGrowContent,
  SAntyMore,
  SAntyMoreButton,
  SButtonLink, SButtonLinkWrap,
} from './table'

interface CollapsedState {
  [key: string]: boolean
}

interface IntegrationItem {
  id: number;
}

export const IntegrationsTable = ({ formik }: TableIntegrateProps) => {
  const {
    data,
    loading,
    error,
    syncing,
    syncError,
  } = useKaccAntyListStore()
  const [selected, setSelected] = useState<KaccAntyData[]>([])
  const [collapsed, setCollapsed] = useState<CollapsedState>({})
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkedModelList, setCheckedModelList] = useState<string[]>([])
  // data check
  const hasData = data && data?.length > 0

  // table dimensions
  const offersHeaderRef = useRef(null)
  const offersHeader = useDimensions(offersHeaderRef)
  const minWidth = offersHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      offersHeader.offsetHeight + offersHeader.offsetTop + 240
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      offersHeader.offsetTop + 240
    }px)`
    : ''

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, antyData: KaccAntyData) => {
    const isChecked = e.target.checked
    const dataId = e.target.value

    if (isChecked) {
      setCheckedModelList(prevValue => [...prevValue, dataId])
      setSelected(prevSelected => [...prevSelected, antyData])
    } else {
      setCheckedModelList(prevValue => prevValue.filter(item => item !== dataId))
      setSelected(prevSelected => prevSelected.filter(item => item.id !== antyData.id))
    }
  }

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    setSelectAllChecked(isChecked)

    if (isChecked) {
      if (data && data.length > 0) {
        const ids = data.map(item => item.id.toString())
        setCheckedModelList(ids)
        setSelected(data)
      }
    } else {
      setCheckedModelList([])
      setSelected([])
    }
  }

  const truncateString = (str: string, num: number) => {
    if (str.length > num) {
      const start = str.slice(0, 4)
      const end = str.slice(-4)
      return `${start}...${end}`
    }
    return str
  }

  const toggleCollapse = (id: string) => {
    setCollapsed(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  // Set formik input value
  useEffect(() => {
    formik.setFieldValue('integrates', selected)
  }, [selected])

  // Resetting selected checkboxes
  useEffect(() => {
    if (formik.status === 'submitted') {
      setCheckedModelList([])
      setSelected([])
      setSelectAllChecked(false)
    }

    if (formik.values.integrates) {
      setCheckedModelList(formik.values.integrates.map((int: IntegrationItem) => int.id.toString()))
      setSelected(formik.values.integrates)
    }
  }, [formik.status, formik.values.integrates])

  return (
    <Table>
      {!loading && !syncing && (
        <TableWrapper
          // className="overflow-auto"
          style={{ height: maxHeight }}
        >
          {hasData && (
            <TableHeader>
              <TableRow ref={offersHeaderRef}>
                <TableCell>
                  <TableValue>
                    <input
                      type="checkbox"
                      id="checkall"
                      value="checkall"
                      onChange={handleSelectAllChange}
                      checked={checkedModelList.length === data?.length}
                    />
                    <TableHeaderLabel style={{ marginLeft: '5px' }} className={'disabled'}>
                      {t('kacc:Anty id')}
                    </TableHeaderLabel>
                  </TableValue>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Anty name')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:FB marketing api')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:FB act id')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Pixel id')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Capi')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Integration name')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Campaign id')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Link')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Campaign name')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Status Keitaro')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:User Anty')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Anty status')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Tags Anty')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Notes Anty')}
                  </TableHeaderLabel>
                </TableCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody style={{ height: minHeight }}>
            {hasData && data?.map((antyData, index) => (
              <div
                key={index + 1}
                style={{ minWidth: minWidth }}
              >
                <TableRow className="aligh-baseline">
                  <TableCell>
                    <TableValue>
                      <input
                        type="checkbox"
                        id={antyData.id.toString()}
                        name="antyIntegration"
                        value={antyData.id.toString()}
                        onChange={(e) => handleCheckboxChange(e, antyData)}
                        checked={Boolean(checkedModelList.includes(antyData.id.toString()))}
                      />
                      <label htmlFor={antyData.name}>
                        {antyData.id}
                      </label>
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {antyData.name}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      <SAlignCell>
                        {
                          antyData.mapiValue
                            ? antyData.mapiValue.length > 10
                              ? <SFlexGrowContent>
                                <Tooltip arrow title={antyData.mapiValue}>
                                  <SCellTitle>{truncateString(antyData.mapiValue, 10)}</SCellTitle>
                                </Tooltip>
                              </SFlexGrowContent>
                              : <SCellTitle>{antyData.mapiValue}</SCellTitle>
                            : <SCellTitle> - </SCellTitle>
                        }
                        {
                          antyData.mapiValue && <EditFBMarketingButton antyData={antyData}/>
                        }
                      </SAlignCell>
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      <SAntyMore onClick={() => toggleCollapse(antyData.id.toString())}>
                        <SAntyMoreButton>
                          {collapsed[antyData.id]
                            ? t('kacc:View list')
                            : t('kacc:Hide List')
                          }
                        </SAntyMoreButton>
                      </SAntyMore>
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue className={'disabled'}>
                      {/*{t('kacc:Pixel id')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue className={'disabled'}>
                      {/*{t('kacc:Capi')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {/*{t('kacc:Integration name')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {/*{t('kacc:Campaigns Id')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue className={'disabled'}>
                      {/*{t('kacc:Link')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {/*{t('kacc:Campaigns Name')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {/*{t('kacc:Status Keitaro')}*/}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      {antyData.userName}
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      <SAntyStatus>{antyData.status}</SAntyStatus>
                    </TableValue>
                  </TableCell>
                  <TableCell>
                    {antyData.tags.length > 3 ? (
                      <Tooltip arrow title={antyData.tags.join(', ')}>
                        <STagsValue>
                          {antyData.tags.slice(0, 3).map((tag, index, array) => (
                            <div
                              key={index}
                              className={'white-space'}>{tag}{index < array.length - 1 ? ', ' : ''}</div>
                          ))}
                        </STagsValue>
                      </Tooltip>
                    ) : (
                      <STagsValue>
                        {antyData.tags.map((tag, index) => (
                          <div
                            key={index}
                            className={'white-space'}>{tag}{index < antyData.tags.length - 1 ? ', ' : ''}</div>
                        ))}
                      </STagsValue>
                    )}
                  </TableCell>
                  <TableCell>
                    <TableValue>
                      <ViewNoteButton antyData={antyData}/>
                    </TableValue>
                  </TableCell>
                </TableRow>

                {Object.values(antyData.integrations).length > 0 && (
                  <SBlockWithCollapse style={{ display: collapsed[antyData.id] ? 'none' : 'block' }}>
                    {Object.values(antyData.integrations).map((integrate, index) => (
                      <div key={index + 1}>
                        <CampaignRow className={'aligh-baseline'}>
                          <TableCell>
                            <TableValue>
                              {/*{ profile id }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ name anty }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ fb marketing Api }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <Tooltip arrow title={integrate.accountId + antyData.name}>
                              <TableValue style={{ fontWeight: 500 }}>
                                {integrate.accountId}
                              </TableValue>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {integrate.pixel === null ? 'null' : integrate.pixel}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              <SAlignCell>
                                {
                                  integrate.capiValue
                                    ? integrate.capiValue.length > 10
                                      ? <SFlexGrowContent>
                                        <Tooltip arrow title={integrate.capiValue}>
                                          <SCellTitle>{truncateString(integrate.capiValue, 10)}</SCellTitle>
                                        </Tooltip>
                                      </SFlexGrowContent>
                                      : <SCellTitle>{integrate.capiValue}</SCellTitle>
                                    : <SCellTitle> - </SCellTitle>
                                }
                              </SAlignCell>
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {integrate.name}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{t('kacc:Campaigns Id')}*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{t('kacc:Link')}*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{t('kacc:Campaigns Name')}*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <SStatusValue>
                              {
                                (integrate.status === 'notExist' && integrate.state === 'notExist') ? (
                                  <TableIntegrationStatus status="notExist"/>
                                ) : integrate.status === 'success' && integrate.state === 'active' ? (
                                  <TableIntegrationStatus status={integrate.status}/>
                                ) : (
                                  <>
                                    <TableIntegrationStatus status={integrate.status}/>
                                    {integrate.status.toString() !== integrate.state.toString() &&
                                      <TableIntegrationStatus status={integrate.state}/>
                                    }
                                  </>
                                )
                              }
                            </SStatusValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ user anty }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ status anty }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ tags anty }*/}
                            </TableValue>
                          </TableCell>
                          <TableCell>
                            <TableValue>
                              {/*{ notes }*/}
                            </TableValue>
                          </TableCell>
                        </CampaignRow>

                        {integrate.campaigns.map((campaign, campaignIndex) => (
                          <TableRow className={'aligh-baseline'} key={`campaign-${campaignIndex}`}>
                            <TableCell>
                              <TableValue>
                                {/*{ profile id }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ name anty }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ fb marketing Api }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{t('kacc:Pixel id')}*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{t('kacc:Capi')}*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{integrate.accountId}*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{integrate.name}*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {campaign.id}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                <SButtonLinkWrap>
                                  <a
                                    href={campaign.link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <SButtonLink
                                      variant={'contained'}
                                      type={'button'}
                                    >
                                      {t('Kacc:Link')}
                                    </SButtonLink>
                                  </a>
                                </SButtonLinkWrap>
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {campaign.name}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                <TableValue>
                                  <TableIntegrationStatus status={campaign.status}/>
                                </TableValue>
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ user anty }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ status anty }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ tags anty }*/}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {/*{ notes }*/}
                              </TableValue>
                            </TableCell>
                          </TableRow>
                        ))}
                      </div>
                    ))}
                  </SBlockWithCollapse>
                )}
              </div>
            ))
            }
          </TableBody>
        </TableWrapper>
      )}
      {
        !error
        && !loading
        && !syncing
        && data?.length === 0 && (
          <TableAlertMessage>
            <AlertMessage severity="warning">
              {t('kacc:Oops! The table is - no data!')}
            </AlertMessage>
          </TableAlertMessage>
        )
      }

      {
        syncError
        && error
        && !loading && (
          <TableAlertMessage>
            <AlertMessage severity="error">
              {t(syncError)}
            </AlertMessage>
          </TableAlertMessage>
        )
      }

      {
        syncing
        && !syncError && (
          <TableAlertMessage>
            <AlertMessage severity="warning">
              {t('kacc:Wait a minute! Synchronization is already in progress!')}
            </AlertMessage>
          </TableAlertMessage>
        )
      }

      {
        !error
        && loading
        && !syncing && (
          <TableAlertMessage>
            <AlertMessage severity="info">
              {t('kacc:Just a minute! Table is loading/updating!')}
            </AlertMessage>
          </TableAlertMessage>
        )
      }
    </Table>
  )
}
