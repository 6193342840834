import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type SearchProps = {
  searchParam: string
  searchActive: string | null
  updateSearch: (value: string) => void
}

export function useSearch(searchParam: string): SearchProps {
  const [search, setSearch] = useSearchParams()
  const [searchActive, setSearchActive] = useState<string | null>(null)

  const updateSearch = (value: string) => {
    search.set(searchParam, value)
    setSearch(search, { replace: true })
  }

  useEffect(() => {
    const searchActive = search.get(searchParam)

    if (searchActive !== null) {
      setSearchActive(searchActive as string)
    } else {
      setSearchActive(null)
    }
  }, [search])

  return {
    searchParam,
    searchActive,
    updateSearch,
  }
}
