import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export enum OfferDetailsSortKeys {
  name = 'name'
}

export type OfferDetailsGoal = {
  id: number,
  active: boolean,
  name: string,
  type: string,
  code: string,
  geo: string,
  price: number,
  cash: number,
  up: number,
  cross: number,
  percent: number,
  currency: boolean | string
}

export type OfferDetailsData = {
  id: number,
  id_generated: number,
  name: string,
  active: number,
  idAffiliateNetwork: number,
  network: string,
  url: string,
  date_created: string,
  date_updated: string,
  geo: { [key: string]: string }[],
  goal: OfferDetailsGoal[]
}

export type DataResponse = {
  data: OfferDetailsData
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}
