import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'
import { Switcher } from '../../../../shared/ui/switcher'

import { DisabledFilterType } from './types'

export const DisabledParam = 'disabled'

export function DisabledFilter() {
  const [search, setSearch] = useSearchParams()
  const { filterActive } = useFilter(DisabledParam, DisabledFilterType.false)

  const getCurrentFilterBoolean = filterActive === DisabledFilterType.true

  /**
   * Handle ItemClickHandler action
   */
  const handleClickHandler = (): void => {
    search.set(DisabledParam, String(!getCurrentFilterBoolean))
    setSearch(search, { replace: true })

    if(filterActive) {
      setSearch(search, { replace: true })
    }
  }

  //Check disabled query values
  useEffect( () => {
    if( filterActive !== null || filterActive === '') {
      if(!filterActive
      || (
        !(filterActive in DisabledFilterType)
              || filterActive === DisabledFilterType.false
      )
      ) {
        search.delete(DisabledParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return(
    <Switcher
      label={ t('subdomains-generator:Show disabled') }
      itemClickHandler={ handleClickHandler }
      itemActive={ getCurrentFilterBoolean }
      defaultValue={ false }
    />
  )
}
