import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './app/App'
//import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

process.env.REACT_APP_GIT_HASH && console.log(`Frontend version: ${process.env.REACT_APP_GIT_HASH}`)

serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*
  Метрики производительности, которые reportWebVitals отслеживает, включают в себя такие показатели,
    как время загрузки страницы (LCP - Largest Contentful Paint),
    время задержки перед отрисовкой (FCP - First Contentful Paint),
    время задержки перед взаимодействием пользователя (CLS - Cumulative Layout Shift)
  и другие.*/
// reportWebVitals(console.log)
