import { handleValueEmptyData, setPriceColor } from '../lib'
import { SETTINGS_FORMAT_KEYS, SETTINGS_PRICE_COLOR_KEYS } from '../../../../entities/kacc/catalog'

import { TableCell, TableValue } from './table'

import type { KaccCatalogSettingsFilter, KaccCatalogTotals } from '../../../../entities/kacc/catalog'

export type TableBlockTotalsGroupsProps = {
    settings: KaccCatalogSettingsFilter,
    settingsKey: keyof KaccCatalogSettingsFilter,
    catalogTotals: KaccCatalogTotals
}
export function TableBlockTotalsGroups({ settings, settingsKey, catalogTotals }: TableBlockTotalsGroupsProps) {

  return (
    <>
      {settings?.[settingsKey]?.filter((setting) => setting.checked)
        ?.map(setting => {
          const key = setting.key as keyof KaccCatalogTotals
          const totals = (catalogTotals?.[key] !== undefined) ? catalogTotals[key] : null
          const color = SETTINGS_PRICE_COLOR_KEYS
            .some(value => value === key)
          const format = SETTINGS_FORMAT_KEYS
            .filter(value => value.key === key)
            .map(value => value.format)[0]
              ?? 'string'

          return (
            <TableCell
              key={setting.key}
              className={setting.key}
              style={{ minWidth: setting.width }}>

              {key in catalogTotals &&
                    (
                      <TableValue style={color ? setPriceColor(Number(totals)) : {}}>
                        {handleValueEmptyData(totals, format)}
                      </TableValue>
                    )
              }
            </TableCell>
          )
        })
      }
    </>
  )
}
