import styled from 'styled-components'

import {
  UTable,
  UTableHeader,
  UTableHeaderLabel,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'

export const Table = styled(UTable)``

export const TableWrapper = styled(UTableWrapper)`
  border: 1px solid #efefef;
  border-radius: 4px;
  display: grid;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    height: 59px;
  }
`

export const TableCell = styled(UTableCell)`
  flex-grow: 0;
`

export const TableHeader = styled(UTableHeader)`
  background: #E3F2F6;
  color: #32383E;
  position: sticky;
  top: 0;
  
  & ${TableCell} {
    box-shadow: -1px -1px 0px 0px #c4d9de inset;
    overflow: hidden;

    &:last-child {
      box-shadow: -1px 0px 0px 0px #E3F2F6 inset;
    }
  }
`

export const TableHeaderLabel = styled(UTableHeaderLabel)`
  box-shadow: -1px -1px 0px 0px #efefef inset;
  gap: 0.5rem;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.2px;
  margin: 12px 16px;
  white-space: nowrap;

  &:after, &:before {
    content: "";
    border-color: #d9d7d7;
    border-style: solid;
    border-radius: 1px;
    border-width: 4px;
    position: absolute;
    right: -1rem;
  }

  &:after {
    border-bottom-width: 0;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 5px;
    margin-bottom: -6px;
  }

  &:before {
    border-bottom-width: 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    margin-top: -6px;
  }

  &.asc:after, &.desc:before {
    border-color: #a9b5b9;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &:not(.disabled):hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;

    &:after, &:before {
      display: none;
    }
  }
`

export const TableRow = styled(UTableRow)`
  min-height: 47px;
`

export const TableValue = styled(UTableValue)`
  margin: 12px 16px;
  word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)``

export const TableBody = styled(UTableBody)`
  border-top: 1px solid rgba(39, 85, 114, 0.10);

  & ${TableCell} {
    box-shadow: -1px -1px 0px 0px #efefef inset;
    word-break: break-all;

    &:last-child {
      box-shadow: -1px 0px 0px 0px #efefef inset;
    }
  }

  & ${TableRow} {
    &:nth-child(even) {
      background-color: rgba(249, 250, 250, 0.9);
    }
  }
`
