import debounce from 'debounce'
import { t } from 'i18next'

import { type SortProps, useSort } from '../../../../shared/lib'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { KaccCatalogSettingsFilter } from '../../../../entities/kacc/catalog'
import { getSortDirectionClassName, handleOnResize, handleSortChange } from '../lib'

import { STableResizable, TableCell, TableHeaderLabel } from './table'

export type TableBlockHeaderGroupsProps = {
    settings: KaccCatalogSettingsFilter,
    settingsKey: keyof KaccCatalogSettingsFilter,
    sortActive: SortProps['sortActive'],
    sortColumn: SortProps['sortColumn'],
}
export function TableBlockHeaderGroups({ settings, settingsKey, sortActive, sortColumn }: TableBlockHeaderGroupsProps) {
  const { setAsc, setDesc, toggleSort } = useSort()

  // Debounce sort change action
  const debouncedSortChange = debounce(handleSortChange, 150)

  return (
    <>
      {settings?.[settingsKey]?.filter((setting) => setting.checked)
        ?.map(setting =>
          (
            <STableResizable
              key={setting.key}
              size={{ width: setting.width, height: 'auto' }}
              className={setting.key}
              onResizeStop={
                (event, direction, elementRef, delta) =>
                  handleOnResize(settings, event, direction, elementRef, delta, settingsKey, setting.key)
              }
            >
              <Tooltip title={t(`kacc:${setting.key}`)}>
                <TableCell
                  className={getSortDirectionClassName(setting.key, sortColumn, sortActive)}
                  onClick={() => debouncedSortChange(setting.key, sortActive, sortColumn, setAsc, setDesc, toggleSort)}>
                  <TableHeaderLabel>
                    {t(`kacc:${setting.key}`)}
                  </TableHeaderLabel>
                </TableCell>
              </Tooltip>
            </STableResizable>
          ),
        )}
    </>
  )
}
