import { useTranslation } from 'react-i18next'

import { RecoverForm } from '../../../widgets/auth/recover'
import { AuthLayout } from '../../../widgets/layouts'

export function Recover() {
  const { t } = useTranslation('auth')

  return (
    <AuthLayout
      header={t('Recover password')}
      title={t('Lost password?')}
      subtitle={
        <span>
          {t('Recover your CpaBox account at')}
          {' '}
          <span style={{ fontWeight: 'bold' }}>{window.location.hostname}</span>
        </span>
      }
    >
      <RecoverForm/>
    </AuthLayout>
  )
}
