import styled from 'styled-components'

import { AntyForm } from './anty-form'
import { KeitaroForm } from './keitaro-form'
import { FlexForm } from './flex-form'

export const IntegrationsBlock = () => {
  return (
    <SIntegrationsBlock>
      <KeitaroForm/>
      <AntyForm/>
      <FlexForm/>
    </SIntegrationsBlock>
  )
}

const SIntegrationsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`
