import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getGroups, getPayloadFromLink, getReports } from './api'

import type { CustomReportsCatalog, CustomReportsCatalogFilters, CustomReportsData, CustomReportsGroups } from './types'

export type CustomReportsCatalogType = {
    catalog: CustomReportsCatalog[] | null,
    catalogCount: CustomReportsData['count'] | null,
    catalogFilters: CustomReportsCatalogFilters | null,
    catalogIsLoading: boolean,
    catalogHasError: string | boolean,
    getCatalog: (search: URLSearchParams) => Promise<void>,

    groups: CustomReportsGroups[] | null,
    groupsIsLoading: boolean,
    groupsHasError: boolean,
    getGroups: () => Promise<void>,

    getPayloadFromLink: (url: string) => Promise<void>,
}

export const useCustomReportsCatalogStore = create<CustomReportsCatalogType>((set, _get) => ({
  catalog: null,
  catalogCount: null,
  catalogFilters: null,
  catalogIsLoading: false,
  catalogHasError: false,
  getCatalog: async (search: URLSearchParams) => {
    try {
      set({ catalog: null })
      set({ catalogIsLoading: true })
      set({ catalogHasError: false })

      const response = await getReports(search)

      if (!axios.isAxiosError(response)) {
        set({ catalog: response.data?.reports })
        set({ catalogCount: response.data?.count })
        set({ catalogFilters: response.data?.filters })
      }
    } catch (error) {
      let errorText = t('custom-reports:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ catalogHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ catalogIsLoading: false })
    }
  },

  groups: null,
  groupsIsLoading: false,
  groupsHasError: false,
  getGroups: async () => {
    try {
      set({ groups: null })
      set({ groupsIsLoading: true })
      set({ groupsHasError: false })

      const response = await getGroups()

      if (!axios.isAxiosError(response))
        set({ groups: response.data })
    } catch (error) {
      let errorText = t('custom-reports:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ groupsHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ groupsIsLoading: false })
    }
  },

  getPayloadFromLink: async (url: string) => {
    try {
      const response = await getPayloadFromLink(url)

      if (!axios.isAxiosError(response))
        return response.data
    } catch (error) {
      let errorText = t('custom-reports:Unknown error')

      if (axios.isAxiosError(error)) errorText = error.response?.data.message

      throw errorText
    }
  },
}))
