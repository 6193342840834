export enum IntervalFilterType {
  // today = 'today',
  yesterday = 'yesterday',
  seven_days_ago = '7_days_ago',
  // first_day_of_this_month = 'first_day_of_this_month',
  // previous_month = 'previous_month',
   one_month_ago = '1_month_ago',
  // first_day_of_this_year = 'first_day_of_this_year',
  // all_time = 'all_time',
  // custom_date_range = 'custom_date_range',
  // custom_time_range = 'custom_time_range'
}
