import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'

import type { DomainsGeneratorGroups } from '../../../../entities/domains-generator/generator'
import type { TableOptionsGroupsProps } from './types'

export const GroupsFilterParam = 'groups'

export const TableOptionsGroups = ({ data }: TableOptionsGroupsProps) => {
  const { filterActive } = useFilter(GroupsFilterParam, '')
  const [ search, setSearch] = useSearchParams()
  const initialOptions = filterActive
      && data?.filter(
        (item) => item
            && filterActive
              .split(',')
              .includes(String(item.id)),
      )

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: DomainsGeneratorGroups[]) => {
    const values = value.map((v) => v?.id).join()

    if (values.length > 0) search.set(GroupsFilterParam, values as string)
    if (values.length === 0 && filterActive) search.delete(GroupsFilterParam)
    setSearch(search, { replace: true })
  }

  // Check groups query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && initialOptions && !(initialOptions.length > 0)
      )) {
        search.delete(GroupsFilterParam)
        setSearch(search, { replace: true })
      }
    }

  }, [data, filterActive])

  return (
    <Autocomplete
      autoHighlight
      disablePortal
      multiple
      limitTags={1}
      popupIcon={ <KeyboardArrowDownIcon/> }
      onChange={ handleOnChange }
      options={ data ?? [] }
      openOnFocus={ true }
      noOptionsText={t('subdomains-generator:No options')}
      value={ initialOptions ? initialOptions : [] }
      defaultValue={ initialOptions ? initialOptions : [] }
      getOptionLabel={option => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id }
      renderOption={ (props, option) => (
        (option && <Box
          component="li"
          value={ option?.id }
          {...props}
        >
          { option?.name }
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('subdomains-generator:Groups') }
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        width: 300,
      }}
    />
  )
}
