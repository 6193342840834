import { useTranslation } from 'react-i18next'

import { PageBlock, PageContainer } from '../../../../shared/ui/page'
import { PasswordBlock } from '../../../../widgets/user/settings/password'
import { MainLayout } from '../../../../widgets/layouts'
import { UserSettingsLayout } from '../../../../widgets/layouts/settings'

export function UserSettingsPassword() {
  const { t } = useTranslation('settings')

  return (
    <MainLayout header={t('User settings | Password')}>
      <UserSettingsLayout>
        <PageBlock style={{ width: '100%', height: 'auto' }}>
          <PageContainer style={{ width: '100%', height: '100%' }}>
            <PasswordBlock/>
          </PageContainer>
        </PageBlock>
      </UserSettingsLayout>
    </MainLayout>
  )
}
