import React, { useState } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'

import { AddEditReportModal } from './add-edit-report-modal'

export const AddReportButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <AddEditReportModal
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <Button
        onClick={() => setModalVisible(true)}
        variant={'contained'}
        size={'small'}
        type={'button'}
      >
        {t('custom-reports:Add report')}
      </Button>
    </>
  )
}
