import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { enqueueSnackbar } from 'notistack'
import { Alert, CircularProgress, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { useSettingsDomainsStore } from '../../../../../entities/domains-generator/settings'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

import type { DictionaryInitialProps, ModalProps } from '../types'

export const AddEditDictionaryModal = ({ onClose, initialValues, isOpen }: ModalProps) => {
  const {
    getDictionariesData,
    createDictionary,
    editDictionary,
  } = useSettingsDomainsStore()

  const formikInitialValues = {
    id: initialValues?.id ?? 0,
    name: initialValues?.name ?? '',
    words:  initialValues?.dict?.join(', ') ?? '',
    submit: null,
  }

  const yupValidationSchema = Yup.object().shape({
    name: Yup.string()
      .max(255)
      .required(t('subdomains-generator:The dictionary name field is required')),
    words: Yup.string()
      .max(1500, t('subdomains-generator:Words must be at most 1500 characters')),
  })

  const onSubmitHandler = async (
    values: DictionaryInitialProps,
    { setStatus, setSubmitting }: FormikHelpers<DictionaryInitialProps>,
  ) => {
    try {
      !initialValues?.id
        ? await createDictionary?.(values.name, values.words)
        : await editDictionary?.(values.id, values.name, values.words)

      await getDictionariesData()

      await setTimeout(() => onClose?.(), 500)

      if (!initialValues?.id) {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} dictionary was added successfully!', { name: values.name }),
          { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} dictionary was edited successfully!', { name: values.name }),
          { variant: 'success' },
        )
      }
      setStatus({ success: true })
    } catch (error: any) {
      setStatus({ success: false })

      if (!initialValues?.id) {
        enqueueSnackbar(
          `${t('subdomains-generator:{{name}} dictionary was not added!', { name: values.name })} 
          ${t(`subdomains-generator:${error}`)}`,
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          `${t('subdomains-generator:{{name}} dictionary was not edited!', { name: values.name })} 
          ${t(`subdomains-generator:${error}`)}`,
          { variant: 'error' },
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ModalOverlayMui
      handleClose={onClose}
      isOpen={isOpen}
    >
      <SModalHeader>
        <SModalHeaderText>
          {t('subdomains-generator:Add new vocabulary')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <Formik
          initialValues={formikInitialValues}
          validationSchema={yupValidationSchema}
          onSubmit={onSubmitHandler}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <SModalForm
              noValidate
              onSubmit={handleSubmit}
            >
              <SModalContent>
                <TextField
                  error={Boolean(touched.submit && touched.name && errors.name)}
                  fullWidth
                  margin="normal"
                  autoFocus
                  label={t('subdomains-generator:Type name')}
                  name="name"
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name.trim()}
                />
                <TextField
                  error={Boolean(touched.submit && touched.words && errors.words)}
                  label={t('subdomains-generator:Words list')}
                  name="words"
                  multiline
                  rows={4}
                  style={{ marginTop: '16px' }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.words.trim()}
                />

                <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
                  {touched.submit && Object.keys(errors).length > 0
                    && (
                      <Alert severity="error">
                        {errors.id && <div> {t(`subdomains-generator:${errors.id}`)} </div>}
                        {errors.name && <div> {t(`subdomains-generator:${errors.name}`)} </div>}
                        {errors.words && <div> {t(`subdomains-generator:${errors.words}`)} </div>}
                      </Alert>)
                  }
                </div>

                <div
                  style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    marginTop: 'auto',
                    gap: '15px',
                  }}
                >
                  <Button
                    variant={'contained'}
                    disabled={isSubmitting}
                    type={'submit'}
                    size={'medium'}
                    onClick={() => handleSubmit}
                  >
                    {isSubmitting && <CircularProgress size="1rem"/>}
                    {t('subdomains-generator:Submit')}
                  </Button>

                  <SModalCancel
                    variant="outlined"
                    onClick={onClose}
                    size={'medium'}
                    type={'button'}
                    style={{ marginLeft: 'auto' }}
                    disableRipple
                  >
                    {t('subdomains-generator:Close')}
                  </SModalCancel>
                </div>
              </SModalContent>
            </SModalForm>
          )}
        </Formik>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`

const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #F35B5B;
    color: #F35B5B;

    &:hover {
      border-color: #F35B5B;
      background-color: #FFE2E2;
    }
  }
`
