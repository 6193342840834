import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PageRows, ContentRowWrapper } from '../../../shared/ui/style'
import { DomainsBlock, DictionariesBlock } from '../../../widgets/domains-generator/settings'
import { useSettingsDomainsStore } from '../../../entities/domains-generator/settings'
import { MainLayout } from '../../../widgets/layouts'

export function DomainsGeneratorSettings() {
  const [search] = useSearchParams()
  const { getDictionariesData, getDomainsData } = useSettingsDomainsStore()
  const { t } = useTranslation('subdomains-generator')

  useEffect(() => {
    getDictionariesData()
    getDomainsData(search)
  },[search])

  return (
    <MainLayout header={t('Subdomains generator settings')}>
      <PageRows>
        <ContentRowWrapper>
          <DictionariesBlock/>
          <DomainsBlock/>
        </ContentRowWrapper>
      </PageRows>
    </MainLayout>
  )
}
