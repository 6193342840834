import { AxiosError } from 'axios'

import { request } from '../../../shared/api'

import type { DataResponse } from './types'

export const getOfferInfo = (
  id: string | number,
  searchParams?: URLSearchParams,
): Promise<DataResponse | AxiosError> => {
  const params = new URLSearchParams(searchParams || '')
  params.append('id', id.toString())

  return request.get('/watch-kitty/get-one', { params })
}
