import type { CustomReportInfoRow } from '../../../entities/custom-reports/info'

export const getReportInfoKeys = <T extends CustomReportInfoRow>(arr: readonly T[]): readonly (keyof T[number])[] => {
  const keysSet = new Set<keyof T[number]>()

  for (const obj of arr) {
    const objKeys = Object.keys(obj) as (keyof T[number])[]
    objKeys.forEach(key => keysSet.add(key))
  }

  return Array.from(keysSet)
}
