import type { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export enum CustomReportInfoDisabledEnum {
    true = 'true',
    false = 'false',
}

export enum CustomReportInfoLimitEnum {
    TwentyFive = '25',
    Fifty = '50',
    OneHundred = '100',
    TwoHundredFifty = '250',
    FiveHundreds = '500',
    Thousand = '1000',
}

export enum CustomReportDirectionEnum {
    ascendant = 'asc',
    descendant = 'desc',
}

export type CustomReportInfoGroups = {
    id: number,
    name: string,
    disabled: boolean,
    created_at: string,
    updated_at: string,
}

export type CustomReportInfoReport = {
    id: number,
    name: string,
    comment: string,
    url: string,
    payload: string,
    disabled: boolean,
    created_at: string,
    updated_at: string,
    groups?: CustomReportInfoGroups[],
}

export type CustomReportInfoRow = {
    [key: string]: string | number
}

export type CustomReportInfoPayload = {}

export type CustomReportInfoData = {
    total: number,
    summary?: object,
    meta?: object,
    payload: CustomReportInfoPayload,
    rows: CustomReportInfoRow[],
    report: CustomReportInfoReport,
}

export type CustomReportInfoResponse = {
    data: CustomReportInfoData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}
