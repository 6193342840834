import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import styled from 'styled-components'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useSearch } from '../../../shared/lib'

export const CreatedDateParam = 'created_date'

export function CreatedDateFilter() {
  const [search, setSearch] = useSearchParams()
  const { searchActive } = useSearch(CreatedDateParam)
  const { offsetActive } = usePagination()
  const value = searchActive ? dayjs(searchActive) : null

  /**
   * Check if dayjs date is valid and is after 1900 and before (including) today
   */
  const checkDateIsValid = (date: any) => {
    return dayjs(date).isValid()
      && dayjs(date).isAfter('1900')
      && dayjs(date).isBefore(dayjs())
  }

  /**
   * Handle InputChange action
   */
  const handleInputChange = (value: any): void => {
    const formattedValue = String(dayjs(value).format('YYYY-MM-DD'))

    if (checkDateIsValid(formattedValue))
      search.set(CreatedDateParam, formattedValue)
    if (searchActive && (!checkDateIsValid(formattedValue) || !checkDateIsValid(searchActive)))
      search.delete(CreatedDateParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check date query values
  useEffect(() => {
    if (searchActive !== null || searchActive === '') {
      if (!searchActive || (
        searchActive && (
          !(searchActive.length > 0) || searchActive === 'Invalid Date'
        )
      )) {
        search.delete(CreatedDateParam)
        setSearch(search, { replace: true })
      }
    }
  }, [searchActive])

  return (
    <>
      <SDatePicker
        disableFuture
        label={t('watch-cat:Created date')}
        value={value ?? null}
        defaultValue={value ?? null}
        onChange={(newValue: any) => handleInputChange(newValue)}
        slotProps={{
          textField: { size: 'small' },
          field: { clearable: true, onClear: () => handleInputChange(null) },
        }}
      />
    </>
  )
}

const SDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    max-width: 100px;
  }

  & .MuiOutlinedInput-root {
    height: 40px;
  }
`
