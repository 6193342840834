import * as React from 'react'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

import { Button } from '../button'
import { ModalOverlayMui } from '../modal-overlay-mui'

import {
  SModalHeader,
  SModalBody,
  SModalContent,
  SMessageWrapper,
  SIconWrapper,
  SMessageTitle,
  SMessageDescription,
  SModalButtonWrapper,
} from './style'

import type { ModalProps } from './types'

export const ModalConfirmation = ({
  onClose,
  isOpen,
  title,
  description,
  handleClick,
  buttonTitle,
  cancelText,
}: ModalProps) => {

  return (
    <ModalOverlayMui isOpen={isOpen}>
      <SModalHeader></SModalHeader>
      <SModalBody>
        <SModalContent>
          <SIconWrapper>
            <ErrorOutlineOutlinedIcon fontSize={'inherit'} color={'inherit'}/>
          </SIconWrapper>
          <SMessageWrapper>
            <SMessageTitle>{title}</SMessageTitle>
            <SMessageDescription>{description}</SMessageDescription>
          </SMessageWrapper>
        </SModalContent>
        <SModalButtonWrapper>
          <Button
            variant="contained"
            onClick={handleClick}
            color={'error'}
            size={'medium'}
            type={'button'}
            style={{ marginLeft: 'auto' }}
            disableRipple
          >
            {buttonTitle}
          </Button>
          <Button
            variant={'contained'}
            onClick={onClose}
            size={'medium'}
          >
            {cancelText}
          </Button>
        </SModalButtonWrapper>
      </SModalBody>
    </ModalOverlayMui>
  )
}