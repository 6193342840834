import React from 'react'
import { styled, Box, Typography, Button } from '@mui/material'
import { t } from 'i18next'

import { ErrorSpaceManIcon } from '../../../shared/ui/icon-assets/error-space-man'

const SvgContent = styled(Box)(() => ({
  '&': {
    animation: 'mover 1s infinite alternate',
    maxWidth: '350px',
    width: '100%',
  },
  '@keyframes mover': {
    '0%': {
      transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(-16px)',
    },
  },
}),
)

const NotFound404 = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)

const NotFoundHeader = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)

const NotFoundSubHeader = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)

export function UnderConstructionContent() {
  return (
    <>
      <SvgContent>
        <ErrorSpaceManIcon/>
      </SvgContent>
      <Box
        sx={{
          textAlign: 'center',
        }}>
        <NotFound404
          variant="h1"
        >
          {t('under-construction:Under construction')}
        </NotFound404>
        <NotFoundHeader
          variant="h5"
        >
          {t('under-construction:{{website}} is under construction!', {
            website: window.location.hostname,
          })} 😭
        </NotFoundHeader>
        <NotFoundSubHeader
          variant="subtitle1"
        >
          {t('under-construction:We are working hard to bring you something awesome. Please check back later!')}
        </NotFoundSubHeader>
        <Button
          sx={{
            mt: 3,
            mb: 10,
          }}
          color="primary"
          size="large"
          variant="contained"
          href="/"
        >
          {t('under-construction:Try again')}
        </Button>
      </Box>
    </>
  )
}
