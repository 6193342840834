import React, { FC, useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'

import { getArraySplitToNChunks, useDimensionsWindow } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { KeitaroAddedCheckbox } from '../../../../shared/ui/icon-assets/keitaro-added'
import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnWrapper,
  TableValue,
  TableRow,
  TableWrapper,
} from './table'

import type { DomainsGeneratedList } from '../../../../entities/domains-generator/generator'
import type { TableDomainsProps } from '../types'

export const DomainsSelectedParam = 'domains'

export const TableDomains: FC<TableDomainsProps> = (props) => {
  const [columnsCount, setColumnsCount] = useState(1)
  const [selected, setSelected] = useState<DomainsGeneratedList[] | null>(null)
  const { screenSize } = useDimensionsWindow()
  const {
    generations,
    generateError,
    generateLoading,
    lastGenerateLoading,
    sendGenerateLoading,
    syncError,
  } = useDomainsGeneratorStore()

  const hasData = (
    !generateLoading
      && !lastGenerateLoading
      && !generateError
      && !sendGenerateLoading
      && generations?.domains
      && generations.domains?.length > 0
  )

  const domainsNChunks = useMemo(() => {
    return generations?.domains && getArraySplitToNChunks(generations.domains, columnsCount)
  }, [generations?.domains, columnsCount])

  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = String(e.currentTarget.value)
    let generationsWithSelection: DomainsGeneratedList[] | null = null

    if (!e.currentTarget.checked)
      generationsWithSelection = selected?.map(items =>
        items?.domain === value ? { ...items, selected: false } : { ...items }) ?? null

    if (e.currentTarget.checked)
      generationsWithSelection = selected?.map(items =>
        items?.domain === value ? { ...items, selected: true } : { ...items }) ?? null

    setSelected(generationsWithSelection)
  }

  useEffect(() => {
    if (screenSize.width < 500)
      setColumnsCount(1)

    if (screenSize.width >= 500 && screenSize.width < 1100)
      setColumnsCount(2)

    if (screenSize.width >= 1100 && screenSize.width < 1600)
      setColumnsCount(3)

    if (screenSize.width >= 1600)
      setColumnsCount(4)
  }, [screenSize])

  // Set initial selected ids
  useEffect(() => {
    const initialSelected = generations?.domains?.map(v =>
      v?.status !== null
        ? { ...v, selected: false }
        : { ...v },
    )

    initialSelected && setSelected(initialSelected)

  }, [generations?.domains])

  // Set formik input value
  useEffect(() => {
    selected && selected?.filter(v => v.selected)?.length > 0
      ? props.formik.setFieldValue(DomainsSelectedParam, selected)
      : props.formik.setFieldValue(DomainsSelectedParam, null)

  }, [selected])

  return <>
    <Table>
      <TableWrapper className={ 'overflow-auto' }>
        <TableBody>
          { hasData &&
              Array.from({ length: columnsCount }, (_, columnIndex) => (
                <TableColumn key={ columnIndex }>
                  { domainsNChunks &&
                        domainsNChunks[columnIndex]?.map((row: DomainsGeneratedList, rowIndex: number) => (
                          <TableColumnWrapper key={ rowIndex }>
                            <TableRow>
                              <TableCell>
                                <TableValue className={ row?.status ?? '' }>
                                  { row?.status !== 'added'
                                    ? <>
                                      <input
                                        type="checkbox"
                                        onChange={ handleOnChange }
                                        id={ row?.domain }
                                        name={ 'domains' }
                                        value={ row?.domain }
                                        disabled={ row?.status === 'added' }
                                        checked={ Boolean(selected
                                            && selected?.filter((value) =>
                                              value.domain === row?.domain
                                                && value.selected
                                                && value.status !== 'added').length > 0) }
                                      />
                                      <label
                                        htmlFor={ row?.domain }>
                                        { row?.domain }
                                      </label>
                                    </>
                                    : <>
                                      <KeitaroAddedCheckbox/>
                                      { row?.domain }
                                    </>
                                  }
                                </TableValue>
                              </TableCell>
                            </TableRow>
                          </TableColumnWrapper>
                        )) }
                </TableColumn>
              ))
          }
          {
            !generateLoading
              && !lastGenerateLoading
              && !sendGenerateLoading
              && !syncError
              && (!generations?.domains || generations?.domains?.length === 0)
              && (!generateError || generateError) && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  { t('subdomains-generator:Oops! The table is empty - no data!') }
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
              && (lastGenerateLoading || sendGenerateLoading) && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  { t('subdomains-generator:Just a minute! Table is loading/updating!') }
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData && syncError && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  {t('subdomains-generator:Uh oh! Synchronize failed! Please try again!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  </>
}
