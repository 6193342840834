import React, { useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'

import { getArraySplitToNChunks, getFormattedFullDate, useDimensionsWindow } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { useSettingsDomainsStore } from '../../../../entities/domains-generator/settings'
import { DisableDomainButton } from '../../../../features/domains-generator/settings/disable-domain'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnWrapper,
  TableRow,
  TableValue,
  TableWrapper,
  SCellTitle,
} from './table'

import type { DomainsDetails } from '../../../../entities/domains-generator/settings'

export function ColumTableBlock() {
  const [columnsCount, setColumnsCount] = useState(1)
  const { screenSize } = useDimensionsWindow()
  const {
    domainsData,
    domainsDataError,
    loading,
    syncError,
  } = useSettingsDomainsStore()

  const hasData = (
    !loading
    && !domainsDataError
    && !syncError
    && domainsData
    && Object.keys(domainsData).length > 0
  )


  useEffect(() => {
    domainsData?.domains?.sort((a, b) =>
      (a?.status && b?.status) ? 0 : (a?.status ? -1 : 1))
  }, [domainsData])

  const domainsNChunks = useMemo(() => {
    return Array.isArray(domainsData) && getArraySplitToNChunks(domainsData, columnsCount)
  }, [domainsData, columnsCount])

  useEffect(() => {
    if (screenSize.width < 1100)
      setColumnsCount(1)

    if (screenSize.width >= 1100 && screenSize.width < 1600)
      setColumnsCount(2)

    if (screenSize.width >= 1600)
      setColumnsCount(3)
  }, [screenSize])

  const truncateString = (str: any, num: number) => {
    return str.length > num ? `${str.slice(0, num)}...` : str
  }

  return (
    <Table>
      <TableWrapper className={'overflow-auto'}>
        <TableBody>
          {hasData
            && [...Array(columnsCount)]
              ?.map((_, index) =>
                <TableColumn key={index}>
                  {domainsNChunks && domainsNChunks[index]
                    ?.map((row: DomainsDetails, rowIndex: number) =>
                      <TableColumnWrapper key={rowIndex}>
                        <TableRow>
                          <TableCell>
                            <TableValue>
                              { row?.expired_date
                                ? (
                                  <Tooltip
                                    title={`${t('subdomains-generator:Expire date')}: ${
                                      getFormattedFullDate(row?.expired_date)
                                    }`}>
                                    <>
                                      {
                                        row?.name && row?.name?.length > 18
                                          ? <Tooltip arrow title={row?.name}>
                                            <SCellTitle
                                              style={ row?.expired_date && new Date() > new Date(row?.expired_date)
                                                ? { color: 'red' }
                                                : {}}>{truncateString(row?.name, 18)}</SCellTitle>
                                          </Tooltip>
                                          : (
                                            <SCellTitle
                                              style={
                                                row?.expired_date && new Date() > new Date(row?.expired_date)
                                                  ? { color: 'red' }
                                                  : {}
                                              }>{row?.name}</SCellTitle>
                                          )
                                      }
                                    </>
                                  </Tooltip>
                                )
                                : (
                                  <>
                                    {
                                      row?.name && row?.name?.length > 18
                                        ? <Tooltip arrow title={row?.name}>
                                          <SCellTitle>{truncateString(row?.name, 18)}</SCellTitle>
                                        </Tooltip>
                                        : <SCellTitle>{row?.name}</SCellTitle>
                                    }
                                  </>
                                )}
                              <DisableDomainButton
                                initialValues={{
                                  id: row?.id,
                                  domain: row?.name,
                                }}
                                disabled={row?.status}/>
                            </TableValue>
                          </TableCell>
                        </TableRow>
                      </TableColumnWrapper>,
                    )
                  }
                </TableColumn>,
              )
          }
          {
            !hasData
            && !loading
            && !syncError
            && domainsData
            && Object.keys(domainsData).length === 0 && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  {t('subdomains-generator:Oops! The table is empty - no data!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
            && !loading
            && syncError && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  {t('subdomains-generator:Uh oh! Synchronize failed! Please try again!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
            && loading && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  {t('subdomains-generator:Just a minute! Table is loading/updating!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
