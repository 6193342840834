import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

import { InputProps } from './types'

export const InputFormText = ({ label, value, onInputChange, inputWidth, error, noOptionsText }: InputProps) => {
  return (
    <Autocomplete
      freeSolo
      autoFocus={false}
      defaultValue={value ?? ''}
      value={value}
      inputValue={value ?? ''}
      clearOnBlur={true}
      open={false}
      options={[]}
      noOptionsText={noOptionsText}
      onInputChange={(_e, value) => onInputChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{ width: inputWidth ? inputWidth : '200px' }}
    />
  )
}
