import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export enum IntegrationCampaignsHistorySources {
  facebook = 'facebook',
  keitaro = 'keitaro',
}

export enum IntegrationCampaignsHistoryActions {
  added = 'added',
  deleted = 'deleted',
}

export interface ICampaignHistoryData {
  id: number;
  source: IntegrationCampaignsHistorySources;
  action: IntegrationCampaignsHistoryActions;
}

export interface ICampaignsHistoryObject {
  id: number;
  antyId: number;
  accountId: string;
  pixelId: string | null;
  integrationName: string;
  campaigns: ICampaignHistoryData[];
  created_at: Date;
}

export type IntegrationCampaignsHistoryResponse = {
  data: ICampaignsHistoryObject[]
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}
