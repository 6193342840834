import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { LightTheme } from '../../shared/themes'
import { GlobalStyles } from '../../shared/themes/global-styles'
import { useAppStore } from '../../entities/app'

const ThemeList: { [key: string]: any } = {
  LightTheme,
}

export enum CurrentThemeConfig {
  fallback = 'LightTheme'
}

const CurrentTheme = function () {
  const themeDefault = CurrentThemeConfig.fallback
  const { themeCurrent, setThemeCurrent } = useAppStore()
  themeCurrent === 'null' && setThemeCurrent(themeDefault)

  return ThemeList[themeCurrent] ?? ThemeList[themeDefault]
}

const withTheme = (component: () => React.ReactNode) => () => (
  <MuiThemeProvider theme={ CurrentTheme() }>
    <StyledThemeProvider theme={ CurrentTheme() }>
      <GlobalStyles/>
      {component()}
    </StyledThemeProvider>
  </MuiThemeProvider>
)

export default withTheme