import { UploadTemplateButton } from '../../../../features/white-generator/generator/upload-template'

import { GeneratorForm } from './generator-form'

export const GeneratorBlock = () => {
  return (
    <>
      <UploadTemplateButton/>
      <GeneratorForm/>
    </>
  )
}