import React from 'react'

import { DragProps } from './types'

import type {
  KaccCatalogSettingsAnty,
  KaccCatalogSettingsCampaigns,
  KaccCatalogSettingsFacebook,
  KaccCatalogSettingsGroup,
  KaccCatalogSettingsOffers,
} from '../../../../entities/kacc/catalog'

export const sortArrayByKey = <
    T extends KaccCatalogSettingsAnty | KaccCatalogSettingsFacebook
        | KaccCatalogSettingsCampaigns | KaccCatalogSettingsOffers
>(
    array: T[],
    key: keyof T,
  ) => array.sort((a, b) => Number(b[key]) - Number(a[key]))

export const toggleValueChecked = <
    T extends KaccCatalogSettingsAnty | KaccCatalogSettingsFacebook
        | KaccCatalogSettingsCampaigns | KaccCatalogSettingsOffers
>(
    e: React.SyntheticEvent<HTMLInputElement>,
    array: T[],
  ): T[] => {
  array = array?.map((values) =>
    values?.key === String(e.currentTarget.value)
      ? { ...values, checked: e.currentTarget.checked }
      : { ...values },
  )

  return sortArrayByKey(array, 'checked')
}

export const toggleGroupChecked = <T extends KaccCatalogSettingsGroup>(
  e: React.SyntheticEvent<HTMLInputElement>,
  array: T[],
  group: T['key'],
): T[] => array?.map((values) =>
    values?.key === String(group)
      ? { ...values, checked: e.currentTarget.checked }
      : { ...values },
  )

export const toggleGroupValueChecked = <
    T extends KaccCatalogSettingsAnty | KaccCatalogSettingsFacebook
        | KaccCatalogSettingsCampaigns | KaccCatalogSettingsOffers
>(
    e: React.SyntheticEvent<HTMLInputElement>,
    array: T[],
  ): T[] => {
  array = array?.map((values) =>
    !values?.uncheckable
      ? { ...values, checked: e.currentTarget.checked }
      : { ...values },
  )

  return sortArrayByKey(array, 'checked')
}

export const changeElementPosition = <
    T extends KaccCatalogSettingsAnty | KaccCatalogSettingsFacebook
        | KaccCatalogSettingsCampaigns | KaccCatalogSettingsOffers
>(
    array: T[],
    fromIndex: number,
    toIndex: number,
  ) => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex

    const [item] = array.splice(fromIndex, 1)
    array.splice(endIndex, 0, item)
  }

  return sortArrayByKey(array, 'checked')
}

export const DRAG_DEFAULT: DragProps = {
  start: {
    key: null,
    group: null,
  },
  end: {
    key: null,
    group: null,
  },
}
