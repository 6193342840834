import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { LOCALSTORAGE_SESSION_KEY, LOCALSTORAGE_SESSIONS_KEY } from '../../shared/configs/constants'

import { loginAction } from './api'

import type { AuthResponseData } from './types'

export interface IUserStore {
    session: AuthResponseData | null,
    setSession: (session: AuthResponseData) => void,

    sessions: AuthResponseData[] | null,
    existsInSessions: (session: AuthResponseData) => boolean,

    login: (values: object) => void,
    logout: (session: AuthResponseData | null) => void,
    loginRedirect: string,
    isLoggedIn: boolean,
}

export const useUserStore = create<IUserStore>((set, get) => ({
  // set session on load from storage or null
  session: JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSION_KEY)!) ?? null,

  // setSession logics & set isLoggedIn
  setSession: (session) => {
    let currentSessions: AuthResponseData[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSIONS_KEY)!) ?? []

    // if session existsInSessions do remove
    if (session && get().existsInSessions(session))
      currentSessions = currentSessions?.filter(item => item.id !== session.id)

    // update sessions
    currentSessions?.push(session)

    // set current session
    localStorage.setItem(LOCALSTORAGE_SESSION_KEY, JSON.stringify(session))
    set({ session: session })

    // set sessions list
    localStorage.setItem(LOCALSTORAGE_SESSIONS_KEY, JSON.stringify(currentSessions))
    set({ sessions: currentSessions })

    set({ isLoggedIn: true })
  },

  // set sessions on load from storage or null
  sessions: JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSIONS_KEY)!) ?? null,

  // check if session (user id) existsInSessions list
  existsInSessions: (session) => !!JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSIONS_KEY)!)
    ?.some((item: AuthResponseData) => item.id === session.id),

  // login logics or error
  login: async (values) => {
    try {
      const response = await loginAction(values)

      if (!axios.isAxiosError(response)) {
        const session = response?.data

        session && get().setSession(session)
      }
    } catch (error) {
      let errorText = t('auth:Unknown error')

      if (axios.isAxiosError(error)) {
        errorText = error.response?.data.message
      }

      throw errorText
    }
  },

  // logout logics & isLoggedIn
  logout: (session) => {
    let currentSessions: AuthResponseData[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSIONS_KEY)!) ?? null
    const currentSession: AuthResponseData = JSON.parse(localStorage.getItem(LOCALSTORAGE_SESSION_KEY)!) ?? null

    // if session exists sessions do remove
    if (session && get().existsInSessions(session)) {
      currentSessions = currentSessions?.filter(item => item.id !== session.id)
      localStorage.setItem(LOCALSTORAGE_SESSIONS_KEY, JSON.stringify(currentSessions))
      set({ sessions: currentSessions })

      if (currentSessions?.length === 0) localStorage.removeItem(LOCALSTORAGE_SESSIONS_KEY)
    }

    // else remove session and isLoggedIn false
    if (session?.id === currentSession?.id || !session) {
      localStorage.removeItem(LOCALSTORAGE_SESSION_KEY)
      set({ session: null })
      set({ isLoggedIn: false })
    }
  },

  // redirect to path that was before redirected to login page
  loginRedirect: '/',

  // set isLoggedIn on load from storage
  isLoggedIn: !!localStorage.getItem(LOCALSTORAGE_SESSION_KEY),
}))
