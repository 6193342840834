import React, { useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'

import { Button } from '../../../../../shared/ui/button'

import { ManageGroupsModal } from './manage-groups-modal'

export const ManageGroupsButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <ManageGroupsModal
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <SButton
        onClick={() => setModalVisible(true)}
        variant={'contained'}
        size={'small'}
        type={'button'}
      >
        {t('custom-reports:Manage groups')}
      </SButton>
    </>
  )
}

const SButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #D2FAFF;
    color: #179EAE;
    box-shadow: 0 1px 1px 0 #aab7c1;

    &:hover {
      background: #C6E6F5;
    }
  }
`
