import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { TitleBlock, BlockContent } from '../../../../shared/ui/style'
import { useSettingsDomainsStore } from '../../../../entities/domains-generator/settings'
import { DisabledFilter } from '../../../../features/domains-generator/settings/filter-disabled'
import { GroupsFilter } from '../../../../features/domains-generator/settings/filter-groups'
import { DGUpdateButton } from '../../../../features/domains-generator/settings/update-table'

import { ColumTableBlock } from './table-block'

export function DomainsBlock() {
  const {
    syncing,
    syncKeitaro,
    syncError,
    getGroupsData,
    groupsData,
  } = useSettingsDomainsStore()

  return (
    <SDomainSettingsBlock>
      <TitleBlock>{t('subdomains-generator:Domain settings')}</TitleBlock>
      <BlockContent>
        <SFilterBlock>
          <DGUpdateButton
            updating={syncing}
            updateSettings={syncKeitaro}
            updatingError={syncError}
          />
          <GroupsFilter
            getGroupsData={getGroupsData}
            groupsData={groupsData}
          />
          <DisabledFilter/>
        </SFilterBlock>
        <SContentTitle>{t('subdomains-generator:Domain list')}</SContentTitle>
        <ColumTableBlock/>
      </BlockContent>
    </SDomainSettingsBlock>
  )
}

export const SDomainSettingsBlock = styled.div`
  flex-basis: calc(100% - 40%);
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 #D7DCE2;
  border-radius: 5px;
`

export const SFilterBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
`

export const SContentTitle = styled(TitleBlock)`
  padding: 20px 16px 20px 0;
  color: #275572;
  border-bottom: none;
`
