import React, { FC, useState } from 'react'
import { t } from 'i18next'

import { Tooltip } from '../../../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../../../shared/ui/table-button-action'

import { AddEditReportModal } from './add-edit-report-modal'

import type { CustomReportsAddEditInitial } from '../types'

type EditReportButtonProps = {
  initialValues: CustomReportsAddEditInitial
}

export const EditReportButton: FC<EditReportButtonProps> = ({ initialValues }) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <AddEditReportModal
        initialValues={initialValues}
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <Tooltip
        title={t('custom-reports:Edit')}>
        <TableButtonAction
          target={'edit'}
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
