export const catalogKeysConfig = {
  'campaign_id': '100px',
  'parent_campaign_id': '150px',
  'click_id': '350px',
  'campaign_group_id': '150px',
  'offer_group_id': '150px',
  'landing_group_id': '100px',
  'landing_id': '100px',
  'offer_id': '100px',
  'parent_sub_ids': '100px',
  'sub_id': '150px',
  'affiliate_network_id': '150px',
  'ts_id': '100px',
  'stream_id': '100px',
  'ad_campaign_id': '100px',
  'datetime': '200px',
  'landing_clicked_datetime': '150px',
  'destination': '150px',
  'is_unique_stream': '150px',
  'is_unique_campaign': '150px',
  'is_unique_global': '150px',
  'is_empty_referrer': '150px',
  'landing_clicked': '150px',
  'is_lead': '150px',
  'is_sale': '150px',
  'is_rejected': '150px',
  'parent_campaign': '150px',
  'campaign': '300px',
  'campaign_group': '150px',
  'landing_group': '150px',
  'offer_group': '150px',
  'landing': '300px',
  'offer': '300px',
  'affiliate_network': '150px',
  'ts': '150px',
  'stream': '150px',
  'source': '250px',
  'x_requested_with': '150px',
  'referrer': '150px',
  'search_engine': '150px',
  'keyword': '150px',
  'visitor_code': '150px',
  'external_id': '150px',
  'creative_id': '150px',
  'year': '150px',
  'month': '150px',
  'week': '250px',
  'weekday': '150px',
  'day': '150px',
  'hour': '150px',
  'day_hour': '150px',
  'is_bot': '150px',
  'language': '150px',
  'device_type': '150px',
  'user_agent': '300px',
  'os_icon': '150px',
  'os': '150px',
  'os_version': '150px',
  'browser': '150px',
  'browser_version': '150px',
  'device_model': '150px',
  'browser_icon': '150px',
  'ip': '250px',
  'is_using_proxy': '150px',
  'connection_type': '150px',
  'operator': '150px',
  'isp': '150px',
  'sub_id_1': '200px',
  'sub_id_2': '200px',
  'sub_id_3': '200px',
  'sub_id_4': '200px',
  'sub_id_5': '200px',
  'sub_id_6': '200px',
  'sub_id_7': '200px',
  'sub_id_8': '200px',
  'sub_id_9': '200px',
  'sub_id_10': '200px',
  'sub_id_11': '200px',
  'sub_id_12': '200px',
  'sub_id_13': '200px',
  'sub_id_14': '200px',
  'sub_id_15': '200px',
  'sub_id_16': '200px',
  'sub_id_17': '200px',
  'sub_id_18': '200px',
  'sub_id_19': '200px',
  'sub_id_20': '200px',
  'sub_id_21': '200px',
  'sub_id_22': '200px',
  'sub_id_23': '200px',
  'sub_id_24': '200px',
  'sub_id_25': '200px',
  'sub_id_26': '200px',
  'sub_id_27': '200px',
  'sub_id_28': '200px',
  'sub_id_29': '200px',
  'sub_id_30': '200px',
  'country_flag': '150px',
  'country': '150px',
  'region': '150px',
  'city': '150px',
  'ip_mask1': '250px',
  'ip_mask2': '250px',
  'country_code': '150px',
  'profitability': '150px',
  'revenue': '150px',
  'profit': '150px',
  'lead_revenue': '150px',
  'sale_revenue': '150px',
  'rejected_revenue': '150px',
  'rebills': '150px',
  'cost': '150px',
  'clicks': '150px',
  'campaign_unique_clicks': '150px',
  'stream_unique_clicks': '150px',
  'global_unique_clicks': '150px',
  'uc_campaign_rate': '150px',
  'uc_stream_rate': '150px',
  'uc_global_rate': '150px',
  'bots': '150px',
  'bot_share': '150px',
  'proxies': '150px',
  'empty_referrers': '150px',
  'conversions': '150px',
  'leads': '150px',
  'sales': '150px',
  'rejected': '150px',
  'approve': '150px',
  'profit_confirmed': '150px',
  'cr': '150px',
  'crs': '150px',
  'crl': '150px',
  'roi': '150px',
  'roi_confirmed': '150px',
  'epc': '150px',
  'uepc': '150px',
  'epc_hold': '150px',
  'uepc_hold': '150px',
  'epc_confirmed': '150px',
  'uepc_confirmed': '150px',
  'cps': '150px',
  'cpl': '150px',
  'cpa': '150px',
  'cpc': '150px',
  'ucpc': '150px',
  'ecpc': '150px',
  'ecpm': '150px',
  'ecpm_confirmed': '150px',
  'ec': '150px',
  'ec_confirmed': '150px',
  'landing_clicked_period': '150px',
  'lp_views': '150px',
  'lp_clicks': '150px',
  'lp_ctr': '150px',
}
