import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Alert, CircularProgress, TextField } from '@mui/material'
import { darken } from '@mui/material/styles'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { useSettingsIntegrationsStore } from '../../../../../entities/settings/integrations'
import { changeAnty } from '../api'

import type { AntyFormValues } from '../types'

export const AntyForm = () => {
  const [ changed, setChanged ] = useState(false)
  const { anty, getIntegrations } = useSettingsIntegrationsStore()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object()
    .shape({
      token: Yup.string()
        .required(t('settings:Anty token is required!')),
    })

  const formik = useFormik<AntyFormValues>({
    enableReinitialize: true,
    initialValues: {
      token: anty?.token ?? '',
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      const { token } = values
      const sendValues = {
        ...(token && { token: token }),
      }

      changeAnty(sendValues)
        .then(() => {
          setChanged(false)
          getIntegrations()
          enqueueSnackbar(t('settings:Saved your Anty integration!'), { variant: 'success' })
        })
        .catch((error: any) => {
          enqueueSnackbar(`${
            t('settings:Unable to save your Anty integration!')
          } ${
            t(`settings:${error.response?.data?.message ?? error?.message}`)
          }`, { variant: 'error' })
        }).finally(() => {
          formikHelpers.setSubmitting(false)
        })
    },
  })

  return (
    <Form
      onChange={ () => setChanged(true) }
      onSubmit={ formik.handleSubmit }
    >
      <Title>
        { t('settings:Anty integration') }
      </Title>
      <SFields>
        <TextField
          multiline
          rows={10}
          error={ Boolean(formik.touched.submit && formik.touched.token && formik.errors.token) }
          margin="normal"
          label={ t('settings:Anty token') }
          name="token"
          onBlur={ formik.handleBlur }
          onChange={ formik.handleChange }
          size={ 'small' }
          type="text"
          value={ formik.values.token }
          variant="outlined"
        />

        { typeof anty?.status === 'boolean'
          && <SFieldStatus className={ String(anty?.status) }>
            { anty?.status
              ? <CheckCircleOutlineIcon/>
              : <ErrorOutlineIcon/>
            }
          </SFieldStatus>
        }
      </SFields>

      { formik.touched.submit && Object.keys(formik.errors).length > 0
          && (
            <div
              style={{
                marginBottom: '1rem',
                marginTop: '0.5rem',
              }}
            >
              <Alert
                severity="error"
                style={{
                  width: 'fit-content',
                }}
              >
                { formik.errors.token && formik.touched.token && <div>
                  { t(`settings:${formik.errors.token}`) } </div>
                }
              </Alert>
            </div>
          )}

      <ButtonsBlock>
        <SubmitButton
          startIcon={ formik.isSubmitting
            ? <CircularProgress size="1rem"/>
            : null }
          disabled={ formik.isSubmitting }
          type={ 'submit' }
          size={ 'small' }
          variant={ 'contained' }
        >
          { formik.isSubmitting
            ? t('settings:Saving')
            : t('settings:Save') }
        </SubmitButton>

        <CancelButton
          variant={ 'contained' }
          onClick={ (e) => {
            formik.handleReset(e)
            setChanged(false)
          }}
          type={ 'reset' }
          disabled={ formik.isSubmitting || !changed }
        >
          { t('settings:Reset') }
        </CancelButton>
      </ButtonsBlock>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  & .MuiFormControl-root {
    flex-grow: 1;
    min-width: 310px;
  }

  @media (max-width: 1100px) {
    & .MuiFormControl-root {
      width: 100%;
    }
  }
`

const Title = styled.div`
  color: #407698;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
`

const SFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  
  @media (max-width: 1100px) {
    align-items: flex-start;
    gap: inherit;
    flex-direction: column;
  }
`

const SFieldStatus = styled.div`
  align-items: flex-start;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 #AAB7C1;
  display: flex;
  flex-grow: 0;
  gap: 10px;
  margin-top: 16px;
  margin-bottom: 8px;
  max-height: 37px;
  padding: 10px;
  
  &.true {
    color: #109B87;
    background: #CBFFE4;
  }

  &.false {
    color: #F6453B;
    background: #FFE2E2;  
  }
  
  & .MuiSvgIcon-root {
    font-size: 16px;
  }
`

const ButtonsBlock = styled.div`
  display: flex;
  gap: 16px;
  margin-top: auto;
`

const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`

const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #F6453B;
    color: #ffffff;

    &:hover {
      background-color: ${ darken('#F6453B', 0.05) };
      color: ${ darken('#ffffff', 0.05) };
    }
  }
`
