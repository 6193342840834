import styled from 'styled-components'

export const UTable = styled.div`
  display: grid;
`

export const UTableHeader = styled.div`
  width: 100%;
`

export const UTableHeaderLabel = styled.div`
  align-items: center;
  display: inline-flex;
`

export const UTableBody = styled.div`
  width: 100%;
`

export const UTableWrapper = styled.div`
  display: grid;

  &.overflow-auto {
    overflow: auto hidden;

    & ${UTableBody} {
      overflow: hidden auto;
    }
  }
`

export const UTableRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const UTableCell = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-wrap: wrap;
`

export const UTableValue = styled.div`
  width: 100%;
`

export const UTableAlertMessage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
