import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { Alert, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import * as Yup from 'yup'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'
import { OptionsGroup } from '../../../../features/domains-generator/generations/select-group'
import { SelectUnselectAll } from '../../../../features/domains-generator/generations/select-unselect-all'
import { OptionsComment } from '../../../../features/domains-generator/generations/set-comment'

import { TableDomains } from './table-domains'

import type { DomainsGeneratorGroups } from '../../../../entities/domains-generator/generator'
import type { GenerationsFormValues } from '../types'

export const GenerationsForm = () => {
  const {
    sendToKeitaro,
    sendGenerateLoading,
    getLastGeneratedMain,
    generations,
    data,
  } = useDomainsGeneratorStore()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object().shape({
    domains: Yup.array()
      .of(
        Yup.object().shape({
          domain: Yup.string(),
          selected: Yup.boolean(),
          status: Yup.string().nullable(),
        }),
      )
      .nullable(),
    group_id: Yup.number().nullable().positive(t('subdomains-generator:You have an error while selecting a group!')),
    comment: Yup.string().nullable().max(254, t('subdomains-generator:Comment must be maximum 254 symbols!')),
  })

  const formik = useFormik<GenerationsFormValues>({
    initialValues: {
      domains: null,
      group: null,
      comment: null,
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { domains, group, comment } = values
      const sendValues = {
        ...(domains && { domains: domains }),
        ...(group && { group_id: group.id }),
        ...(comment && { comment: comment }),
      }

      try {
        await sendToKeitaro(sendValues)

        enqueueSnackbar(t('subdomains-generator:Subdomains were sent to Keitaro successfully!'), { variant: 'success' })

        await getLastGeneratedMain()
      } catch (error) {
        enqueueSnackbar(`${
          t('subdomains-generator:Unable to send your subdomains to Keitaro!')
        } ${
          t(`subdomains-generator:${error}`)
        }`, { variant: 'error' })
      } finally {
        formikHelpers.setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('group', data?.groups?.filter(item => item.id === generations?.group_id)[0])
  }, [data?.groups])

  useEffect(() => {
    formik.setFieldValue('comment', generations?.comment)
  }, [generations])

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Options>
        <OptionsGroup
          value={formik.values.group}
          options={data?.groups}
          handleOnChange={ (value: DomainsGeneratorGroups | null) => {
            formik.setFieldValue('group', value)
          } }
          error={ Boolean(formik.touched.group && formik.errors.group) }
        />
        <OptionsComment
          value={ formik.values.comment }
          onInputChange={ (value: string) => formik.setFieldValue('comment', value) }
          error={ Boolean(formik.touched.comment && formik.errors.comment) }
        />
        <SelectUnselectAll/>
      </Options>

      <TableDomains formik={ formik }/>

      { Boolean(
        formik.touched.submit
          && Object.keys(formik.errors).length > 0,
      ) && (
        <div
          style={{
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          <Alert
            severity="error"
            style={{
              width: 'fit-content',
            }}
          >
            { formik.errors.domains && <div>
              { t(`subdomains-generator:${formik.errors.domains}`) } </div>
            }
            { formik.errors.group && <div>
              { t(`subdomains-generator:${formik.errors.group}`) } </div>
            }
            { formik.errors.comment && <div>
              { t(`subdomains-generator:${formik.errors.comment}`) } </div>
            }
          </Alert>
        </div>
      )}

      <Button
        startIcon={ sendGenerateLoading
          ? <CircularProgress size="1rem"/>
          : null }
        disabled={ sendGenerateLoading
            || formik.values.domains === null
        }
        type={ 'submit' }
        sx={(theme) => theme.customButtons.textSizeExtraLargeButton}
        variant={ 'contained' }
      >
        { sendGenerateLoading
          ? t('subdomains-generator:Sending')
          : t('subdomains-generator:Send to Keitaro') }
      </Button>
    </form>
  )
}

const Options = styled.div`
  border-bottom: 1px dotted #B7C6D3;
  display: flex;
  gap: 16px;
  padding: 16px 0;
`
