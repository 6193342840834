import { request } from '../../../../shared/api'

import type { AxiosError, AxiosResponse } from 'axios'

export const changeKeitaro = (values: object): Promise<AxiosResponse | AxiosError> =>
  request.post('/users/edit_data_keitaro', values)

export const changeAnty = (values: object): Promise<AxiosResponse | AxiosError> =>
  request.post('/users/edit_data_dolphin', values)

export const changeFlex = (values: object): Promise<AxiosResponse | AxiosError> =>
  request.post('/users/edit_data_flex', values)
