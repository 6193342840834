import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageBlockDivider } from '../../../shared/ui/page'
import { useOfferDetailsStore } from '../../../entities/watch-cat/offer-details'
import { GoalsBlock, TitleBlock } from '../../../widgets/watch-cat/offer-details'
import { MainLayout } from '../../../widgets/layouts'

export function WatchCatInfo() {
  const params = useParams()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const { getOfferDetails, error } = useOfferDetailsStore()
  const { t } = useTranslation('watch-cat')

  useEffect(() => {
    const d = debounce(() => getOfferDetails(params.offerId as string, search), 300)
    d()
    return () => d.clear()
  }, [params, search])

  useEffect(() => {
    if (error) {
      navigate('/watch-cat')
    }
  }, [error])

  return (
    <MainLayout header={ t('Watch cat info') as string }>
      <SPage>
        <SPageBlock>
          <TitleBlock/>
          <SPageBlockDivider/>
          <GoalsBlock/>
          {/*
        <OfferHistoryBlock/>
        <OfferSimilarBlock/>
        */ }
        </SPageBlock>
      </SPage>
    </MainLayout>
  )
}

const SPage = styled(Page)``
const SPageBlock = styled(PageBlock)``
const SPageBlockDivider = styled(PageBlockDivider)``
