import styled from 'styled-components'
import { t } from 'i18next'

import { GenerationsForm } from '../../generations'

export const GenerationsBlock = () => {

  return <>
    <STitle>
      {t('white-generator:Generation results')}
    </STitle>
    <GenerationsForm/>
  </>
}

const STitle = styled.div`
  color: #407698;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
`
