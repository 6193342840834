import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

import { ModalConfirmation } from '../../../shared/ui/modal-confirmation'
import { Tooltip } from '../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../shared/ui/table-button-action'
import { deleteDomain, useDomainsWatcherStore } from '../../../entities/domains-watcher'

import type { DeleteDomainButtonProps } from './types'

export const DeleteDomainButton: FC<DeleteDomainButtonProps> = (props) => {
  const [search] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const { getDomains } = useDomainsWatcherStore()
  const [modalVisible, setModalVisible] = useState(false)

  const handleClick = async () => {
    try {
      await deleteDomain(props.initialValues.id)

      enqueueSnackbar(
        t('domains-watcher:{{name}} was deleted successfully!', { name: props.initialValues.domain }),
        { variant: 'success' },
      )

      await getDomains(search)
    } catch (error) {
      enqueueSnackbar(
        `${t('domains-watcher:{{name}} can not be deleted!', { name: props.initialValues.domain })} 
        ${t(`domains-watcher:${error}`)}`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      {modalVisible && (
        <ModalConfirmation
          isOpen
          onClose={() => setModalVisible(false)}
          buttonTitle={t('domains-watcher:Delete domain')}
          title={t('domains-watcher:Delete domain?')}
          description={t('domains-watcher:You can’t recover deleted domain')}
          handleClick={handleClick}
          cancelText={t('domains-watcher:Cancel')}
        />
      )}
      <Tooltip title={t('domains-watcher:Delete')}>
        <TableButtonAction
          target={ 'delete' }
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>
    </>
  )
}
