import { t } from 'i18next'
import { Autocomplete, InputAdornment, TextField } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PaginationOffsetParam, usePagination, useSearch } from '../../../../shared/lib'

export const IntegrationIdSearchParam = 'id'

export function IntegrationIdSearchFilter() {
  const [ search, setSearch ] = useSearchParams()
  const [ focus, setFocus ] = useState(false)
  const { searchActive } = useSearch(IntegrationIdSearchParam)
  const { offsetActive } = usePagination()

  /**
   * Handle InputChange action
   */
  const handleOnInputChange = (event: React.SyntheticEvent<Element, Event>, value: any): void => {
    if (value) search.set(IntegrationIdSearchParam, value)
    if (!value && searchActive) search.delete(IntegrationIdSearchParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  return (
    <Autocomplete
      freeSolo
      defaultValue={ searchActive ?? null }
      value={ searchActive ?? null }
      clearOnBlur={ true }
      open={ false }
      options={ [] }
      onInputChange={ handleOnInputChange }
      onFocus={ () => setFocus(true) }
      onBlur={ () => setFocus(false) }
      noOptionsText={t('watch-cat:No options')}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ t('watch-cat:Search by id') }
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={
            !searchActive && !focus
              ? {
                shrink: false,
                style: { paddingLeft: '1.5rem' },
              }
              : {}
          }
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      sx={{
        minWidth: '150px',

        '& .MuiInputAdornment-positionStart': {
          color: 'rgba(18, 42, 81, 0.60)',
          paddingLeft: '8px',
        },
        '& .MuiInputBase-root .MuiInputBase-input.MuiInputBase-inputAdornedStart': {
          paddingLeft: '0',
        },
      }}
    />
  )
}