import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Alert, Box, Button, CircularProgress, Link, TextField } from '@mui/material'
import * as Yup from 'yup'
import { t } from 'i18next'

import { capitalizeFirstLetter } from '../../../shared/lib'
import { useUserStore } from '../../../entities/user'

import type { ISignInForm } from './types'

export const initialValues: ISignInForm = {
  email: '',
  password: '',
  submit: null,
}

export function SignInForm() {
  const navigate = useNavigate()
  const { login, loginRedirect } = useUserStore()

  const yupValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth:The email provided should be a valid email address'))
      .max(255)
      .required(t('auth:The email field is required')),
    password: Yup.string()
      .max(255)
      .required(t('auth:The password field is required')),
  })

  const formikOnSubmitAction = async (values: ISignInForm, {
    setErrors,
    setStatus,
    setSubmitting,
  }: FormikHelpers<ISignInForm>) => {
    try {
      const { email, password } = values
      const sendValues = {
        ...(email && { email: email }),
        ...(password && { password: password }),
      }

      await login(sendValues)
      setStatus({ success: true })
      navigate(loginRedirect)
    } catch (error: any) {
      setErrors({ submit: Array.isArray(error) ? error?.join('|') : error })
      setStatus({ success: false })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupValidationSchema}
      onSubmit={formikOnSubmitAction}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.submit && touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            label={t('auth:Email')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.submit && touched.password && errors.password)}
            fullWidth
            margin="normal"
            label={t('auth:Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />

          <Box
            alignItems="center"
            display="flex"
            justifyContent="right"
          >
            <Link
              component={RouterLink}
              to="/recover"
              display="flex"
              alignItems="center">
              <b>{t('auth:Lost password?')}</b>
            </Link>
          </Box>

          { touched.submit && Object.keys(errors).length > 0
            && (
              <Alert severity="error">
                { errors.email && touched.email && <div>
                  { t(`auth:${errors.email}`) } </div>
                }
                { errors.password && touched.password && <div>
                  { t(`auth:${errors.password}`) } </div>
                }
                { errors.submit && touched.submit && <div
                  style={{
                    display: 'flex', flexDirection: 'column', gap: '1rem',
                  }}>
                  { errors.submit?.split('|')?.map((item: string) => (
                    <span key={item}>{t(`auth:${capitalizeFirstLetter(item)}`)}</span>
                  ))}
                </div>
                }
              </Alert>
            )}

          <Button
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('auth:Submit')}
          </Button>
        </form>
      )}
    </Formik>
  )
}
