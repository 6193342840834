import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem } from 'react-pro-sidebar'
import styled from 'styled-components'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import GroupIcon from '@mui/icons-material/Group'
import { t } from 'i18next'

import { PageBlock } from '../../../../shared/ui/page'
import { useUserStore } from '../../../../entities/user'

export function SettingsSidebar() {
  const session = useUserStore().session

  const isRouteActive = (route: string) => {
    return window.location.pathname === route
  }

  return (
    <SPageBlock>
      <SAvatarBlock>
        <SAvatarImage>
          { session?.email.slice(0, 2) }
        </SAvatarImage>
        <SAvatarTitle>
          { session?.email }
        </SAvatarTitle>
      </SAvatarBlock>
      <SMenu>
        <MenuItem
          active={ isRouteActive('/settings/integrations') }
          component={ <Link to="/settings/integrations"/> }
          icon={ <CloudSyncOutlinedIcon/> }
        >
          { t('settings:Integrations') }
        </MenuItem>
        <MenuItem
          active={ isRouteActive('/settings/password') }
          component={ <Link to="/settings/password"/> }
          icon={ <LockOutlinedIcon/> }
        >
          { t('settings:Password') }
        </MenuItem>
        <MenuItem
          active={ isRouteActive('/settings/members') }
          component={ <Link to="/settings/members"/> }
          icon={ <GroupIcon/> }
        >
          { t('settings:Manage members') }
        </MenuItem>
      </SMenu>
    </SPageBlock>
  )
}

const SAvatarBlock = styled.div`
  align-items: center;
  border-radius: 5px 5px 0 0;
  background: #E3F2F6;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 16px 0;
  justify-content: center;
  width: 100%;
`
const SAvatarImage = styled.div`
  align-items: center;
  display: flex;
  color: #ffffff;
  background-color: #0094A6;
  border: 2px solid #0094A6;
  border-radius: 100%;
  box-shadow: inset 0 0 0 3px #ffffff;
  flex-shrink: 0;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  height: 120px;
  width: 120px;
`
const SAvatarTitle = styled.div`
  color: #455A64;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
`
const SPageBlock = styled(PageBlock)`
  height: auto;
  min-width: 300px;
  max-width: 400px;
`
const SMenu = styled(Menu)`
  margin-top: 25px;
  padding: 0 20px;
  height: auto;
  width: 100%;

  & .ps-menuitem-root {
    & .ps-menu-button {
      align-items: center;
      display: flex;
      padding: 7px 5px 7px 10px;
      gap: 10px;
      height: initial;
      width: 100%;
      color: #455A64;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.ps-active,
      &.ps-active:hover {
        color: #407698;
        background-color: #f3f3f3;
        font-weight: 500;
      }
    }

    & .ps-menu-icon {
      margin-right: 0;
      min-width: initial;
      width: initial;
      height: initial;

      & .MuiSvgIcon-root {
        font-size: 18px;
      }
    }

    & .ps-menu-label {
    }
  }
`
