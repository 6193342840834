import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export enum DomainsListDataFbStatus {
    active = 'Active',
    banned = 'Banned',
}

export enum DomainsListSortKeys {
    domain = 'domain',
    source = 'source',
    expiredDate = 'expiredDate',
    statusFb = 'statusFb',
}

export enum DomainsListDataSource {
    keitaro = 'keitaro',
    manual = 'manual',
}

export enum DomainsListDataKeys {
    menu = 'menu',
    id = 'id',
    userId = 'user_id',
    domain = 'domain',
    expiredDate = 'expired_date',
    source = 'source',
    whois = 'whois',
    fbStatus = 'fb_status',
    comment = 'comment',
    disabled = 'disabled',
    createdAt = 'created_at',
    updatedAt = 'updated_at',
    deletedAt = 'deleted_at',
}

export type DomainsListData = Record<DomainsListDataKeys, string>

export type DomainsData = {
    count: number | null,
    domains: DomainsListData[],
}

export type DomainEditProps = {
    id: string;
    domain: string;
    comment: string;
}

export type DataResponse = {
    data: DomainsData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}
