import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { CircularProgress, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { ModalMarketingApiProps } from '../types'
import { useKaccAntyListStore } from '../../../../../entities/kacc/integration'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

export const AddEditFbMarketingModal = ({ initialValues, onClose, isOpen }: ModalMarketingApiProps) => {
  const { editFBMarketingApi } = useKaccAntyListStore()
  const [search] = useSearchParams()
  const [newValue, setNewValue] = useState(initialValues.newValue)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await editFBMarketingApi(initialValues.antyId, newValue, initialValues.oldValue, search)

      await setTimeout(() => onClose?.(), 500)

      enqueueSnackbar(
        t('kacc:FB marketing api was updated successfully!'),
        { variant: 'success' },
      )
    } catch (error) {
      enqueueSnackbar(
        `${t('kacc:FB marketing api was not updated!')} ${t(`kacc:${error}`)}`,
        { variant: 'error' },
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <ModalOverlayMui
      isOpen={isOpen}
      handleClose={onClose}
    >
      <SModalHeader>
        <SModalHeaderText>
          {initialValues?.antyId ? t('kacc:Edit FB marketing api') : t('kacc:Add FB marketing api')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <SModalForm>
          <SModalContent>
            <TextField
              label={t('kacc:FB api')}
              name="newValue"
              multiline
              rows={4}
              style={{ marginTop: '16px' }}
              onChange={(e) => setNewValue(e.target.value)}
              value={newValue}
            />

            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginTop: 'auto',
                gap: '15px',
              }}
            >
              <Button
                variant={'contained'}
                disabled={loading}
                type={'submit'}
                size={'medium'}
                onClick={handleSubmit}
              >
                {loading && <CircularProgress size="1rem"/>}
                {t('kacc:Submit')}
              </Button>

              <SModalCancel
                variant="outlined"
                onClick={onClose}
                size={'medium'}
                type={'button'}
                style={{ marginLeft: 'auto' }}
                disableRipple
              >
                {t('kacc:Close')}
              </SModalCancel>
            </div>
          </SModalContent>
        </SModalForm>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`

const SModalForm = styled.div`
  display: flex;
  flex-direction: column;
  //min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  //min-height: 280px;
  height: 100%;
  width: 100%;
  gap: 40px;
`

const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #F35B5B;
    color: #F35B5B;

    &:hover {
      border-color: #F35B5B;
      background-color: #FFE2E2;
    }
  }
`
