import { useTranslation } from 'react-i18next'

import { SignInForm } from '../../../widgets/auth/sign-in'
import { AuthLayout } from '../../../widgets/layouts'

export function SignIn() {
  const { t } = useTranslation('auth')

  return (
    <AuthLayout
      header={t('Sign in')}
      title={t('Sign in')}
      subtitle={
        <span>
          {t('Sign in your CpaBox account at')}
          {' '}
          <span style={{ fontWeight: 'bold' }}>{window.location.hostname}</span>
        </span>
      }
    >
      <SignInForm/>
    </AuthLayout>
  )
}
