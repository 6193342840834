import { useState } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { useDomainsGeneratorStore } from '../../../../entities/domains-generator/generator'

import { handleSelectAction } from './lib'

export const SelectUnselectAll = () => {
  const [ selected, setSelected ] = useState<boolean>(true)
  const { generations, handleGenerationsData } = useDomainsGeneratorStore()

  const handleClick = () => {
    if (selected) {
      generations && handleGenerationsData(handleSelectAction(generations, false))
      setSelected(false)
    } else {
      generations && handleGenerationsData(handleSelectAction(generations, true))
      setSelected(true)
    }
  }

  return (
    <Button
      size={ 'small' }
      sx={(theme) => theme.customButtons.noShadowButton}
      onClick={handleClick}
    >
      { selected
        ? t('subdomains-generator:Unselect all')
        : t('subdomains-generator:Select all')
      }
    </Button>
  )
}
