import styled from 'styled-components'

import { CreatedDateFilter } from '../../../../features/watch-cat/filter-created-date'
import { UpdatedDateFilter } from '../../../../features/watch-cat/filter-date-updated'
import { GeoFilter } from '../../../../features/watch-cat/filter-geo'
import { PartnerFilter } from '../../../../features/watch-cat/filter-partner'
import { ResetAllFilters } from '../../../../features/watch-cat/reset-all-filters'
import { OfferSearchFilter } from '../../../../features/watch-cat/search-offers'
import { StatusFilter } from '../../../../features/watch-cat/filter-status'
import { GoalTypeFilter } from '../../../../features/watch-cat/filter-goal-type'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <OfferSearchFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <StatusFilter/>
        <PartnerFilter/>
        <GeoFilter/>
        <GoalTypeFilter/>
        <CreatedDateFilter/>
        <UpdatedDateFilter/>
        <ResetAllFilters/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`

const SFiltersGroup = styled.div`
  display: flex;
  gap: 15px;
`
