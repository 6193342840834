import React, { useState } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'

import { AddEditDictionaryModal } from './add-edit-dictionary-modal'

export function AddDictionaryButton() {
  const [modalVisible, setModalVisible] = useState(false)

  return(
    <>
      <AddEditDictionaryModal
        onClose={ () => setModalVisible(false) }
        isOpen={modalVisible}
      />

      <Button
        onClick={ () => setModalVisible(true) }
        variant={ 'contained' }
        type={ 'button' }
      >
        { t('subdomains-generator:Add vocabulary') }
      </Button>
    </>
  )
}
