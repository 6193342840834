/**
 * Check if offset is positive or zero
 * @example getCurrentOffset(0)
 * @param {number} offset - Offset parameter is number
 * @return {number}
 */
export const getOffsetPositiveOrZero = (offset: number): number => {
  return (offset > 0) ? offset : 0
}

/**
 * Check if offset + limit is more than count
 * @example getCurrentOffsetLimit(0, 25, 100)
 * @param {number} offset - Offset parameter is number
 * @param {number} limit - Limit parameter is number
 * @param {number} count - Count parameter is number
 * @return {number}
 */
export const getCurrentOffsetLimit = (offset: number, limit: number, count: number): number => {
  return offset + limit > count
    ? count
    : offset + limit
}

/**
 * Get current page position using offset divided to limit
 * @example getCurrentPagePosition(0, 25)
 * @param offset - Offset parameter is number
 * @param limit - Limit parameter is number
 * @return {number}
 */
export const getCurrentPagePosition = (offset: number, limit: number): number => {
  return Math.ceil(offset / limit)
}

/**
 * Get new page position using limit multiplied to page
 * @example getNewPagePosition(1, 25)
 * @param page - Page parameter is number
 * @param limit - Limit parameter is number
 * @return {number}
 */
export const getNewPagePosition = (page: number, limit: number): number => {
  return limit * page
}

/**
 * Get total pages using count divided to limit
 * @example getTotalPages(0, 25)
 * @param count - Count parameter is number
 * @param limit - Limit parameter is number
 * @return {number}
 */
export const getTotalPages = (count: number, limit: number): number => {
  return Math.ceil(count / limit)
}
