import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { useDomainsGeneratorStore } from '../../../entities/domains-generator/generator'
import { GenerationsBlock } from '../../../widgets/domains-generator/generator'
import { GeneratorForm } from '../../../widgets/domains-generator/generator'
import { MainLayout } from '../../../widgets/layouts'

export function DomainsGenerator() {
  const { getMain, getLastGeneratedMain } = useDomainsGeneratorStore()
  const [search] = useSearchParams()
  const { t } = useTranslation('subdomains-generator')

  const debouncedMain = useMemo(() => {
    return debounce(() => getMain(search), 300)
  }, [getMain, search])

  const debouncedLast = useMemo(() => {
    return debounce(() => getLastGeneratedMain(), 300)
  }, [getLastGeneratedMain])

  useEffect(() => {
    debouncedMain()

    return () => {
      debouncedMain.clear()
    }
  }, [debouncedMain])

  useEffect(() => {
    debouncedLast()

    return () => {
      debouncedLast.clear()
    }
  }, [debouncedLast])

  return (
    <MainLayout header={t('Subdomains generator')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <GeneratorForm/>
          </PageContainer>
        </PageBlock>
        <PageBlock>
          <PageContainer>
            <GenerationsBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
