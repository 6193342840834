import styled from 'styled-components'

import {
  UTable,
  UTableHeader,
  UTableHeaderLabel,
  UTableBody,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../shared/ui/table'
import { TableResizable } from '../../../../shared/ui/table-resizable'

export const STableResizable = styled(TableResizable)`
  &:hover {
    cursor: pointer;
  }
`

export const TableWrapper = styled.div`
    display: grid;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        height: 59px;
    }
`

export const TableHeader = styled(UTableHeader)`
    position: sticky;
    top: 0;
    z-index: 1;
`

export const TableHeaderLabel = styled(UTableHeaderLabel)`
    display: block;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    margin: 12px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const TableBody = styled(UTableBody)`
    position: relative;
`

export const TableData = styled(UTableBody)`
    position: relative;
`

export const TableTotals = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
`

export const AntyRow = styled(UTableRow)`
    width: 100%;
`

export const FacebookRow = styled(UTableRow)`
    width: 100%;
`

export const CampaignRow = styled(UTableRow)`
    width: 100%;
`

export const OfferRow = styled(UTableRow)`
    width: 100%;
`

export const TableCell = styled(UTableCell)`
    align-items: flex-start;
    flex-grow: 0;
`

export const TableGroup = styled(UTableCell)`
    align-items: stretch;
    flex-grow: 0;
`

export const TableValue = styled(UTableValue)`
    padding: 6px 12px;
    word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)`
    height: 100%;
`

export const Table = styled(UTable)`
  & ${TableWrapper} { // Table wrapper
    border: 1px solid rgba(38, 86, 115, 0.2);
    border-radius: 4px;
  }

  & .offer_id { // Decorate any offer_id cell with sticky
    position: sticky !important;
    left: 0;
  }

  & ${TableHeader} { // Table header
    color: #32383E;

    & ${STableResizable} { // Column header label
      background-color: #E3F2F6;
      border-right: 1px solid rgba(38, 86, 115, 0.1);
      border-bottom: 1px solid rgba(38, 86, 115, 0.2);

      &:hover { // Decorate on hover
        background-color: rgb(214, 230, 236);
      }

      &:has(.asc) { // Decorate when asc
        border-bottom-color: rgba(38, 86, 115, 0.4);
        box-shadow: rgba(38, 86, 115, 0.4) 0px -3px 0px 0px inset;
      }

      &:has(.desc) { // Decorate when desc
        border-top-color: rgba(38, 86, 115, 0.4);
        box-shadow: rgba(38, 86, 115, 0.4) 0px 3px 0px 0px inset;
      }
    }

    & .offer_id { // Decorate offer_id cell for sticky
      z-index: 1;
      border-left: 1px solid rgba(38, 86, 115, 0.15);
      border-right: 2px solid rgba(38, 86, 115, 0.15);
    }
  }

  & ${TableData} { // Table data 

    & ${TableCell} { // Decorate all cells
      border-right: 1px solid rgba(38, 86, 115, 0.1);

      &.offer_id { // Decorate offer_id cell for sticky
        border-left: 1px solid rgba(38, 86, 115, 0.15);
        border-right: 2px solid rgba(38, 86, 115, 0.15);
      }
    }

    & ${AntyRow} { // Any anty row

      & ${TableCell} { // Decorate all cells
        border-bottom: 1px solid rgba(38, 86, 115, 0.2);
      }

      &:nth-child(odd) { // Anty row is odd

        & ${TableCell} { // Decorate all cells
          background-color: #ffffff;
        }
      }

      &:nth-child(even) { // Anty row is even

        & ${TableCell} { // Decorate all cells
          background-color: #fbfbfb;
        }
      }

      & ${OfferRow}:hover { // Decorate offer row hover action

        & ${TableCell} { // Decorate all cells
          background-color: #edf4f6;
        }
      }
    }
  }

  & ${TableTotals} { // Totals
    color: #32383E;

    & ${TableCell} { // Decorate all cells
      background-color: #E3F2F6;
      border-top: 1px solid rgba(38, 86, 115, 0.2);
      border-right: 1px solid rgba(38, 86, 115, 0.1);

      &.offer_id { // Decorate offer_id cell for sticky
        border-left: 1px solid rgba(38, 86, 115, 0.15);
        border-right: 2px solid rgba(38, 86, 115, 0.15);
      }
    }
  }
`
