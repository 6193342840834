import React, { useState } from 'react'
import styled from 'styled-components'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { Tooltip } from '../../../../../shared/ui/tooltip'

import { HistoryModal } from './history-modal'

export function WatchHistoryButton() {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      { modalVisible && (
        <HistoryModal
          isOpen={modalVisible}
          onClose={ () => setModalVisible(false) }
        />
      ) }

      <Tooltip title={ t('kacc:Watch integrations history') }>
        <SButton
          onClick={ () => setModalVisible(true) }
          variant={ 'contained' }
          size={ 'small' }
          type={ 'button' }
        >
          <ListAltIcon/>
        </SButton>
      </Tooltip>
    </>
  )
}

const SButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #DDF4FF;
    color: #51ADE7;
    box-shadow: 0 1px 1px 0 rgba(39, 85, 114, 0.23);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    padding: 9px 10px;
    width: 40px;
    height: 40px;

    &:hover {
      background: #C6E6F5;

    }
  }

  & .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
`
