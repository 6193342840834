import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { useFilter } from '../../../shared/lib'
import { useWatchCatCatalogStore } from '../../../entities/watch-cat/catalog'

export const PartnerFilterParam = 'goal_type'

export function GoalTypeFilter() {
  const { filterActive } = useFilter(PartnerFilterParam)
  const [search, setSearch] = useSearchParams()
  const { goalsTypes } = useWatchCatCatalogStore()

  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) {
      search.set(PartnerFilterParam, value as string)
    } else {
      search.delete(PartnerFilterParam)
    }

    setSearch(search, { replace: true })
  }

  useEffect(() => {
    const filter = search.get(PartnerFilterParam)
    if (filter && goalsTypes?.length && !goalsTypes?.some(value => value === filterActive)) {
      search.delete(PartnerFilterParam)
      setSearch(search, { replace: true })
    }
  }, [filterActive, goalsTypes])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={ <KeyboardArrowDownIcon/> }
      value={ filterActive ?? null }
      defaultValue={ filterActive ?? null }
      onChange={ handleOnChange }
      options={ goalsTypes ?? [] }
      openOnFocus={ true }
      noOptionsText={ t('watch-cat:No options') }
      getOptionLabel={ (option) => option }
      isOptionEqualToValue={ (option, value) => option === value }
      renderOption={ (props, option) => (
        (option !== '' && <Box
          component="li"
          value={ option }
          { ...props }
          key={ Math.random() }
        >
          { t(`watch-cat:${ option }`) }
        </Box>)
      ) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ t('watch-cat:Goal type') }
          inputProps={ {
            ...params.inputProps,
          } }
        />
      ) }
      slotProps={ {
        clearIndicator: {
          tabIndex: 0,
        },
      } }
    />
  )
}
