import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export type DomainsGeneratorDomains = {
    id: number,
    name: string,
    comment: string,
    campaigns_count: number,
    group_id: number,
    total: number,
    period: number,
    last_use: number,
    selected: boolean,
    status: boolean,
    expired_date: string,
}

export type DomainsGeneratorDictionaries = {
    id: number,
    name: string,
    selected: boolean
}

export type DomainsGeneratorPeriod = number | null

export type DomainsGeneratorGroups = {
    id: number,
    name: string,
    selected: boolean
}

export type DomainsGeneratorData = {
    domains: DomainsGeneratorDomains[],
    dicts: DomainsGeneratorDictionaries[],
    groups: DomainsGeneratorGroups[],
    period: DomainsGeneratorPeriod,
    count: number,
}

export type DomainsGeneratorResponse = {
    data: DomainsGeneratorData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}

export type DomainsGeneratedList = {
    domain: string,
    selected: boolean,
    status: string | 'keitaro' | 'added' | null
}

export type DomainsGeneratedData = {
    domains: DomainsGeneratedList[],
    group_id: number,
    comment: string
}

export type DomainsGeneratedResponse = {
    data: DomainsGeneratedData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}

export enum PeriodFilterType {
    today = 'today',
    yesterday = 'yesterday',
    week = 'week',
    last_week = 'last week',
    month = 'month',
    last_month = 'last month',
}
