import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { Alert, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { useWhiteGeneratorStore } from '../../../../../entities/white-generator/generator'
import { ModalProps, UploadTemplateProps } from '../types'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

import { InputFileUpload } from './input-file-upload'

export const UploadTemplateModal = ({ onClose, isOpen }: ModalProps) => {
  const [loading, setLoading] = useState(false)
  const { uploadTemplate, getGeneratorData } = useWhiteGeneratorStore()

  const MAX_FILE_SIZE = 102400 //100KB

  const yupValidationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255),
    file: Yup.mixed<FileList>()
      .required(t('white-generator:Required to upload zip file'))
      .test('is-valid-size', t('white-generator:Max allowed size is 100KB'),
        files =>
          !files || // Check if `files` is defined
          files.length === 0 || // Check if `files` is not an empty list
          Array.from(files).every(file => file.size <= MAX_FILE_SIZE)),
  })

  const formik = useFormik<UploadTemplateProps>({
    initialValues: {
      title: '',
      file: null,
      submit: null,
    },
    validationSchema: yupValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { title, file } = values

      try {
        setLoading(true)
        await uploadTemplate(title, file)

        await getGeneratorData()

        setTimeout(() => onClose?.(), 500)

        enqueueSnackbar(t('white-generator:Template was uploaded successfully!'), { variant: 'success' })
      } catch (error) {
        enqueueSnackbar(`${
          t('white-generator:Something went wrong!')
        } ${
          t(`white-generator:${error}`)
        }`, { variant: 'error' })
      } finally {
        setLoading(false)
        formikHelpers.setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm()
    }
  }, [isOpen])

  return (
    <ModalOverlayMui
      isOpen={isOpen}
      handleClose={onClose}
    >
      <SModalHeader>
        <SModalHeaderText>
          {t('white-generator:Upload template')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <SModalForm
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <SModalContent>
            <TextField
              sx={{
                height: '40px',
                '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
                '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
              }}
              error={Boolean(formik.touched.title && formik.errors.title)}
              fullWidth
              margin="normal"
              size={'small'}
              autoFocus
              label={t('white-generator:Type name')}
              name="title"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.title || ''}
            />

            <InputFileUpload
              error={Boolean(formik.touched.file && formik.errors.file)}
              value={formik.values.file?.name || ''}
              onChange={(file: File | null) => formik.setFieldValue('file', file)}
            />

            {Boolean(
              formik.touched.submit
              && Object.keys(formik.errors).length > 0,
            ) && (
              <div
                style={{
                  marginBottom: '1rem',
                  marginTop: '0.5rem',
                }}
              >
                <Alert
                  severity="error"
                  style={{
                    width: 'fit-content',
                  }}
                >
                  {formik.errors.title && <div>
                    {t(`white-generator:${formik.errors.title}`)} </div>
                  }
                  {formik.errors.file && <div>
                    {t(`white-generator:${formik.errors.file}`)} </div>
                  }
                </Alert>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginTop: 'auto',
                gap: '15px',
              }}
            >
              <Button
                variant={'contained'}
                type={'submit'}
                size={'medium'}
                disabled={loading || formik.isSubmitting}
              >
                {t('white-generator:Submit')}
              </Button>

              <SModalCancel
                variant="outlined"
                onClick={onClose}
                size={'medium'}
                type={'button'}
                style={{ marginLeft: 'auto' }}
                disableRipple
              >
                {t('white-generator:Close')}
              </SModalCancel>
            </div>
          </SModalContent>
        </SModalForm>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`

const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`

const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #F35B5B;
    color: #F35B5B;

    &:hover {
      border-color: #F35B5B;
      background-color: #FFE2E2;
    }
  }
`
