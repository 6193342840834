import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getOfferInfo } from './api'

import type { OfferDetailsData } from './types'

export interface IOfferDetailsStore {
  offerDetails: OfferDetailsData | null,
  loading: boolean,
  error: string | null,
  getOfferDetails: (id: string | number, params?: URLSearchParams) => void,
}

export const useOfferDetailsStore = create<IOfferDetailsStore>((set) => ({
  offerDetails: null,
  loading: false,
  error: null,
  getOfferDetails: async (id: string | number, params?: URLSearchParams) => {
    try {
      set({ loading: true })
      set({ error: null })
      // set({ offerDetails: null })

      const response = await getOfferInfo(id, params)
      if (!axios.isAxiosError(response)) {
        set({ offerDetails: response.data })
      }
    } catch (error) {
      let errorText = t('watch-cat:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
