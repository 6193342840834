import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'debounce'
import axios from 'axios'

import { recoverTokenVerify } from '../../../entities/user'
import { AuthLayout } from '../../../widgets/layouts'
import { RecoverTokenForm } from '../../../widgets/auth/recover-token'
import { RecoverTokenInvalid } from '../../../widgets/auth/recover-token-invalid'

export function RecoverToken() {
  const params = useParams()
  const [verify, setVerify] = useState<null | boolean>(null)
  const { t } = useTranslation('auth')

  const debouncedVerifyToken = useMemo(() => {
    return debounce(async () => {
      try {
        const result = await recoverTokenVerify(String(params.token))
        setVerify(!axios.isAxiosError(result))
      } catch (error) {
        setVerify(false)
      }
    }, 300)
  }, [params.token])

  useEffect(() => {
    debouncedVerifyToken()

    return () => {
      debouncedVerifyToken.clear()
    }
  }, [debouncedVerifyToken])

  return (
    <>
      { typeof verify === 'boolean' && verify &&
          (
            <AuthLayout
              header={t('Recover password')}
              title={t('Recover password')}
              subtitle={
                <span>
                  {t('Set new password for your CpaBox account at')}
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>{window.location.hostname}</span>
                </span>
              }
            >
              <RecoverTokenForm/>
            </AuthLayout>
          )
      }
      { typeof verify === 'boolean' && !verify &&
          (
            <AuthLayout
              header={t('Recover password')}
              hasLogo={false}
            >
              <RecoverTokenInvalid/>
            </AuthLayout>
          )
      }
    </>
  )
}
