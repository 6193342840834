import styled from 'styled-components'

export const SModalHeader = styled.div`
  background: #D32B30;
  height: 15px;
`

export const SModalBody = styled.div`
  height: 100%;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  min-height: 90px;
  height: 100%;
  width: 100%;
  border-bottom: 1px dashed #B7C6D3;
`

export const SIconWrapper = styled.div`
  font-size: 53px;
  display: flex;
  text-align: center;
  color: #CD3D40;
`

export const SMessageWrapper = styled.div`
  text-align: left;
  letter-spacing: 0.38px;
  color: #CD3D40;
`

export const SMessageTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 7px;
`

export const SMessageDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`

export const SModalButtonWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  gap: 10px;
`