import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { useSettingsDomainsStore } from '../../../../entities/domains-generator/settings'

import type { DisableDomainButtonProps } from './types'

export const DisableDomainButton: FC<DisableDomainButtonProps> = (props) => {
  const [search] = useSearchParams()
  const { enqueueSnackbar } = useSnackbar()
  const { getDomainsData, disableDomain } = useSettingsDomainsStore()


  const handleClick = async () => {
    disableDomain(props?.initialValues?.id).then(() => {
      if(props.disabled) {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} was disabled successfully!', { name: props.initialValues.domain }),
          { variant: 'success' })
      } else {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} was enabled successfully!', { name: props.initialValues.domain }),
          { variant: 'success' })
      }

      getDomainsData(search)
    }).catch((error) => {

      if(props.disabled) {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} can not be disabled!', { name: props.initialValues.domain }) + t(error),
          { variant: 'success' })
      } else {
        enqueueSnackbar(
          t('subdomains-generator:{{name}} can not be enabled!', { name: props.initialValues.domain }) + t(error),
          { variant: 'success' })
      }
    })
  }

  return (
    <SButton
      type={'button'} $buttonType={props.disabled}
      onClick={handleClick}>
      {props.disabled ? t('subdomains-generator:Disable') : t('subdomains-generator:Enable')}
    </SButton>
  )
}

const SButton = styled(Button)<{ $buttonType?: boolean }>`
  flex-shrink: 0;

  &.MuiButtonBase-root {
    box-shadow: 0 1px 1px 0 #AAB7C1;
    color: ${({ $buttonType }) => $buttonType ? '#F6453B' : '#A4820F'};
    background: ${({ $buttonType }) => $buttonType ? '#FFE2E2' : '#FFEF9D'};

    font-size: 12px;
    line-height: 16px;
    padding: 4px 5px;
    height: 24px;

    &:hover {
      color: #ffffff;
      background: ${({ $buttonType }) => $buttonType ? '#F6453B' : '#C7AB17'};
    }
  }
`
