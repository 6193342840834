import React from 'react'
import { useSnackbar } from 'notistack'
import { useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Alert, CircularProgress, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { darken } from '@mui/material/styles'
import styled from 'styled-components'
import * as Yup from 'yup'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'
import { addDomain, editDomainComment, useDomainsWatcherStore } from '../../../../entities/domains-watcher'
import { ModalOverlayMui } from '../../../../shared/ui/modal-overlay-mui'

import type { AddEditDomainModalProps, DomainInitialProps } from '../types'

export const AddEditDomainModal = ({ initialValues, onClose, isOpen }: AddEditDomainModalProps) => {
  const { getDomains } = useDomainsWatcherStore()
  const { enqueueSnackbar } = useSnackbar()
  const [search] = useSearchParams()

  const formikInitialValues = {
    id: initialValues?.id ?? '',
    domain: initialValues?.domain ?? '',
    comment: initialValues?.comment ?? '',
    submit: null,
  }

  const yupValidationSchema = Yup.object().shape({
    domain: Yup.string()
      .max(255)
      .required(t('domains-watcher:The domain name field is required')),
    comment: Yup.string().max(255, t('domains-watcher:Comment must be at most 255 characters')),
  })

  const onSubmitHandler = async (
    values: DomainInitialProps,
    { setStatus, setSubmitting }: FormikHelpers<DomainInitialProps>,
  ) => {
    try {
      !initialValues?.id
        ? await addDomain(values.domain, values.comment)
        : await editDomainComment(values.id, values.comment)

      await getDomains(search)

      await setTimeout(() => onClose?.(), 500)

      if (!initialValues?.id) {
        enqueueSnackbar(
          t('domains-watcher:{{name}} domain was added successfully!', { name: values.domain }),
          { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('domains-watcher:{{name}} domain was edited successfully!', { name: values.domain }),
          { variant: 'success' },
        )
      }
      setStatus({ success: true })
    } catch (error: any) {
      setStatus({ success: false })

      if (!initialValues?.id) {
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} was not added!', { name: values.domain })} 
            ${t(`domains-watcher:${error.response?.data?.message ?? error?.message}`)}`,
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} was not edited!', { name: values.domain })} 
            ${t(`domains-watcher:${error.response?.data?.message ?? error?.message}`)}`,
          { variant: 'error' },
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {!initialValues?.id ? t('domains-watcher:Add custom domain') : t('domains-watcher:Edit custom domain')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={yupValidationSchema}
            onSubmit={onSubmitHandler}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <SModalForm
                noValidate
                onSubmit={handleSubmit}
              >

                <TextField
                  sx={{
                    height: '40px',
                    '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
                    '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                    '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                    '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                  }}
                  size={'small'}
                  aria-readonly={true}
                  disabled={!!values.id}
                  error={Boolean(touched.submit && touched.domain && errors.domain)}
                  fullWidth
                  margin="normal"
                  autoFocus
                  label={t('domains-watcher:Domain name')}
                  name="domain"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="domain"
                  value={values.domain.trim()}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.submit && touched.comment && errors.comment)}
                  fullWidth
                  margin="normal"
                  label={t('domains-watcher:Comment')}
                  name="comment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="comment"
                  value={values.comment}
                  variant="outlined"
                />
                <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
                  {touched.submit && Object.keys(errors).length > 0
                    && (
                      <Alert severity="error">
                        {errors.domain && <div> {t(String(errors.domain))} </div>}
                        {errors.comment && <div> {t(String(errors.comment))} </div>}
                      </Alert>)
                  }
                </div>

                <div
                  style={
                    {
                      display: 'flex',
                      marginLeft: 'auto',
                      marginTop: 'auto',
                      gap: '15px',
                    }
                  }
                >
                  <Button
                    variant={'contained'}
                    disabled={isSubmitting}
                    type={'submit'}
                    size={'medium'}
                    onClick={() => handleSubmit}
                  >
                    {isSubmitting
                      ? <CircularProgress size="1rem"/>
                      : null}
                    {t('domains-watcher:Submit')}
                  </Button>
                  <SModalCancel
                    variant={'outlined'}
                    onClick={onClose}
                    size={'medium'}
                    type={'button'}
                    style={{ marginLeft: 'auto' }}
                  >
                    {t('domains-watcher:Cancel')}
                  </SModalCancel>
                </div>
              </SModalForm>
            )}
          </Formik>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`
const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`
// .attrs({ as: 'a' })
const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #FF6961;
    color: #FF6961;

    &:hover {
      border-color: ${darken('#FF6961', 0.05)};
      color: ${darken('#FF6961', 0.05)};
    }
  }
`
