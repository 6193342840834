import React, { useState } from 'react'
import styled from 'styled-components'
import { darken } from '@mui/material/styles'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'

import { UploadTemplateModal } from './upload-template-modal'

export const UploadTemplateButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  const initialValues = {
    title: null,
    file: null,
    submit: null,
  }

  return (
    <>
      <UploadTemplateModal
        onClose={() => setModalVisible(false)}
        initialValues={initialValues}
        isOpen={modalVisible}
      />

      <Button
        disabled={false}
        onClick={() => setModalVisible(true)}
        variant={'contained'}
        color={'warning'}
        type={'reset'}
      >
        {t('white-generator:Upload template')}
      </Button>
    </>
  )
}