import { t } from 'i18next'
import React, { useRef } from 'react'

import { getPrettyDate, useDimensions } from '../../../../shared/lib'
import { useCampaignListStore } from '../../../../entities/kacc/campaign-list'
import { Tooltip } from '../../../../shared/ui/tooltip'
import { SCellTitle, TableAlertMessage } from '../../integrations/ui/table'
import { AlertMessage } from '../../../../shared/ui/alert-message'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
  TableValue,
} from './table'

export function TableBlock() {
  const { campaignList, loading, error } = useCampaignListStore()

  const hasData = campaignList && campaignList?.length > 0

  // table dimensions
  const offersHeaderRef = useRef(null)
  const offersHeader = useDimensions(offersHeaderRef)
  const minWidth = offersHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      offersHeader.offsetHeight + offersHeader.offsetTop + 250
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      offersHeader.offsetTop + 250
    }px)`
    : ''

  const truncateString = (str: string, num: number): string => {
    if (!str) return ''
    return str.length > num ? `${str.slice(0, num)}...` : str
  }

  return (
    <Table>
      {!loading && (
        <TableWrapper className="overflow-auto" style={{ height: maxHeight }}>
          {hasData && (
            <TableHeader>
              <TableRow ref={offersHeaderRef}>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Name')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Domain')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Alias')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Group Id')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Integrations')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:State')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Created at')}
                  </TableHeaderLabel>
                </TableCell>
                <TableCell>
                  <TableHeaderLabel className={'disabled'}>
                    {t('kacc:Updated at')}
                  </TableHeaderLabel>
                </TableCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody style={{ height: minHeight }}>
            {hasData
              && campaignList?.map((campaign, index) => (
                <div
                  key={index + 1}
                  style={{ minWidth: minWidth }}
                >
                  <TableRow className="aligh-baseline">
                    <TableCell>
                      <TableValue>
                        {campaign.name}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {campaign.domain}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {campaign.alias}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {campaign.group_id}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <Tooltip arrow title={campaign.integrations.join(', ') || ''}>
                        <SCellTitle>
                          {truncateString(campaign.integrations.join(', '), 25)}
                        </SCellTitle>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {campaign.state}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {getPrettyDate(campaign.created_at.toLocaleString(), true)}
                      </TableValue>
                    </TableCell>
                    <TableCell>
                      <TableValue>
                        {getPrettyDate(campaign.updated_at.toLocaleString(), true)}
                      </TableValue>
                    </TableCell>
                  </TableRow>
                </div>
              ))}
          </TableBody>
        </TableWrapper>
      )}
      {
        !error
        && !loading
        && campaignList?.length === 0 && (
          <TableAlertMessage>
            <AlertMessage severity="warning">
              {t('kacc:Oops! The table is - no data!')}
            </AlertMessage>
          </TableAlertMessage>
        )
      }
      {
        error
        && !loading && (
          <TableAlertMessage>
            <AlertMessage severity="error">
              {t(error)}
            </AlertMessage>
          </TableAlertMessage>
        )
      }
      {
        !error
        && loading && (
          <TableAlertMessage>
            <AlertMessage severity="info">
              {t('kacc:Just a minute! Table is loading/updating!')}
            </AlertMessage>
          </TableAlertMessage>
        )
      }
    </Table>
  )
}