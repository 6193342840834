import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export type IIntegrationCampaign = {
  id: number,
  name: string,
  link: string,
  status: string
}

export type KaccAntyIntegrationData = {
  accountId: string,
  name: string | null,
  state: IntegrationStates,
  status: IntegrationStatuses,
  capiValue: string | null,
  pixel: string | null,
  campaigns: IIntegrationCampaign[],
}

export type KaccAntyData = {
  id: number,
  name: string,
  mapiValue: string,
  notes: string,
  userName: string,
  status: string,
  actIds: string[],
  integrations: KaccAntyIntegrationData[],
  tags: string[]
}

export type IntegrationsListResponse = {
  data: KaccAntyData[]
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}

export enum AntyListStatuses {
  integrated = 'integrated',
  notIntegrated = 'not_integrated',
  error = 'error',
  inactive = 'inactive',
}

export type IntegrationHistoryResponse = {
  data: IntegrationHistoryData[]
  status: number
  statusText: string
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  config: InternalAxiosRequestConfig<any>
  request?: any
}

export type IntegrationHistoryData = {
  antyId: number
  accountId: string
  pixelId: number
  created_at: string
  status: HistoryStatuses
}

export enum HistoryStatuses {
  created = 'created',
  updated = 'updated'
}

export enum IntegrationStatuses {
  success = 'success',
  error = 'error',
  notExist = 'notExist',
}

export enum IntegrationStates {
  active = 'active',
  disabled = 'disabled',
  notExist = 'notExist',
}
