import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import debounce from 'debounce'
import { useParams } from 'react-router-dom'
import axios from 'axios'

import { AuthLayout } from '../../../widgets/layouts'
import { RegisterTokenForm } from '../../../widgets/auth/register-token'
import { registerTokenVerify } from '../../../entities/user'
import { RegisterTokenInvalid } from '../../../widgets/auth/register-token-invalid'


export function RegisterToken() {
  const params = useParams()
  const [verify, setVerify] = useState<null | boolean>(null)
  const { t } = useTranslation('auth')

  const debouncedVerifyToken = useMemo(() => {
    return debounce(async () => {
      try {
        const result = await registerTokenVerify(String(params.token))
        setVerify(!axios.isAxiosError(result))
      } catch (error) {
        setVerify(false)
      }
    }, 300)
  }, [params.token])

  useEffect(() => {
    debouncedVerifyToken()

    return () => {
      debouncedVerifyToken.clear()
    }
  }, [debouncedVerifyToken])

  return (
    <>
      {typeof verify === 'boolean' && verify && (
        <AuthLayout
          header={t('Register password')}
          title={t('Register password')}
          subtitle={
            <span>
              {t('Set new password for your CpaBox account at')}
              {' '}
              <span style={{ fontWeight: 'bold' }}>{window.location.hostname}</span>
            </span>
          }
        >
          <RegisterTokenForm/>
        </AuthLayout>
      )}
      {typeof verify === 'boolean' && !verify && (
        <AuthLayout
          header={t('Recover password')}
          hasLogo={false}
        >
          <RegisterTokenInvalid/>
        </AuthLayout>
      )}
    </>
  )
}
