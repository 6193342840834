import styled from 'styled-components'
import { t } from 'i18next'
import React from 'react'

import { OfferDetailsStatusFilter } from '../../../../features/watch-cat/offer-details-filter-status'

import { GoalsTable } from './goals-table'

export function GoalsBlock() {

  return (
    <SOfferGoalsBlock>
      <SOfferGoalsTitle>
        { t('watch-cat:Offer goals') }
      </SOfferGoalsTitle>

      <SFiltersBlock>
        <OfferDetailsStatusFilter/>
      </SFiltersBlock>

      <GoalsTable/>
    </SOfferGoalsBlock>
  )
}

const SOfferGoalsBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
`

const SFiltersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`

const SOfferGoalsTitle = styled.div`
  margin-bottom: 16px;
  color: #455A64;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.38px;
  text-transform: uppercase;
`
