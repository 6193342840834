import styled from 'styled-components'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
`
