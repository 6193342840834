import React from 'react'
import { useSnackbar } from 'notistack'
import { useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { t } from 'i18next'
import * as Yup from 'yup'
import styled from 'styled-components'
import {
  Autocomplete,
  Alert,
  Box,
  CircularProgress,
  TextField,
  TextareaAutosize,
  darken,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Button } from '../../../../../shared/ui/button'
import {
  useCustomReportsCatalogStore,
  addReport,
  deleteReport,
  updateReport,
} from '../../../../../entities/custom-reports/catalog'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

import type { CustomReportsAddEditInitial, CustomReportsEditButtonProps } from '../types'

export const AddEditReportModal = ({ initialValues, onClose, isOpen }: CustomReportsEditButtonProps) => {
  const { getCatalog, groups, getPayloadFromLink } = useCustomReportsCatalogStore()
  const { enqueueSnackbar } = useSnackbar()
  const [search] = useSearchParams()

  const formikInitialValues: CustomReportsAddEditInitial = {
    ...(initialValues?.id && { id: initialValues?.id }),
    name: initialValues?.name ?? '',
    comment: initialValues?.comment ?? '',
    url: initialValues?.url ?? '',
    payload: initialValues?.payload ?? '',
    disabled: initialValues?.disabled ?? false,
    groups: initialValues?.groups ?? [],
    submit: null,
  }

  const initialGroups = initialValues?.groups
    && groups?.filter(
      (item) => item
        && String(initialValues?.groups)
          .split(',')
          .includes(String(item.id)),
    )

  const yupValidationSchema = Yup.object().shape({
    name: Yup.string()
      .max(255)
      .required(t('custom-reports:The name field is required')),
    comment: Yup.string().max(255, t('custom-reports:Comment must be at most 255 characters')),
  })

  const onSubmitHandler = async (
    values: CustomReportsAddEditInitial,
    { setStatus, setSubmitting }: FormikHelpers<CustomReportsAddEditInitial>,
  ) => {
    try {
      // Get all disabled groups for this report
      const disabledGroups = initialValues?.groups?.filter(group => group?.disabled) ?? []

      // Save groups that were disabled to be able to re-enable them
      values = {
        ...values,
        groups: [
          ...values?.groups?.filter(group => !group?.disabled) ?? [], // Filter all disabled
          ...disabledGroups, // Add the disabled groups
        ],
      }

      !initialValues?.id
        ? await addReport(values)
        : await updateReport(values)

      await getCatalog(search)

      setTimeout(() => onClose?.(), 500)

      if (!initialValues?.id) {
        enqueueSnackbar(
          t('custom-reports:{{name}} was added successfully!', { name: values.name }), { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('custom-reports:{{name}} was edited successfully!', { name: values.name }), { variant: 'success' },
        )
      }
      setStatus({ success: true })
    } catch (error: any) {
      setStatus({ success: false })

      if (!initialValues?.id) {
        enqueueSnackbar(
          t('custom-reports:{{name}} was not added!', { name: values.name }) +
          t(`custom-reports:${error.response?.data?.message ?? error?.message}`),
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          t('custom-reports:{{name}} was not edited!', { name: values.name }) +
          t(`custom-reports:${error.response?.data?.message ?? error?.message}`),
          { variant: 'error' },
        )
      }
    } finally {
      setSubmitting(false)
    }
  }

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: CustomReportsAddEditInitial['id'],
  ) => {
    e.preventDefault()

    const params = new URLSearchParams()
    params.append('id', String(id))

    await deleteReport(params)

    enqueueSnackbar(
      t('custom-reports:{{name}} was deleted successfully!', { name: initialValues?.name }),
      { variant: 'success' },
    )

    onClose?.()

    await getCatalog(search)
  }

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {!initialValues?.id && t('custom-reports:Add custom report')}
            {initialValues?.id && t('custom-reports:Edit custom report')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <Formik
            initialValues={formikInitialValues}
            validationSchema={yupValidationSchema}
            onSubmit={onSubmitHandler}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <SModalForm
                noValidate
                onSubmit={handleSubmit}
              >

                <TextField
                  sx={{
                    height: '40px',
                    '.MuiInputBase-root': { height: '40px' },
                    '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                    '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                    '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                  }}
                  error={Boolean(touched.submit && touched.name && errors.name)}
                  fullWidth
                  margin="normal"
                  autoFocus
                  size={'small'}
                  label={t('custom-reports:Name')}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name?.trimStart()}
                  variant="outlined"
                />

                <TextField
                  sx={{
                    height: '40px',
                    '.MuiInputBase-root': { height: '40px' },
                    '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                    '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                    '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                  }}
                  error={Boolean(touched.submit && touched.comment && errors.comment)}
                  fullWidth
                  margin="normal"
                  size={'small'}
                  label={t('custom-reports:Comment')}
                  name="comment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.comment}
                  variant="outlined"
                />

                <Autocomplete
                  autoHighlight
                  disablePortal
                  multiple
                  limitTags={3}
                  popupIcon={<KeyboardArrowDownIcon/>}
                  onChange={(_e, value) => setFieldValue('groups', value ?? null)}
                  options={groups?.filter(group => group?.disabled === false) ?? []}
                  openOnFocus={true}
                  noOptionsText={t('custom-reports:No options')}
                  value={values.groups?.filter(group => !group.disabled)}
                  defaultValue={initialGroups ? initialGroups : []}
                  getOptionLabel={option => option?.name ?? ''}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option) => (
                    (option && <Box
                      component="li"
                      value={option?.id}
                      {...props}
                    >
                      {option?.name}
                    </Box>)
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name="groups"
                      margin="normal"
                      label={t('custom-reports:Groups')}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  slotProps={{
                    clearIndicator: {
                      tabIndex: 0,
                    },
                  }}
                />

                <div>
                  <TextField
                    sx={{
                      height: '40px',
                      '.MuiInputBase-root': { height: '40px' },
                      '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                      '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                      '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                    }}
                    error={Boolean(touched.submit && touched.url && errors.url)}
                    fullWidth
                    size={'small'}
                    margin="normal"
                    label={t('custom-reports:Keitaro link')}
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.url}
                    variant="outlined"
                  />
                  <Button
                    onClick={async () => {
                      try {
                        const response = await getPayloadFromLink(values?.url ?? '')
                        await setFieldValue('payload', JSON.stringify(response))
                        enqueueSnackbar(
                          t('custom-reports:Link was parsed successfully'),
                          { variant: 'success' },
                        )
                      } catch (error) {
                        enqueueSnackbar(
                          error
                            ? t(`custom-reports:${error}`)
                            : t('custom-reports:Unknown error'), { variant: 'error' },
                        )
                      }
                    }}
                    variant={'contained'}
                    size={'small'}
                    type={'button'}
                  >
                    {t('custom-reports:Get payload from Keitaro link')}
                  </Button>
                </div>

                <TextField
                  fullWidth
                  multiline
                  type="text"
                  name="payload"
                  margin="normal"
                  variant="outlined"
                  label={t('custom-reports:Keitaro report payload')}
                  value={values.payload}
                  error={Boolean(touched.submit && touched.payload && errors.payload)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    inputProps: {
                      style: {
                        resize: 'vertical',
                        maxHeight: '200px',
                      },
                    },
                  }}
                />

                <div style={{ width: '100%', textAlign: 'center', color: '#2E95D3' }}>
                  <Autocomplete
                    options={[
                      { label: t('custom-reports:Enabled'), value: false },
                      { label: t('custom-reports:Disabled'), value: true },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={{
                      label: values.disabled ? t('custom-reports:Disabled') : t('custom-reports:Enabled'),
                      value: values.disabled,
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue('disabled', newValue.value)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('custom-reports:Set status for report')}
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                  />
                </div>

                <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
                  {touched.submit && Object.keys(errors).length > 0 && (
                    <Alert severity="error">
                      {errors.name && <div> {t(`custom-reports:${errors.name}`)} </div>}
                      {errors.comment && <div> {t(`custom-reports:${errors.comment}`)} </div>}
                    </Alert>)
                  }
                </div>

                <div
                  style={
                    {
                      display: 'flex',
                      marginTop: 'auto',
                      gap: '15px',
                    }
                  }>

                  {initialValues?.id &&
                    <SDeleteButton
                      variant={'contained'}
                      type={'button'}
                      size={'medium'}
                      onClick={(e) => handleDelete(e, initialValues?.id)}
                    >
                      {isSubmitting
                        ? <CircularProgress size="1rem"/>
                        : null}
                      {t('custom-reports:Delete')}
                    </SDeleteButton>
                  }

                  <div
                    style={
                      {
                        display: 'flex',
                        marginLeft: 'auto',
                        gap: '15px',
                      }
                    }
                  >
                    <Button
                      variant={'contained'}
                      disabled={isSubmitting}
                      type={'submit'}
                      size={'medium'}
                      onClick={() => handleSubmit}
                    >
                      {isSubmitting
                        ? <CircularProgress size="1rem"/>
                        : null}
                      {t('custom-reports:Submit')}
                    </Button>

                    <SModalCancel
                      variant={'outlined'}
                      onClick={onClose}
                      size={'medium'}
                      type={'reset'}
                      style={{ marginLeft: 'auto' }}
                    >
                      {t('custom-reports:Cancel')}
                    </SModalCancel>
                  </div>
                </div>
              </SModalForm>
            )}
          </Formik>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 500px;
`
const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`
const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`
// .attrs({ as: 'a' })
const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #FF6961;
    color: #FF6961;

    &:hover {
      border-color: ${darken('#FF6961', 0.05)};
      color: ${darken('#FF6961', 0.05)};
    }
  }
`

const SDeleteButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #FF6961;
    color: #FFF;

    &:hover {
      background-color: ${darken('#FF6961', 0.1)};
    }
  }
`
