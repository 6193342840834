import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import {
  addCatalogSettings,
  getCatalog,
  getCatalogForceUpdate,
  getCatalogIntervals,
  getCatalogSettings,
} from './api'
import { SETTINGS_DEFAULT } from './lib'

import type { KaccCatalogStoreProps } from './types'

export const useKaccCatalogStore = create<KaccCatalogStoreProps>((set, get) => ({
  catalog: null,
  catalogCount: null,
  catalogQuery: null,
  catalogTotals: null,
  catalogFilters: null,
  catalogInterval: null,
  catalogError: null,
  catalogLoading: false,
  getCatalog: async (search) => {
    try {
      set({ catalog: null })
      set({ catalogFilters: null })
      set({ catalogError: null })
      set({ catalogLoading: true })
      set({ intervals: null })

      // Get settings before getting catalog
      get().getSettings()

      //Get intervals before getting catalog
      get().getIntervals()

      // get catalog
      const response = await getCatalog(search)

      if (!axios.isAxiosError(response)) {
        set({ catalog: response.data.catalog })
        set({ catalogCount: response.data.count })
        set({ catalogQuery: response.data.query })
        set({ catalogTotals: response.data.totals })
        set({ catalogFilters: response.data.filters })
        set({ catalogInterval: response.data.interval })

        // If intervals were null but after automatically created
        set({ intervals: response.data.filters?.intervals })
      }
    } catch (error) {
      let errorText = t('kacc:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ catalogError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ catalogLoading: false })
    }
  },

  forceSyncLoading: false,
  forceSyncError: null,
  getForceSync: async (search) => {
    try {
      set({ forceSyncLoading: true })
      set({ forceSyncError: null })

      await getCatalogForceUpdate(search)

    } catch (error) {
      let errorText = t('kacc:Unknown error')

      if (axios.isAxiosError(error)) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          set({ getForceSync: error.response?.data.message || errorText })
          errorText = error.response?.data.message
        }

        throw errorText

      } else {
        errorText = String(error || errorText)
      }
      throw errorText
    } finally {
      set({ forceSyncLoading: false })
    }
  },

  settings: null,
  getSettings: async () => {
    try {
      set({ settings: null })

      const response = await getCatalogSettings()

      // check response schema or set SETTINGS_DEFAULT
      if (!axios.isAxiosError(response))
        response.data.settings.anty
                && response.data.settings.facebook
                && response.data.settings.campaigns
                && response.data.settings.offers
          ? set({ settings: response.data.settings })
          : set({ settings: SETTINGS_DEFAULT })

    } catch (error) {
      // force change to SETTINGS_DEFAULT and saveSettings
      set({ settings: SETTINGS_DEFAULT })
      await get().saveSettings(SETTINGS_DEFAULT)
    }
  },
  updateSettings: async (settings) => {
    set({ settings: settings })
  },
  saveSettings: async (settings) => {
    if (settings) await addCatalogSettings(settings)
  },

  intervals: null,
  getIntervals: async () => {
    try {
      set({ intervals: null })

      const response = await getCatalogIntervals()

      if (!axios.isAxiosError(response))
        set({ intervals: response.data })

    } catch (error) {
      let errorText = t('kacc:Unknown error')

      if (axios.isAxiosError(error)) {
        errorText = error.response?.data.message
      }

      throw errorText
    }
  },
}))
