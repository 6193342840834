import React, { FC, useState } from 'react'
import { t } from 'i18next'

import { Tooltip } from '../../../../shared/ui/tooltip'
import { TableButtonAction } from '../../../../shared/ui/table-button-action'

import { AddEditDomainModal } from './add-edit-domain-modal'

import type { DomainEditProps } from '../../../../entities/domains-watcher'

export type EditDomainButtonProps = {
    initialValues: DomainEditProps
}

export const EditDomainButton: FC<EditDomainButtonProps> = ({ initialValues }:EditDomainButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <AddEditDomainModal
        initialValues={ initialValues }
        onClose={ () => setModalVisible(false) }
        isOpen={modalVisible}
      />

      <Tooltip
        title={ t('domains-watcher:Edit') }>
        <TableButtonAction
          target={ 'edit' }
          onClick={ () => setModalVisible(true) }
        />
      </Tooltip>
    </>
  )
}
