import React, { useEffect, useState } from 'react'

export const useDimensions = (myRef: React.MutableRefObject<any>) => {
  const [offsetWidth, setOffsetWidth] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [offsetTop, setOffsetTop] = useState(0)

  const handleResize = () => {
    const newOffsetWidth = myRef.current.offsetWidth
    const newOffsetHeight = myRef.current.offsetHeight
    const newOffsetTop = myRef.current.offsetTop

    if (offsetWidth !== newOffsetWidth)
      setOffsetWidth(newOffsetWidth)

    if (offsetHeight !== newOffsetHeight)
      setOffsetHeight(newOffsetHeight)

    if (offsetTop !== newOffsetTop)
      setOffsetTop(newOffsetTop)
  }

  useEffect(() => {
    if (myRef
        && myRef.current
        && myRef.current.offsetWidth > 0
        && myRef.current.offsetHeight > 0
        && myRef.current.offsetTop > 0
    ) {
      handleResize()
      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [myRef, handleResize])

  return { offsetWidth, offsetHeight, offsetTop }
}
