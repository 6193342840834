import React from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import { SyncButton } from '../../../../shared/ui/sync-button'
import { Tooltip } from '../../../../shared/ui/tooltip'

import type { TableUpdateButtonProps } from './types'

export const TableUpdateButton = ({ updateMain, updating }: TableUpdateButtonProps) => {
  const [search] = useSearchParams()

  const updateHandler = () => updateMain(search)

  return (
    <SSyncButtonWrapper>
      <Tooltip title={t('subdomains-generator:Sync Keitaro')}>
        <SyncButton
          onClick={updateHandler}
          updating={updating}
        >
          {t('subdomains-generator:Sync')}
        </SyncButton>
      </Tooltip>
    </SSyncButtonWrapper>
  )
}

const SSyncButtonWrapper = styled.div`
  margin-left: auto;
`
