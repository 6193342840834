import styled from 'styled-components'

export const SPage = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 15px;
  padding: 24px;
`

export const SPageBlock = styled.div`
  grid-column: span 12;
  border-radius: 5px;
  height: fit-content;
`

export const SPageWithMoreBlocks = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 15px;
`

export const SChartBlockTwo = styled.div`
  grid-column: span 6;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 10px 0 rgba(55, 67, 81, 0.15);
  height: fit-content;
  padding: 16px;
`

export const SChartBlockThree = styled.div`
  grid-column: span 4;
  min-width: 350px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0 4px 10px 0 rgba(55, 67, 81, 0.15);
  height: fit-content;
  padding: 16px;
`

export const SChartBlockThreeCountries = styled(SChartBlockThree)`
  height: 350px;
`

export const SPageContainer = styled.div`
  padding: 16px;
  background: #FFF;
  box-shadow: 0 4px 10px 0 rgba(55, 67, 81, 0.15);
`

export const SInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 15px;
`

export const SRightBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-content: center;
  gap: 15px;
`

export const STitleChart = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: rgb(55, 61, 63);
  margin-bottom: 15px;
`

export const SButtonChart = styled.button`
  border: transparent;
  border-radius: 3px;
  padding: 7px 16px;
  box-shadow: 0 1px 1px 0 #aab7c1;
  background: #ddf4ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.01em;
  color: #0d79bd;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px;

  &:hover {
    background: #c6e6f5;
  }
`

export const SIconButtonChart = styled.button`
  border: transparent;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  box-shadow :0 1px 1px 0 #aab7c1;
  background: #ddf4ff;
  color: #0d79bd;

  &.svg {
    width: 16px;
    height: 14px;
    fill: #51ade7;
  }

  &:hover {
    background: #c6e6f5;
  }
`