import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { CampaignData } from './types'
import { getCampaignList } from './api'

export interface ICampaignListStore {
  count: number | null,
  limits: number[],
  campaignList: CampaignData[] | null,
  loading: boolean,
  error: string | null,
  getCampaign: (search: URLSearchParams) => void,
}

export const useCampaignListStore = create<ICampaignListStore>((set, get) => ({
  count: null,
  limits: [10, 25, 50, 100, 250],
  campaignList: null,
  loading: false,
  error: null,

  getCampaign: async (search) => {
    try {
      set({ count: null })
      set({ campaignList: null })
      set({ error: null })
      set({ loading: true })

      const response = await getCampaignList(search)
      if (!axios.isAxiosError(response)) {
        set({ count: response.data.totalCount })
        set({ campaignList: response.data.data })
      }

    } catch (error) {
      let errorText = t('kacc:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
