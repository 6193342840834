import React, { useEffect } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { Alert } from '@mui/material'
import { t } from 'i18next'

import { InputFormText } from '../../../../shared/ui/input-form-text'
import { useWhiteGeneratorStore } from '../../../../entities/white-generator/generator'

import { TableTemplates } from './table-templates'

type GenerationsFormValues = {
  template: string | null
  name: string | null;
  comment: string | null;
  title: string | null;
  submit: null,
}
export const GenerationsForm = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    generations,
    getGeneratedData,
    sendToKeitaro,
    setTitle,
    setComment,
  } = useWhiteGeneratorStore()

  const validationSchema = Yup.object().shape({
    template: Yup.string(),
    name: Yup.string(),
    title: Yup.string().nullable().max(254, t('white-generator:Text must be maximum 254 symbols!')),
    comment: Yup.string().nullable().max(254, t('white-generator:Comment must be maximum 254 symbols!')),
  })

  const formik = useFormik<GenerationsFormValues>({
    initialValues: {
      template: null,
      name: null,
      comment: '',
      title: '',
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { template, name, title, comment } = values

      try {
        await sendToKeitaro(template, name, title, comment, generations)

        enqueueSnackbar(t('white-generator:Template were sent to Keitaro successfully!'), { variant: 'success' })

        await getGeneratedData()

      } catch (error) {
        enqueueSnackbar(`${
          t('white-generator:Unable to send your template to Keitaro!')
        } ${
          t(`white-generator:${error}`)
        }`, { variant: 'error' })
      } finally {
        formikHelpers.setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    setComment(String(generations?.comment))
  }, [generations, setComment])

  useEffect(() => {
    setTitle(String(generations?.title))
  }, [generations, setTitle])

  return <>
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Options>
        <InputFormText
          label={t('white-generator:Title')}
          value={formik.values.title}
          onInputChange={(value: string) => formik.setFieldValue('title', value)}
          inputWidth={'250px'}
          error={Boolean(formik.touched.title && formik.errors.title)}
          noOptionsText={t('white-generator:No options')}
        />
        <InputFormText
          label={t('white-generator:Comment on the generate')}
          value={formik.values.comment}
          onInputChange={(value: string) => formik.setFieldValue('comment', value)}
          inputWidth={'300px'}
          error={Boolean(formik.touched.comment && formik.errors.comment)}
          noOptionsText={t('white-generator:No options')}
        />
      </Options>

      <TableTemplates formik={formik}/>

      {Boolean(
        formik.touched.submit
        && Object.keys(formik.errors).length > 0,
      ) && (
        <div
          style={{
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          <Alert
            severity="error"
            style={{
              width: 'fit-content',
            }}
          >
            {formik.errors.title && <div>
              {t(`white-generator:${formik.errors.title}`)} </div>
            }
            {formik.errors.comment && <div>
              {t(`white-generator:${formik.errors.comment}`)} </div>
            }
          </Alert>
        </div>
      )}
    </form>
  </>
}

const Options = styled.div`
  border-bottom: 1px dotted #B7C6D3;
  display: flex;
  gap: 16px;
  padding: 16px 0;
`
