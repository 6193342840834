import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useTranslationStore } from '../../entities/translation'

import 'dayjs/locale/en'
import 'dayjs/locale/ru'

export type DatePickerProviderProps = {
  children?: React.ReactNode;
};

const DatePickerProvider = function ({ children }: DatePickerProviderProps) {
  let { translationCurrent } = useTranslationStore()

  return (
    <LocalizationProvider
      dateAdapter={ AdapterDayjs }
      adapterLocale={ translationCurrent }>
      {children}
    </LocalizationProvider>
  )
}

const withXDatePicker = (component: () => React.ReactNode) => () => (
  <DatePickerProvider>
    { component() }
  </DatePickerProvider>
)

export default withXDatePicker