import { create } from 'zustand'
import axios from 'axios'

import { LOCALSTORAGE_THEME_KEY } from '../../shared/configs/constants'

import { getAppVersion } from './api'

export interface IThemeStore {
  themeCurrent: string,
  setThemeCurrent: (theme: string) => void,
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void,
  getAppVersion: () => Promise<string | undefined>,
}

export const useAppStore = create<IThemeStore>((set, get) => ({
  themeCurrent: String(localStorage.getItem(LOCALSTORAGE_THEME_KEY)),
  setThemeCurrent: (theme) => {
    localStorage.setItem(LOCALSTORAGE_THEME_KEY, String(theme))
    set({ themeCurrent: String(theme) })
  },

  sidebarOpen: true,
  setSidebarOpen: (value) => set({ sidebarOpen: value }),

  getAppVersion: async () => {
    try {
      const response = await getAppVersion()
      if (!axios.isAxiosError(response))
        return response.data
        // set({ appVersion: response.data })
    } catch (error) {
      console.error('Failed to fetch app version:', error)
    }
  },
}))
