import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { generateDomains, getGeneratorMain, getLastGeneration, syncKeitaro, uploadGenerations } from './api'

import type { DomainsGeneratedData, DomainsGeneratorData } from './types'

export interface IDomainsGeneratorStore {
  data: DomainsGeneratorData | null,
  error: string | null,
  loading: boolean,
  getMain: (params: URLSearchParams) => void,

  syncing: boolean,
  syncError: string | null,
  syncKeitaro: (params: URLSearchParams) => void,
  handleMainData: (data: DomainsGeneratorData | null) => void,

  generations: DomainsGeneratedData | null,
  generateError: string | null,
  generateLoading: boolean,
  generateMain: (values: object) => Promise<void>,
  handleGenerationsData: (generations: DomainsGeneratedData | null) => void,

  lastGenerateError: string | null,
  lastGenerateLoading: boolean,
  getLastGeneratedMain: () => Promise<void>,

  sendGenerateLoading: boolean,
  sendGenerateError: string | null,
  sendToKeitaro: (values: object) => Promise<void>,
}

export const useDomainsGeneratorStore = create<IDomainsGeneratorStore>((set, get) => ({
  data: null,
  error: null,
  loading: false,
  getMain: async (params: URLSearchParams) => {
    try {
      set({ data: null })
      set({ error: null })
      set({ loading: true })
      set({ syncing: false })

      const response = await getGeneratorMain(params)

      if (!axios.isAxiosError(response)) {
        set({ data: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  syncing: false,
  syncError: null,
  syncKeitaro: async (search) => {
    try {
      set({ syncing: true })
      set({ syncError: null })

      const response
        = await syncKeitaro()

      if (!axios.isAxiosError(response)) {
        get().getMain(search)
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ syncError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ syncing: false })
    }
  },
  handleMainData: (main) => {
    set({ data: main })
  },

  generations: null,
  generateError: null,
  generateLoading: false,
  generateMain: async (values: object) => {
    try {
      set({ generations: null })
      set({ generateError: null })
      set({ generateLoading: true })

      const response = await generateDomains(values)

      if (!axios.isAxiosError(response)) {
        set({ generations: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ generateError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ generateLoading: false })
    }
  },
  handleGenerationsData: (generations) => {
    set({ generations: generations })
  },

  lastGenerateError: null,
  lastGenerateLoading: false,
  getLastGeneratedMain: async () => {
    try {
      set({ generations: null })
      set({ lastGenerateError: null })
      set({ lastGenerateLoading: true })

      const response = await getLastGeneration()

      if (!axios.isAxiosError(response)) {
        set({ generations: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ lastGenerateError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ lastGenerateLoading: false })
    }
  },

  sendGenerateError: null,
  sendGenerateLoading: false,
  sendToKeitaro: async (values: object) => {
    try {
      set({ generations: null })
      set({ sendGenerateError: null })
      set({ sendGenerateLoading: true })

      const response = await uploadGenerations(values)

      if (!axios.isAxiosError(response)) {
        set({ generations: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ sendGenerateError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ sendGenerateLoading: false })
    }
  },
}))
