import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import {
  createDictionaryRequest,
  deleteDictionaryRequest,
  editDictionaryRequest,
  getDictionariesDataRequest,
  getDomainsDataRequest,
  getGroupsDataRequest,
  disableDomainRequest,
  syncKeitaro,
} from './api'

import type { DictionariesData, DomainsData, GroupsData } from './types'

export interface IDomainsGeneratorStore {
  dictionariesData: DictionariesData | null,
  getDictionariesData: () => Promise<void>,
  dictionariesDataError: string | null,

  groupsData: GroupsData[] | null,
  getGroupsData: () => void,

  createDictionary: (name: string, dict: string) => Promise<void>,
  createDictionaryError: string | null,

  editDictionary: (id: number, name: string, dict: string) => Promise<void>,
  editDictionaryError: string | null,

  deleteDictionary: (id: number) => Promise<void>,
  deleteDictionaryError: string | null,

  domainsData: DomainsData | null,
  getDomainsData: (search: URLSearchParams) => void,
  domainsDataError: string | null,

  disableDomain: (id: number | undefined) => Promise<void>,
  disableDomainError: string | null,

  syncing: boolean,
  syncError: string | null,
  syncKeitaro: (search: URLSearchParams) => void,

  loading: boolean,
}

export const useSettingsDomainsStore = create<IDomainsGeneratorStore>((set, get) => ({
  dictionariesData: null,
  getDictionariesData: async () => {
    set({ loading: true })
    set({ dictionariesData: null })
    set({ dictionariesDataError: null })

    try {
      const response = await getDictionariesDataRequest()

      if (!axios.isAxiosError(response)) {
        set({ dictionariesData: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ dictionariesDataError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  dictionariesDataError: null,

  groupsData: null,
  getGroupsData: async () => {
    try {
      set({ groupsData: null })

      const response = await getGroupsDataRequest()

      if (!axios.isAxiosError(response)) {
        set({ groupsData: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        errorText = error.response?.data.message
      }

      throw errorText
    }
  },

  createDictionary: async (name: string, dict: string) => {
    set({ loading: true })
    set({ createDictionaryError: null })

    try {
      await createDictionaryRequest(name, dict)
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ createDictionaryError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  createDictionaryError: null,

  editDictionary: async (id: number, name: string, dict: string) => {
    set({ loading: true })
    set({ editDictionaryError: null })
    editDictionaryRequest(id, name, dict)
      .catch(response => set({ editDictionaryError: response.data.message }))
      .finally(() => set({ loading: false }))

    try {
      await editDictionaryRequest(id, name, dict)
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ editDictionaryError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  editDictionaryError: null,

  deleteDictionary: async (id: number) => {
    set({ loading: true })
    set({ deleteDictionaryError: null })

    try {
      await deleteDictionaryRequest(id)
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ deleteDictionaryError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  deleteDictionaryError: null,

  domainsData: null,
  getDomainsData: async (search) => {
    set({ loading: true })
    set({ domainsData: null })
    set({ domainsDataError: null })

    try {
      const response = await getDomainsDataRequest(search)

      if (!axios.isAxiosError(response)) {
        set({ domainsData: response.data })
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ domainsDataError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  domainsDataError: null,

  disableDomain: async (id?: number) => {
    set({ loading: true })
    set({ disableDomainError: null })

    try {
      await disableDomainRequest(id as number)
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ disableDomainError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
  disableDomainError: null,

  syncing: false,
  syncError: null,
  syncKeitaro: async (search) => {
    try {
      set({ syncing: true })
      set({ syncError: null })

      const response = await syncKeitaro()

      if (!axios.isAxiosError(response)) {
        get().getDomainsData(search)
      }
    } catch (error) {
      let errorText = t('subdomains-generator:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ syncError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ syncing: false })
    }
  },

  loading: false,
}))
