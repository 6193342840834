import { useTranslation } from 'react-i18next'

import { NotFoundContent } from '../../widgets/errors/not-found'
import { ErrorLayout } from '../../widgets/layouts'

export function NotFound() {
  const { t } = useTranslation('not-found')

  return (
    <ErrorLayout header={t('Not Found')}>
      <NotFoundContent/>
    </ErrorLayout>
  )
}
