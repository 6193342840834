import { t } from 'i18next'
import { Box, Button, styled, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React from 'react'

export function RecoverTokenInvalid() {
  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          mt: 10,
        }}>
        <NotFound404
          variant="h1"
        >
          {t('auth:Error')}
        </NotFound404>
        <NotFoundHeader
          variant="h5"
        >
          {t('auth:Sorry, page was not found!')} 😭
        </NotFoundHeader>
        <NotFoundSubHeader
          variant="subtitle1"
        >
          {t('auth:Recovery link is invalid or has expired!')}
        </NotFoundSubHeader>
        <Button
          sx={{
            mt: 3,
            mb: 10,
          }}
          color="primary"
          size="large"
          variant="contained"
          href="/"
        >
          <ArrowBackIcon/>
          {t('auth:Back to Sign in page')}
        </Button>
      </Box>
    </>
  )
}


const NotFound404 = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)

const NotFoundHeader = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)

const NotFoundSubHeader = styled(Typography)(({ theme }) => ({
  '&': {
    //color: theme.pages.NotFoundPage.title.color,
    marginBottom: '0.5rem',
  },
}),
)
