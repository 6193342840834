import React, { useRef } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { WeekProgressData } from './week-progress-data'
import { ChartAlertMessage } from './alerts'

export const WeekProgressChart = () => {
  const { weekProgress, weekIsLoading, weekHasError } = useDashboardStore()
  const weekChartRef = useRef<ReactApexChart>(null)

  const hasData = weekProgress && weekProgress?.length > 0

  if (weekIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('kacc:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (weekHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(weekHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!hasData) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('kacc:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  const saleRevenueData = weekProgress.map(item => item.saleRevenue)

  const costData = weekProgress.map(item => item.cost ?? null)
  const profitConfirmedData = weekProgress.map(item => item.profitConfirmed ?? null)

  const sortedWeekProgress = [...weekProgress].sort((a, b) => {
    return new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime()
  })
  const labels = sortedWeekProgress.map(item => item.reportDate)

  const series = [
    {
      name: 'Revenue',
      data: saleRevenueData,
    },
  ]

  if (costData.length > 0) {
    series.push({
      name: 'Cost',
      data: costData as number[],
    })
  }

  if (profitConfirmedData.length > 0) {
    series.push({
      name: 'Profit',
      data: profitConfirmedData as number[],
    })
  }


  const options: ApexOptions = {
    chart: {
      id: 'week-progress-chart',
      height: 350,
      width: '100%',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      type: 'bar',
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ['#337AFF', '#FF5733', '#33FF57'],
    plotOptions: {
      bar: {
        // columnWidth: '45%',
        distributed: false,
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function (value: number): string {
        return `${value.toFixed(2)} $`
      },
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return `${value.toFixed(2)} $`
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 10,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(0)
        },
      },
    },
  }

  return (
    <>
      <ChartContainer>
        <ReactApexChart
          ref={weekChartRef}
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </ChartContainer>
      <WeekProgressData/>
    </>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`