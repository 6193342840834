import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

import { Button } from '../../../../../shared/ui/button'
import { SETTINGS_DEFAULT, useKaccCatalogStore } from '../../../../../entities/kacc/catalog'
import { SettingsInitialProps, ModalProps } from '../types'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

import { TableSettingsData } from './table-settings-data'

export const ManageSettingsModal = ({ onClose, isOpen }: ModalProps) => {
  const { settings, updateSettings, saveSettings } = useKaccCatalogStore()
  const { enqueueSnackbar } = useSnackbar()

  const initialValues = {
    settings: { ...settings ?? SETTINGS_DEFAULT },
    submit: null,
  }

  const validationSchema = Yup.object().shape({})

  const formik = useFormik<SettingsInitialProps>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { settings } = values

      try {
        updateSettings(settings)
        saveSettings(settings)
        enqueueSnackbar(t('kacc:Settings were saved successfully!'), { variant: 'success' })

        await setTimeout(() => onClose?.(), 500)
      } catch (error) {
        enqueueSnackbar(`${t('kacc:Something went wrong!')} ${error}`, { variant: 'error' })
      } finally {
        formikHelpers.setSubmitting(false)
      }
    },
  })

  const handleOnClickResetSettings = () => {
    updateSettings(SETTINGS_DEFAULT)
    saveSettings(SETTINGS_DEFAULT)
    enqueueSnackbar(t('kacc:Settings were reset successfully!'), { variant: 'success' })
    setTimeout(() => onClose?.(), 500)
  }

  useEffect(() => {
    formik.setFieldValue('settings', settings)
  }, [settings])

  return (
    <ModalOverlayMui isOpen={isOpen}>
      <SModalHeader>
        <SModalHeaderText>
          {t('kacc:Table settings')}
        </SModalHeaderText>
        <SModalHeaderClose
          onClick={onClose}
        />
      </SModalHeader>
      <SModalBody>
        <SModalForm
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <SModalContent>
            <TableSettingsData
              settings={initialValues.settings}
              formik={formik}
            />

            <div
              style={{
                display: 'flex',
                marginTop: 'auto',
                gap: '15px',
              }}
            >
              <SModalReset
                variant="contained"
                onClick={handleOnClickResetSettings}
                size={'medium'}
                type={'button'}
                disableRipple
              >
                <RestoreFromTrashIcon/>
                {t('kacc:Reset all settings')}
              </SModalReset>

              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  gap: '15px',
                }}>
                <SModalCancel
                  variant="outlined"
                  onClick={onClose}
                  size={'medium'}
                  type={'button'}
                  disableRipple
                >
                  {t('kacc:Cancel')}
                </SModalCancel>

                <Button
                  variant={'contained'}
                  disabled={formik.isSubmitting}
                  type={'submit'}
                  size={'medium'}
                  style={{ marginLeft: 'auto' }}
                  onClick={() => formik.handleSubmit}
                >
                  {formik.isSubmitting && <CircularProgress size="1rem"/>}
                  {t('kacc:Save')}
                </Button>
              </div>
            </div>
          </SModalContent>
        </SModalForm>
      </SModalBody>
    </ModalOverlayMui>
  )
}

const SModalHeader = styled.div`
    align-items: center;
    background: ${
  ({ theme }) => theme.colors.primary.main
};
    display: inline-flex;
    padding: 16px 24px;
    flex-shrink: 0;
    justify-content: space-between;
`
const SModalHeaderText = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha.white[100]};

    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
`

const SModalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 280px;
    height: 100%;
`

const SModalBody = styled.div`
    display: inline-flex;
    padding: 16px 24px;
    height: 100%;
`
const SModalContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 280px;
    height: 100%;
`

const SModalCancel = styled(Button)`
    &.MuiButtonBase-root {
        border-color: #F35B5B;
        color: #F35B5B;
        height: 40px;

        &:hover {
            border-color: #F35B5B;
            background-color: #FFE2E2;
        }
    }
`

const SModalReset = styled(Button)`
    &.MuiButtonBase-root {
        border-color: #F35B5B;
        background-color: #F35B5B;
        color: #ffffff;
        height: 40px;

        &:hover {
            border-color: #ce4e4e;
            background-color: #ce4e4e;
        }
    }
`
