import React, { useState } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'

import { AddEditDomainModal } from './add-edit-domain-modal'

export const AddDomainButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <AddEditDomainModal
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <Button
        onClick={ () => setModalVisible(true) }
        variant={ 'contained' }
        size={ 'small' }
        type={ 'button' }
      >
        { t('domains-watcher:Add domain') }
      </Button>
    </>
  )
}
