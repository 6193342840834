import React from 'react'
import styled, { keyframes } from 'styled-components'

export const FallbackLoader = () => {
  return (
    <LoaderContainer>
      <LoaderSpinner />
    </LoaderContainer>
  )
}

// Define keyframes for animation
const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

// Styled components for the loader
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const LoaderSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spinAnimation} 0.6s linear infinite;
`
