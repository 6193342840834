import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { useCustomReportInfoStore, CustomReportInfoLimitEnum } from '../../../../entities/custom-reports/info'

export function PaginationBlock() {
  const { catalogCount, catalogIsLoading } = useCustomReportInfoStore()

  return (
    !catalogIsLoading &&
      <SPaginationBlock>
        <Pagination
          count={catalogCount ?? 0}
          limits={Object.values(CustomReportInfoLimitEnum).map(s => Number(s))}/>
      </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
  display: flex;
  gap: 20px;
`
