import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { manageFacebookSync } from '../../../shared/lib/manageFacebookSync'

import { KaccAntyData, IntegrationHistoryData } from './types'
import {
  editFBMarketingApiRequest,
  getList,
  integrateAntyAccounts,
  syncWithAntyAndKeitaro,
  syncFacebook,
  getIntegrationsHistory,
} from './api'

export type KaccAntyListStoreProps = {
  data: KaccAntyData[] | null,
  loading: boolean,
  error: string | null,
  getList: (search: URLSearchParams) => Promise<void>,

  editMapiLoading: boolean,
  editFBMarketingApi: (
    antyId: number,
    newValue: string,
    oldValue: string | null,
    search: URLSearchParams
  ) => Promise<void>,
  editFBMarketingApiError: string | null,

  integrateLoading: boolean,
  integrate: (values: number[] | null) => Promise<{ message: string, variant: 'success' | 'warning' | 'error' }[]>

  integrationsHistory: IntegrationHistoryData[] | null,
  getIntegrationsHistory: () => Promise<void>,

  syncing: boolean,
  syncError: string | null,
  syncKeitaro: (search: URLSearchParams) => Promise<void>,

  handleCheckedData: (data: KaccAntyData[]) => void,
}

export const useKaccAntyListStore = create<KaccAntyListStoreProps>((set, get) => {
  return ({
    data: null,
    loading: false,
    error: null,
    getList: async (search: URLSearchParams) => {
      try {
        set({ data: null })
        set({ error: null })
        set({ loading: true })

        const response = await getList(search)
        if (!axios.isAxiosError(response))
          set({ data: response.data })
      } catch (error) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          set({ error: error.response?.data.message || errorText })
          errorText = error.response?.data.message
        }

        throw errorText
      } finally {
        set({ loading: false })
      }
    },

    integrationsHistory: null,
    getIntegrationsHistory: async () => {
      try {
        set({ integrationsHistory: null })

        const response = await getIntegrationsHistory()
        if (!axios.isAxiosError(response))
          set({ integrationsHistory: response.data })
      } catch (error) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          set({ error: error.response?.data.message || errorText })
          errorText = error.response?.data.message
        }

        throw errorText
      } finally {
        set({ loading: false })
      }
    },

    editMapiLoading: false,
    editFBMarketingApiError: null,
    editFBMarketingApi: async (
      antyId: number,
      newValue: string,
      oldValue: string | null,
      search: URLSearchParams,
    ) => {
      try {
        set({ editMapiLoading: true })
        set({ editFBMarketingApiError: null })

        const response = await editFBMarketingApiRequest(antyId, newValue, oldValue)
        if (!axios.isAxiosError(response)) {
          get().getList(search)
        }
      } catch (error) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          set({ editFBMarketingApiError: error.response?.data.message || errorText })
          errorText = error.response?.data.message
        }
        throw errorText
      } finally {
        set({ editMapiLoading: false })
      }
    },

    integrateLoading: false,
    integrate: async (values: number[] | null) => {
      const messages: { message: string, variant: 'success' | 'warning' | 'error' }[] = []

      try {
        set({ integrateLoading: true })

        const response = await integrateAntyAccounts(values)
        if (!axios.isAxiosError(response)) {
          response.data.data.forEach((item: { message: string; status: number; accountId?: number }) => {
            switch (item.status) {
            case 401:
            case 402:
              messages.push({ message: item.message, variant: 'success' })
              break
            case 403:
              messages.push({ message: t('kacc:Error integration'), variant: 'warning' })
              break
            case 404:
              messages.push({ message: t('kacc:Nothing to integrate'), variant: 'warning' })
              break
            }
          })
        }
      } catch (error) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          errorText = error.response?.data.message
        }

        throw errorText
      } finally {
        set({ integrateLoading: false })
      }

      return messages
    },

    syncing: false,
    syncError: null,
    syncKeitaro: async (search) => {
      try {
        set({ syncing: true })
        set({ syncError: null })

        await syncWithAntyAndKeitaro()

        await manageFacebookSync(syncFacebook)

      } catch (error) {
        let errorText = t('kacc:Unknown error')

        if (axios.isAxiosError(error)) {
          set({ syncError: error.response?.data.message || errorText })
          errorText = error.response?.data.message
        } else {
          errorText = String(error || errorText)
        }

        throw errorText
      } finally {
        get().getList(search)
        set({ syncing: false })
      }
    },

    handleCheckedData: (checkedList) => {
      set({ data: checkedList })
    },
  })
})
