import { AxiosError, AxiosResponse } from 'axios'

import { request } from '../../../shared/api'
import { requestFile } from '../../../shared/api/axios'

import type { TemplatesDataResponse, TemplatesGeneratedResponse } from './types'

export const getWhiteGeneratorData = async (): Promise<TemplatesDataResponse | AxiosError> =>
  request.get('/whites-generator/generator-form', {})

export const uploadTemplateRequest = (title: string, file: File): Promise<AxiosResponse | AxiosError> => {
  const bodyFormData = new FormData()
  bodyFormData.append('file', file)
  bodyFormData.append('title', title)

  return requestFile.post('/whites-generator/upload-template', bodyFormData)
}

export const generateTemplates = (values: object): Promise<TemplatesGeneratedResponse | AxiosError> => {
  return request.post('/whites-generator/generate-templates', values)
}

export const getLastGeneration = async (): Promise<TemplatesGeneratedResponse | AxiosError> =>
  request.get('/whites-generator/last-generations', {})

export const uploadGenerations = (
  pack: string,
  name: string,
  title: string,
  comment: string,
): Promise<TemplatesGeneratedResponse | AxiosError> => {
  return request.post('/whites-generator/upload-generation', { pack, name, title, comment })
}
