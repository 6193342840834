import styled from 'styled-components'

import {
  UTable,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../../../shared/ui/table'

export const Table = styled(UTable)`
    border-right: 1px dashed #B7C6D3;
    margin-top: 16px;
    margin-bottom: 24px;
`

export const TableRow = styled(UTableRow)`
    height: max-content;
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
    align-items: flex-start;
`

export const TableValue = styled(UTableValue)`
    align-items: center;
    display: flex;
    color: #1C2529;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.1px;
    word-wrap: break-word;
    gap: 10px;
    width: 100%;

    & svg {
        color: #455A64;
        cursor: pointer;
        font-size: 1rem;

        &.unchecked {
            color: #aaaaaa;
            cursor: no-drop;
        }
    }

    & input {
        margin: 0;

        &:disabled + label {
            color: #aaaaaa;
        }
    }

    & label {
    }
`

export const TableAlertMessage = styled(UTableAlertMessage)`
    width: 100%;`

export const TableColumn = styled.div`
    display: flex;
    flex-direction: column;
`

export const TableGroup = styled.div`
    flex-grow: 1;
    height: 100%;

    &:nth-child(1) {
        border-left: 1px dashed #B7C6D3;
        border-right: 1px dashed #B7C6D3;
        min-width: 200px;
        flex-grow: 0;
    }

    &:nth-child(2) {
      border-right: 1px dashed #B7C6D3;
      min-width: 200px;
      flex-grow: 0;
    }

    &:nth-child(3) {
        border-right: 1px dashed #B7C6D3;
        min-width: 250px;
        flex-grow: 0;

        & ${TableColumn} {
            justify-content: center;
        }
    }

    &:nth-child(4) {
        border-right: 1px dashed #B7C6D3;
        display: flex;
        min-width: 400px;
        flex-grow: 1;

        & ${TableColumn} {
            justify-content: right;
        }

        &:is(:last-child) {
            justify-content: left;

            & ${TableRow} {
                max-width: inherit;
            }
        }
    }
`

export const TableHeader = styled.div`
    background: #ECF6FB;
    border-top: 1px dashed #B7C6D3;
    display: flex;
    justify-content: stretch;

    & ${TableGroup} {
        & ${TableValue} {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.2px;
            text-transform: capitalize;
        }

        & ${TableCell} {
            padding: 12px 10px;
        }

        &:is(:last-child) {
            border-right-color: transparent;
        }
    }
`

export const TableBody = styled(UTableBody)`
    border-top: 1px dashed #B7C6D3;
    border-bottom: 1px dashed #B7C6D3;
    display: grid;
    max-height: 400px;

    & ${TableGroup} {

        & ${TableRow} {
            border-bottom: 1px dashed #B7C6D3;
        }

        & ${TableCell} {
            flex-basis: fit-content;
            padding: 6px 10px;
        }

        &:is(:last-child) {
            & ${TableColumn} {
                min-width: 200px;

                &:not(:last-child) {
                    border-right: 1px dashed #B7C6D3;
                }
            }
        }
    }
`

export const TableBodyWrapper = styled.div`
    display: flex;
    justify-content: stretch;
    height: 100%;
`
