import React, { useEffect } from 'react'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Box, TextField } from '@mui/material'

import { useFilter } from '../../../shared/lib'

import { IntervalFilterType } from './types'
export const IntervalFilterParam = 'interval'

export function DashboardIntervalFilter() {
  const { filterActive } = useFilter(IntervalFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const defaultFilter = IntervalFilterType.seven_days_ago
  const filters = Object.values(IntervalFilterType)

  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) search.set(IntervalFilterParam, String(value))
    if (!value && filterActive) search.delete(IntervalFilterParam)
    setSearch(search, { replace: true })
  }

  // Check source query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive
        && (filterActive === defaultFilter
          || filters.indexOf(filterActive as IntervalFilterType) === -1)
      )) {
        search.delete(IntervalFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive || defaultFilter ? t(`dashboard:${filterActive ?? defaultFilter}`) : null}
      defaultValue={filterActive || defaultFilter ? t(`dashboard:${filterActive ?? defaultFilter}`) : null}
      onChange={handleOnChange}
      options={filters ?? []}
      openOnFocus={true}
      noOptionsText={t('kacc:No options')}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => t(`dashboard:${option}`) === value}
      renderOption={(props, option) => (
        option !== '' && (
          <Box
            component="li"
            value={option}
            {...props}
          >
            {t(`dashboard:${option}`)}
          </Box>
        )
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('dashboard:Select interval')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        width: 200,
      }}
    />
  )
}
