import React, { useRef } from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { ChartAlertMessage } from './alerts'

export const WeekProgressTestChart = () => {
  const { weekProgress, weekIsLoading, weekHasError } = useDashboardStore()
  const weekChartRef = useRef<ReactApexChart>(null)

  const hasData = weekProgress && weekProgress?.length > 0

  if (weekIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('dashboard:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (weekHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(weekHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!hasData) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('dashboard:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  const saleRevenueData = weekProgress?.map(item => item.saleRevenue)
  const costData = weekProgress?.map(item => item.cost || 0)
  const profitConfirmedData = weekProgress?.map(item => item.profitConfirmed || 0)

  const sortedWeekProgress = [...weekProgress].sort((a, b) => {
    return new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime()
  })
  const labels = sortedWeekProgress.map(item => item.reportDate)

  const series = [
    {
      name: 'Revenue',
      type: 'column',
      data: saleRevenueData,
    },
    {
      name: 'Profit',
      type: 'column',
      data: profitConfirmedData,
    },
    {
      name: 'Cost',
      type: 'line',
      data: costData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      height: 350,
      width: '100%',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 4],
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 10,
    },
    xaxis: {
      categories: labels,
      labels: {
        formatter: function (value) {
          return value
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Revenue',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB',
        },
        labels: {
          formatter: function (value) {
            return value.toFixed(0)
          },
          style: {
            colors: '#008FFB',
          },
        },
        title: {
          text: t('Revenue'),
          style: {
            color: '#008FFB',
          },
        },
      },
      {
        min: 0,
        seriesName: 'Profit',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396',
        },
        labels: {
          // todo: Тут возник вопросик, данная конструкция нужна, но она влият встроенную верстку чарта, надо решить
          // formatter: function (value) {
          //   return value.toFixed(0)
          // },
          style: {
            colors: '#00E396',
          },
        },
        title: {
          text: t('Profit'),
          style: {
            color: '#00E396',
          },
        },
      },
      {
        seriesName: 'Cost',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019',
        },
        labels: {
          // formatter: function (value) {
          //   return value.toFixed(0)
          // },
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: t('Cost'),
          style: {
            color: '#FEB019',
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: function (value: number) {
          return `${value.toFixed(2)} $`
        },
      },
    },
  }

  return (
    <ChartContainer>
      <ReactApexChart
        ref={weekChartRef}
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`