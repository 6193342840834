import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import debounce from 'debounce'

import { UserSettingsLayout } from '../../../../widgets/layouts/settings'
import { PageBlock, PageContainer } from '../../../../shared/ui/page'
import { useManageMembersStore } from '../../../../entities/settings/members'
import { MainLayout } from '../../../../widgets/layouts'
import { MembersBlock } from '../../../../widgets/user/settings/members'

export function UserSettingsManageMembers() {
  const { getMembersList } = useManageMembersStore()
  const { t } = useTranslation('settings')

  const debouncedMembersList = useMemo(() => {
    return debounce(() => getMembersList(), 300)
  }, [getMembersList])

  useEffect(() => {
    debouncedMembersList()

    return () => {
      debouncedMembersList.clear()
    }
  }, [debouncedMembersList])

  return (
    <MainLayout header={t('User settings | Members')}>
      <UserSettingsLayout>
        <PageBlock style={{ width: '100%', height: 'auto' }}>
          <PageContainer style={{ width: '100%', height: 'fit-content' }}>
            <MembersBlock/>
          </PageContainer>
        </PageBlock>
      </UserSettingsLayout>
    </MainLayout>
  )
}
