import { t } from 'i18next'

import type { AxiosError, AxiosResponse } from 'axios'

type SyncFacebookCallbackType = () => Promise<AxiosResponse<any> | AxiosError<any>>;

export async function manageFacebookSync(syncFacebookCallback: SyncFacebookCallbackType) {
  const LOCALSTORAGE_KACC_KEITARO_SYNC_TIMESTAMP = 'LOCALSTORAGE_KACC_KEITARO_SYNC_TIMESTAMP'
  const localStorageTimestamp = localStorage.getItem(LOCALSTORAGE_KACC_KEITARO_SYNC_TIMESTAMP)
  const currentTimestamp = new Date().getTime()
  const sixHoursInMillis = 6 * 60 * 60 * 1000

  if (!localStorageTimestamp || new Date(localStorageTimestamp).getTime() <= currentTimestamp - sixHoursInMillis) {
    localStorage.setItem(LOCALSTORAGE_KACC_KEITARO_SYNC_TIMESTAMP, new Date(currentTimestamp).toISOString())

    await syncFacebookCallback()

  } else {
    const remainingTime = Math.ceil(
      (sixHoursInMillis - (currentTimestamp - new Date(localStorageTimestamp).getTime())) / (60 * 1000),
    )
    const hours = Math.floor(remainingTime / 60)
    const minutes = remainingTime % 60

    throw new Error(
      t(
        'kacc:Synchronization with Facebook will be available in {{hours}} hours {{minutes}} minutes!',
        { hours, minutes },
      ),
    )
  }
}