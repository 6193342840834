import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'
import { Switcher } from '../../../shared/ui/switcher'

import { FbStatusFilterType } from './types'

// FbStatusParam query name
export const FbStatusParam = 'fb_banned'

/**
 * FBStatusFilter feature
 * @constructor {JSX.Element}
 */
export function FBStatusFilter() {
  const [search, setSearch] = useSearchParams()
  const { filterActive } = useFilter(FbStatusParam, FbStatusFilterType.false)
  const { offsetActive } = usePagination()

  const getCurrentFilterBoolean = filterActive === FbStatusFilterType.true

  /**
   * Handle ItemClickHandler action
   * @return {void}
   */
  const handleItemClickHandler = (): void => {
    search.set(FbStatusParam, String(!getCurrentFilterBoolean))
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check fb_banned query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive
          || (
            !(filterActive in FbStatusFilterType)
              || filterActive === FbStatusFilterType.false
          )
      ) {
        search.delete(FbStatusParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <SSwitcherWrapper>
      <Switcher
        label={ t('domains-watcher:FB Status') }
        itemClickHandler={ handleItemClickHandler }
        itemActive={ getCurrentFilterBoolean }
        defaultValue={ false }
      />
    </SSwitcherWrapper>
  )
}

const SSwitcherWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
`
