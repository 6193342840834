import React from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Alert, Box, Button, CircularProgress, Link, TextField } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import * as Yup from 'yup'
import { t } from 'i18next'

import { capitalizeFirstLetter } from '../../../shared/lib'
import { registerUserConfirm } from '../../../entities/user'

import type { IRegisterTokenForm } from './types'

export const initialValues: IRegisterTokenForm = {
  password: '',
  passwordConfirm: '',
  token: '',
  submit: null,
}

export function RegisterTokenForm() {
  const params = useParams()
  initialValues.token = params.token as string

  const yupValidationSchema = Yup.object().shape({
    password: Yup.string()
      .max(255)
      .required(t('auth:The password field is required')),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required(t('auth:The password confirm field is required')),
  })

  const formikOnSubmitAction = async (values: IRegisterTokenForm, {
    setErrors,
    setStatus,
    setSubmitting,
  }: FormikHelpers<IRegisterTokenForm>) => {
    try {
      await registerUserConfirm({
        ...(values.token && { token: values.token }),
        ...(values.password && { password: values.password }),
        ...(values.passwordConfirm && { confirm_password: values.passwordConfirm }),
      })

      setStatus({ success: true })
      setSubmitting(false)
    } catch (error: any) {
      setStatus({ success: false })
      setErrors({ submit: Array.isArray(error.response.data.message)
        ? error.response.data.message?.join('|')
        : error.message })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupValidationSchema}
      onSubmit={formikOnSubmitAction}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.submit && touched.password && errors.password)}
            fullWidth
            margin="normal"
            label={t('auth:Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />

          <TextField
            error={Boolean(touched.submit && touched.passwordConfirm && errors.passwordConfirm)}
            fullWidth
            margin="normal"
            label={t('auth:Password confirm')}
            name="passwordConfirm"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.passwordConfirm}
            variant="outlined"
          />

          <Box
            alignItems="center"
            display="flex"
            justifyContent="left"
            style={{
              margin: '1rem 0',
            }}
          >
            <Link
              component={RouterLink}
              to="/signin"
              display="flex"
              alignItems="center">
              <ArrowBackIcon/>
              <b>{t('auth:Back to Sign in page')}</b>
            </Link>
          </Box>

          { touched.submit && !status?.success && Object.keys(errors).length > 0
              && (
                <Alert severity="error">
                  { errors.password && touched.password && <div>
                    { t(`auth:${errors.password}`) } </div>
                  }
                  { errors.passwordConfirm && touched.passwordConfirm && <div>
                    { t(`auth:${errors.passwordConfirm}`) } </div>
                  }
                  { errors.submit && touched.submit && <div
                    style={{
                      display: 'flex', flexDirection: 'column', gap: '1rem',
                    }}>
                    { errors.submit && touched.submit && <div
                      style={{
                        display: 'flex', flexDirection: 'column', gap: '1rem',
                      }}>
                      {errors.submit.includes('|')
                        ? errors.submit?.split('|')?.map((item: string) => (
                          <span key={item}>{t(`auth:${capitalizeFirstLetter(item)}`)}</span>
                        ))
                        : <span>{t(`auth:${errors.submit}`)}</span>}
                    </div>
                    }
                  </div>
                  }
                </Alert>
              )}

          {
            touched.submit && status?.success
              && (
                <Alert severity="success">
                  { touched.submit && <div
                    style={{
                      display: 'flex', flexDirection: 'column', gap: '1rem',
                    }}>
                    <span>{t('auth:Your account is now activated! Please go back to Sign in page!')}</span>
                  </div>
                  }
                </Alert>
              )
          }

          {
            !status?.success && (
              <Button
                sx={{
                  mt: 3,
                }}
                color="primary"
                startIcon={isSubmitting ? <CircularProgress size="1rem"/> : null}
                disabled={isSubmitting}
                type="submit"
                fullWidth
                size="large"
                variant="contained"
              >
                {t('auth:Submit')}
              </Button>
            )
          }
        </form>
      )}
    </Formik>
  )
}
